import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { GlobalElementsService } from './../../../../global-elements/services/global-elements.service';

@Component({
  selector: 'app-delete-channel-dialog',
  templateUrl: './delete-channel-dialog.component.html',
  styleUrls: ['./delete-channel-dialog.component.scss']
})
export class DeleteChannelDialogComponent implements OnInit {
channelId:string;
isDelConfirmed:boolean = false;

message = 'Are you sure you want to delete this channel ?'
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalElementsService: GlobalElementsService,
  ) { }

  ngOnInit() {
    this.channelId = this.data
  }
  deleteChannel(){
    this.isDelConfirmed = true;
    this.message = "Deleting...Please wait.";
    this.globalElementsService.deleteChannel(this.data).subscribe(res => {
      this.message = "Successfully deleted Channel.";      
    }, err => {
      this.message = "Error deleting Channel.";
    })
  }

}
