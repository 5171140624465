import { PAGE_MODES, USER_ACCESS_LEVEL } from '../../../../constants/app-constants';
import { Component, Input, OnDestroy, OnInit, EventEmitter, Output,TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SharedDataService } from 'app/common/services/shared-data.service';
import { GLOBAL_ELEMENTS } from 'app/constants/app-constants';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { GlobalElementsService } from '../../../services/global-elements.service';
import { UserManagementServiceService } from '../user-management-service.service';
import { CreateUserDialogComponent } from '../../../../common/components/dialogs/create-user-dialog/create-user-dialog.component';
import { EditUserDialogComponent } from '../../../../common/components/dialogs/edit-user-dialog/edit-user-dialog.component';
import { userAccessSetupDialogComponent } from '../../../../common/components/dialogs/user-access-setup-dialog/user-access-setup-dialog.component';
import { userAccessSetupDialogMultipleComponent } from '../../../../common/components/dialogs/user-access-setup-dialog-multiple/user-access-setup-dialog-multiple.component';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { MarketService } from 'app/market/services/market.service';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { ConfirmationDialogComponent } from "../../../../common/components/dialogs/confirmation-dialog/confirmation-dialog.component"
import { TreeviewI18n, TreeviewItem, TreeviewConfig, DropdownTreeviewComponent, TreeviewHelper } from 'ngx-treeview';
import { environment } from 'environments/environment';
import ab2b64 from 'ab2b64';
import { getFileBuffer} from 'app/common/utils/common-util';
import { getHeapSpaceStatistics } from 'v8';
const BASE_API_URL = `${environment.api}`;
@Component({
  selector: 'app-bulk-create-user',
  templateUrl: './create-bulk-user.component.html',
  styleUrls: ['./create-bulk-user.component.scss']
})
export class CreateBulkUserComponent implements OnInit, OnDestroy {

  @Input() pageMode;
  @Input() editData;
  @Output() changePageModeEmitter = new EventEmitter
  @Output('dirty')  formDirty = new EventEmitter<boolean>()
  @ViewChild('bulkUserUpload', { static: true }) bulkUserUpload: TemplateRef<any>;
  @ViewChild('Input', {static: false}) myFileInput: ElementRef;
  userForm: FormGroup;
  portfolios: FormArray;
  allMarkets: any = [];
  formSubmitAttempt: boolean;
  allPersonas: any;
  allRoles: any;
  userData: any;
  portfoliosByMarketList = [];
  plansByMarketList = [];
  marketSelectionError = {
    noMarket: false,
    noPortfolio: false,
    noplan:false
  }
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 280
  });
  buttonClass:string = "btn-outline-primary";
  items: TreeviewItem[] = [];
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  usrGpid;
  isEditMode: boolean = false;
  displayName: string = 'Add Bulk User';
  isLoading: boolean = true;
  saveBtnSubscription: Subscription;
  fileTitle: string = 'Choose File'
  //portfolioLevelAccess: boolean = true;
  //brnadLevelAccess: boolean =false;
  displayedColumns: string[] = ['pfName','role','delete'];  
  displayedColumnsplan:  string[] = ['planName','role','delete'];
  base64textString: string;
  type: string;
  selectedPortfolios: any [] =[];
  uploadMessage: string = "";
  popupCloseBtn: boolean = false;
  isError: boolean = false;
  constructor(
    public dialog: MatDialog,
    public fb: FormBuilder,
    private globalElementsService: GlobalElementsService,
    private userManagementService: UserManagementServiceService,
    private sharedData: SharedDataService,
    protected googleTagManagerService: GoogleTagManagerService,
    protected MarketService: MarketService
  ) {

  }

  /*........... Initialization Methods........ */
  ngOnChanges() {
    if(this.base64textString) {
      this.globalElementsService.setSaveEnableState(true);
    }
  }
  ngOnInit() {
    
    this.globalElementsService.setShowSaveBtn(true);
    this.globalElementsService.setSaveEnableState(false);
    this.usrGpid = this.userInfo.gpid;
    //const userMgmtEle = { ...GLOBAL_ELEMENTS.USER_MGMT };
    //userMgmtEle.showSave = true;
    //this.sharedData.setSideNavSelection(userMgmtEle);
    // Get the list of all Markets, initialization of data is depended on the response of this API call.
    this.globalElementsService.getAllMarketsData().subscribe(res => {
      this.allMarkets = res.markets.sort((a, b)=> a.displayName >= b.displayName ? 1 : -1);
      this.initialize();
      //this.allMarkets.forEach(mkt =>   this.getAllPortfolios(mkt));
    });

    this.globalElementsService.getAllMarketsAndPortfolios().subscribe(res => {
      const _data = res["marketsAndPortfolios"] ?  res["marketsAndPortfolios"] : [];

      _data.forEach((el) => {
      const _obj: any = {};
      _obj.text = el.displayName;
      _obj.value = `MkId__${el.id}`;
      if(el.children &&  el.children.length > 0) {
        _obj.children = el.children.map(elp => {
          return { text: elp.displayName, value : elp.id, checked: false}
        })
      this.items.push(new TreeviewItem(_obj));
      }
    })

    });
    //this.isLoading = false;


    this.getAllPersonas();
    this.getAllRole();
   



   this.saveBtnSubscription =  this.globalElementsService.getSidePanelSaveEvent.pipe(take(1)).subscribe(() =>{
      this.displayDialog1();
    });
  }
  displayDialog1(){
    this.uploadMessage = `Please wait... Validating uploaded file`;
    this.popupCloseBtn = false;
    this.isError = false;
    this.dialog.open(this.bulkUserUpload, {
      maxHeight: "600px",
      maxWidth: "400px",
      disableClose: true
    }).afterClosed()
    .subscribe(() => {
      if(!this.isError) {
        this.backToHome();
      }
    });
    this.globalElementsService.bulkUserUpload({Base64String: this.base64textString, type: this.type,selectedPortfolios: this.selectedPortfolios}).subscribe(res => {
      this.isError = false;
      this.popupCloseBtn = true;
      this.myFileInput.nativeElement.value = "";
      this.fileTitle = "Choose File";
      this.uploadMessage = res["message"];
    }, err => {

      this.uploadMessage = err && err.error && err.error.error ? err.error.error : "Something went wrong, please try again later or contact GlobalMSP@pepsico.com.";
      this.isError = true;
      this.popupCloseBtn = true;
      this.myFileInput.nativeElement.value = "";
      this.fileTitle = "Choose File";
    })
  }
  returnRoleName(ele) {
    return ele && ele.roleId && this.allRoles.find(el => el.id == ele.roleId ) ?   this.allRoles.find(el => el.id == ele.roleId ).name.toUpperCase() : ""
  }
  showHidePFOption(_obj, pfId) {
    return _obj.find(el => el.pfId == pfId ) ? false : true;
  }
  // Initialize forms with values, in either create mode or edit mode
  initiateuserForm(userData) {

    this.userForm = this.fb.group({
      gpid: [userData ? userData.gpid : '', Validators.required],
      firstName: [userData ? userData.firstName : '', Validators.required],
      lastName: [userData ? userData.lastName : '', Validators.required],
      email: [userData ? userData.email : '', [Validators.required, Validators.email]],
      roleId: [userData ? userData.roleId : '', Validators.required],
      personaId: [userData ? userData.personaId : '', Validators.required],
      markets: this.fb.array([]),
    });

    this.globalElementsService.setSaveEnableState(false);

    this.userForm.valueChanges.subscribe(() => {
      if (this.userForm.valid) {
        const market = this.userForm.value.markets;
        this.marketSelectionError.noMarket = !market.length;
        if (!this.marketSelectionError.noMarket) {
          this.marketSelectionError.noPortfolio = market.some(el => (el.pfDataSource.data.length == 0 || el.pfDataSource.data.filter(el => el.roleId).length == 0 ) && (el.planDataSource.data.length == 0 || el.planDataSource.data.filter(el => el.roleId).length == 0 ));
        }
        const shouldEnableSave = !this.marketSelectionError.noMarket && !this.marketSelectionError.noPortfolio;
        this.globalElementsService.setSaveEnableState(shouldEnableSave);
      }
      this.formDirty.emit(this.userForm.dirty);

    });

  }

  initialize() {
    this.isLoading = false;
    this.initiateuserForm(null);
    
  }
  
  /*........... functionality Methods........ */

  // Renders dynamic forms of market and portfolio list on UI
  addPorfoliosFormsOfMarket(market, portfoliosList = [], plansList = [], portfolioRoledata = [], planRoleData = []) {
    const { id } = market;
    let defaultPortfolios = [];
    let defaultPlans = [];
    portfoliosList.map(pf => {
      if (pf.isChecked) {
        defaultPortfolios.push(pf.id)
      }
    })
    plansList.map(pln => {
      if (pln.isChecked) {
        defaultPlans.push(pln.id)
      }
    })
    const _options = this.isEditMode ? (defaultPlans.length > 0 ? "brandLevelAccess" : "portfolioLevelAccess" ) : "portfolioLevelAccess";
    const portfolioGroup = this.fb.group({
        marketId: [id, Validators.required],
        portfolioIds: this.fb.array(defaultPortfolios),
        planIds:this.fb.array(defaultPlans),
        options: _options,
        pfDataSource: new MatTableDataSource<any>(),
        planDataSource: new MatTableDataSource<any>(),
        filter1:"",
        planFilter: "",
      });
     
        
        if(_options === USER_ACCESS_LEVEL.BRAND) {
          let datatopass = portfolioRoledata.length === 0 ? plansList.map(el =>  { return { planId: el.id, id: el.id, roleId: null, displayName: el.displayName }}) : portfolioRoledata;
          portfolioGroup.patchValue({"planDataSource": new MatTableDataSource<any>(datatopass), "pfDataSource": new MatTableDataSource<any>([]) });
          (<FormArray>this.userForm.get('markets')).push(portfolioGroup);
        } else {
          let datatopass = portfolioRoledata.length === 0 ? portfoliosList.map(el =>  { return { pfId: el.id, roleId: null, pfName: el.displayName }}) : portfolioRoledata;
          portfolioGroup.patchValue({"pfDataSource": new MatTableDataSource<any>(datatopass), "planDataSource": new MatTableDataSource<any>([])});
          (<FormArray>this.userForm.get('markets')).push(portfolioGroup);
        }
    }
  levelOfAccessChange(marketId, levelOfAccessChange){
    if(levelOfAccessChange === "portfolioLevelAccess") {
      const pfArray = <FormGroup>(<FormArray>this.userForm.get('markets')).at(marketId);
      //const pfArray = <FormArray>(<FormArray>this.userForm.get('markets')).at(marketId).get('pfDataSource');
      const _data = this.portfoliosByMarketList[marketId].portfolios.map(el =>  { return { pfId: el.id, roleId: null, pfName: el.displayName }});
     // const dataSource = [new MatTableDataSource<any>(_data)];
      pfArray.patchValue({"pfDataSource": new MatTableDataSource<any>(_data)});
    } else {
      const pfArray = <FormGroup>(<FormArray>this.userForm.get('markets')).at(marketId);
      //const pfArray = <FormArray>(<FormArray>this.userForm.get('markets')).at(marketId).get('pfDataSource');
      const _data = this.portfoliosByMarketList[marketId].plans.map(el =>  { return { id: el.id, planId: el.id, roleId: null, displayName: el.displayName }});;
     // const dataSource = [new MatTableDataSource<any>(_data)];
      pfArray.patchValue({"planDataSource": new MatTableDataSource<any>(_data)});
      

    }
  }
downloadBulkUserUploadTemplate() {
  const downloadLink = document.createElement("a");
  downloadLink.download = 'BulkUserUploadTemplate.xlsx';
  downloadLink.href = `${BASE_API_URL}/v3/PPT_Templates/BulkUserUploadTemplate.xlsx`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
getUserCurrentSelectedPortfolioAndRoles(marketId) {
  
 
}
onFileSelected(event){
  let fileList: FileList = event.target.files;
  getFileBuffer(fileList[0]).then(async (fileBufferArray: ArrayBuffer) => {
    let fileName =  fileList[0].name;
    this.fileTitle = fileName;
    //let path=`${this.tcpDocData.hierarchy}/${this.year}/`;
    //let Base64String = ab2b64.ab2b64(fileBufferArray);
    this.type = fileList[0].type;
   
    this.base64textString = ab2b64.ab2b64(fileBufferArray);
    if(this.base64textString && this.selectedPortfolios.length > 0) {
      this.globalElementsService.setSaveEnableState(true);
    } else {
      this.globalElementsService.setSaveEnableState(false);
    }
    
})
}
  onSelectedChange(data) {
    this.selectedPortfolios = data;
    if(this.base64textString && this.selectedPortfolios.length > 0) {
      this.globalElementsService.setSaveEnableState(true);
    } else {
      this.globalElementsService.setSaveEnableState(false);
    }
  }
  onFilterChange(data) {
    console.log(data);
  }
  
  updatedRow(_index, currentSelectedPF, currentSelectedRole) {
  if(currentSelectedPF) {
    const pfArray = <FormGroup>(<FormArray>this.userForm.get('markets')).at(_index);
    if(pfArray) {
      let currentDS = pfArray.get("pfDataSource").value ? pfArray.get("pfDataSource").value.data : [];
      let getIndex = currentDS.findIndex(el => el.pfId == currentSelectedPF);

      if(currentDS[getIndex]) {
        currentDS[getIndex].roleId = currentSelectedRole ? currentSelectedRole : null;
        pfArray.patchValue({"pfDataSource": new MatTableDataSource<any>(currentDS)});
        pfArray.patchValue({"filter1": "" });
      }
    }
   } else {
     //Display Error Message
 }
}
updatedRowPlan(_index, currentSelectedPlan, currentSelectedRole) {
  if(currentSelectedPlan) {
    const pfArray = <FormGroup>(<FormArray>this.userForm.get('markets')).at(_index);
    if(pfArray) {
      let currentDS = pfArray.get("planDataSource").value ? pfArray.get("planDataSource").value.data : [];
      let getIndex = currentDS.findIndex(el => el.id == currentSelectedPlan);

      if(currentDS[getIndex]) {
        currentDS[getIndex].roleId = currentSelectedRole ? currentSelectedRole : null;
        pfArray.patchValue({"planDataSource": new MatTableDataSource<any>(currentDS)});
        pfArray.patchValue({"planFilter": "" });
      }
    }
   } else {
     //Display Error Message
 }
}

deleteRowPlan(_index, planId) {
  this.dialog.open(ConfirmationDialogComponent, {
    data: "Are you sure you want to revoke the user access?"
  }).afterClosed()
  .subscribe((_result: Boolean) => {
    if(_result) {
      this.updatedRowPlan(_index, planId, null);
    }
  });
}
deleterow(_index, pfId) {
  this.dialog.open(ConfirmationDialogComponent, {
    data: "Are you sure you want to revoke the user access?"
  }).afterClosed()
  .subscribe((_result: Boolean) => {
    if(_result) {
      this.updatedRow(_index, pfId, null);
    }
  });
}
  /*levelOfAccessChange(selectedValue){
    if(selectedValue === "brnadLevelAccess") {
      this.brnadLevelAccess = true;
      this.portfolioLevelAccess = false;
    } else {
      this.brnadLevelAccess = false;
      this.portfolioLevelAccess = true;
    }
  }*/



  // Gets all portfolios of a martker chosen in the from the market dropdown
  getAllPortfolios(market) {
    let obj = {
      marketId: market.id,
    }

    this.globalElementsService.getAllPortfoliosData(obj).subscribe(async(res: any) => {

      let { portfolios } = res;
      //Disabled the market in dropdown list to prevent the user from clicking on it
      this.disableMarket(market.id, true);

      //In edit mode, the portfolios has to be pre-selected/checked based on the userdata
      let res1 = await this.globalElementsService.getPlansForMarket(obj).toPromise().catch((err)=>{
        return {plans:[]};
      });
      let plans = res1['plans'];
      let resPFData;
      if (this.isEditMode) {
        const userPortfoliosList = this.markAssignedPortfolios(this.editData.markets);
        portfolios.forEach(pf => {
          if (userPortfoliosList.includes(pf.id)) {
            pf.isChecked = true;
          }
        });
        const usePlansList = this.markAssignedPlans(this.editData.markets);
        plans.forEach(pln => {
          if (usePlansList.includes(pln.id)) {
            pln.isChecked = true;
          }
        });
         resPFData = await this.globalElementsService.getAllSelecetdRoleByPF({userId: this.editData.id, marketId: obj.marketId}).toPromise().catch((err)=>{
          return {data:[], userAccessLevel: "portfolioLevelAccess"};
        });
      }

      const portfoliosByMarket = { market, portfolios, plans };
      this.portfoliosByMarketList.push(portfoliosByMarket);
      //this.addPorfoliosFormsOfMarket(market, portfolios, plans, resPFData ? resPFData['data']: [] );
    });
  }
 
openMultiselectPopup(_index, userAccess) {
  const dataSourceValue = <FormArray>(<FormArray>this.userForm.get('markets')).at(_index).get(userAccess  == USER_ACCESS_LEVEL.BRAND ? "planDataSource" : "pfDataSource");
  const dataToPass = dataSourceValue.value && dataSourceValue.value.data ? dataSourceValue.value.data.filter(el => el.roleId ==  null || el.roleId ==  "" || el.roleId == 0) : [];
    let dialogRefnew = this.dialog.open(userAccessSetupDialogMultipleComponent, {
      data: {index: _index, portfolios: dataToPass, allRoles: this.allRoles, plans: dataToPass, userAccess },
      minWidth: '500px',
      maxHeight: '90%',
      disableClose: true
    });

    dialogRefnew.afterClosed().subscribe((res: any) => {
      if(res.cancelBtnClickedSts) {

      } else if(res.resultData){
        const _data = res.resultData.selectedData;
        _data.forEach(el => {
        if(res.resultData.userAccess === USER_ACCESS_LEVEL.BRAND) {
          this.updatedRowPlan(res.resultData.index, el.id, res.resultData.roleId);
        } else {
          this.updatedRow(res.resultData.index, el.pfId, res.resultData.roleId);
        }
      });
      }

    });
  
}
editRow(_index, element, userAccess) {
  const dataSourceValue = <FormArray>(<FormArray>this.userForm.get('markets')).at(_index).get("pfDataSource");
    let dialogRef = this.dialog.open(userAccessSetupDialogComponent, {
      data: {index: _index, datasource: element, portfolios: this.portfoliosByMarketList[_index].portfolios, allRoles: this.allRoles, plans: this.portfoliosByMarketList[_index].plans, userAccess },
      minWidth: '500px',
      maxHeight: '90%',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if(res.cancelBtnClickedSts) {

      } else if(res.resultData){
        if(res.resultData.userAccess === USER_ACCESS_LEVEL.BRAND) {
          this.updatedRowPlan(res.resultData.index, res.resultData.planId, res.resultData.roleId);
        } else {
          this.updatedRow(res.resultData.index, res.resultData.pfId, res.resultData.roleId);
        }
      }

    });
}
getAllPlans(market) {
  let obj = {
    marketId: market.id,
  }

  this.MarketService.getPlansForMarket(obj).subscribe((res: any) => {
    let { plans } = res;
    //Disabled the market in dropdown list to prevent the user from clicking on it
    this.disableMarket(market.id, true);

    //In edit mode, the portfolios has to be pre-selected/checked based on the userdata
    if (this.isEditMode) {
      const usePlansList = this.markAssignedPlans(this.editData.markets);
      plans.forEach(pf => {
        if (usePlansList.includes(pf.id)) {
          pf.isChecked = true;
        }
      });
    }

    const plansByMarket = { market, plans };
    this.plansByMarketList.push(plansByMarket)
    //this.addPorfoliosFormsOfMarket(market, plans);
  });
}
  disableMarket(id: number, isDisabled: boolean) {
    this.allMarkets.forEach(mkt => {
      if (mkt.id == id) {
        mkt.disable = isDisabled;
      }
    });
  }


  //All portfolios of a given market are fetched, 
  // User assigned portfolios are fetched in EDIT mode,
  // this method amerks the user assigned portfolios in allpoerfoltio response obj
  markAssignedPortfolios(portfoliosBymarket) {
    let allUserPortfolios = []
    const portfoioArray = portfoliosBymarket.map(mkts => mkts.portfolios ? mkts.portfolios : []);
    if(portfoioArray.length > 0){
      portfoioArray.map(pf => pf.forEach(el => allUserPortfolios.push(el.id)))
    }
    return allUserPortfolios;
  }
  markAssignedPlans(portfoliosBymarket) {
    let allUserPlans = []
      const plansArray = portfoliosBymarket.map(mkts =>  mkts.plans ?  mkts.plans : [] );
      if(plansArray.length > 0) {
        plansArray.map(pf => pf.forEach(el => allUserPlans.push(el.id)))
      }
      return allUserPlans;
  }


  addNewPortFolio(event: any): void {
    const mkt = event.value;
    const matSelect: MatSelect = event.source;
    matSelect.writeValue(null);
    if (mkt) {
      this.getAllPortfolios(mkt);
      this.getAllPlans(mkt);
    }
  }



  // ........... Form helper Methods..............
  addPortfolioToForm(id, isChecked, key) {
    const pfArray = <FormArray>(<FormArray>this.userForm.get('markets')).at(key).get('portfolioIds');

    if (isChecked) {
      pfArray.push(new FormControl(id));
    } else {
      let idx = pfArray.controls.findIndex(x => x.value == id);
      pfArray.removeAt(idx);
    }
  }
  addPlansToForm(id, isChecked, key) {
    const plnArray = <FormArray>(<FormArray>this.userForm.get('markets')).at(key).get('planIds');

    if (isChecked) {
      plnArray.push(new FormControl(id));
    } else {
      let idx = plnArray.controls.findIndex(x => x.value == id);
      plnArray.removeAt(idx);
    }
  }

  backToHome() {
    this.changePageModeEmitter.emit(PAGE_MODES.HOME)
  }

  isFieldValid(field: string) {
    return (!this.userForm.get(field).valid && this.userForm.get(field).touched) ||
      (this.userForm.get(field).untouched && this.formSubmitAttempt);
  }


  userFromErrorHandeling(control: string, error: string) {
    return this.userForm.controls[control].hasError(error);
  }


  removeMarketnPortfolios(idx) {
    const { market } = this.portfoliosByMarketList[idx];
    //disables the market in MArket Dropdown
    this.disableMarket(market.id, false);

    //Remove from formArray in formBuilder
    (<FormArray>this.userForm.get('markets')).removeAt(idx);

    //Removes selection list, which contains portfoio selection
    
    this.portfoliosByMarketList = this.portfoliosByMarketList.filter((_, elIdx) => elIdx !== idx);

    
  }

  openUserAccessDialog(_index) {
    
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  displayDialog() {
    let userInfo = this.userForm.value;

    const postObj = {
      userInfo: this.userForm.value
    }
    
    let dialogRef;
    postObj.userInfo.markets.forEach((el, _index) => {
      const _dataSource = el.pfDataSource.data ? el.pfDataSource.data.filter((el) => el.roleId) : [];
      postObj.userInfo.markets[_index].pfDataSource = _dataSource;
      const planDS = el.planDataSource.data ? el.planDataSource.data.filter((el) => el.roleId) : [];
      postObj.userInfo.markets[_index].planDataSource = planDS;
    }); 
    if (this.isEditMode) {
      postObj.userInfo.id = this.editData.id;
      dialogRef = this.dialog.open(EditUserDialogComponent, {
        data: postObj,
        minWidth: '500px'
      });
    } else {
      dialogRef = this.dialog.open(CreateUserDialogComponent, {
        data: postObj,
        minWidth: '500px'
      });
    }





    dialogRef.beforeClose().subscribe(() => {
      this.userForm.reset();

      this.backToHome();

    });
  }

 /*........... Service/API calls........ */
  
  //get all personas data
  getAllPersonas() {
    this.globalElementsService.getAllPersonasData().subscribe((res: any) => {
      this.allPersonas = res.personas;
    },
      (error) => {
        console.error('Error: ', error);
      })
  }

  //get all personas data
  getAllRole() {
    this.globalElementsService.getAllRolesData().subscribe((res: any) => {
      this.allRoles = res.roles;
    },
      (error) => {
        console.error('Error: ', error);
      })
  }

 /*........... Clean up methods........ */ 

  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
    this.globalElementsService.setShowSaveBtn(false);
    this.formDirty.emit(false);
  }
  doFilter = (value: string, _index) => {
   const dataSourceFil = <FormArray>(<FormArray>this.userForm.get('markets')).at(_index).get("pfDataSource");
   dataSourceFil.value.filter= value.trim().toLocaleLowerCase();
  }
  doFilterPlan = (value: string, _index) => {
    const dataSourceFil = <FormArray>(<FormArray>this.userForm.get('markets')).at(_index).get("planDataSource");
    dataSourceFil.value.filter= value.trim().toLocaleLowerCase();
   }
}