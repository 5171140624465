// Modules.
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
// Misc.
import { environment } from 'environments/environment';
import { User } from 'app/types';

const BASE_API_URL = `${ environment.api }`;

@Injectable({
    providedIn: 'root'
})
export class AnnualMediaCalendarService {
    userInfo: BehaviorSubject<object>;
    userInfo1: any;

    constructor(
        private http: HttpClient,
    ){};

    public getPortfolioAnnualMediaCalendar(portfolioObj :any) {
        return this.http.post(`${BASE_API_URL}/v3/portfolio/getAnnualMediaCalendar`, portfolioObj)
    }

    public getPlanAnnualMediaCalendar(obj :any) {
        return this.http.post(`${BASE_API_URL}/v3/plan/getAnnualMediaCalendar`, obj)
    }

}
