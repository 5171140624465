import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { updateWidgetValueTypeDialog } from 'app/common/components/dialogs/update-widget-value-type-dialog/update-widget-value-type-dialog.component';
import { IWidgetPlanInfo } from 'app/common/interfaces/plan';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { Subscription } from '../../../../node_modules/rxjs';
import { take } from '../../../../node_modules/rxjs/operators';
import { IBreadcrumb } from './../../common/interfaces/common';
import { BREADCRUMBS_LAST_NODE_TYPES, BREADCRUMBS_REFERENCE_TYPES } from './../../constants/app-constants';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import { number } from '@amcharts/amcharts4/core';


@Component({
  selector: 'app-widget-configuration',
  templateUrl: './widget-configuration.component.html',
  styleUrls: ['./widget-configuration.component.scss']
})
export class widgetConfiguration implements OnInit, OnDestroy {
  
  
  @Output('dirty')  formDirty = new EventEmitter<boolean>()
  
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  planTargetForm: FormGroup;
  targetData: IWidgetPlanInfo ;
  saveBtnSubscription: Subscription;
  pathDetails : IBreadcrumb;
  isFormInitialized: boolean = false;
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private globalElementsService: GlobalElementsService,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }

  /*........... Initialization Methods........ */

  ngOnInit() {
    this.globalElementsService.setShowSaveBtn(this.userInfo.roleId !== 6);
    this.globalElementsService.setSaveEnableState(false);
    //this.targetData=this.elementDetails.planWidgetData;
   // console.log('elementDetails', this.elementDetails);
    this.intiateForm();
    this.initialData();
    this.planTargetForm.valueChanges.subscribe(() => {
      this.globalElementsService.setSaveEnableState(this.planTargetForm.valid);
      this.formDirty.emit(this.planTargetForm.dirty);
    })


    this.saveBtnSubscription = this.globalElementsService.getSidePanelSaveEvent.pipe(take(1)).subscribe(() => this.updateBrandTarget());
    this.userInfo.roleId === 6 ? this.planTargetForm.disable() : null;
  }
  
  intiateForm() {
   /* this.planTargetForm = this.fb.group({
      monthlyTarget: [ Number(0).toFixed(2), [Validators.max(100), Validators.min(0)]],
      quarterlyTarget: [Number(0).toFixed(2), [Validators.max(100), Validators.min(0)]],
      annualTarget: [Number(0).toFixed(2), [Validators.max(100), Validators.min(0)]]
    });*/
    if (!this.isFormInitialized) {
      this.planTargetForm = this.fb.group({
        widgets: this.fb.array([]),
      });
      this.isFormInitialized = true;
    }
    
  }
  widgets(): FormArray {
    return this.planTargetForm.get("widgets") as FormArray
  }
initialData() {
  this.globalElementsService.getMasterWidgetList().subscribe((res) => {
    if(res) {
      const _data = res.data;
      for(let key in  _data) {
        let newWidgetForm = this.newWidget();
        newWidgetForm.patchValue({
          name: _data[key].widgetName,
          displayName: _data[key].widgetDisplayName,
          options: _data[key].widgetValueType
        });
        this.widgets().push(newWidgetForm);
      }
    }
  });
}
  /*........... functionality Methods........ */
  newWidget(): FormGroup {
    return this.fb.group({
      displayName:[],
      name: ['', Validators.required],
      options:  [],
    })
  }
  updateBrandTarget() {
    let dialogRef;
    dialogRef = this.dialog.open(updateWidgetValueTypeDialog, {
      data: this.constructBrandTargetObj(),
      minWidth: '500px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.globalElementsService.setSidePanelCancelEvent();
      this.dialog.closeAll();
    });
  }
  widgetValueTypeChange (_index, _value) {
    console.log(_index);
    console.log(_value);
  }
  constructBrandTargetObj() {
    const formData = this.planTargetForm.value;
    let targetObj = {
      //planId: this.targetData.planId,
      //year: this.targetData.year,
      //widgetId: this.targetData.widgetId,
      //widgetName: this.targetData.widgetName,
      updateData: formData.widgets
    }
    return targetObj;
  }
  
  /*........... Clean up methods........ */
 
  ngOnDestroy(){
    this.saveBtnSubscription.unsubscribe();
    this.globalElementsService.setShowSaveBtn(false);
    this.formDirty.emit(false);
  }
}
