import { BrandManagementDialogsComponent } from './../../../../common/components/dialogs/brand-management-dialogs/brand-management-dialogs.component';
import { MatDialog } from '@angular/material/dialog';
import { Component, EventEmitter, OnInit, Output, OnChanges, Input, OnDestroy } from '@angular/core';
import { IMarketGroup } from '../../../../common/interfaces/brandManagement';
import { BrandManagementService } from '../../../services/brand-management.service';
import { PAGE_MODES } from './../../../../constants/app-constants';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';

@Component({
  selector: 'app-homepage-market-group',
  templateUrl: './homepage-market-group.component.html',
  styleUrls: ['./homepage-market-group.component.scss']
})
export class HomepageMarketGroupComponent implements OnInit, OnChanges, OnDestroy {

  @Output() marketGroupSelectionEvent = new EventEmitter();
  @Output() setHomePageModeEvent = new EventEmitter();
  @Input() pageMode;

  marketGroups: Array<IMarketGroup> = [];
  selectedMarketList = [];
  isLoading = true;
  errorLoading = false;

  constructor(
    public dialog: MatDialog,
    private brandManagementService: BrandManagementService,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }

  ngOnInit() {
    this.getMarketGroups();
    this.marketGroupSelectionEvent.emit([]);
  }

  ngOnChanges() {
    if (this.pageMode == PAGE_MODES.DELETE) {
      this.handleDelete()
    }
  }

  isMarketGroupSelected(mktId) {
    return this.selectedMarketList.includes(mktId)
  }


  selectMarketGroup(mktId) {

    if (this.selectedMarketList.includes(mktId)) {
      this.selectedMarketList = this.selectedMarketList.filter(el => el != mktId)
    } else {
      this.selectedMarketList.push(mktId);
    }

    const marketGroupsList = this.marketGroups.filter(mkt => this.selectedMarketList.includes(mkt.id))
    this.marketGroupSelectionEvent.emit(marketGroupsList);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Market_Group_Management`,
      eventAction: "PageView",
      eventLable: `Global Elements Market Group Management Page view`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }


  handleDelete() {

    const dialogRef = this.dialog.open(BrandManagementDialogsComponent, {
      data: {
        type: 'delete',
        module: 'marketGroup',
        apiData: {
          marketGroups: this.selectedMarketList
        }
      },

      minWidth: '500px'
    })


    dialogRef.beforeClose().subscribe(() => {
      this.selectedMarketList = [];
      this.getMarketGroups();
      this.marketGroupSelectionEvent.emit([]);
      this.setHomePageModeEvent.emit();

    });

  }


  getMarketGroups() {
    this.isLoading = true;
    this.errorLoading = false;
    this.brandManagementService.getAllMarketGroups().subscribe((res: Array<IMarketGroup>) => {
      this.marketGroups = res;
      this.isLoading = false;
      this.errorLoading = false;
    }, () => this.errorLoading = true);
  }
  ngOnDestroy(): void {
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Market_Group_Management`,
      eventAction: "Market Group Management Page Exit",
      eventLable: `Global Elements Market Group Management Page Exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }


}
