/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { templateSettings, template, isNil } from 'lodash-es';
import { environment } from '../../../../../environments/environment';
import {
  IHttpApiAppEnvConfig
} from '../config/interface/ihttp-api-app-env-config';
import { IHttpApiConfig } from '../config/interface/ihttp-api-config';
import { IHttpApiRequest } from '../interface/ihttp-api-request';
import { IHttpApiRequestOptions } from '../interface/ihttp-api-request-options';

@Injectable({
  providedIn: 'root'
})
export class HttpApiHelperService {

  constructor() {
    // use custom delimiter {{ }}
    templateSettings.interpolate = /{{([\s\S]+?)}}/g;
  }
  /**
   * @name getUrl
   * @description method to get Url
   * @param {IHttpApiRequest} request
   * @returns {string} - url
   */
  public getUrl(request: IHttpApiRequest): string {
    let url = '';
    const activeConfig = this.getEnvConfig();
    if (request.config.useMockData ) {
      url = request.config.mockDataPath;
    } else {
      // live webservice
      const baseUrl = this.getBaseUrl(request, activeConfig);
      const urlSuffix = request.urlTemplate
        ? this.getURLResourceParam(request)
        : '';
      url = baseUrl + request.config.resource + urlSuffix;
    }
    return url;
  }
  /**
   * @name getURLResourceParam
   * @description method to getURLResourceParam
   * @param {IHttpApiRequest} request
   * @returns {string} result
   */
  private getURLResourceParam(request: IHttpApiRequest) {
    // interpolate
    const jsonInterpolation = template(request.urlTemplate);
    return jsonInterpolation(request.args);
  }
  /**
   * @name getBaseUrl
   * @description method to get BaseUrl
   * @param {IHttpApiRequest} request
   * @param {IHttpApiAppEnvConfig} activeConfig
   * @returns {string} - BaseUrl
   */
  public getBaseUrl(
    request: IHttpApiRequest,
    activeConfig: IHttpApiAppEnvConfig
  ) {
    let baseUrl = '';
    if (request.config.apiUrl) {
      baseUrl = request.config.apiUrl;
    } else {
      const group = request.config.group || '';
      let overriden = false;
      baseUrl = overriden ? baseUrl :activeConfig.api;
    }
    return baseUrl;
  }
  /**
   * @name getEnvConfig
   * @description method to get EnvConfig
   * @returns {IHttpApiAppEnvConfig} - result obj
   */
  public getEnvConfig(): IHttpApiAppEnvConfig {
    // return the first option if none of the above condition satisfies
    return environment;
  }

  /**
   * @name isMock
   * @description method to return isMock flag
   * @param {IHttpApiRequest} request
   * @returns {boolean} - result
   */
  public isMock(request: IHttpApiRequest) {
   // const config = this.httpApiConfigService.getConfiguration();
    const offline: boolean = request.config.useMockData;
    return offline;
  }

  public getCurrentEndpoint(request: IHttpApiRequest) {
    return request.config;
  }

  /**
   * @name getHttpOptions
   * @description method to getHttpOptions
   * @param {IHttpApiRequest} request
   * @returns {IHttpApiRequestOptions} - result
   */
  public getHttpOptions(request: IHttpApiRequest): IHttpApiRequestOptions {
    const options: IHttpApiRequestOptions = {};
    const currentEndpoint: IHttpApiConfig = this.getCurrentEndpoint(request);
    let headersPassed: Record<string, string> = {};
    // pass responseType if provided
    if (currentEndpoint && currentEndpoint.responseType) {
      options['responseType'] = currentEndpoint.responseType as 'json';
    }
    if (currentEndpoint && currentEndpoint.headers) {
      headersPassed = currentEndpoint.headers;
    }
    headersPassed = {
      ...headersPassed,
      ...{
        noCache: !isNil(currentEndpoint.noCache)
          ? '' + currentEndpoint.noCache
          : 'false',
        useMockData: !isNil(currentEndpoint.useMockData)
          ? '' + currentEndpoint.useMockData
          : 'false',
        showApiLoader: !isNil(currentEndpoint.showApiLoader)
          ? '' + currentEndpoint.showApiLoader
          : 'true',
        showApiTimeLog: !isNil(currentEndpoint.showApiTimeLog)
          ? '' + currentEndpoint.showApiTimeLog
          : 'true',
        showApiErrorDialog: !isNil(currentEndpoint.showApiErrorDialog)
          ? '' + currentEndpoint.showApiErrorDialog
          : 'true'
      }
    };
    // add headers from active configuration provided in apiConfig

    const headers = new HttpHeaders(headersPassed);
    console.log('HttpHeaders headers', headers);
    options['headers'] = headers;
    options['observe'] = 'response' as 'body';
    return options;
  }
}

