export const CHART_MONTHS = [
  {
    name: 'Jan',
    accesor: 'monthJan'
  },
  {
    name: 'Feb',
    accesor: 'monthFeb'
  },
  {
    name: 'Mar',
    accesor: 'monthMar'
  },
  {
    name: 'Apr',
    accesor: 'monthApr'
  },
  {
    name: 'May',
    accesor: 'monthMay'
  },
  {
    name: 'Jun',
    accesor: 'monthJun'
  },
  {
    name: 'Jul',
    accesor: 'monthJul'
  },
  {
    name: 'Aug',
    accesor: 'monthAug'
  },
  {
    name: 'Sep',
    accesor: 'monthSep'
  },
  {
    name: 'Oct',
    accesor: 'monthOct'
  },
  {
    name: 'Nov',
    accesor: 'monthNov'
  },
  {
    name: 'Dec',
    accesor: 'monthDec'
  }

]
export const CHART_QUARTER = [
  {
    name: 'Q1',
    accesor: 'quarterOne'
  },
  {
    name: 'Q2',
    accesor: 'quarterTwo'
  },
  {
    name: 'Q3',
    accesor: 'quarterThree'
  },
  {
    name: 'Q4',
    accesor: 'quarterFour'
  }
]
export const CHART_PERIODS = {
  monthly:'Monthly',
  quaterly:'Quaterly'
}

export const TRENDS_CHART_LEGENDS = {
  PSPN:'PSPN',
  ASPN:'ASPN',
  PDRO:'PDRO',
  AROI:'AROI',
}
export const WIDGET_CHART_LEGENDS = {
  PWGT:'PWGT',
  AWGT:'AWGT'
}

export const CHART_SERIES={
  pspn_bar:'pspn_bar',
  aspn_bar:'aspn_bar',
  prevy_bar:'prevy_bar',
  pdro_line:'pdro_line',
  aroi_line:'aroi_line',
  prevy_line:'prevy_line'
}

export const WIDGETS_TYPES=["Market Share", "Share of Voice", "LRB Share of Market", "Food Share of Market", "Reach", "Frequency", "Working Media / Net Revenue"]; 

export const TREND_CHART_LEGENDS={
  PLANNED_BUDGET:'PLANNED BUDGET',
  ACTUAL_BUDGET:'ACTUAL BUDGET',
  PDR_OBJECTIVE:'PDR OBJECTIVE',
  ACTUAL_ROI:'ACTUAL ROI',
  FY_ACTUAL:'FY’#MinifiedPrevYear# ACTUAL'
}