// 'PLN'   || 'CUR' -> 'Draft'
// 'APR:A' || 'REQ' -> 'Submitted'

import { RevenueType } from './types';

// 'APR'            -> 'Approved'
export const StatusConstants = {
  Current: 'CUR',
  Dead: 'DED',
  Hold: 'ONH',
  Planning: 'PLN',
  Complete: 'CPL',    // Project and Task status
  Requested: 'REQ',
  RequestedA: 'REQ:A',
  Approved: 'APR',    // Project and Task status
  ApprovedA: 'APR:A',
  Rejected: 'REJ',
  Idea: 'IDA',
  Queue: 'QUE',
  Optimizing: 'OPT:P',
  Optimized: 'OPT:C',
  New: 'NEW',         // Task status
  InProgress: 'INP',  // Task status
};

export const TCP_BRIEF_CONSTANTS = {
  STEP_NAMES: {
    STEP_1: 'Details and Objectives',
    STEP_2: 'Opportunity',
    STEP_3: 'Measurement',
  },
};

export const REVENUE_TYPES: { [key: string]: RevenueType } = {
  REVENUE_ROI: 'roi',
  NET_REVENUE_ROI: 'roiNet',
  GROSS_PROFIT_MARGIN_ROI: 'roiGPM',
}
