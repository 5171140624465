import { ScenarioPlanningDialogComponent } from './../../common/components/dialogs/scenario-planning-dialog/scenario-planning-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { PlanService } from './../services/plan.service';
import { GLOBAL_ELEMENTS, STATUS_LIST, OPTIMIZE_STATUS, STATUS_LIST_TYPES, BREADCRUMBS_REFERENCE_TYPES, BREADCRUMBS_LAST_NODE_TYPES,FINANCIAL_VALUES } from './../../constants/app-constants';
import { SharedDataService } from 'app/common/services/shared-data.service';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource, MatDialog } from '../../../../node_modules/@angular/material';
import * as moment from 'moment';
import { IUpfrontPlan, IScenarioChannel } from 'app/common/interfaces/plan';
import { IBreadcrumb } from '../../common/interfaces/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId, isValidUserAccess } from 'app/common/utils/common-util';
import { StoreService } from '../../old/_services/_store.service';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';
import { OptimizerMessageDialogComponent } from 'app/common/components/dialogs/optimizer-message-dialog/optimizer-message-dialog.component';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { ANALYTICS_TYPE} from '../../constants/app-constants';

@Component({
  selector: 'app-scenario-planning',
  templateUrl: './scenario-planning.component.html',
  styleUrls: ['./scenario-planning.component.scss']
})
export class ScenarioPlanningComponent implements OnInit {

  userInfo = JSON.parse(sessionStorage.getItem('user'));
  attach_money = sessionStorage.getItem('currencySign');
  brand: string;
  dataSource = [];//new MatTableDataSource<any>();
  scenarios = [];
  scenarioChannelData: Array<IScenarioChannel>;
  displayedColumns: string[] = ['channelName', 'cWMBudget', 'cpp', "weeksOnAir", "ROI", "effectiveness", "revenue", "channelLock"];
  paramsId: number;
  brandName: string;
  planId : string;
  planningYear: number;
  upfrontPlanData: IUpfrontPlan;
  isOvwBudgetEditMode: boolean = false;
  isOvwMetricsEditMode: boolean = false;
  owBudgetVal;
  owROIVal;
  owEffectivenessVal;
  plannedBudget: number = 0;
  plannedROI: number = 0;
  plannedEffectiveness: number = 0;
  selectedScenarioIdx = null;
  totalDiff: number;
  roiDiff: number;
  efftvnessDiff: number;
  scenarioStatusList: Array<string> = STATUS_LIST;
  optimizingStatus = OPTIMIZE_STATUS;
  statusListType = STATUS_LIST_TYPES;
  pathDetails: IBreadcrumb;
  isBaseLineScenario: boolean = false;
  diffOfROI: number = 0;
  diffOfEff: number = 0;
  financialValue:string = '';
  isBrandAvailable: boolean = false;
  enableOptimize:boolean = false;
  constructor(
    private sharedData: SharedDataService,
    private planService: PlanService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    protected googleTagManagerService: GoogleTagManagerService,
    private store: StoreService,
    private globalElementsService: GlobalElementsService,
  ) { }

  /*........... Initialization Methods........ */

  ngOnInit() {
    this.sharedData.getPlanningYear.subscribe((res: any) => {
      if (res) {
        this.planningYear = res;
        this.getScenarioDetails();
      }
    })

    this.route.params.subscribe(params => {
      this.paramsId = params['id'];
      const year = params['year'];
      if(year && parseInt(year)) {
        this.store.set('planningYear', parseInt(year));
        this.sharedData.setPlanningYear(parseInt(year));
        this.planningYear = parseInt(year);
      }
      this.getScenarioDetails();

    });


    this.sharedData.sideNavSelection.subscribe((res: any) => {
      if (res && res.id == 0) {
        this.getScenarioDetails();
      }
    });
    this.getFinancialValueFromPortfolioForSubBrand();
  }

  /*........... functionality Methods........ */

  getFormattedDate(dateString) {
    return moment(dateString).isValid() ? moment(dateString).format('lll') : 'N/A';

  }
  getFinancialValueFromPortfolioForSubBrand(){
    const postObj = {
      planEntityId: this.paramsId,
      year: this.planningYear
    }
    this.planService.getFinancialValueFromPortfolioForSubBrand(postObj).subscribe(response => {
      const fValue = response['portfolioData']['financialValue'];
      this.financialValue = FINANCIAL_VALUES[fValue];
    })
  }

  displayChart(data: any, scenarios: any) {
    let sidePanelEle = { ...GLOBAL_ELEMENTS.SCENARIO_PLOT };
    const displayName = sidePanelEle.displayName.replace('##brandName##', this.brandName);
    const chartData = {
      activeScenario: data.filteredData,
      scenario: scenarios,
      year: this.planningYear,
      planEntityId: this.paramsId,
    }
    sidePanelEle.displayName = displayName;
    sidePanelEle.chartData = chartData;
    this.sharedData.setSideNavSelection(sidePanelEle);

  }


  selectedScenario(scenatioDetails, scenarioIdx) {


    this.selectedScenarioIdx = scenarioIdx;
    this.plannedBudget = scenatioDetails.aWMBudget;
    this.plannedEffectiveness = scenatioDetails.plannedEffectiveness;
    this.plannedROI = scenatioDetails.plannedROI;
    this.getScenarioDetails();


  }



  createNewScenario() {
    let sidePanelEle = { ...GLOBAL_ELEMENTS.NEW_SCENARIO };
    this.openSidePanel(sidePanelEle);
    let tagObject =  {
        event: `custom_${environment.environmentName}_Event`,
              eventCategory: `Scenario_Planning`,
              eventAction: "Add New Scenario",
              eventLable: `Upfront Plan New Scenario Button clicked`,
              customeEvent: `custom_${environment.environmentName}_Event`,
              userId: getGpId(),
              user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }


  editScenario(scenarioDetails) {
    scenarioDetails.channels[0].cWMBudget = Number(scenarioDetails.channels[0].cWMBudget).toLocaleString();
    scenarioDetails.channels[0].ROI = Number(scenarioDetails.channels[0].ROI).toLocaleString();
    let sidePanelEle = { ...GLOBAL_ELEMENTS.NEW_SCENARIO };
    sidePanelEle['payload'] = scenarioDetails;
    this.openSidePanel(sidePanelEle);
    let tagObject =  {
        event: `custom_${environment.environmentName}_Event`,
              eventCategory: `Scenario_Planning`,
              eventAction: "Edit Scenarios",
              eventLable: `Upfront Plan Edit Scenario Button clicked`,
              customeEvent: `custom_${environment.environmentName}_Event`,
              userId: getGpId(),
              user_id: getGpId()
    }
      googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  openSidePanel(sidePanelEle) {
    const displayName = sidePanelEle.displayName.replace('##brandName##', this.brandName);
    sidePanelEle.displayName = displayName;
    sidePanelEle.brandName = this.brandName;
    sidePanelEle['planEntityId'] = this.paramsId;
    this.sharedData.setSideNavSelection(sidePanelEle);
  }

  deleteScenario(scenarioDetails) {
    const scenarioId = scenarioDetails.scenario.scenarioId;
    const delDialogRef = this.dialog.open(ScenarioPlanningDialogComponent, {
      data: {
        type: 'delete',
        module: 'upfront',
        element: 'scenario',
        apiData: scenarioId
      },
      minWidth: '500px'
    })

    delDialogRef.afterClosed().subscribe(() => this.getScenarioDetails());
  }

  updateUfPlanStatus() {
    const upfrontData = this.constructUpfrontPostObj('');
    const dialogRef = this.dialog.open(ScenarioPlanningDialogComponent, {
      data: {
        type: 'update',
        module: 'upfront',
        element: 'ufPlanStatus',
        apiData: upfrontData
      },
      minWidth: '500px'
    })
    dialogRef.afterClosed().subscribe(() => {
      this.getScenarioDetails();
    });

  }

  updateScenarioStatus(scenario) {

    const scenarioData = this.constructScenarioStatusPostObj(scenario);

    const dialogRef = this.dialog.open(ScenarioPlanningDialogComponent, {
      data: {
        type: 'update',
        module: 'upfront',
        element: 'scenarioStatus',
        apiData: scenarioData
      },
      minWidth: '500px'
    })
    dialogRef.afterClosed().subscribe(() => {
      this.getScenarioDetails();
    });

  }

  createBaseLineScenario() {
    const postObj = {
      planEntityId: this.paramsId,
      year: this.planningYear
    };
    const totalBudget = this.upfrontPlanData.aBudgetInput && Number(this.upfrontPlanData.aBudgetInput);

    const dialogRef = this.dialog.open(ScenarioPlanningDialogComponent, {
      data: {
        type: 'save',
        module: 'ufPlanScenario',
        element: 'scenarioBaseline',
        isTotalBudgetEmpty: !totalBudget,
        apiData: postObj
      },
      minWidth: '500px'
    })
    dialogRef.afterClosed().subscribe(() => this.getScenarioDetails());
  }

  /*........... Service/API calls........ */

  getScenarioDetails() {
  const postObj = {
      planEntityId: this.paramsId,
      year: this.planningYear
    };
    this.planService.getScenarioDetailsBySubBrand(postObj).subscribe((res: any) => {
  
      this.scenarioChannelData = res.scenarioChannelData ? res.scenarioChannelData : [];
      this.scenarioChannelData.map((el) =>{
        const modalChannelCount = el.channels.filter((chnl) => chnl.channelId && chnl.channelType == 'DATA');
        this.enableOptimize = modalChannelCount && modalChannelCount.length >=2;
        console.log(this.enableOptimize);
        el['enableOptimize'] = this.enableOptimize;
        
      })
      this.brandName = res.displayName;
      if(this.brandName) this.isBrandAvailable=true;
      this.planId = res.planId ? res.planId : null;
      this.upfrontPlanData = res.upfrontPlanData ?  res.upfrontPlanData : {} ;
      this.pathDetails = {
        lastNodeName: this.brandName,
        upfrontScenrioName: `${this.planningYear} Upfront Scenario`,
        pageId: this.paramsId,
        pageRefType: BREADCRUMBS_REFERENCE_TYPES.PLAN_ENTITY,
        lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.SCENARIO
      }

      this.owBudgetVal = this.upfrontPlanData.aBudgetInput ? Number(this.upfrontPlanData.aBudgetInput).toLocaleString(): null;
       this.owROIVal = this.upfrontPlanData.estROI ? Number(this.upfrontPlanData.estROI).toLocaleString() : null;
      this.owEffectivenessVal = this.upfrontPlanData.estEffectiveness ? Number(this.upfrontPlanData.estEffectiveness).toLocaleString() : null;
      /*let orderedScenarios = []
      for (let [key, value] of Object.entries(STATUS_LIST_TYPES)) {
        orderedScenarios = [...orderedScenarios, ...this.scenarioChannelData.filter(x => x.scenario.scenarioStatus == value)]
      }
      this.scenarioChannelData = orderedScenarios;*/
      this.scenarios = [];
      this.scenarioChannelData.forEach((scd, idx) => {
        if (!scd.scenario) {
          scd.scenario = { scenarioStatus: "" };
        }
        this.scenarios[idx] = scd.scenario;
        const channels = scd.channels.map(el => { 
          el.channelType === 'CUSTOM' ? el.cpp = 0 : el.cpp = Math.round(el.cpp);
          el.channelType === 'CUSTOM' ? el.effectiveness = null : el.effectiveness;
           return el;
        })
        this.dataSource[idx] = new MatTableDataSource<any>(channels);
      });
      this.diffOfROI = Number(this.plannedROI- this.owROIVal)/this.owROIVal;
      this.diffOfEff = Number(this.plannedEffectiveness- this.owEffectivenessVal)/this.owEffectivenessVal;
  
      if (!isFinite(this.diffOfROI)) {
  
        this.diffOfROI = null;
      }
      if (!isFinite(this.diffOfEff)) {
  
        this.diffOfEff = null;
      }
    })
    
  }

  submitOptimze(optimizeObj) {
    this.planService.optimizeScenario(optimizeObj).subscribe(res => {
      this.globalElementsService.setSidePanelCancelEvent();
    })
  }
  optimizeScenario(scenario) {
    console.log(scenario);
    const dialogRef = this.dialog.open(OptimizerMessageDialogComponent, {
      minWidth: '500px'
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      const optObj = this.populateOptimizeScenarioObj(scenario);
      this.submitOptimze(optObj);
      setTimeout(() => {
        this.getScenarioDetails();
      },1000)
    });

  }
  populateOptimizeScenarioObj(scenario) {
    const postObj = {
      scenarioId: scenario.scenarioId,
      scenarioName: scenario.scenarioName,
      scenarioType: ANALYTICS_TYPE.UPFRONT,
      optimizedByUserId: this.userInfo.id
    }
    return postObj;
  }
  
  updateOverviewBudget() {
    if (this.isOvwBudgetEditMode) {
      const upfrontData = this.constructUpfrontPostObj('budget');
      this.planService.updateUpfrontPlan(upfrontData).subscribe(() => this.getScenarioDetails())
    }
    this.isOvwBudgetEditMode = !this.isOvwBudgetEditMode;
  }


  updateOverviewMetrics() {

    if (this.isOvwMetricsEditMode) {
      const upfrontData = this.constructUpfrontPostObj('');
      this.planService.updateUpfrontPlan(upfrontData).subscribe(() => this.getScenarioDetails());
    }
    this.isOvwMetricsEditMode = !this.isOvwMetricsEditMode;
  }

  duplicateScenario(scenarioDetails) {
    const scenarioId = scenarioDetails.scenario.scenarioId;
    const userId = this.userInfo.id;

    const newScenarioBase = {
      planEntityId: this.paramsId,
      scenarioId,
      year: this.planningYear,
      userId: userId
    }

    this.planService.duplicateScenario(newScenarioBase).subscribe(() => this.getScenarioDetails());
  }


  constructUpfrontPostObj(type: string) {

    const isBudgetUpdate = type == 'budget';

    const upfrontData = {

      upfrontPlanId: this.upfrontPlanData.ufPlanId,
      upfPlanInfo: {
        estROI: !isBudgetUpdate ? (this.owROIVal == null) ? null : parseFloat(this.owROIVal.toString().replace(/,/g, '')) : (this.upfrontPlanData.estROI == null) ? null : parseFloat(this.upfrontPlanData.estROI.toString().replace(/,/g, '')),
        estEffectiveness: !isBudgetUpdate ? (this.owEffectivenessVal == null) ? null : parseFloat(this.owEffectivenessVal.toString().replace(/,/g, '')) : (this.upfrontPlanData.estEffectiveness == null ) ? null : parseFloat(this.upfrontPlanData.estEffectiveness.toString().replace(/,/g, '')),
        aBudgetInput: isBudgetUpdate ? (this.owBudgetVal == null) ? null : parseFloat(this.owBudgetVal.toString().replace(/,/g, '')) : (this.upfrontPlanData.aBudgetInput == null) ? null : parseFloat(this.upfrontPlanData.aBudgetInput.toString().replace(/,/g, '')),
        ufPlanStatus: this.upfrontPlanData.ufPlanStatus || null,
        year: this.planningYear,
        planEntId: this.upfrontPlanData.planEntId
      }
    }



    return upfrontData;

  }

  constructScenarioStatusPostObj(scenario) {
    const scenarioData = {
      planEntityId: this.upfrontPlanData.planEntId,
      upfrontPlanId: this.upfrontPlanData.ufPlanId,
      scenarioInfo: {
        scenarioId: scenario.scenarioId,
        scenarioName: scenario.scenarioName,
        scenarioType: scenario.scenarioType,
        scenarioStatus: scenario.scenarioStatus || null
      }

    }
    return scenarioData;
  }
  CommaFormatted(event) {
    if(event.which >= 37 && event.which <= 40) return;
   
    if (event.target.value) {
      event.target.value = event.target.value.replace(/\D/g, "")
       .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
   
   numberCheck (args) {
   if (args.key === 'e' || args.key === '+' || args.key === '-') {
     return false;
   } else {
     return true;
   }
  }

  CalculateROI(event) {   
    if (event.target.value) {
      let a = event.target.value.split(".");
      a[0] = a[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.owROIVal = a.join(".");
    }
  }
  CalculateEffectiveness(event) {   
    if (event.target.value) {
      let a = event.target.value.split(".");
      a[0] = a[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.owEffectivenessVal = a.join(".");
    }
  }
  CalculateBudget(event) {   
    if (event.target.value) {
      let a = event.target.value.split(".");
      a[0] = a[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.owBudgetVal = a.join(".");
    }
  }
returnAccess(_oprator, conditionRoleId) {
  return isValidUserAccess(conditionRoleId, _oprator, null, this.planId)
}


}