import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { CommonDialogToShowNotificationComponent } from '../../../../common/components/dialogs/common-dailog-to-show-notification/common-dailog-to-show-notification';
import { PAGE_MODES } from '../../../../constants/app-constants';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';


@Component({
  selector: 'app-enhance-tooltip-list',
  templateUrl: './add-tooltip.component.html',
  styleUrls: ['./add-tooltip.component.scss']
})
export class EnhanceTooltipListComponent implements OnInit, OnDestroy {

  @Input() pageMode;
  @Input() editData;
  @Input() marketList;
  @Output() tooltipCatSelctionEvent = new EventEmitter();
  @Output() changePageModeEmitter = new EventEmitter
  userInfo = JSON.parse(sessionStorage.getItem('user'));


  tooltipForm: FormGroup;
  //tooltipCatCode: string;
  //tooltipCategoryName: string;
  isEditMode:boolean = false;
  mdmMarkets = [];
  saveBtnSubscription: Subscription;
  currencyOptions: Array<object>;
  textFeild: string = 'displayName';
  valueFeild: string = 'id';
  //marketList:any = [];
  selctedData:any = [];
  tooltipCatData = [];
  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    private globalElementsService: GlobalElementsService,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }




  /*........... Initialization Methods........ */

  ngOnInit() {
   //console.log('currencyToSymbolMap', currencyToSymbolMap);
    this.isEditMode = this.pageMode === PAGE_MODES.EDIT; 
    this.getTooltipCategories();
    this.globalElementsService.setShowSaveBtn(this.userInfo.roleId !==6);
    this.globalElementsService.setSaveEnableState(false);
    this.iniitializetooltipForm();
    this.tooltipForm.valueChanges.subscribe(() => {
      this.globalElementsService.setSaveEnableState(this.tooltipForm.valid);
    })

    this.saveBtnSubscription = this.globalElementsService.getSidePanelSaveEvent.pipe(take(1)).subscribe(() => this.onSubmit());
    this.userInfo.roleId === 6 ? this.tooltipForm.disable() : null;
  }



  iniitializetooltipForm(): void {
    this.tooltipForm = this.fb.group({
      tooltipCatCode: ['', Validators.required],
      tooltipText: ['', [Validators.required, Validators.minLength(4)]],
      selectedMarket: [Array, Validators.required],
      tooltipCatData:  [[]]
    })
    if(this.isEditMode && this.editData) {
      this.tooltipForm.patchValue({
        tooltipCatCode: this.editData.tooltipCatCode,
        tooltipText: this.editData.tooltipText,

      });
      this.selctedData = this.editData.selectedMarkets ? this.editData.selectedMarkets : [];
      this.tooltipForm.get("selectedMarket").patchValue(this.selctedData);
    }
  }



  /*........... funcaitonality Methods........ */


  tooltipFormErrorHandeling(control: string, error: string): boolean {
    return this.tooltipForm.controls[control].hasError(error);
  }

  backToHome() {
    this.changePageModeEmitter.emit(PAGE_MODES.HOME)
  }
  alphaNumberOnly (e) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^[a-zA-Z_-]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }

    e.preventDefault();
    return false;
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }

  onSubmit(): void {

    const dialogRef = this.dialog.open(CommonDialogToShowNotificationComponent, {
      data: {
        type: 'save',
        modulenm: 'tooltip',
        apiData: this.constructPostObj(),
        deafultMsg: 'Saving Tooltip.'
      },
      minWidth: '500px'
    })

    dialogRef.afterClosed().subscribe(res => {
      this.backToHome();
    });
  }

  getTooltipCategories() {
    this.globalElementsService.getTooltipCatData().subscribe((res:any) => {
      this.tooltipCatData = res.data;
      this.tooltipForm.get("tooltipCatData").patchValue(this.tooltipCatData);
    });

  }

  constructPostObj(): any {

    const {  tooltipCatCode, tooltipText, selectedMarket  } = this.tooltipForm.value;
    let postObj  = {
      tooltipCatCode: tooltipCatCode,
      tooltipText: tooltipText,
      marketList: selectedMarket
    };

    return postObj;
  }
  sendData(){
    return this.marketList;
  }
  sendSelectedData(){
    return this.selctedData;
  }
   selectChange = (event: any) => {
     this.selctedData = event.data;
     this.tooltipForm.get("selectedMarket").patchValue(this.selctedData);
   };
  /*........... Clean up methods........ */
  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
    this.globalElementsService.setShowSaveBtn(false);
  }

}
