import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { PlanService } from "../../plan/services/plan.service";
import { MatDialog } from "@angular/material";
import { GlobalElementsService } from "../services/global-elements.service";
import { ShowAuditHistoryDialogComponent } from "app/common/components/dialogs/show-audit-history-dialog/show-audit-history-dialog.component";
import { AddBrandMappingDialogComponent } from "app/common/components/dialogs/add-brand-mapping-dialog/add-brand-mapping-dialog.component";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { BrandManagementService } from "../services/brand-management.service";

import { IBrandsMDM } from "app/common/interfaces/brandManagement";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { ShowUpdateMessageDialogComponent } from "app/common/components/dialogs/show-update-message-dialog/show-update-message-dialog.component";
import { WarningMessageBrandMappingDialogComponent } from "app/common/components/dialogs/warning-message-brand-mapping-dialog/warning-message-brand-mapping-dialog.component";

@Component({
  selector: "app-brand-mapping",
  templateUrl: "./brand-mapping.component.html",
  styleUrls: ["./brand-mapping.component.scss"],
})
export class BrandMappingComponent implements OnInit {
  arefiltersApplied: boolean = true;
  isLoaded: boolean = false;
  selectedMarket: any;
  marketsData: any;
  globalData: any;
  commonFilters = [];
  userInfo = JSON.parse(sessionStorage.getItem("user"));
  displayedColumns: string[] = [
    "id",
    "marketName",
    "roiBrandName",
    "roiBrandType",
    "mdmBrandName",
    "mdmBrandType",
    "Action",
  ];
  dataSource = [];
  manageBrandMappingForm: FormGroup;
  brandMappingForm: FormGroup;
  ROIbrands = [];
  ALLROIbrands: any;
  mdmBrandType = ["Masterbrand", "Sub Brand"];
  brandsMDMList: Array<IBrandsMDM> = [];
  masterbrandsMDMList: Array<IBrandsMDM> = [];
  saveBtnSubscription: Subscription;
  @Output("dirty")
  formDirty = new EventEmitter<boolean>();
  filteredBrandList = [];
  isEditMode = [];
  constructor(
    private globalElementsService: GlobalElementsService,
    public dialog: MatDialog,
    private brandManagementService: BrandManagementService,
    private _formBuilder: FormBuilder
  ) {
    this.brandMappingForm = this._formBuilder.group({
      manageBrandMappingRows: this._formBuilder.array([]),
      editableRows: this._formBuilder.array([]),
    });
  }

  ngOnInit() {
    this.getMarkets();
    this.getAllROIBrands();
    this.getMDMBrands();
    this.getbrandMappingData();
    this.globalElementsService.setShowSaveBtn(this.userInfo.roleId !== 6);

    this.globalElementsService.setSaveEnableState(false);

    this.saveBtnSubscription = this.globalElementsService.getSidePanelSaveEvent
      .pipe(take(1))
      .subscribe(() => this.onSubmit());
    this.brandMappingForm.valueChanges.subscribe(() => {
      const isFormValid =
        this.manageBrandMappingRows.length > 0 &&
        this.manageBrandMappingRows.valid
          ? true
          : false;
      const isFromEdited = this.getEditableFormValue();
      let isEditedFormValid = true;
      for (let i = 0; i < isFromEdited.length; i++) {
        if (!isFromEdited[i].valid) {
          isEditedFormValid = false;
        }
      }
      if (isFormValid && isFromEdited.length > 0 && isEditedFormValid) {
        this.globalElementsService.setSaveEnableState(true);
      } else if (isFormValid && isFromEdited.length == 0) {
        this.globalElementsService.setSaveEnableState(true);
      } else if (
        isFromEdited.length > 0 &&
        isEditedFormValid &&
        this.manageBrandMappingRows.length == 0
      ) {
        this.globalElementsService.setSaveEnableState(true);
      } else {
        this.globalElementsService.setSaveEnableState(false);
      }
      this.formDirty.emit(this.brandMappingForm.dirty);
    });
  }
  getAllROIBrands() {
    this.globalElementsService.getROIBrandList().subscribe((response) => {
      if (response["data"].length > 0) {
        this.ROIbrands = response["data"];
        this.ALLROIbrands = response["data"];
      }
    });
  }
  getMDMBrands() {
    this.brandManagementService
      .getBrandsFromMDM()
      .subscribe((res: Array<IBrandsMDM>) => {
        this.brandsMDMList = res
          ? res.sort((a, b) => (a.mdmBrandName >= b.mdmBrandName ? 1 : -1))
          : [];
        this.masterbrandsMDMList = res
          ? res.sort((a, b) => (a.mdmBrandName >= b.mdmBrandName ? 1 : -1))
          : [];
        const uniqueIds = [];
        if (this.brandsMDMList) {
          this.brandsMDMList = res.filter((el) => {
            const isDuplicate = uniqueIds.includes(el.mdmBrandName);
            if (!isDuplicate) {
              uniqueIds.push(el.mdmBrandName);

              return true;
            }
            return false;
          });
        }
      });
  }
  getbrandMappingData() {
    this.globalElementsService.getbrandMappingData().subscribe((response) => {
      this.globalData = response["data"] ? response["data"] : [];
      if (this.globalData && this.globalData.length > 0) {
        this.isLoaded = true;
      }
      this.dataSource = this.globalData.map((el) => {
        this.isEditMode[el] = false;
        // el.MDMBrandName = this.camelizeBrandName(el.MDMBrandName.toLowerCase());
        if (el.MDMBrandName.includes("Rte")) {
          el.MDMBrandName = el.MDMBrandName.replace("Rte", "RTE");
        }
        return el;
      });
      if (this.dataSource != null) {
        this.addEditableRows();
      }
    });
  }

  filterBrandsList() {
    let marketId = this.selectedMarket;

    this.filteredBrandList = this.ALLROIbrands.filter(
      (el) => el.marketId.toLowerCase() == marketId.toLowerCase()
    );
  }

  updateROIType(ROIBrand, index) {
    let ROIbrandObj = this.ROIbrands.find((el) => el.reference_id == ROIBrand);
    // this.addBrandMappingForm.patchValue({selectedROIBrandType: ROIbrandObj.reference_type })
    this.manageBrandMappingRows
      .at(index)
      .get("selectedROIBrandType")
      .patchValue(ROIbrandObj.reference_type);
  }

  addbrandMappingRow() {
    const manageBrandMappingRow = this._formBuilder.group({
      selectedMarket: [this.selectedMarket, Validators.required],
      selectedROIBrand: ["", Validators.required],
      selectedROIBrandType: ["", Validators.required],
      selectedMDMBrand: ["", Validators.required],
      selectedMDMBrandType: ["", Validators.required],
    });
    this.manageBrandMappingRows.push(manageBrandMappingRow);
    // this.filteredBrandList.push(this.ALLROIbrands);
  }

  removebrandMappingRow(i) {
    this.manageBrandMappingRows.removeAt(i);
    // this.filteredBrandList.splice(i, 1);
  }
  /* new brandmapping */
  saveRowData(editableRow, i) {
    if (editableRow.valid) {
      this.saveBrandMappingData(editableRow, i);
      const filtererdRow = this.editableRows.controls.filter((control) => {
        control.get("Id").value == editableRow.get("Id").value;
      });
      this.editableRows.at(i).get("edited").setValue(false);
      this.editableRows.at(i).get("edited").updateValueAndValidity();
    }
  }
  resetEditableRows() {
    this.editableRows.controls.forEach((control) => {
      control.get("edited").setValue(false);
      control.get("edited").updateValueAndValidity();
    });
    this.editableRows.clear();
  }
  /*  new brandmapping ends*/

  get manageBrandMappingRows() {
    return this.brandMappingForm.controls[
      "manageBrandMappingRows"
    ] as FormArray;
  }
  /* new brandmapping*/
  validateMarketDropdownValue(
    control: AbstractControl
  ): ValidationErrors | null {
    const selectedValue = control.value;
    const BrandName = this.filteredBrandList.map((item) => item.reference_id);
    if (!BrandName.includes(selectedValue)) {
      return { invalidDropdownValue: true };
    }
    return null;
  }
  validateMarketTypeDropdownValue(
    control: AbstractControl
  ): ValidationErrors | null {
    const selectedValue = control.value;
    const BrandType = this.ROIbrands.map((item) => item.reference_type);
    if (!BrandType.includes(selectedValue)) {
      return { invalidDropdownValue: true };
    }
    return null;
  }
  validateMDMBrandDropdownValue(
    control: AbstractControl
  ): ValidationErrors | null {
    const selectedValue = control.value;
    const BrandName = this.brandsMDMList.map((item) => item.mdmBrandName);
    if (!BrandName.includes(selectedValue)) {
      return { invalidDropdownValue: true };
    }
    return null;
  }
  validateMDMBrandTypeDropdownValue(
    control: AbstractControl
  ): ValidationErrors | null {
    const selectedValue = control.value;
    if (!this.mdmBrandType.includes(selectedValue)) {
      return { invalidDropdownValue: true };
    }
    return null;
  }

  get editableRows() {
    return this.brandMappingForm.get("editableRows") as FormArray;
  }
  addEditableRows() {
    this.dataSource.forEach((rowItem) => {
      const row = this._formBuilder.group({
        edited: new FormControl(false),
        Id: new FormControl(rowItem.id),
        marketName: new FormControl(rowItem.marketId, [Validators.required]),
        ROIBrand: new FormControl(rowItem.ROIBrandName, [
          Validators.required,
          this.validateMarketDropdownValue.bind(this),
        ]),
        ROIBrandType: new FormControl(rowItem.RoIebrandtype, [
          Validators.required,
          this.validateMarketTypeDropdownValue.bind(this),
        ]),
        MDMBrand: new FormControl(rowItem.MDMBrandName, [
          Validators.required,
          this.validateMDMBrandDropdownValue.bind(this),
        ]),
        MDMBrandType: new FormControl(rowItem.MDMbrandtype, [
          Validators.required,
          this.validateMDMBrandTypeDropdownValue.bind(this),
        ]),
        status: new FormControl(rowItem.status),
      });
      this.editableRows.push(row);
    });
  }
  editItem(rowdata, index) {
    const data = this.getDatasourceMapping(rowdata.get("Id").value);
    this.isEditMode[index] = true;
    this.editableRows.at(index).get("edited").setValue(true);
    this.editableRows.at(index).get("marketName").setValue(data[0].marketId);
    this.editableRows.at(index).get("marketName").updateValueAndValidity();
    this.editableRows.at(index).get("ROIBrand").setValue(data[0].ROIBrandName);
    this.editableRows.at(index).get("ROIBrand").updateValueAndValidity();
    this.editableRows
      .at(index)
      .get("ROIBrandType")
      .setValue(data[0].RoIebrandtype);
    this.editableRows.at(index).get("ROIBrandType").updateValueAndValidity();
    this.editableRows.at(index).get("MDMBrand").setValue(data[0].MDMBrandName);
    this.editableRows.at(index).get("MDMBrand").updateValueAndValidity();
    this.editableRows
      .at(index)
      .get("MDMBrandType")
      .setValue(data[0].MDMbrandtype);
    this.editableRows.at(index).get("MDMBrandType").updateValueAndValidity();
    this.editableRows.at(index).get("status").setValue(data[0].status);
    this.editableRows.at(index).get("status").updateValueAndValidity();
  }

  getDatasourceMapping(id) {
    return this.dataSource.filter((el) => el.id == id);
  }
  getEditableFormValue() {
    let editedControls = [];
    this.editableRows.controls.forEach((formGroup: FormGroup) => {
      if (formGroup.get("edited").value) {
        editedControls.push(formGroup);
      }
    });
    return editedControls;
  }
  onSubmit() {
    let formValue: any[] = [];
    if (this.manageBrandMappingRows.value.length > 0) {
      this.manageBrandMappingRows.controls.map((fg) =>
        formValue.push(fg.value)
      );
    }
    console.log("fg value", formValue);
    const editedFormGroups = this.getEditableFormValue();
    if (editedFormGroups.length > 0) {
      editedFormGroups.forEach((group) => {
        if (group.valid) {
          formValue.push(group.value);
        }
      });
    }
    if (formValue.length > 0) {
      this.globalElementsService.saveBrandMappingRows(formValue).subscribe(
        (response) => {
          let dialogRef;
          dialogRef = this.dialog.open(AddBrandMappingDialogComponent, {
            maxWidth: "700px",
            disableClose: true,
            data: {
              message: "Brand Mapping has been saved successfully",
            },
          });
          if (this.manageBrandMappingRows.value) {
            this.manageBrandMappingRows.clear();
            this.manageBrandMappingRows.reset();
            this.resetEditableRows();
          }
          // this.getbrandMappingData();

          this.removeFilters();
        },
        (error) => {
          // console.log(error);
          let dialogRef;
          dialogRef = this.dialog.open(AddBrandMappingDialogComponent, {
            maxWidth: "700px",
            disableClose: true,
            data: {
              message: "Something went wrong!",
            },
          });
        }
      );
    }
  }
  saveBrandMappingData(dataList, i) {
    if (dataList) {
      this.globalElementsService
        .saveBrandMappingRows([dataList.value])
        .subscribe(
          (response) => {
            let dialogRef;
            dialogRef = this.dialog.open(AddBrandMappingDialogComponent, {
              maxWidth: "700px",
              disableClose: true,
              data: {
                message: "Brand Mapping has been saved successfully",
              },
            });
            this.saveAuditInfo(dataList.get("Id").value, i);
            this.editableRows.at(i).reset();
            //this.removeFilters();
          },
          (error) => {
            // console.log(error);
            let dialogRef;
            dialogRef = this.dialog.open(AddBrandMappingDialogComponent, {
              maxWidth: "700px",
              disableClose: true,
              data: {
                message: "Something went wrong!",
              },
            });
          }
        );
    }
  }
  changeStatus(elementId, type) {
    const postObj = {
      brandMappingId: elementId,
      type: type,
      userId: this.userInfo.id,
    };
    const dialogRef = this.dialog.open(
      WarningMessageBrandMappingDialogComponent,
      {
        disableClose: true,
        maxWidth: "500px",
        data: postObj,
      }
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (res["status"] == 200) {
        this.removeFilters();
      }
    });
  }
  editRow(brandMappingId, index) {
    this.isEditMode[index] = true;
  }

  saveAuditInfo(brandMappingId, index) {
    let userId = this.userInfo.id;
    const postObj = {
      userId: userId,
      brandMappingId: brandMappingId,
      action: "UPDATE",
    };
    this.globalElementsService.saveAuditHistory(postObj).subscribe((res) => {
      console.log(res);
      this.isEditMode[index] = false;
      // const dialogRef = this.dialog.open(ShowUpdateMessageDialogComponent, {
      //   disableClose: false,
      //   maxWidth: "500px",
      // });
      // dialogRef.afterClosed().subscribe((res: any) => {
      //   this.isEditMode[index] = false;
      // });
    });
  }
  handleCancel(index) {
    this.isEditMode[index] = false;
  }
  handleEditCancel(rowdata, index) {
    this.isEditMode[index] = false;
    this.editableRows.at(index).get("edited").setValue(false);
    this.editableRows.at(index).get("edited").updateValueAndValidity();
  }
  openDialog(elementId) {
    this.dialog.open(ShowAuditHistoryDialogComponent, {
      disableClose: false,
      maxWidth: "500px",
      data: elementId,
    });
  }

  getMarkets() {
    this.globalElementsService
      .getAllMarket(this.userInfo.gpid)
      .subscribe((res) => {
        if (res["markets"].length > 0) {
          this.marketsData = res["markets"];
          this.marketsData = this.marketsData
            ? this.marketsData.sort((a, b) =>
                a.displayName >= b.displayName ? 1 : -1
              )
            : [];
        } else {
          this.marketsData = [];
        }
      });
  }
  filterBrandMappingData() {
    this.arefiltersApplied = false;
    if (this.selectedMarket != null) {
      this.dataSource = this.globalData.filter(
        (el) => el.marketId == this.selectedMarket
      );
    }
    this.filterBrandsList();
  }

  removeFilters() {
    this.ngOnInit();
    this.arefiltersApplied = true;
    this.selectedMarket = null;
  }

  camelizeBrandName(str: any) {
    let arr: any;
    let MDMBrandName: any;
    if (str.includes("-")) {
      arr = str.split("-");
    } else if (str.includes("_")) {
      arr = str.split("_");
    } else {
      arr = str.split(" ");
    }

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    if (str.includes("-")) {
      MDMBrandName = arr.join("-");
    } else if (str.includes("_")) {
      MDMBrandName = arr.join("_");
    } else {
      MDMBrandName = arr.join(" ");
    }
    return MDMBrandName;
  }

  /*........... Clean up methods........ */
  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
    this.globalElementsService.setShowSaveBtn(false);
    this.formDirty.emit(false);
  }
}
