/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { HttpErrorResponse } from '@angular/common/http';
import { AppErrorFailure } from './app-error-failure';
export class AppError {
  public code: string;
  public message: string;
  public errorCount?: number;
  public failures?: AppErrorFailure[];
  public status?: number;
  constructor(httpError: HttpErrorResponse) {
   if (httpError.status === 403) {
      this.code = 'UNAUTHORIZED';
      this.errorCount = 1;
      this.message = 'UNAUTHORIZED';
      this.status = httpError.status;
      this.failures = [
        new AppErrorFailure({
          cause: 'UNAUTHORIZED',
          description: 'UNAUTHORIZED',
          failureType: 'UNAUTHORIZED',
          errorId: 'UNAUTHORIZED',
          errorParameters: {}
        })
      ];
    } else if (httpError.status === 500) {
      this.code = 'INTERNAL_SERVER_ERROR';
      this.errorCount = 1;
      console.log("INTERNAL_SERVER_ERROR",httpError.message);
      this.message =
       httpError.error? httpError.error.error : httpError.message;
      this.status = httpError.status;
      this.failures = [
        new AppErrorFailure({
          cause: httpError.statusText,
          description: this.message,
          failureType: 'INTERNAL_SERVER_ERROR',
          errorId: 'INTERNAL_SERVER_ERROR',
          errorParameters: {}
        })
      ];
    } else if (httpError.status === 400) {
      this.code = 'BAD_REQUEST';
      this.errorCount = 1;
      this.message = httpError.error? httpError.error.error : httpError.message;
      this.status = httpError.status;
      this.failures = [
        new AppErrorFailure({
          cause: httpError.statusText,
          description: this.message,
          failureType: 'BAD_REQUEST',
          errorId: 'BAD_REQUEST',
          errorParameters: {}
        })
      ];
    } else {
      this.code = 'Unknown Error';
      this.errorCount = 1;
      this.message =  httpError.error? httpError.error.error : httpError.message;
         console.log("INTERNAL_SERVER_ERROR",httpError.message);
      this.status = httpError.status;
      this.failures = [
        new AppErrorFailure({
          cause: 'Unknown Error',
          description:  httpError.message,
          failureType: 'Unknown Error',
          errorId: 'UNKNOWN_ERROR',
          errorParameters: {}
        })
      ];
    }
  }
}
