import { BrandManagementService } from '../../../services/brand-management.service';
import { PAGE_MODES } from '../../../../constants/app-constants';
import { GlobalElementsService } from '../../../services/global-elements.service';
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CommonDialogToShowNotificationComponent  } from '../../../../common/components/dialogs/common-dailog-to-show-notification/common-dailog-to-show-notification';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
@Component({
  selector: 'app-tooltip-home-page',
  templateUrl: './list-tooltip.component.html',
  styleUrls: ['./list-tooltip.component.scss']
})
export class TooltipListHomePage implements OnInit, OnChanges, OnDestroy {

  @Output() tooltipSelctionEvent = new EventEmitter();
  @Output() setHomePageModeEvent = new EventEmitter();
  @Output() tooltipSelctionEventDelete = new EventEmitter();
  @Input() pageMode;

  tooltipCategories = [];
  selectedTooltip = [];

  marketInfo: any [];
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  selectedMarket = [];
  selectedCategory = [];
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['MarketName','Tooltip_Category_Code','Tooltip_Category','Action'];
  isLoading = true;
  errorLoading = false;

  constructor(
    public dialog: MatDialog,
    protected googleTagManagerService: GoogleTagManagerService,
    private globalElementsService:GlobalElementsService
  ) { }

  ngOnInit() {
    this.getMarkets();
    this.getTooltipCategories();
  }

  getMarkets() {
    this.globalElementsService.getAllMarket(this.userInfo.gpid).subscribe(res => {
      if(res['markets'].length > 0){
        this.marketInfo = res['markets'].sort((a, b)=> a.displayName >= b.displayName ? 1 : -1);
        this.marketChangeEvent(null);
      } else {
        this.marketInfo = [];
      }
    })

  }
  marketChangeEvent(marketId){
    if(this.selectedMarket!= null && this.selectedCategory == null){
      this.globalElementsService.getTooltipData().subscribe(response => {
        if(response['data'].length > 0){
          let _dataToBind = response['data'];
          if(marketId) {
            _dataToBind = _dataToBind.filter(el => el.marketId == marketId);
          }
          this.dataSource = new MatTableDataSource<any>(_dataToBind);
          this.isLoading = false;
        } else {
          this.dataSource = new MatTableDataSource<any>([]);
        }
      })
    } 
    else {
      this.globalElementsService.getTooltipData().subscribe(response => {
        if(response['data'].length > 0){
          let _dataToBind = response['data'];
          if(marketId) {
            _dataToBind = _dataToBind.filter(el => el.marketId == marketId && el.tooltipCatCode == this.selectedCategory);
          }
          this.dataSource = new MatTableDataSource<any>(_dataToBind);
          this.isLoading = false;
        } else {
          this.dataSource = new MatTableDataSource<any>([]);
        }
      })
    }
   
  }
  applyFilter(value){
    if(this.selectedMarket.length > 0 && this.selectedCategory.length == 0){
      this.globalElementsService.getTooltipData().subscribe(response => {
        if(response['data'].length > 0){
          let _dataToBind = response['data'];
            _dataToBind = _dataToBind.filter(el => el.marketId == this.selectedMarket);
          this.dataSource = new MatTableDataSource<any>(_dataToBind);
          this.isLoading = false;
        } else {
          this.dataSource = new MatTableDataSource<any>([]);
        }
      })
    } 
    else if(this.selectedMarket.length > 0 && this.selectedCategory.length > 0){
      this.globalElementsService.getTooltipData().subscribe(response => {
        if(response['data'].length > 0){
          let _dataToBind = response['data'];
            _dataToBind = _dataToBind.filter(el => el.marketId == this.selectedMarket && el.tooltipCatCode == this.selectedCategory);
          this.dataSource = new MatTableDataSource<any>(_dataToBind);
          this.isLoading = false;
        } else {
          this.dataSource = new MatTableDataSource<any>([]);
        }
      })
    } else if(this.selectedCategory.length > 0 && this.selectedMarket.length == 0){
      this.globalElementsService.getTooltipData().subscribe(response => {
        if(response['data'].length > 0){
          let _dataToBind = response['data'];
            _dataToBind = _dataToBind.filter(el => el.tooltipCatCode == this.selectedCategory);
          this.dataSource = new MatTableDataSource<any>(_dataToBind);
          this.isLoading = false;
        } else {
          this.dataSource = new MatTableDataSource<any>([]);
        }
      })
    } 
  }
  categoryChangeEvent(categoryName){
    if(this.selectedMarket == null){
      console.log("No Market Selected");
      this.globalElementsService.getTooltipData().subscribe(response => {
        if(response['data'].length > 0){
          let _dataToBind = response['data'];
          if(categoryName) {
            _dataToBind = _dataToBind.filter(el => el.tooltipCatCode == categoryName);
          }
          this.dataSource = new MatTableDataSource<any>(_dataToBind);
          this.isLoading = false;
        } else {
          this.dataSource = new MatTableDataSource<any>([]);
        }
      })
    } else {
      // console.log("Market is Selected");
      this.globalElementsService.getTooltipData().subscribe(response => {
        if(response['data'].length > 0){
          let _dataToBind = response['data'];
          if(categoryName) {
            _dataToBind = _dataToBind.filter(el => el.tooltipCatCode == categoryName && el.marketId == this.selectedMarket);
          }
          this.dataSource = new MatTableDataSource<any>(_dataToBind);
          this.isLoading = false;
        } else {
          this.dataSource = new MatTableDataSource<any>([]);
        }
      })
    }
    
  }
  clearFilters(){
    this.globalElementsService.getTooltipData().subscribe(response => {
      if(response['data'].length > 0){
        let _dataToBind = response['data'];
        this.dataSource = new MatTableDataSource<any>(_dataToBind);
        this.isLoading = false;
        this.selectedMarket = [];
        this.selectedCategory = [];
      } else {
        this.dataSource = new MatTableDataSource<any>([]);
      }
    })
  }


  ngOnChanges() {
    if (this.pageMode == PAGE_MODES.DELETE) {
      //this.handleDelete()
    }
  }

  istooltipCatCodeSelected(tooltipCatCode) {
    return this.selectedTooltip.includes(tooltipCatCode)
  }




  editTooltip(_object) {
    this.selectedTooltip = _object;
    this.tooltipSelctionEvent.emit(_object);
  }

  deleteTootip(_elementId){
    this.handleDelete(_elementId);
  }
  handleDelete(_elementId) {
    const deleteData =  {
      type: 'delete',
      modulenm: 'tooltip',
      apiData: {
        tooltipId: _elementId
      }
    };
    const dialogRef = this.dialog.open(CommonDialogToShowNotificationComponent, {
      data: deleteData ,
      minWidth: '500px'
    })


    dialogRef.afterClosed().subscribe(() => {
      this.globalElementsService.getTooltipData().subscribe(response => {
        if(response['data'].length > 0) {
          this.dataSource = new MatTableDataSource<any>(response['data']);
        } else {
          this.dataSource = new MatTableDataSource<any>([]);
        }
      })
      this.selectedTooltip = [];
      this.getTooltipCategories();
      this.tooltipSelctionEvent.emit([]);
      this.setHomePageModeEvent.emit();

    });
  }




  getTooltipCategories() {
    this.isLoading = true;
    this.errorLoading = false;
    this.globalElementsService.getTooltipCatData().subscribe((res:any) => {
      this.isLoading = false;
      this.errorLoading = false;
      this.tooltipCategories = res.data;
    }, () => this.errorLoading = true);

  }

  ngOnDestroy(): void {
  }
}
