export class AppErrorFailure {
  public cause: string;
  public description: string;
  public failureType: string;
  public errorId: string;
  public errorParameters: Record<string, string>;
  constructor(errorFailure: AppErrorFailure) {
    this.cause = errorFailure.cause;
    this.description = errorFailure.description;
    this.errorId = errorFailure.errorId;
    this.errorParameters = errorFailure.errorParameters;
    this.failureType = errorFailure.failureType;
  }
}
