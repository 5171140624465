import { getGpId } from './../../common/utils/common-util';
import { IEditLock } from './../../common/interfaces/program';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output,TemplateRef,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ProgramDialogComponent } from 'app/common/components/dialogs/program-dialog/program-dialog.component';
import { IApproverList, IBreadcrumb, ITeamMembers, IDocument } from 'app/common/interfaces/common';
import { ICampaignIdeaSave } from 'app/common/interfaces/program';
import { getCompletedAndFinalizedUsers, getDocsFromSharepoint ,getDocsFromSharepointFilesAPI, isValidUserAccess} from 'app/common/utils/common-util';
import { PAGE_MODES,FINAL_STATUS_LIST, FORM_REFERENCE_TYPES, MINIFIED_STATUS_LIST, STATUS_LIST, STATUS_LIST_TYPES, SHAREPOINT_DOC_PATH } from 'app/constants/app-constants';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DomSanitizer } from '../../../../node_modules/@angular/platform-browser';
import { TeamMembersDialogComponent } from '../../common/components/dialogs/team-members-dialog/team-members-dialog.component';
import { ProgramService2 } from '../services/program.service';
import { ITeamMembersReq } from './../../common/interfaces/common';
import { BREADCRUMBS_LAST_NODE_TYPES, BREADCRUMBS_REFERENCE_TYPES } from './../../constants/app-constants';
import { FileUploadDialogComponent } from 'app/common/components/dialogs/file-upload-dialog/file-upload-dialog.component';
import {MultiselectAutocompleteComponent} from 'app/common/components/multiselect-autocomplete/multiselect-autocomplete.component';
import * as _ from 'lodash';
import { CampaignIdeaUploadDialogComponent } from 'app/common/components/dialogs/campaign-idea-upload-dialog/campaign-idea-upload-dialog.component';
import { SendNotificationsDialogComponent } from './../../common/components/dialogs/send-notifications-dialog/send-notifications-dialog.component';
import { saveAs } from 'file-saver';
import { fileMimeTypes } from 'app/constants/app-constants';
import ab2b64 from 'ab2b64' ;
import { IAudienceThumnails } from 'app/common/interfaces/plan';
import { DownloadFileDialogComponent } from 'app/common/components/dialogs/download-file-dialog/download-file-dialog.component';

@Component({
  selector: 'app-campaign-idea',
  templateUrl: './campaign-idea.component.html',
  styleUrls: ['./campaign-idea.component.scss']
})

export class CampaignIdeaComponent implements OnInit, OnDestroy {

  @Input() elementDetails;
  userInfo = JSON.parse(sessionStorage.getItem('user'));

  @Output('dirty') formDirty = new EventEmitter<boolean>()
  @ViewChild('showImgageDailog', { static: true }) showImgageDailog: TemplateRef<any>;

  USER_INFO = JSON.parse(sessionStorage.getItem('user'));
  campaignIdeaForm: FormGroup;
  saveBtnSubscription: Subscription;
  audienceList:Array<string>;
  currentSelectedAudience:Array<string>;
  allSelectedAudienceList:Array<string>=[];
  outofScopeAudience:Array<string>=[];
  selectedCampaignGoals:Array<string>=[];
  assets;
  selectedLogoImg: string;
  isImportCampaign:boolean=false;
  campaignIdeaList=[];
  campIdeaStatusList: Array<string> = STATUS_LIST;
  campIdeaStatus: string = STATUS_LIST_TYPES.DRAFT;
  isStatus: boolean = true;
  campIdeaApproverList: Array<IApproverList>;
  selectedCampIdeaApprovers: Array<IApproverList>;
  campIdeaEditableStatus:boolean = true;
  oldStatus: string;
  pathDetails : IBreadcrumb;
  teamMembersList: Array<ITeamMembers> = [];
  selectedTeamMembers: Array<number> = [];
  selectedTeamMembersAvatar = [];
  ideaFormDocList: Array<IDocument> = [];
  imageBase64Data:string;
  imageData:string | ArrayBuffer=null;
  base64textString: string;
  campaignGoals:Array<string>=[];
  mode = PAGE_MODES.HOME;
  selectedAudience: Array<string> = [];
  audienceDocs: Array<IDocument> = [];
  audienceThumnailList: Array<IAudienceThumnails> = [];
  audienceImageObj;

  constructor(
    private globalElementsService: GlobalElementsService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private programService: ProgramService2,
  ) { }

  /*........... Initialization Methods........ */

  ngOnInit() {
    this.globalElementsService.setShowSaveBtn(this.returnAccess('!==', 6));
    this.globalElementsService.setSaveEnableState(false);
    this.initiateMjtbdForm();
    this.campaignIdeaForm.valueChanges.subscribe(() => {
      this.globalElementsService.setSaveEnableState(this.campaignIdeaForm.valid && this.campIdeaEditableStatus);
      this.formDirty.emit(this.campaignIdeaForm.dirty);

    })
    this.getApproversList();
    this.getTeamMembersList();
    this.getAudience();
    this.getCampaignDocsFromSharepoint();
    this.pathDetails = {
      lastNodeName: `${this.elementDetails.campIdeaData.mjtbdName} >> Campaign Idea`,
      pageId:this.elementDetails.campIdeaData.mjtbdId,
      pageRefType: BREADCRUMBS_REFERENCE_TYPES.MJTBD,
      lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.MJTBD
    }

    const campaignData = this.elementDetails.campIdeaData;
    if (campaignData.mode == 'save') {
      this.isStatus = false;
      this.isImportCampaign = true;
      this.campaignIdeaList = this.elementDetails.campIdeaData.campaignIdeaList;
    }
    
   this.saveBtnSubscription =  this.globalElementsService.getSidePanelSaveEvent.pipe(take(1)).subscribe(() => this.saveCampaignIdea());
   this.returnAccess('===', 6) ? this.campaignIdeaForm.disable() : null;

  }
  getSuccessKpisFromOppBrief(){
    const campaignId = this.elementDetails.campIdeaData.data.campaignId;
    this.programService.getSuccessKpisFromOppBrief(campaignId).subscribe(res => {
      this.selectedCampaignGoals = res['successKPIs'] ? res['successKPIs'].split(','): [];
      this.campaignIdeaForm.get("campaignGoal").patchValue(this.selectedCampaignGoals ? this.selectedCampaignGoals : null);
      console.log(res);
    })
  }

  initiateMjtbdForm() {

    this.campaignIdeaForm = this.fb.group({
      ciTitle: ['', [Validators.required, Validators.maxLength(100)]],
      whatWouldWeDo: [''],
      campIdeaApprover:[''],
      situation: [''],
      campaignImage: [''], 
      campaignImageBase64: [''],            
      target: [''],
      campaignGoal: [''],
      insight: [''],
      results: [''],
      cmpLogo: [''],
      connectionToBBI: [''],
      addionalInsight: [''],
    });
   
    const campaignData = this.elementDetails.campIdeaData;
    if (campaignData.mode == 'update') {
      this.patchFormValues(campaignData.data);
      // uncomment for campaign idea image upload
      this.globalElementsService.getCampaignImage({imageName: campaignData.data.campaignImage}).subscribe(res=>{
        this.selectedLogoImg = res.campaignImageBase64;
        this.campaignIdeaForm.get("cmpLogo").patchValue(this.selectedLogoImg);
      })
    } else {
      this.getSuccessKpisFromOppBrief();
    }

  }

  patchFormValues(campaignIdeaData) {
    const target = campaignIdeaData.target ? campaignIdeaData.target.split(',') : null;

    this.campaignIdeaForm.patchValue({
      ciTitle: campaignIdeaData ? campaignIdeaData.ciTitle : null,
      whatWouldWeDo: campaignIdeaData ? campaignIdeaData.whatWouldWeDo : null,
      situation: campaignIdeaData ? campaignIdeaData.situation : null,
      target: target,
      insight: campaignIdeaData ? campaignIdeaData.insight : null,
      results: campaignIdeaData ? campaignIdeaData.results : null,
      connectionToBBI: campaignIdeaData ? campaignIdeaData.connectionToBBI : null,
      addionalInsight: campaignIdeaData ? campaignIdeaData.addionalInsight : null,
      campaignImage: campaignIdeaData ? campaignIdeaData.campaignImage : null,
      cmpLogo:  this.base64textString,//uncomment for campaign idea image upload
      // cmpLogo: campaignIdeaData ? campaignIdeaData.campaignImage : ''
      
    });

    this.selectedTeamMembers = campaignIdeaData.teamMembers ? campaignIdeaData.teamMembers.split(',').map(el => Number(el)) : [];
    this.selectedLogoImg = campaignIdeaData ? campaignIdeaData.campIdeaImageURL : '';//uncomment for campaign idea image upload
    // this.selectedLogoImg = campaignIdeaData ? campaignIdeaData.campaignImage : '';

    this.selectedCampaignGoals = campaignIdeaData.campaignGoal ? campaignIdeaData.campaignGoal.split(',') : [];
    this.campaignIdeaForm.get("campaignGoal").patchValue(this.selectedCampaignGoals ? this.selectedCampaignGoals : null);
    this.campIdeaStatus = campaignIdeaData && campaignIdeaData.ciFormStatus || STATUS_LIST_TYPES.DRAFT;
    this.oldStatus= campaignIdeaData ? campaignIdeaData.ciFormStatus : null;
   // this.updateApproverData(campaignIdeaData);
    this.updateStatusList(campaignIdeaData);
  }

  /*........... functionality Methods........ */

  addTeamMembers(){
    
    let tmDialogRef;
    tmDialogRef = this.dialog.open(TeamMembersDialogComponent, {
      data: {
      teamMembers : this.teamMembersList,
      selectedMembers : this.selectedTeamMembers
      },
      height: '400px',
      width: '500px',
      disableClose: true
    });

    tmDialogRef.afterClosed().subscribe((res: any) => {
      this.selectedTeamMembers = res;
      this.generateTeamMemeberAvatars(); 
    });
  }
  sendData(){
    return this.campIdeaApproverList;
  }
  sendSelectedData(){
    return this.selectedCampIdeaApprovers; 
   }
  
  selectChange = (event: any) => {
    this.selectedCampIdeaApprovers = event.data;
    this.campaignIdeaForm.get("campIdeaApprover").patchValue(this.selectedCampIdeaApprovers);
  };

  generateTeamMemeberAvatars(){
    if(this.teamMembersList){
      const selectedUsers = this.teamMembersList.filter(tm => this.selectedTeamMembers ? this.selectedTeamMembers.includes(tm.userId) : '');
      this.selectedTeamMembersAvatar = selectedUsers.map(user => {
        const initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
        let avatar;
        if (user.userAvatar) {
          let byteArray = new Uint8Array(user.userAvatar.data);
          const charString = byteArray.reduce((data, byte) => {
            return data + String.fromCharCode(byte);
          }, '');
          let base64String = btoa(charString);
          avatar = this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + base64String);
        }
        return {
          initials,
          avatar
        }
      })
    }
  }

  deleteFile(doc: IDocument) {
    const docObj = {
      isDelete: true,
      fileName: doc.fileName,
      docPath: doc.path.split('/Shared Documents/')[1]
    };
    let dialogRef;
    dialogRef = this.dialog.open(FileUploadDialogComponent, {
      data: { fileType: '', docObj },
      minWidth: '500px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res.status) {
        this.getCampaignDocsFromSharepoint();
      }
      this.dialog.closeAll();
    });
  }

  downloadFile(path:string){
    let fileName = path.split('/').pop();
    path = path ? path.split('/Shared Documents/')[1] : '';
    let docPath = path.split(`/${fileName}`)[0];
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    const downloadObj = {
      fileName:fileName,
      path:path,
      docPath:docPath,
      extension:extension
    } 
    let dialogRef;
        dialogRef = this.dialog.open(DownloadFileDialogComponent, {
          data: downloadObj,
          minWidth: '500px',
          disableClose: true
        });
    // let fileName = path.split('/').pop();
    // const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length); 
    // this.globalElementsService.downloadFile({fileName: fileName, path: path.split('/Shared Documents/')[1]}).subscribe(response => {
    //   this.downloadFileFromBase64(fileName,response['thumbnailb64data'], fileMimeTypes[extension] );
      // const byteCharacters = window.atob(response['thumbnailb64data']);
      // const downloadLink = document.createElement("a");
      // downloadLink.download = fileName.replace(/ /g,'');
      // downloadLink.href = source;
      // document.body.appendChild(downloadLink);
      // downloadLink.click();
      // document.body.removeChild(downloadLink);
      // const byteNumbers = new Array(byteCharacters.length);
      // for (let i = 0; i < byteCharacters.length; i++) {
      //   byteNumbers[i] = byteCharacters.charCodeAt(i);
      // }
      // const byteArray = new Uint8Array(byteNumbers);
      // const blob = new Blob([byteArray], {type: `data:${fileMimeTypes[extension]}`});
      // saveAs(blob, fileName.replace(/ /g,''));
    // })
  }
  downloadFileFromBase64(fileName, b64encodedString: string, contentType ) {
    if (b64encodedString) {
      var blob = this.base64ToBlob(b64encodedString, contentType);
      saveAs(blob, fileName);
    }
  }

public base64ToBlob(b64Data, contentType='', sliceSize=512) {

  let byteArrays = ab2b64.b642ab(b64Data)
  return new Blob([byteArrays], {type: contentType});
}

  async getCampaignDocsFromSharepoint() {
    const { year, hierarchy, campaignName } = this.elementDetails.campIdeaData;
    const campIdeaPath = `${SHAREPOINT_DOC_PATH.TCP}/${year}/${hierarchy}/${SHAREPOINT_DOC_PATH.CAMPAIGN_IDEA}/${SHAREPOINT_DOC_PATH.ATTACHMENTS}`;
    const campIdeaDocObj = await getDocsFromSharepointFilesAPI(campIdeaPath, this.globalElementsService);
    this.ideaFormDocList = campIdeaDocObj.filter((doc) => {
      return doc.title.includes(campaignName);
    });

  }
  campaignIdeaFormErrorHandeling(control: string, error: string) {
    return this.campaignIdeaForm.controls[control].hasError(error);
  }
  
  audienceChange(audience) {
    this.currentSelectedAudience = audience;
    if(this.currentSelectedAudience.length==0){
      this.audienceThumnailList = [];
    } else {
      this.getAudienceThumbnailUrls();
    }
    // setTimeout(()=>{                         
    //   this.getAudienceThumbnailUrls();
    // }, 3000);
    // this.getAudienceThumbnailUrls();
    // this.allSelectedAudienceList = _.uniq(_.union(this.outofScopeAudience, this.currentSelectedAudience)) || [];
  }
  async getAudienceDocsFromSharePoint() {
    let year = this.elementDetails.campIdeaData.data.year;
    let hierarchy = this.elementDetails.campIdeaData.hierarchy.split("/");
    let market = hierarchy[0];
    let path = `${SHAREPOINT_DOC_PATH.INPUT_FILES}/${SHAREPOINT_DOC_PATH.CDNA}/${year}/${market}`;
    getDocsFromSharepointFilesAPI(path, this.globalElementsService).then((res) => {
      this.audienceDocs = res;
      this.getAudienceThumbnailUrls();
    })
  }
  async getAudienceThumbnailUrls() {
    // this.audienceThumnailList = [];
    let year = this.elementDetails.campIdeaData.data.year;
    let hierarchy = this.elementDetails.campIdeaData.hierarchy.split("/");
    let market = hierarchy[0];
    let plan = hierarchy[2];

    let path = `${SHAREPOINT_DOC_PATH.INPUT_FILES}/${SHAREPOINT_DOC_PATH.CDNA}/${year}/${market}`;
    if(this.currentSelectedAudience.length > 0){
      this.currentSelectedAudience.forEach(async (audience: string) => {
        if(!this.audienceThumnailList.find((audienceThumnail: any) => audienceThumnail.title === audience)) {
          const condition = `${plan}_${audience}`.trim();
          const hasAudienceDoc = this.audienceDocs.find((doc: IDocument) => doc.fileName.toLocaleLowerCase().indexOf(condition.toLocaleLowerCase()) > -1);
          const audience_thumbnailb64data = hasAudienceDoc && await this.globalElementsService.getThumbnailBase64DataSharePoint_new({filePathWName: `${path}/${hasAudienceDoc.fileName}`}).toPromise();
          const audienceDocPath = audience_thumbnailb64data && `data:image/jpg;base64,` + audience_thumbnailb64data["thumbnailb64data"];
          if(!this.audienceThumnailList.find((audience_new: any) => audience_new.title === audience)) {
            this.audienceThumnailList.push({ title: audience, thumnailUrl: audienceDocPath, path: hasAudienceDoc ? hasAudienceDoc.path : "" });
          }
        }
     });
     this.audienceThumnailList = this.audienceThumnailList.filter((el) =>{
      return this.currentSelectedAudience.some((f) => {
        return f === el.title;
      });
    })
    }
    
  }
  deleteOutOfScopeAudience(audience) {
    this.outofScopeAudience = _.without(this.outofScopeAudience,audience);
    this.allSelectedAudienceList = _.uniq(_.union(this.outofScopeAudience, this.currentSelectedAudience)) || [];
  }
  openshowImgageDailog(audienceObj) {
    this.dialog.open(this.showImgageDailog, {
      height: '800',
      width: '900',
      disableClose: true,
    });
    this.audienceImageObj = audienceObj 
  }
  saveCampaginGoals() {
      const { campaignGoal } = this.campaignIdeaForm.value;
      if(!campaignGoal){
        return null
      }
      this.selectedCampaignGoals.push(campaignGoal);
      this.campaignIdeaForm.get("campaignGoal").patchValue('');    
  }


  closeDialog() {
    this.dialog.closeAll();
  }

  openImgUploadDialog() {
    let dialogRef;
    dialogRef = this.dialog.open(ProgramDialogComponent, {
      data: { type: 'assets', planId: this.elementDetails.campIdeaData.planId },
      width: '650px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res.img) {
        this.selectedLogoImg = res.img;
        this.campaignIdeaForm.get("cmpLogo").patchValue(this.selectedLogoImg);
      }
      this.dialog.closeAll();
    });
  }

  openImportCampModal() {
    let dialogRef; 
    dialogRef = this.dialog.open(ProgramDialogComponent, {
      data: { type: 'import', campaignIdeaList: this.campaignIdeaList, planId: this.elementDetails.campIdeaData.planId },
      minWidth: '500px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        const campaignIdeaData = this.campaignIdeaList.find(campIdea => campIdea.ciFormId == res.ciFormId);
        this.patchFormValues(campaignIdeaData);
        this.patchCampaignIdeaTarget(campaignIdeaData);
      }
      this.dialog.closeAll();
    });
  }

  saveCampaignIdea(){
    const campIdea = this.constructCampIdeaObj();
    let dialogRef;
    dialogRef = this.dialog.open(ProgramDialogComponent, {
      data: campIdea,
      minWidth: '500px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.globalElementsService.setSidePanelCancelEvent();
      this.dialog.closeAll();
    });
  }
  
  patchCampaignIdeaTarget(campIdeaData){
    this.currentSelectedAudience = campIdeaData.target ? campIdeaData.target.split(',') : null;
    if(this.currentSelectedAudience && this.currentSelectedAudience.length > 0) {
      this.outofScopeAudience = _.difference( this.currentSelectedAudience, this.audienceList) || [];
      this.currentSelectedAudience =_.difference( this.currentSelectedAudience, this.outofScopeAudience) || [];
      this.allSelectedAudienceList = _.uniq(_.union(this.outofScopeAudience, this.currentSelectedAudience)) || [];
    }
    this.campaignIdeaForm.get("target").patchValue(this.currentSelectedAudience && this.currentSelectedAudience.length > 0 ? this.currentSelectedAudience : []);
    this.getAudienceDocsFromSharePoint();
  }
  
  updateApproverData(campaignIdeaData){
   const approvers = campaignIdeaData.approversList ? campaignIdeaData.approversList.split(',') : [];
    this.selectedCampIdeaApprovers=[];
    approvers.forEach((id:number) =>{
      const approversList=this.campIdeaApproverList.find((el:IApproverList) => el.userId == id);
      if(approversList){
        this.selectedCampIdeaApprovers.push(approversList);
      } else {
        this.selectedCampIdeaApprovers = [];
      }
    });
    this.campaignIdeaForm.get("campIdeaApprover").patchValue(this.selectedCampIdeaApprovers);
  }

  campIdeaApproverChange(apprvData: Array<IApproverList>) {
    this.selectedCampIdeaApprovers = apprvData;
  }
  

  updateStatusList(campaignIdeaData) {

    this.campaignIdeaForm.get("campIdeaApprover").setValidators([Validators.required]);
    let approversIds: Array<any> = [];
    if (campaignIdeaData && campaignIdeaData.approversList) {
      approversIds = campaignIdeaData.approversList.split(',');
    }

    let isMatched = false;

    // if (this.USER_INFO.roleId <= 3) {
    //   isMatched = true;
    // } else {
    //   isMatched = false;
    // }

    approversIds.forEach(id => {
      if (this.USER_INFO.id == id) {
        isMatched = true;
      }
    });

    if (this.campIdeaStatus === STATUS_LIST_TYPES.DRAFT || this.campIdeaStatus == null) {
      this.campaignIdeaForm.get("campIdeaApprover").setValidators([]);
      this.campIdeaStatusList = MINIFIED_STATUS_LIST;
      this.campIdeaEditableStatus = true;
    } else if (isMatched && this.campIdeaStatus === STATUS_LIST_TYPES.COMPLETE) {
      this.campIdeaEditableStatus = true;
      this.campIdeaStatusList = STATUS_LIST;
    } else if (isMatched && this.campIdeaStatus === STATUS_LIST_TYPES.FINAL) {
      this.campIdeaEditableStatus = true;
      this.campIdeaStatusList = FINAL_STATUS_LIST;
    } else {
      this.campIdeaEditableStatus = false;
      this.campIdeaStatusList = STATUS_LIST;
    }
    this.campaignIdeaForm.get("campIdeaApprover").updateValueAndValidity();
    this.globalElementsService.setSaveEnableState(this.campaignIdeaForm.valid);
  }

  campIdeaStatusChange() {
    if (this.campIdeaStatus === STATUS_LIST_TYPES.DRAFT) {
      this.campaignIdeaForm.get("campIdeaApprover").setValidators([]);
    } else {
      this.campaignIdeaForm.get("campIdeaApprover").setValidators([Validators.required]);      
    }
    this.campaignIdeaForm.get("campIdeaApprover").updateValueAndValidity();
    this.globalElementsService.setSaveEnableState(this.campaignIdeaForm.valid);
  
  }
  /*........... Service/API calls........ */
  getApproversList() {
    const { mjtbdId, year } = this.elementDetails.campIdeaData.data;
    let obj = {
      referenceId: mjtbdId,
      referenceType: FORM_REFERENCE_TYPES.MJTBD_FORM,
      year: year
    }
    this.globalElementsService.getApproversList(obj).subscribe((res: Array<IApproverList>) => {
      this.campIdeaApproverList = res;
      const campaignData = this.elementDetails.campIdeaData.data;
      this.updateApproverData(campaignData)
    })
  }
  
  getTeamMembersList(){
    const { mjtbdId, year } = this.elementDetails.campIdeaData.data;
    let teamMemberReq : ITeamMembersReq = {
      referenceId: mjtbdId,
      referenceType: FORM_REFERENCE_TYPES.MJTBD_FORM,
      year: year
    }

    this.globalElementsService.getTeamMembersList(teamMemberReq).subscribe((res:Array<ITeamMembers>)=>{
      this.teamMembersList = res ? res.sort((a, b)=> a.name >= b.name ? 1 : -1) : [];
      const uniqueIds = [];
      if(this.teamMembersList){
        this.teamMembersList = res.filter(el => {
          const isDuplicate = uniqueIds.includes(el.userId);
          if (!isDuplicate) {
            uniqueIds.push(el.userId);
        
            return true;
          }
          return false;
        })
      }
      this.generateTeamMemeberAvatars();
    })
    
  }

  sendMessageToCollaborators(){
    let data = this.constructCampIdeaObj();
    let snDialogRef;
      snDialogRef = this.dialog.open(SendNotificationsDialogComponent, {
        data: {
          data :data,
          type:FORM_REFERENCE_TYPES.CAMP_IDEA,
          },
        height: '150px',
        width: '500px',
        disableClose:true
      });

    snDialogRef.afterClosed().subscribe((res: any) => {
    });
    
  }
  getAudience(){
    const { mjtbdId } = this.elementDetails.campIdeaData.data;
    let mjtbdObj = {
      mjtbdId: mjtbdId
    }
    this.programService.getKPIsandAudience(mjtbdObj).subscribe((res:any)=>{
      this.audienceList=res.audience;
      this.patchCampaignIdeaTarget(this.elementDetails.campIdeaData.data);
    });
  }

  constructCampIdeaObj(){
    const formValues = this.campaignIdeaForm.value;
    const campaignData=this.elementDetails.campIdeaData;
    const {mjtbdId, campaignId,year}=campaignData.data;
    let campIdeaApprover = formValues.campIdeaApprover && formValues.campIdeaApprover.map((obj: IApproverList) => obj.userId);
    const {completedByUserId, finalizedByUserID}=getCompletedAndFinalizedUsers(this.oldStatus, this.campIdeaStatus, campaignData.data);
    const campaignObj:ICampaignIdeaSave={
      mjtbdId, 
      campaignId,
      year,
      alertURL: `/program/${mjtbdId}`,
      campaignIdeaInfo: {
        ciTitle: formValues.ciTitle,
        //uncomment for campaign idea image upload
        campaignImage: formValues.campaignImage,
        campaignImageBase64: formValues.cmpLogo? formValues.cmpLogo.split('data:image/jpg;base64,')[1]:null,
        // campaignImage: this.selectedLogoImg,

        whatWouldWeDo: formValues.whatWouldWeDo,
        situation: formValues.situation,
        target: this.currentSelectedAudience && this.currentSelectedAudience.length > 0 ? this.currentSelectedAudience.join(",") : '',
        campaignGoal: this.selectedCampaignGoals ? this.selectedCampaignGoals.toString() : '',
        insight: formValues.insight,
        addionalInsight: formValues.addionalInsight,
        teamMembers: this.selectedTeamMembers.toString(),
        results: formValues.results,
        connectionToBBI: formValues.connectionToBBI,
        approversList: campIdeaApprover ? campIdeaApprover.toString() : '',
        completedByUserId: completedByUserId,
        finalizedByUserID: finalizedByUserID,
        currentUserID: this.userInfo.id
      }

    }
    if (campaignData.mode == 'save') {
      return { mode: 'save', type: 'saveCampaignIdea', apiData: campaignObj }
    } else if (campaignData.mode == 'update') {
      const { ciFormId } = campaignData.data;
      campaignObj.campaignIdeaInfo['ciFormStatus'] = this.campIdeaStatus;
      campaignObj.campaignIdeaInfo['ciFormId'] = ciFormId;
      return { mode: 'update', type: 'updateCampaignIdea', apiData: campaignObj, planId: this.elementDetails.campIdeaData.planId }
    } else {
      return null;
    }    
  }
  //uncomment for campaign idea image upload start
  fileChangeEvent(event){
    var files = event.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();

      reader.onload =this.handleFile.bind(this);

      reader.readAsBinaryString(file);
    }
  }



  handleFile(event) {
    var binaryString = event.target.result;
    this.base64textString= btoa(binaryString);



    const campaignData = this.elementDetails.campIdeaData;
    const campaignId = campaignData.data.campaignId;
    const ciFormId = campaignData.data.ciFormId;

    const imageData = {
      campaignId:campaignId,
      base64String:this.base64textString,
      ciFormId
    }
    this.selectedLogoImg = 'data:image/jpg;base64,'+this.base64textString;
    this.campaignIdeaForm.get("cmpLogo").patchValue(this.selectedLogoImg);
    // let dialogRef;
    // dialogRef = this.dialog.open(CampaignIdeaUploadDialogComponent, {
    //   data: imageData,
    //   minWidth: '500px'
    // });
    // dialogRef.afterClosed().subscribe((res: any) => {
    //   if (res.status) {
    //   }
    //   this.dialog.closeAll();
    // });
  }
  //uncomment for campaign idea image upload end


  //can be removed start
  // fileChangeEvent(event){
  //   const campaignData = this.elementDetails.campIdeaData;
  //   const campaignId = campaignData.data.campaignId;
  //   const result = this.constructImage(event.target.files[0]);
  //   // const result = this.readThis($event.target);

  //   const imageData = {
  //     campaignId:campaignId,
  //     base64String:result
  //   }
  //   let dialogRef;
  //   dialogRef = this.dialog.open(CampaignIdeaUploadDialogComponent, {
  //     data: imageData,
  //     minWidth: '500px'
  //   });
  //   dialogRef.afterClosed().subscribe((res: any) => {
  //     if (res.status) {
  //     }
  //     this.dialog.closeAll();
  //   });
    
  // }

  // readThis(inputValue: any){
  //   var file:File = inputValue.files[0];
  //   var myReader:FileReader = new FileReader();
  
  //   myReader.onloadend = (e) => {
  //     this.imageBase64Data = myReader.result as string;
  //   }
  //   myReader.readAsDataURL(file);
  //   return this.imageBase64Data;
  // }
  // constructImage(fileData){
  //   let me  = this;
  //   let reader = new FileReader();
  //   reader.readAsDataURL(fileData);
  //   reader.onloadend =e => {
  //     this.imageBase64Data = reader.result as string;
  //   }
  //   // reader.onload =e => this.imageData = reader.result.toString();
  //   this.imageData = this.imageBase64Data;
  //   return this.imageData.split(',')[1];
  // }
  //can be removed end

  ngOnDestroy(): void {
    this.formDirty.emit(false);

    const formId = this.elementDetails.campIdeaData.data.ciFormId;
    this.saveBtnSubscription.unsubscribe();
    const editLockObj: IEditLock = {
      beingEdited: 'N',
      editingBy: getGpId(),
      formId
    }

    if (formId) {
      this.programService.updateCampaignIdeaLock(editLockObj).subscribe(() => { });
    }
  

  }
  returnAccess(_oprator, conditionRoleId) {
    return isValidUserAccess(conditionRoleId,_oprator, null, this.elementDetails.campIdeaData.planId)
  }
  openKPIFrameworkPPTPage(){
    // let sidePanelEle = { ...GLOBAL_ELEMENTS.KPI_FRAMEWORK };
    // this.sharedDataService.setSideNavSelection(sidePanelEle); 
    this.changePageMode(PAGE_MODES.EDIT);
  }
  changePageMode(pageMode) {
    this.mode = pageMode
  }
  isHomePageMode() {
    return !(this.mode === PAGE_MODES.HOME);
  }

}
