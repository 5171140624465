import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HttpCacheService } from './cache/http-cache.service';
import { CacheInterceptor } from './interceptor/cache-interceptor.service';
import { CoreInterceptor } from './interceptor/core-interceptor.service';
import { LoaderInterceptor } from './interceptor/loader.interceptor';
import { ApiTimeLoggerInterceptorService } from './interceptor/api-time-logger-interceptor.service';

@NgModule({
  declarations: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CoreInterceptor,
      multi: true
    },
    HttpCacheService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
       useClass: LoaderInterceptor,
       multi: true
     },
     {
      provide: HTTP_INTERCEPTORS,
       useClass: ApiTimeLoggerInterceptorService,
       multi: true
     }
  ]
})
export class HttpCoreModule {}
