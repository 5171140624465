import { Component, OnInit, Inject } from '@angular/core';
import { PlanService } from 'app/plan/services/plan.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatSort, } from '@angular/material';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { ROLES } from 'app/constants/app-constants'
import { isValidUserAccess } from 'app/common/utils/common-util'

@Component({
  selector: 'app-manage-custom-audience-dialog-component',
  templateUrl: './manage-custom-audience-dialog.component.html',
  styleUrls: ['./manage-custom-audience-dialog.component.scss']
})
export class manageCustomAudienceDialog implements OnInit {
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  status=false;
  displayedColumns: string[] = [ 'audienceId', 'audienceName','updatedAt'];
  customAudience: string = '';
  dataSource= new MatTableDataSource<any>();
  showError: boolean = false;
  message: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private planService: PlanService,
    private dialogRef: MatDialogRef<manageCustomAudienceDialog>,
    private globalElementsService: GlobalElementsService,
  ) { }

  ngOnInit() {
    this.showError = false;
    this.message = '';
   this.getAllCustomAudience();
  }

deleteCustomAudience(_audienceId, customAudienceName) {
  this.showError = false;
    this.message = '';
  this.globalElementsService.customAudienceDelete({ audienceId: _audienceId, audienceName: customAudienceName, type: this.data.type }).subscribe(res => {
    if(res) {
      this.getAllCustomAudience();
    }
  
  }, (err) => {
    this.showError = true;
    this.message = 'Oops something went wrong, Deleting Custom Audience failed';

  });
}
  getAllCustomAudience(){
    this.globalElementsService.customAudienceGetAll({planId: this.data.planId, type: this.data.type}).subscribe(res => {
      if(res.data) {
        this.dataSource = new MatTableDataSource<any>(res["data"]);
      }
    }, (err) => {

    });
  }
  returnDate(_date) {
    return new Date(_date).toLocaleDateString();
   }
  saveCustomAudience() {
    if(this.customAudience) {
      this.showError = false;
      this.message = '';
      this.globalElementsService.customAudienceSave({planId: this.data.planId,refrenceId: this.data.refrenceId,audienceName: this.customAudience, type : this.data.type }).subscribe(res => {
        if(res) {
          this.getAllCustomAudience();
          this.customAudience = "";
        }
      
      }, (err) => {
        this.showError = true;
        this.message = 'Oops something went wrong, Failed to Save Custom Audience';
      });
    } else {
      this.showError = true;
      this.message = 'Please enter custom audience...';
    }
  }
  closeDialog(){
    this.dialogRef.close({ status: this.status });
  }
  showDelteBtn(element) {
    return (this.userInfo.gpid === element.updatedBy || isValidUserAccess(ROLES.ADMIN, '<=', null, this.data.planId ));
  }

}
