import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IOverview } from 'app/common/interfaces/common';
import { IOverTableInfo, IOverviewTable, ISectionHeader } from 'app/common/interfaces/overviewSection';
import { IPlans, IPortfolioBody, IPortfolioResponse } from 'app/common/interfaces/portfolio';
import { SharedDataService } from 'app/common/services/shared-data.service';
import { constructOverviewTableData } from 'app/common/utils/overview-util';
import { SECTION_HEADER_INFO, TABLE_HEADERS } from 'app/constants/overview-constants';
import { IBreadcrumb } from './../common/interfaces/common';
import { AGGR_VIEW, BREADCRUMBS_LAST_NODE_TYPES, BREADCRUMBS_REFERENCE_TYPES } from './../constants/app-constants';
import { PortfolioService } from "./services/portfolio.service";
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { Subscription } from '../../../node_modules/rxjs';
import { environment } from 'environments/environment';
import { returnAccessflag, isValidUserAccess } from 'app/common/utils/common-util';
import { ACCESS_LEVEL } from 'app/constants/app-constants';
import { googleAnalyticsCode } from 'app/util';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { IMarketCurrency } from 'app/common/interfaces/market';
@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  paramsId: number;
  portfolioId: string
  portfolioName: string;
  workingYear:number;
  pathDetails : IBreadcrumb;
  type:string='portfolio';
  overviewTableData:IOverTableInfo;
  plans: Array<IPlans>;
  sectionHeader: ISectionHeader;
  overviewTableObj: IOverviewTable = {
    businessUnit: '',
    totalPlanned: '',
    plannedToDate: '',
    actualToDate: '',
    spenDiff: '',
    plannedROI: '',
    actualROI: '',
    roiDiff: '',
    upfrontPlan: '',
    isGroup: false,
    isList: false,
    id: '',
    path: '',
    brandGrowth: '',
    audienceSeg: '',
    status: '',
    accessible: false
  };
  planItViewovwData: Array<IOverview>;
  tcpViewOvwData: Array<IOverview>;
  overviewData: Array<IOverview>;
  finalList: Array<IOverviewTable> = [];
  aggrView:string;
  userInfo;
  aggrViewSubscription: Subscription;
  workingYearSubscription: Subscription;
  
  constructor(
    private portfolioService: PortfolioService, 
    private route: ActivatedRoute,
    private sharedDataService: SharedDataService,
    public router: Router,
    protected googleTagManagerService: GoogleTagManagerService,
    private globalElementsService: GlobalElementsService
    ) { this.userInfo = JSON.parse(sessionStorage.getItem("user")); }

  /*........... Initialization Methods........ */

  ngOnInit() {

   this.aggrViewSubscription =  this.sharedDataService.getAggrView.subscribe((viewType: string) => {
      this.aggrView = viewType;
      
      if (this.aggrView == AGGR_VIEW.PLANIT) {
        this.getMediaMix();
        this.overviewData = this.planItViewovwData;
      } else {
        this.overviewData = this.tcpViewOvwData;
      }

    });

   this.workingYearSubscription = this.sharedDataService.getWorkingYear.subscribe((res: number) => {
      if (res != this.workingYear){
        this.workingYear = res;
        if(this.paramsId){
          this.getportfolioData();
        }
      }
    })

    this.route.params.subscribe(params => {
      const portfolioId = params['id'];
      this.portfolioId =  params['id'] ? params['id'].toString(): "";
      const returnAccessFlagResult = returnAccessflag(ACCESS_LEVEL.PORTFOLIO, portfolioId)
      if(!returnAccessFlagResult.result) {
         this.router.navigate(['/accessDenied']);
      } else if(returnAccessFlagResult.marketChange && returnAccessFlagResult.marketId) {
        this.globalElementsService.getCurrencyExchange(returnAccessFlagResult.marketId).subscribe((_res:IMarketCurrency) => {
          if(_res) {
              const xchangeRate = Number(_res['conversionFactor']);
              sessionStorage.setItem('currencySign', _res['currencySign']);
              this.sharedDataService.setMarket(returnAccessFlagResult.marketId);
              this.sharedDataService.setCurrencyView(_res['currencyCode']);
              this.sharedDataService.setCurrencySymbolSource(_res['currencySign'] ? _res['currencySign'] : '$');
              this.sharedDataService.setCurrencyExchangeRate(xchangeRate);
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.navigate([this.router.url]);
          }
      }, (err) => {
          
      });
    }
      
      this.paramsId = portfolioId && Number(portfolioId);
      let tagObject =  {
        event: `pageView`,
        pageUrl:`portfolio/${this.paramsId}`,
        pageTitle: `Portfolio Page`,
        userId: getGpId(),
        user_id: getGpId()
      }
      googleAnalyticsCode(tagObject,this.googleTagManagerService);
      this.getportfolioData();
    });
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Portfolio_Page`,
      eventAction: "PageView",
      eventLable: `Portfolio View`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }
  
  /*........... Service/API calls........ */
  
  getportfolioData() {
    this.overviewTableData=null;
    this.overviewData=null;
    this.finalList=[];
    let portfolioObj: IPortfolioBody = {
      portfolioId: this.paramsId,
      year: this.workingYear
    }
    this.portfolioService.getPortfolioData(portfolioObj).subscribe((res: IPortfolioResponse) => {
      if (res) {
        this.portfolioName = res.portfolio.displayName;
        this.tcpViewOvwData = res.pfOverview;
        this.planItViewovwData = res.planItOverview;
        this.overviewData = this.aggrView == AGGR_VIEW.PLANIT ? this.planItViewovwData : this.tcpViewOvwData;
        this.sectionHeader = SECTION_HEADER_INFO.PORTFOLIO;
        this.sectionHeader.path = `/portfolioAllocation/${res.portfolio.id}`;
        this.plans = res.plans;
        this.overviewTableData = this.constructOverviewTableData();
        this.pathDetails = {
          lastNodeName: this.portfolioName,
          pageId: this.paramsId,
          pageRefType: BREADCRUMBS_REFERENCE_TYPES.PORTFOLIO,
          lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.PORTFOLIO
        }
        if (this.aggrView == AGGR_VIEW.PLANIT) {
          this.getMediaMix();
        }
      }

    });

  }

  constructOverviewTableData(): IOverTableInfo {
    this.plans.forEach((plns: IPlans) => {
      const row = constructOverviewTableData({ displayName: plns.displayName, status: plns.status, overview: plns.planOverview }, this.overviewTableObj);
      row.id = plns.id;
      row.accessible = plns.accessible;
      row.path = `/plan/${plns.id}`
      this.finalList.push(row);

    });
    let tableObj: IOverTableInfo = {
      tableMainHeaderRow: TABLE_HEADERS.PORTFOLIO.MAIN,
      tableSubHeaderRow: TABLE_HEADERS.PORTFOLIO.SUB,
      tableData: this.finalList
    }
    return tableObj;
  }

  getMediaMix(){

    if(!this.paramsId || !this.workingYear){
      return null
    }

    const postObj = {
      portfolioId:this.paramsId,
      year: this.workingYear
    }
    this.portfolioService.getMediaMix(postObj).subscribe((res:any)=>{
      const mediaMixObj: IOverview = {
        refernceCode: 'FLOWPACK_MEDIA',
        annualWorkingMediaBudget: null,
        flowPackData: res && res.channelSpend || null,
        ytdROI: null,
        year: this.workingYear
      }
      this.overviewData = [...this.overviewData, mediaMixObj]
    
    })
  }

  navigateToAnnualMediaCalendar() {
    this.router.navigate([`/annualMediaCalendar/portfolio/${this.paramsId}`])
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Portfolio_Page`,
      eventAction: "Media Calendar Click",
      eventLable: `Portfolio Media Calendar Button Clicked`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  ngOnDestroy(): void {
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Portfolio_Page`,
      eventAction: "PageExit",
      eventLable: `Portfolio Page Exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);

    this.aggrViewSubscription.unsubscribe();
    this.workingYearSubscription.unsubscribe();
  }
  returnAccess(_oprator, conditionRoleId) {
    return isValidUserAccess(conditionRoleId,_oprator, this.portfolioId);
  }
}
