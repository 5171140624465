import { Component, EventEmitter,  OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PAGE_MODES } from './../../../constants/app-constants';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { UserDeleteDialogComponent } from '../../../common/components/dialogs/user-delete-dialog/user-delete-dialog.component';
import { MatDialog } from '../../../../../node_modules/@angular/material';
import { UsersDatatableComponent } from './users-datatable/users-datatable.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';
//import { BulkUserUploadDailog } from 'app/common/components/dialogs/bulk-user-upload-dailog/bulk-user-upload-dailog.component'

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit, OnDestroy {

  @ViewChild(UsersDatatableComponent, {static:false}) userDataTable: UsersDatatableComponent;
  @Output('dirty')  formDirty = new EventEmitter<boolean>()

  selectedUsersList = [];
  mode = PAGE_MODES.HOME;
  editData = null;
  userInfo = JSON.parse(sessionStorage.getItem('user'));

  constructor(
    private globalElementsService: GlobalElementsService,
    public dialog: MatDialog,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }

  /*........... Initialization Methods........ */

  ngOnInit() {
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `User_Management`,
      eventAction: "PageView",
      eventLable: `Global Elements User Management Page View`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  /*........... functionality Methods........ */

  getSelectedUser(childEmmitedEvent) {
    this.selectedUsersList = childEmmitedEvent;
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `User_Management`,
      eventAction: "Select User",
      eventLable: `Global Elements Select User Click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }


  isHomePageMode() {
    return this.mode === PAGE_MODES.HOME;
  }
 /* openbulkuseruploadpopup() {
    const dialogRef = this.dialog.open(BulkUserUploadDailog, {
      data: {},
      minWidth: '500px'
    });


    dialogRef.beforeClosed().subscribe(() => {
      this.userDataTable.getUsersLists();
    });
  }*/
  createBulkUser() {
    this.changePageMode(PAGE_MODES.BLKUPLOAD);
  }
  enableCreateMode() {
    this.changePageMode(PAGE_MODES.CREATE);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `User_Management`,
      eventAction: "New User click",
      eventLable: `Global Elements User Management Click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }



  enableEditMode() {
    this.globalElementsService.getUserData(this.selectedUsersList[0].gpid).subscribe((res: any) => {
      this.editData = res.user;
      this.changePageMode(PAGE_MODES.EDIT);
      let tagObject =  {
        event: `custom_${environment.environmentName}_Event`,
        eventCategory: `User_Management`,
        eventAction: "Edit User",
        eventLable: `Global Elements Edit User Click`,
        customeEvent: `custom_${environment.environmentName}_Event`,
        userId: getGpId(),
        user_id: getGpId()
      }
      googleAnalyticsCode(tagObject,this.googleTagManagerService);
    }, err => {
      this.changePageMode(PAGE_MODES.CREATE);
   });
  }

  changePageMode(pageMode) {
    this.mode = pageMode
  }


  handleDelete() {


    /** Opens Dialog modal to confirm delete actions */

    const userIds = this.selectedUsersList.map(user => user.id);

    const dialogRef = this.dialog.open(UserDeleteDialogComponent, {
      data: { userIds },
      minWidth: '500px'
    });


    dialogRef.beforeClose().subscribe(() => {
      this.userDataTable.getUsersLists();

    });

    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `User_Management`,
      eventAction: "Delete User click",
      eventLable: `Global Elements Delete User Click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }
  isFormDirty(isDirty:boolean) {
    this.formDirty.emit(isDirty);
  }
  
   ngOnDestroy(): void {
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `User_Management`,
      eventAction: "PageExit",
      eventLable: `Global Elements User Management Page exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }
       
  

}
