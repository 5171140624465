import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator, MatDialog } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { GlobalElementsService } from '../services/global-elements.service';
import * as moment from 'moment';
import { ALERT_STATUS, GLOBAL_ELEMENTS } from 'app/constants/app-constants';
import { take } from 'rxjs/operators';
import { NotificationDialogComponent } from 'app/common/components/dialogs/notification-dialog/notification-dialog.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})


export class NotificationsComponent implements OnInit {

  USER_INFO = JSON.parse(sessionStorage.getItem('user'));
  usrGpid = this.USER_INFO.gpid;
  obs: Observable<any>;
  dataSource= new MatTableDataSource<any>();
  notificationList=[];
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  isLoading: boolean=false;
  alertStatus=ALERT_STATUS;
  saveBtnSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private globalElementsService: GlobalElementsService,
    protected googleTagManagerService: GoogleTagManagerService,
    private router: Router,
    ) {
   
   }

  ngOnInit() {
    this.globalElementsService.setShowSaveBtn(true);
    this.globalElementsService.setSaveEnableState(false);
    this.saveBtnSubscription = this.globalElementsService.getSidePanelSaveEvent.pipe(take(1)).subscribe(() => this.markAsRead());
    this.getNotifications();
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Notifications_Page`,
      eventAction: " PageView",
      eventLable: `Global Elements Notifications Page View`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }


  getFormattedDate(dateString) {
    return moment(dateString).isValid() ? moment(dateString).format('lll') : 'N/A';
  }
  
  navigateTheLink(link: string) {
    if (!link) {
      return;
    }
    this.globalElementsService.setSidePanelCancelEvent();
    this.router.navigate([link]);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  getNotifications() {
    this.isLoading = true;
    this.globalElementsService.getNotifications().subscribe((res: any) => {
      this.isLoading = false;
      this.notificationList = res;
      this.dataSource = new MatTableDataSource<any>(this.notificationList);
      this.dataSource.paginator = this.paginator;
      this.obs = this.dataSource.connect();

    },
      (error) => {
        this.isLoading = false;
        console.error('Error: ', error)
      });
  }

   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.notificationList.filter((element:any) =>  !element.read).length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.globalElementsService.setSaveEnableState(false);
      return;
    }
    let selectData=this.notificationList.filter((element:any) =>  !element.read);
    this.selection.select(...selectData);
    this.globalElementsService.setSaveEnableState(true);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Notifications_Page`,
      eventAction: "Notifications Read Click",
      eventLable: `Global Elements Mark All Notifications Read Click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }
  selectNotification(notification:any){
    this.selection.toggle(notification);

    if(this.selection.selected.length > 0){
      this.globalElementsService.setSaveEnableState(true);
    }else{
      this.globalElementsService.setSaveEnableState(false);
    };
    
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    return 'select';
  }


  markAsRead() {
    const selectedNotificationIds = this.selection.selected && this.selection.selected.map(obj => obj.alertId);
    const dialogRef = this.dialog.open(NotificationDialogComponent, {
      data: {
        type: 'update',
        module: 'readNotification',
        apiData: { alertIds: selectedNotificationIds },
      },
      minWidth: '500px'
    })

    dialogRef.afterClosed().subscribe(res => {
      this.globalElementsService.setSidePanelCancelEvent();
    });
  }
 
  /*........... Clean up methods........ */
  
  ngOnDestroy() {

    this.saveBtnSubscription.unsubscribe();
    this.globalElementsService.setShowSaveBtn(false);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Notifications_Page`,
      eventAction: "Page Exit",
      eventLable: `Global Elements Notifications Page exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

}
