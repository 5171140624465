import { IMarketsList, IBrandEdit } from './../../common/interfaces/brandManagement';
import { IPortfolioSave, IPortfolioGroupSave, IBrandSave } from '../../common/interfaces/brandManagement';
import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IMarketGroupSave, IMarketSave } from '../../common/interfaces/brandManagement';

const BASE_API_URL = `${environment.api}/v3`;

@Injectable({
    providedIn: 'root'
})
export class BrandManagementService {

    constructor(private httpClient: HttpClient) { }


    /*  ...................... GET APIs ......................  */
    getAllMarketGroups() {
        return this.httpClient.get(`${BASE_API_URL}/brand/getAllMarketGroups`);
    }

    getAllMarkets() {
        return this.httpClient.post(`${BASE_API_URL}/brand/getMarkets`, {});
    }

    getAllPortfolios() {
        return this.httpClient.post(`${BASE_API_URL}/brand/getPortfolios`, null);
    }

    getAllPortfolioGroups() {
        return this.httpClient.get(`${BASE_API_URL}/pfgroup/getAllPortfolioGroups`);
    }


    getAllBrandsByMkt(marketId: number) {
        return this.httpClient.post(`${BASE_API_URL}/brand/getBrands`, { marketId });
    }

    getMarketsByMarketGroup(groupName: string) {
        return this.httpClient.post(`${BASE_API_URL}/brand/getMarketsForMarketGroup`, { groupName });
    }
    
    getPortfoliosByPortfolioGroup(groupName: string) {
        return this.httpClient.post(`${BASE_API_URL}/brand/getPortfoliosForPortfolioGroup`, { groupName });
    }

    getPlanByPortfolio(portfolioId: number) {
        return this.httpClient.post(`${BASE_API_URL}/brand/getPlansByPortfolio`, { portfolioId });
    }

    getBrandLogosFromDAM() {
        return this.httpClient.post(`${BASE_API_URL}/brand/getAssets`, {});
    }

    getSubBrandFromMDM(brandName: string) {
        return this.httpClient.post(`${BASE_API_URL}/brand/getProgramsFromMDM`, { brandName });
    }


    getBrandsFromMDM() {
        return this.httpClient.post(`${BASE_API_URL}/brand/getPlansFromMDM`, {});
    }


    /*  ...................... Save/Edit APIs ......................  */
    saveMarket(market: IMarketSave) {
        return this.httpClient.post(`${BASE_API_URL}/brand/saveMarket`, market);
    }

    saveMarketGroup(marketGroup: IMarketGroupSave) {
        return this.httpClient.post(`${BASE_API_URL}/brand/saveMarketGroup`, marketGroup);
    }

    savePortfolio(portfolio: IPortfolioSave) {
        return this.httpClient.post(`${BASE_API_URL}/brand/savePortfolio`, portfolio);
    }

    savePortfolioGroup(portfoliogroup: IPortfolioGroupSave) {
        return this.httpClient.post(`${BASE_API_URL}/brand/savePortfolioGroup`, portfoliogroup);
    }

    saveBrandAsProgram(brandInfo: IBrandSave) {
        return this.httpClient.post(`${BASE_API_URL}/brand/saveBrand`, brandInfo);
    }

    saveBrandAsPlan(brandInfo: IBrandSave) {
        return this.httpClient.post(`${BASE_API_URL}/brand/saveBrand`, brandInfo);
    }

    editBrand(brandInfo: IBrandEdit){
        return this.httpClient.post(`${BASE_API_URL}/brand/editBrand`, brandInfo);
    }
    
    getUpfrontAllocationByPlan(brandInfo: IBrandEdit){
        return this.httpClient.post(`${BASE_API_URL}/brand/getUpfrontAllocationByPlan`, brandInfo);
    }


    /*  ...................... Delete APIs ......................  */
    deleteMarket(markets: Array<any>) {
        return this.httpClient.post(`${BASE_API_URL}/brand/deleteMarket`, markets);
    }

    deleteMarketGroups(marketGroupIds: Array<number>) {
        return this.httpClient.post(`${BASE_API_URL}/brand/deleteMarketGroup`, marketGroupIds);
    }

    deletePortfolios(portfolioIds: Array<number>) {
        return this.httpClient.post(`${BASE_API_URL}/brand/deletePortfolio`, portfolioIds);
    }

    deletePortfolioGroups(portfolioGroupIds: Array<number>) {
        return this.httpClient.post(`${BASE_API_URL}/brand/deletePortfolioGroup`, portfolioGroupIds);
    }

    deleteBrands(brands: Array<any>) {
        return this.httpClient.post(`${BASE_API_URL}/brand/deleteBrand`, brands);
    }

}
