import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { IHttpApiAppConfig } from '../api/config/interface/ihttp-api-app-config';
import { HttpCacheService } from '../cache/http-cache.service';
import { HttpApiConfigService } from '../api/config/http-api-config.service';
import { isNil } from 'lodash-es';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(
    private cache: HttpCacheService,
    private httpApiConfigService: HttpApiConfigService
  ) {}
  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    const appConfig: IHttpApiAppConfig =
      this.httpApiConfigService.getConfiguration();
    const endpointConfig = this.getEndpointConfig(req);
    // reinitialize req after removing temp http header data used for transferring endpoint configs
    req = endpointConfig.req;
    const cachedResponse =
      req.method.toUpperCase() === 'GET' &&
      appConfig &&
      !appConfig.noCache &&
      !endpointConfig.noCache
        ? this.cache.get(req)
        : null;
    return cachedResponse
      ? of(cachedResponse).pipe(delay(0))
      : this.sendRequest(req, next, this.cache);
  }

  sendRequest(
    req: HttpRequest<unknown>,
    next: HttpHandler,
    cache: HttpCacheService
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          cache.put(req, event);
        }
      })
    );
  }

  getEndpointConfig(req: HttpRequest<unknown>): {
    noCache: boolean;
    useMockData: boolean;
    req: HttpRequest<unknown>;
  } {
    let headers: HttpHeaders = req.headers;
    let noCache = true;
    let useMockData = true;
    if (!isNil(headers.has('noCache'))) {
      noCache = JSON.parse(headers.get('noCache') as string) as boolean;
      headers = headers.delete('noCache');
    }
    if (!isNil(headers.has('useMockData'))) {
      useMockData = JSON.parse(headers.get('useMockData') as string) as boolean;
      headers = headers.delete('useMockData');
    }
    req = req.clone({ headers });

    return {
      noCache,
      useMockData,
      req
    };
  }
}
