import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { IEmbedConfiguration, models } from "powerbi-client";
const BASE_API_URL = `${environment.api}/v3`;
@Injectable({
  providedIn: "root",
})
export class PowerBiConfigService {
  accessToken = { token: "" };
  token: string = "";
  reportId: string;
  reportEmbedUrl: string;
  workspaceId: string;
  datasetId: string;
  config: IEmbedConfiguration;
  constructor(private httpClient: HttpClient) {}
  getAccessToken(): Observable<any> {
    return this.httpClient.get(`${BASE_API_URL}/powerbitoken/getAccessToken`);
  }
  getEmbedUrl(accessToken: string, apiUrl: string): Observable<any> {
    const postObj = {
      token: accessToken,
      apiUrl: apiUrl,
    };

    console.log("apiUrl:::", postObj);
    return this.httpClient.post(
      `${BASE_API_URL}/powerbitoken/getEmbedUrl`,
      postObj
    );
  }
  /**
   * Embeds report
   *
   * @returns Promise<void>
   */
  embedReport(embedReportConfig) {
    if (embedReportConfig) {
      this.reportId = embedReportConfig.embedData.id;
      this.reportEmbedUrl = embedReportConfig.embedData.embedUrl;
      this.workspaceId = embedReportConfig.embedData.datasetWorkspaceId;
      this.datasetId = embedReportConfig.embedData.datasetId;
    }
  }
  embedReportConfig(embedReportConfig) {
    if (embedReportConfig) {
      this.config = embedReportConfig;
    }
  }
  getEmbedToken(): Observable<any> {
    const postObj = {
      reportId: this.reportId,
      datasetId: this.datasetId,
      targetWorkspaceId: this.workspaceId,
      accessToken: this.accessToken.token,
    };
    return this.httpClient.post(
      `${BASE_API_URL}/powerbitoken/getEmbedToken`,
      postObj
    );
  }
  // getPowerBIToken(): Observable<any> {
  //   return this.httpClient.get(`${BASE_API_URL}/powerbitoken/getPowerBIToken`);
  // }
}
