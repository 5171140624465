import { BREADCRUMBS_LAST_NODE_TYPES, ROLES } from './../../constants/app-constants';
import { BREADCRUMBS_REFERENCE_TYPES } from 'app/constants/app-constants';
import { IBreadcrumb } from './../../common/interfaces/common';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { Component, Input, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { IDocument } from 'app/common/interfaces/common';
import { ICampaign, IMjtbds } from 'app/common/interfaces/plan';
import { getDocsFromSharepointFilesAPI, getFileBuffer, isValidUserAccess } from 'app/common/utils/common-util';
import { FILE_NAMES, SHAREPOINT_DOC_PATH, TCP_CHART_LEGENDS, TCP_DOC_ID, TCP_PROGRESS_INPUT_KEYS } from 'app/constants/app-constants';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { PlanService } from '../services/plan.service';
import { TcpProgressPdfComponent } from '../tcp-progress-pdf/tcp-progress-pdf.component';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import { MatTableDataSource, MatSort, Sort, MatDialog } from '@angular/material';
const BASE_API_URL = `${environment.api}`;
import ab2b64 from 'ab2b64' ;
import { async } from '@angular/core/testing';
import { saveAs } from 'file-saver';
import { fileMimeTypes } from 'app/constants/app-constants';

@Component({
  selector: 'app-tcp-document-center',
  templateUrl: './tcp-document-center.component.html',
  styleUrls: ['./tcp-document-center.component.scss']
})
export class TcpDocumentCenterComponent implements OnInit {

  @Input() elementDetails;
  @ViewChild(TcpProgressPdfComponent, { static: true }) TcpProgressPdfComponent: TcpProgressPdfComponent;
  @ViewChild('uploadDialog', { static: true }) uploadDialog: TemplateRef<any>;
  @ViewChild('deleteFiledDialog', { static: true }) deleteFiledDialog: TemplateRef<any>;
  
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  mjtbdData: Array<IMjtbds> = [];
  tcpDocData;
  mjtbdId: number;
  selectedMjtbd: IMjtbds;
  oppBriefAudienceDocList: Array<IDocument>;
  tcpChartData;
  activationCalender: Array<IDocument> = [];
  kpiFrameworkStatus: boolean = false;
  connectionsStatus: boolean = false;
  oppBriefStatus: boolean = false;
  ideaStatus: boolean = false;
  architectureStatus: boolean = false;
  year: number;
  campaignId: number = 0;
  selectedCampaign: ICampaign;
  pathDetails: IBreadcrumb;
  FileNamePPTX: string;
  DownloadBase64Data: string;
  dataSource= new MatTableDataSource<any>();
  displayedColumns: string[] = ['filepath','fileName', 'firstName', 'updatedAt'];  
  chart;
  showCloseBtn: boolean = false;
  message: string = "Uploading File...";
  deletedailogMessage: string = "Deleting File...";
  showDeleteFileBtn: boolean = false;
  delteFileObj: any = null;
  mjtbdIdArray: any = [];
  kpiTemplateData: any = [];
  constructor(
    private globalElementsService: GlobalElementsService,
    private planService: PlanService,
    public dialog: MatDialog,
  ) { }

  /*........... Initialization Methods........ */

  ngOnInit() {

    this.tcpDocData = this.elementDetails.tcpDocData;
    this.pathDetails = {
      lastNodeName: 'TCP Document Tracker',
      pageId: this.tcpDocData.planId,
      pageRefType: BREADCRUMBS_REFERENCE_TYPES.PLAN,
      lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.TCP_DOCUMENT_TRACKER
    }
    this.mjtbdData = this.elementDetails.tcpDocData.mjtbdData;
    this.mjtbdIdArray =  this.mjtbdData.map(el => el.id);
    this.activationCalender = this.tcpDocData.tcpActCalData;
    this.year = this.elementDetails.tcpDocData.year;
    this.getOppBriefDocsFromSharepoint();
    this.refreshTCPDoctableData();
    this.getKpiFrameworktemplateData();
  }

  /*........... functionality Methods........ */

  am4themes_repartition(target) {
    if (target instanceof am4core.ColorSet) {
      target.list = [
        am4core.color("#0C2D83"),
        am4core.color("#DA291C"),
        am4core.color("#ED7D31"),
        am4core.color("#0085CA"),
        am4core.color("#7030A0"),
      ];
    }
  }



  constructChartData() {
      const chartData = [
        {
          category: TCP_CHART_LEGENDS.KPI_FRAMEWORK,
          value: this.getKpiFrameworkPercentage(),
          full: 100,
        }, {
          category: TCP_CHART_LEGENDS.ARCHITECTURE,
          value: this.getArchitecturePercentage(),
          full: 100
        }, {
          category: TCP_CHART_LEGENDS.OPP_BRIEF,
          value: this.getOpportunityPercentage(),
          full: 100
        },
        {
          category: TCP_CHART_LEGENDS.IDEA,
          value: this.getCampaignIdeaPercentage(),
          full: 100
        }, {
          category: TCP_CHART_LEGENDS.CONNECTIONS,
          value: this.getConnectionPercentage(),
          full: 100
        }
      ];
  
  
      return chartData;
  
    
  }

  checkHasChartValues(chartData: Array<any>) {

    chartData.forEach(item => {
      switch (item.category) {
        case TCP_CHART_LEGENDS.KPI_FRAMEWORK: {
          this.kpiFrameworkStatus = item.value > 0;
          break;
        }
        case TCP_CHART_LEGENDS.ARCHITECTURE: {
          this.architectureStatus = item.value > 0;
          break;
        }
        case TCP_CHART_LEGENDS.OPP_BRIEF: {
          this.oppBriefStatus = item.value > 0;
          break;
        }
        case TCP_CHART_LEGENDS.IDEA: {
          this.ideaStatus = item.value > 0;
          break;
        }
        case TCP_CHART_LEGENDS.CONNECTIONS: {
          this.connectionsStatus = item.value > 0;
          break;
        }
      }
    })


  }

  async populateTcpProgressChart() {
    am4core.useTheme(this.am4themes_repartition);

    this.chart = am4core.create("tcpProgressChart", am4charts.RadarChart);
    let chart = this.chart;
    chart.data = await this.constructChartData();
    this.checkHasChartValues(chart.data);

    chart.startAngle = -90;
    chart.endAngle = 180;
    chart.innerRadius = am4core.percent(30);

    chart.numberFormatter.numberFormat = "#.#'%'";

    let cAxis: any = new am4charts.CategoryAxis()
    let categoryAxis = chart.yAxes.push(cAxis);
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.fontWeight = 500;
    categoryAxis.renderer.labels.template.adapter.add("fill", function (fill, target) {
      return (target.dataItem.index >= 0) ? chart.colors.getIndex(target.dataItem.index) : fill;
    });
    categoryAxis.renderer.minGridDistance = 20;

    let valueAx: any = new am4charts.ValueAxis();
    let valueAxis = chart.xAxes.push(valueAx);
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;

    let series1 = chart.series.push(new am4charts.RadarColumnSeries());
    series1.dataFields.valueX = "full";
    series1.dataFields.categoryY = "category";
    series1.clustered = false;
    series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
    series1.columns.template.fillOpacity = 0.08;
    series1.columns.template.strokeWidth = 0;
    series1.columns.template.radarColumn.cornerRadius = 20;

    let series2 = chart.series.push(new am4charts.RadarColumnSeries());
    series2.dataFields.valueX = "value";
    series2.dataFields.categoryY = "category";
    series2.clustered = false;
    series2.columns.template.strokeWidth = 0;
    series2.columns.template.tooltipText = "{category}: [bold]{value}[/]";
    series2.columns.template.radarColumn.cornerRadius = 20;

    series2.columns.template.adapter.add("fill", function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index);
    });

    valueAxis.disabled = true;
  }


  mjtbdChange() {
    this.tcpChartData = [];
    const year = this.elementDetails.tcpDocData.year;
    const mjtbdObj = {
      mjtbdId: this.mjtbdId,
      year
    }
    this.getTcpChartData(mjtbdObj);
    this.selectedMjtbd = this.mjtbdData.find(mjtbd => mjtbd.id == this.mjtbdId);
    this.campaignId = 0;
    this.chart.dispose();

    //this.populateTcpProgressChart();
  }

  campaignChange() {
    this.selectedCampaign = this.tcpChartData.campaigns.find(el => el.campaignId == this.campaignId);
    this.TcpProgressPdfComponent.populateKpiFrameworkData(this.selectedCampaign);
    this.TcpProgressPdfComponent.populateArchitecturePdfData(this.tcpDocData.tcpData);
    this.populateTcpProgressChart();
  }

  getFieldCount(myObj, keys: Array<string>) {
    let count = 0;
    if (myObj) {
      for (let key of keys) {
        if (myObj[key]) {
          count++
        }
      }
    }
    return count;
  }

  getKpiFrameworkPercentage() {
    let kpiPercentage = 0;
    const tcpChartData = this.tcpChartData;
    let hasValueMjtbdInputCount = 0;
    const kpiFramework_mjtbd_inputs = TCP_PROGRESS_INPUT_KEYS.KPI_FRAMEWORK.MJTBD_INPUTS;
    this.mjtbdData.forEach(mjtbd => {
      if (mjtbd.mjtbdForm) {
        hasValueMjtbdInputCount += this.getFieldCount(this.selectedMjtbd.mjtbdForm, kpiFramework_mjtbd_inputs);
      }
    })

    const camapignLength = tcpChartData.campaigns.length;

    let hasValueOppInputCount = 0;
    let campaignIdeaInputCount = 0;
    let hasValueCampignIdeaInputCount = 0;

    if (this.selectedCampaign) {
      hasValueOppInputCount += this.getFieldCount(this.selectedCampaign.oppForm, TCP_PROGRESS_INPUT_KEYS.KPI_FRAMEWORK.OPP_INPUTS);
      if (this.selectedCampaign.campaignIdeas.length != 0) {
        this.selectedCampaign.campaignIdeas.forEach(campignIdea => {
          hasValueCampignIdeaInputCount += this.getFieldCount(campignIdea, TCP_PROGRESS_INPUT_KEYS.KPI_FRAMEWORK.IDEA_INPUTS);
        });
        campaignIdeaInputCount += this.selectedCampaign.campaignIdeas.length;
      }
    }

    const commsTasksLength = this.tcpChartData.commsTasks.length;
    let optimizationInputCount = 0;
    let hasValueOptimizationInputCount = 0;
    if (commsTasksLength != 0) {
      this.tcpChartData.commsTasks.forEach(commsTask => {
        if (commsTask.scenarioData.length != 0) {
          commsTask.scenarioData.forEach(scenarioItem => {
            if (scenarioItem.channels.length != 0) {
              scenarioItem.channels.forEach(channel => {
                hasValueOptimizationInputCount += this.getFieldCount(channel, TCP_PROGRESS_INPUT_KEYS.KPI_FRAMEWORK.CHANNEL_INPUTS);
              })
              optimizationInputCount += scenarioItem.channels.length;
            }
          })
        }
      });
    }
    const totalInputsCount = (kpiFramework_mjtbd_inputs.length * this.mjtbdData.length) + camapignLength + campaignIdeaInputCount + optimizationInputCount;
    const hasValueInputsCount = hasValueMjtbdInputCount + hasValueOppInputCount + hasValueCampignIdeaInputCount + hasValueOptimizationInputCount;
    kpiPercentage = (hasValueInputsCount / totalInputsCount) * 100;
    return kpiPercentage;

  }

  getArchitecturePercentage() {
    const architecture_tcp_inputs = TCP_PROGRESS_INPUT_KEYS.ARCHITECTURE.TCP_INPUTS;
    const architecture_mjtbd_inputs = TCP_PROGRESS_INPUT_KEYS.ARCHITECTURE.MJTBD_INPUTS;
    const hasValueTcpInputCount = this.getFieldCount(this.tcpDocData.tcpData, architecture_tcp_inputs);
    const hasValueMjtbdInputCount = this.getFieldCount(this.selectedMjtbd.mjtbdForm, architecture_mjtbd_inputs);
    const hasDataIntcpActCal = Number(this.tcpDocData.tcpActCalData.length != 0);

    let hasValueCampignIdeaInputCount = 0;
    if (this.selectedCampaign && this.selectedCampaign.campaignIdeas.length != 0) {
      this.selectedCampaign.campaignIdeas.forEach(campignIdea => {
        hasValueCampignIdeaInputCount += this.getFieldCount(campignIdea, TCP_PROGRESS_INPUT_KEYS.ARCHITECTURE.IDEA_INPUTS);
      });
    }
    const totalInputsCount = architecture_tcp_inputs.length + architecture_mjtbd_inputs.length + this.selectedCampaign.campaignIdeas.length + 1;
    const hasValueInputsCount = hasDataIntcpActCal + hasValueTcpInputCount + hasValueMjtbdInputCount + hasValueCampignIdeaInputCount;
    const architecturePercentage = (hasValueInputsCount / totalInputsCount) * 100;
    return architecturePercentage;
  }

  getOpportunityPercentage() {
    let hasValueOppInputCount = 0;
    let hasAudienceDoc = 0;
    let oppPercentage = 0;
    const oppBrief_inputs = TCP_PROGRESS_INPUT_KEYS.OPPORTUNITY.OPP_INPUTS;
    const plan = this.elementDetails.tcpDocData.plan;
    if (this.selectedCampaign && this.selectedCampaign.oppForm) {
      // const campaignName = this.selectedCampaign.campaignName;
      // const audienceDocObj = this.oppBriefAudienceDocList && this.oppBriefAudienceDocList.find(doc => doc.title == `${campaignName} ${FILE_NAMES.OPP_BRIEF_AUDIENCE}`);
      const campaignName = this.selectedCampaign ? this.selectedCampaign.campaignName : "";
      let fileName = `${this.year}${plan}${campaignName}${TCP_DOC_ID.AUDIENCE_CONSIDERATION}`
      fileName = fileName.replace(/ /g, '');
      const audienceDocObj = this.oppBriefAudienceDocList && this.oppBriefAudienceDocList.find(doc => doc.title == `${fileName}.${doc["fileExt"]}`);

      if (audienceDocObj) {
        hasAudienceDoc++;
      }
      hasValueOppInputCount += this.getFieldCount(this.selectedCampaign.oppForm, oppBrief_inputs);
      const totalInputsCount = oppBrief_inputs.length + 1;
      const hasValueInputsCount = hasValueOppInputCount + hasAudienceDoc;
      oppPercentage = (hasValueInputsCount / totalInputsCount) * 100;
    }
    return oppPercentage;
  }

  base64FileTypeByExtension(_ext) {
    switch (`.${_ext}`) {
      case ".pptx":
        return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      case ".pdf":
        return "application/pdf";
      case ".png":
      case ".jpg":
      case ".jpeg":
        return "image/jpeg";
      case ".txt":
        return "text/plain";
      default:
        return "application/octet-stream"

    }
  }

  getCampaignIdeaPercentage() {
    let hasValueCampaignIdeaInputCount = 0;
    let campaignIdeaInputCount = 0;
    let campaignIdeaPercentage = 0;
    const campaign_inputs = TCP_PROGRESS_INPUT_KEYS.CAMPAIGN_IDEA.IDEA_INPUTS;
    if (this.selectedCampaign && this.selectedCampaign.campaignIdeas.length != 0) {
      this.selectedCampaign.campaignIdeas.forEach(campignIdea => {
        hasValueCampaignIdeaInputCount += this.getFieldCount(campignIdea, campaign_inputs);
      });
      campaignIdeaInputCount += this.selectedCampaign.campaignIdeas.length;
      const totalInputsCount = campaign_inputs.length * campaignIdeaInputCount;
      campaignIdeaPercentage = (hasValueCampaignIdeaInputCount / totalInputsCount) * 100;
    }
    return campaignIdeaPercentage;
  }

  getConnectionPercentage() {
    let connectionPercentage = 0;
    const commsTasksLength = this.tcpChartData.commsTasks.length;
    let hasValueConnectionTaskInputCount = 0;
    let connectionTaskInputCount = 0;
    const channel_inputs = TCP_PROGRESS_INPUT_KEYS.CONNECTIONS.CHANNEL_INPUTS;
    if (commsTasksLength != 0) {
      this.tcpChartData.commsTasks.forEach(commsTask => {
        if (commsTask.scenarioData.length != 0) {
          commsTask.scenarioData.forEach(scenarioItem => {
            if (scenarioItem.channels.length != 0) {
              scenarioItem.channels.forEach(channel => {
                channel['commsTaskName'] = commsTask.name;
                hasValueConnectionTaskInputCount += this.getFieldCount(channel, channel_inputs);
              });
              connectionTaskInputCount += scenarioItem.channels.length;
            }
          })
        }
      });
      const totalInputsCount = channel_inputs.length * connectionTaskInputCount;
      connectionPercentage = (hasValueConnectionTaskInputCount / totalInputsCount) * 100;
    }
    return connectionPercentage;
  }

  // htmlToPdfDownload(id: string) {

  //   const plan = this.elementDetails.tcpDocData.plan;
  //   const { year, hierarchy } = this.elementDetails.tcpDocData;
  //   const docId = id.toLocaleUpperCase();
  //   let fileName = `${this.year}${plan}${this.selectedCampaign ? this.selectedCampaign.campaignName : ""}${TCP_DOC_ID[docId]}.pptx`
  //   fileName = fileName.replace(/ /g, '');
  //   switch (`${TCP_DOC_ID[docId]}`) {
  //     case TCP_DOC_ID.OPP_BRIEF:
  //       this.planService.genrateTCPBriefTemplate({ optBriefData: this.selectedCampaign.oppForm, fileName: fileName, campaignName: this.selectedCampaign ? this.selectedCampaign.campaignName : "", mjtbd: this.selectedMjtbd ? this.selectedMjtbd : {} }).subscribe((res) => {
  //         this.FileNamePPTX = `${this.year}${this.elementDetails.tcpDocData.plan}${this.selectedCampaign.campaignName.replace(/ /g, '')}${TCP_DOC_ID[docId]}.pptx`;
  //         this.DownloadBase64Data = "data:application/pptx;base64," + res["base64Data"];
  //         this.downloadFileFromBase64(fileName);
  //       });
  //       break;
  //     case TCP_DOC_ID.ARCHITECTURE:
  //       this.planService.genrateTCPArchitectureTemplate({ templateTCPArchiteData: this.generateTemplateObj(docId), fileName: fileName, campaignName: (this.selectedCampaign ? this.selectedCampaign.campaignName : "") }).subscribe((res) => {
  //         this.FileNamePPTX = `${this.year}${this.elementDetails.tcpDocData.plan}${this.selectedCampaign ? this.selectedCampaign.campaignName.replace(/ /g, '') : ""}${TCP_DOC_ID[docId]}.pptx`;
  //         this.DownloadBase64Data = "data:application/pptx;base64," + res["base64Data"];
  //         this.downloadFileFromBase64(fileName);
  //       });
  //       break;
  //     case TCP_DOC_ID.BRAND_GROWTH:
  //         this.planService.genrateBRAND_GROWTHTemplate({ templateBrandGrowth: this.generateTemplateObj(docId), fileName: fileName, campaignName: this.selectedCampaign ? this.selectedCampaign.campaignName : "" }).subscribe((res) => {
  //         this.FileNamePPTX = `${this.year}${this.elementDetails.tcpDocData.plan}${this.selectedCampaign ? this.selectedCampaign.campaignName.replace(/ /g, '') : ""}${TCP_DOC_ID[docId]}.pptx`;
  //         this.DownloadBase64Data = "data:application/pptx;base64," + res["base64Data"];
  //         this.downloadFileFromBase64(fileName);
  //       });
  //       break;
  //     case TCP_DOC_ID.KPI_FRAMEWORK:
  //         this.planService.genrateKPI_FRAMEWORK_Template({ templateKpiFramework: this.generateTemplateObj(docId), fileName: fileName, campaignName: this.selectedCampaign ? this.selectedCampaign.campaignName : "" }).subscribe((res) => {
  //         this.FileNamePPTX = `${this.year}${this.elementDetails.tcpDocData.plan}${this.selectedCampaign ? this.selectedCampaign.campaignName.replace(/ /g, '') : ""}${TCP_DOC_ID[docId]}.pptx`;
  //         this.DownloadBase64Data = "data:application/pptx;base64," + res["base64Data"];
  //         this.downloadFileFromBase64(fileName);
  //       });
  //       break;
  //     case TCP_DOC_ID.CAMPAIGN_IDEA:
  //       this.planService.genrateTCPCAMPAIGN_IDEATemplate({ templateCampaignIdeaData: this.generateTemplateObj(docId), fileName: fileName, campaignName: this.selectedCampaign ? this.selectedCampaign.campaignName : "" }).subscribe((res) => {
  //         this.FileNamePPTX = `${this.year}${this.elementDetails.tcpDocData.plan}${this.selectedCampaign.campaignName.replace(/ /g, '')}${TCP_DOC_ID[docId]}.pptx`;
  //         this.DownloadBase64Data = "data:application/pptx;base64," + res["base64Data"];
  //         this.downloadFileFromBase64(fileName);
  //       });
  //       break;
  //     case TCP_DOC_ID.AUDIENCE_CONSIDERATION:
  //       const audienceDocObj = this.oppBriefAudienceDocList && this.oppBriefAudienceDocList.find(doc => doc.title.split(".")[0] == `${fileName.split(".")[0]}`);
  //       const extension = `${audienceDocObj ? audienceDocObj["fileExt"] : "pptx"}`
  //       fileName = `${this.year}${plan}${this.selectedCampaign ? this.selectedCampaign.campaignName : ""}${TCP_DOC_ID[docId]}.${extension}`;
  //       let fileNameWithFolderLocation = `${SHAREPOINT_DOC_PATH.TCP}/${year}/${hierarchy}/${SHAREPOINT_DOC_PATH.OPPORTUNITY_BRIEF}/${SHAREPOINT_DOC_PATH.AUDIENCE}/${fileName.replace(/ /g, '')}`;
  //       this.planService.genrateTCPAudienceConsidrationfileDownload({ path: fileNameWithFolderLocation, fileName: fileName.replace(/ /g, '') }).subscribe((res) => {
  //         this.FileNamePPTX = `${year}${this.elementDetails.tcpDocData.plan}${this.selectedCampaign.campaignName.replace(/ /g, '')}${TCP_DOC_ID[docId]}.${extension}`;
  //         this.DownloadBase64Data = `data:${this.base64FileTypeByExtension(extension)};base64,` + res["base64Data"];
  //         this.downloadFileFromBase64(fileName);
  //       });
  //       break;
  //     case TCP_DOC_ID.CONTENT_DISTRIBUTION_STRATEGY:
  //       this.planService.genrateTCPContentDistributionStrategyfileDownload({ templateCampaignIdeaData: this.generateTemplateObj(docId), fileName: fileName, campaignName: this.selectedCampaign ? this.selectedCampaign.campaignName : "" }).subscribe((res) => {
  //         this.FileNamePPTX = `${this.year}${this.elementDetails.tcpDocData.plan}${this.selectedCampaign.campaignName.replace(/ /g, '')}${TCP_DOC_ID[docId]}.pptx`;
  //         this.DownloadBase64Data = "data:application/pptx;base64," + res["base64Data"];
  //         this.downloadFileFromBase64(fileName);
  //       });
  //       break;
  //   }
  // }

  htmlToPdfDownload(id: string) {

    const plan = this.elementDetails.tcpDocData.plan;
    const { year, hierarchy } = this.elementDetails.tcpDocData;
    const docId = id.toLocaleUpperCase();
    let fileName = `${this.year}${plan}${this.selectedCampaign ? this.selectedCampaign.campaignName : ""}${TCP_DOC_ID[docId]}.pptx`
    fileName = fileName.replace(/ /g, '');
    // let extension = "pptx";
    switch (`${TCP_DOC_ID[docId]}`) {
      case TCP_DOC_ID.OPP_BRIEF:
        this.planService.genrateTCPBriefTemplate({ optBriefData: this.selectedCampaign.oppForm, fileName: fileName, campaignName: this.selectedCampaign ? this.selectedCampaign.campaignName : "", mjtbd: this.selectedMjtbd ? this.selectedMjtbd : {} }).subscribe((res) => {
          this.FileNamePPTX = `${this.year}${this.elementDetails.tcpDocData.plan}${this.selectedCampaign.campaignName.replace(/ /g, '')}${TCP_DOC_ID[docId]}.pptx`;
          // this.DownloadBase64Data = "data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64," + res["base64Data"];
          // this.downloadFileFromBase64(fileName);
          this.DownloadBase64Data =  res["base64Data"];
          this.downloadFileFromBase64(fileName, res["base64Data"], this.base64FileTypeByExtension("pptx"));
        });
        break;
      case TCP_DOC_ID.ARCHITECTURE:
        this.planService.genrateTCPArchitectureTemplate({ templateTCPArchiteData: this.generateTemplateObj(docId), fileName: fileName, campaignName: (this.selectedCampaign ? this.selectedCampaign.campaignName : "") }).subscribe((res) => {
          this.FileNamePPTX = `${this.year}${this.elementDetails.tcpDocData.plan}${this.selectedCampaign ? this.selectedCampaign.campaignName.replace(/ /g, '') : ""}${TCP_DOC_ID[docId]}.pptx`;
          // this.DownloadBase64Data = "data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64," + res["base64Data"];
          // this.downloadFileFromBase64(fileName);
          this.DownloadBase64Data =  res["base64Data"];
          this.downloadFileFromBase64(fileName, res["base64Data"], this.base64FileTypeByExtension("pptx"));
        });
        break;
case TCP_DOC_ID.BRAND_GROWTH:
          this.planService.genrateBRAND_GROWTHTemplate({ templateBrandGrowth: this.generateTemplateObj(docId), fileName: fileName, campaignName: this.selectedCampaign ? this.selectedCampaign.campaignName : "" }).subscribe((res) => {
          this.FileNamePPTX = `${this.year}${this.elementDetails.tcpDocData.plan}${this.selectedCampaign ? this.selectedCampaign.campaignName.replace(/ /g, '') : ""}${TCP_DOC_ID[docId]}.pptx`;
          // this.DownloadBase64Data = "data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64," + res["base64Data"];
          // this.downloadFileFromBase64(fileName);
          this.DownloadBase64Data =  res["base64Data"];
          this.downloadFileFromBase64(fileName, res["base64Data"], this.base64FileTypeByExtension("pptx"));
        });
        break;
      case TCP_DOC_ID.KPI_FRAMEWORK:
          this.planService.genrateKPI_FRAMEWORK_Template({ templateKpiFramework: this.generateTemplateObj(docId), fileName: fileName, campaignName: this.selectedCampaign ? this.selectedCampaign.campaignName : "" }).subscribe((res) => {
          this.FileNamePPTX = `${this.year}${this.elementDetails.tcpDocData.plan}${this.selectedCampaign ? this.selectedCampaign.campaignName.replace(/ /g, '') : ""}${TCP_DOC_ID[docId]}.pptx`;
          // this.DownloadBase64Data = "data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64," + res["base64Data"];
          // this.downloadFileFromBase64(fileName);
          this.DownloadBase64Data =  res["base64Data"];
          this.downloadFileFromBase64(fileName, res["base64Data"], this.base64FileTypeByExtension("pptx"));
        });
        break;
      case TCP_DOC_ID.CAMPAIGN_IDEA:
        this.planService.genrateTCPCAMPAIGN_IDEATemplate({ templateCampaignIdeaData: this.generateTemplateObj(docId), fileName: fileName, campaignName: this.selectedCampaign ? this.selectedCampaign.campaignName : "" }).subscribe((res) => {
          this.FileNamePPTX = `${this.year}${this.elementDetails.tcpDocData.plan}${this.selectedCampaign.campaignName.replace(/ /g, '')}${TCP_DOC_ID[docId]}.pptx`;
          // this.DownloadBase64Data = "data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64," + res["base64Data"];
          // this.downloadFileFromBase64(fileName);
          this.DownloadBase64Data =  res["base64Data"];
          this.downloadFileFromBase64(fileName, res["base64Data"], this.base64FileTypeByExtension("pptx"));
        });
        break;
case TCP_DOC_ID.AUDIENCE_CONSIDERATION:
        const audienceDocObj = this.oppBriefAudienceDocList && this.oppBriefAudienceDocList.find(doc => doc.title.split(".")[0] == `${fileName.split(".")[0]}`);
        const extension = `${audienceDocObj ? audienceDocObj["fileExt"] : "pptx"}`
        fileName = `${this.year}${plan}${this.selectedCampaign ? this.selectedCampaign.campaignName : ""}${TCP_DOC_ID[docId]}.${extension}`;
        let fileNameWithFolderLocation = `${SHAREPOINT_DOC_PATH.TCP}/${year}/${hierarchy}/${SHAREPOINT_DOC_PATH.OPPORTUNITY_BRIEF}/${SHAREPOINT_DOC_PATH.AUDIENCE}/${fileName.replace(/ /g, '')}`;
        this.planService.genrateTCPAudienceConsidrationfileDownload({ path: fileNameWithFolderLocation, fileName: fileName.replace(/ /g, '') }).subscribe((res) => {
          this.FileNamePPTX = `${year}${this.elementDetails.tcpDocData.plan}${this.selectedCampaign.campaignName.replace(/ /g, '')}${TCP_DOC_ID[docId]}.${extension}`;
          // this.DownloadBase64Data = `data:${this.base64FileTypeByExtension(extension)};base64,` + res["base64Data"];
          this.DownloadBase64Data =  res["base64Data"];
          this.downloadFileFromBase64(fileName, res["base64Data"], this.base64FileTypeByExtension(extension));
        });
        break;
      case TCP_DOC_ID.CONTENT_DISTRIBUTION_STRATEGY:
        this.planService.genrateTCPContentDistributionStrategyfileDownload({ templateCampaignIdeaData: this.generateTemplateObj(docId), fileName: fileName, campaignName: this.selectedCampaign ? this.selectedCampaign.campaignName : "" }).subscribe((res) => {
          this.FileNamePPTX = `${this.year}${this.elementDetails.tcpDocData.plan}${this.selectedCampaign.campaignName.replace(/ /g, '')}${TCP_DOC_ID[docId]}.pptx`;
          // this.DownloadBase64Data = "data:application/vnd.openxmlformats-officedocument.presentationml.presentation;base64," + res["base64Data"];
          // this.downloadFileFromBase64(fileName);
          this.DownloadBase64Data =  res["base64Data"];
          this.downloadFileFromBase64(fileName, res["base64Data"], this.base64FileTypeByExtension("pptx"));
        });
        break;
    }
  }

  // downloadFileFromBase64(fileName) {
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = this.DownloadBase64Data;
  //   downloadLink.download = fileName;
  //   document.body.appendChild(downloadLink);
  //   downloadLink.click();
  //   document.body.removeChild(downloadLink);
  // }

  downloadFileFromBase64(fileName, b64encodedString: string, contentType ) {
    if (b64encodedString) {
      var blob = this.base64ToBlob(b64encodedString, contentType);
      saveAs(blob, fileName);
      // URL.createObjectURL(blob);
    }
  }

  public base64ToBlob(b64Data, contentType='', sliceSize=512) {
    // b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...
    // let byteCharacters = atob(b64Data);
    // let byteArrays = [];
    // for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    //     let slice = byteCharacters.slice(offset, offset + sliceSize);

    //     let byteNumbers = new Array(slice.length);
    //     for (var i = 0; i < slice.length; i++) {
    //         byteNumbers[i] = slice.charCodeAt(i);
    //     }
    //     let byteArray = new Uint8Array(byteNumbers);
    //     byteArrays.push(byteArray);
    // }
    let byteArrays = ab2b64.b642ab(this.DownloadBase64Data)
    return new Blob([byteArrays], {type: contentType});
  }
  
  generateTemplateObj(Templatetype: any) {
    const _object_to_send = {};
    let MJTBDdata = this.tcpDocData ? Object.assign(this.tcpDocData.mjtbdData) : null;
    switch (TCP_DOC_ID[Templatetype]) {
      case TCP_DOC_ID.ARCHITECTURE:
        // _object_to_send["BIGBRANDIDEA"] = this.tcpDocData && this.tcpDocData.tcpData ? this.tcpDocData.tcpData.bigBrandIdea : "";
        // _object_to_send["BRANDPROBLEMTOSOLVE"] = this.tcpDocData && this.tcpDocData.tcpData ? this.tcpDocData.tcpData.brandProblemToSolve : "";
        if (MJTBDdata && MJTBDdata.length > 0) {
          MJTBDdata = MJTBDdata.sort((a, b) => {
            return a.mjtbdForm.startDt - b.mjtbdForm.startDt;
          });
        }
        // for (let i = 0; i < 3; i++) {
        //   _object_to_send[`MJTBD${i + 1}_Title`] = MJTBDdata[i] && MJTBDdata[i].mjtbdForm ? MJTBDdata[i].mjtbdForm.mjtbdFormTitle : "";
        //   _object_to_send[`MJTBD${i + 1}_CommsObjective`] = MJTBDdata[i] && MJTBDdata[i].mjtbdForm ? MJTBDdata[i].mjtbdForm.commsObjective : "";
        //   _object_to_send[`MJTBD${i + 1}_AudiencePriority`] = MJTBDdata[i] && MJTBDdata[i].mjtbdForm ? MJTBDdata[i].mjtbdForm.audiencePriority : "";
        //   _object_to_send[`MJTBD${i + 1}_KPIStep3`] =  MJTBDdata[i] && MJTBDdata[i].mjtbdForm ? MJTBDdata[i].mjtbdForm.growthTarget : "";
        //   _object_to_send[`MJTBD${i + 1}_AssociatedFormat`] =  MJTBDdata[i] && MJTBDdata[i].mjtbdForm ? MJTBDdata[i].mjtbdForm.associatedFormat : "";
        // }
        if(MJTBDdata.length > 0) {
          const chunkMJTBDArray = _.chunk(MJTBDdata, 3);
          chunkMJTBDArray.forEach((element: Array<any>, index: number) => {
            let obj_row: any = this.returnObjectMapArchitecture();
            element.forEach((innerArrObj: any, idx: number) => {
              let rowNo = idx + 1;
              obj_row["BIGBRANDIDEA"] = this.tcpDocData && this.tcpDocData.tcpData ? this.tcpDocData.tcpData.bigBrandIdea : "";
              obj_row["BRANDPROBLEMTOSOLVE"] = this.tcpDocData && this.tcpDocData.tcpData ? this.tcpDocData.tcpData.brandProblemToSolve : "";
              obj_row[`MJTBD${rowNo}_TITLE`] = innerArrObj && innerArrObj.mjtbdForm ? innerArrObj.mjtbdForm.mjtbdFormTitle : "";
              obj_row[`MJTBD${rowNo}_COMMSOBJECTIVE`] = innerArrObj && innerArrObj.mjtbdForm ? innerArrObj.mjtbdForm.commsObjective : "";
              obj_row[`MJTBD${rowNo}_AUDIENCEPRIORITY`] = innerArrObj && innerArrObj.mjtbdForm ? innerArrObj.mjtbdForm.audiencePriority : "";
              obj_row[`MJTBD${rowNo}_KPISTEP3`] =  innerArrObj && innerArrObj.mjtbdForm ? innerArrObj.mjtbdForm.growthTarget : "";
              obj_row[`MJTBD${rowNo}_ASSOCIATEDFORMAT`] =  innerArrObj && innerArrObj.mjtbdForm ? innerArrObj.mjtbdForm.associatedFormat : "";
            });
            _object_to_send[`Slide_${index + 1}`] = obj_row
          });
        } else {          
          _object_to_send[`Slide_1`] = this.returnObjectMapArchitecture();
          _object_to_send[`Slide_1`]["BIGBRANDIDEA"] = this.tcpDocData && this.tcpDocData.tcpData ? this.tcpDocData.tcpData.bigBrandIdea : "";
          _object_to_send[`Slide_1`]["BRANDPROBLEMTOSOLVE"] = this.tcpDocData && this.tcpDocData.tcpData ? this.tcpDocData.tcpData.brandProblemToSolve : "";
        }
        break;

      case TCP_DOC_ID.BRAND_GROWTH:
        //_object_to_send["brandProblemToSolve"] = this.tcpDocData && this.tcpDocData.tcpData ? this.tcpDocData.tcpData.brandProblemToSolve : "";
        if (MJTBDdata && MJTBDdata.length > 0) {
          MJTBDdata = MJTBDdata.sort((a, b) => {
            return a.mjtbdForm.startDt - b.mjtbdForm.startDt;
          });
        }
        if(MJTBDdata.length > 0) {
        const chunkMJTBDArray = _.chunk(MJTBDdata, 3);
        chunkMJTBDArray.forEach((element: Array<any>, index: number) => {
          let obj_row: any = this.returnObjectMapBrnadgrowth();;
          element.forEach((innerArrObj: any, idx: number) => {
            let rowNo = idx + 1;
            obj_row[`MJTBD${rowNo}_TITLE`] = innerArrObj.name ? innerArrObj.name : "";
          });
          _object_to_send[`Slide_${index + 1}`] = obj_row
        });
      } else {
        _object_to_send[`Slide_1`] = this.returnObjectMapBrnadgrowth();
      }
      break;
      case TCP_DOC_ID.KPI_FRAMEWORK:
        //_object_to_send["brandProblemToSolve"] = this.tcpDocData && this.tcpDocData.tcpData ? this.tcpDocData.tcpData.brandProblemToSolve : "";
        if (MJTBDdata && MJTBDdata.length > 0) {
          MJTBDdata = MJTBDdata.sort((a, b) => {
            return a.mjtbdForm.startDt - b.mjtbdForm.startDt;
          });
        }
        if(MJTBDdata.length > 0) {
        const chunkMJTBDArray = _.chunk(MJTBDdata, 2);
        chunkMJTBDArray.forEach((element: Array<any>, index: number) => {
          let obj_row: any = this.returnObjectMapKPIFrameWork();;
          element.forEach((innerArrObj: any, idx: number) => {
            let rowNo = idx + 1;
            obj_row[`BRAND_OBJECTIVE_${rowNo}`] = innerArrObj.mjtbdForm && innerArrObj.mjtbdForm.brandObjective ? innerArrObj.mjtbdForm.brandObjective : "";
            const commsObjectiveArray = innerArrObj.mjtbdForm && innerArrObj.mjtbdForm.commsObjective ? innerArrObj.mjtbdForm.commsObjective.split(',') : [];
            const commsObjectiveFirstTwo = commsObjectiveArray.splice(2);
              for(let i = 1; i <= 2; i++) {
                obj_row[`CAMPAIGN_OBJECTIVE_${rowNo}_${i}`] = commsObjectiveArray[i-1] ? commsObjectiveArray[i-1] : 0;
              }
              if(commsObjectiveFirstTwo.length > 0) {
                obj_row[`CAMPAIGN_OBJECTIVE_${rowNo}_3`] = commsObjectiveFirstTwo ? commsObjectiveFirstTwo.join(',') : '';
              }
              obj_row[`CAMPAIGN_GOAL_${rowNo}`] = this.kpiTemplateData &&  this.kpiTemplateData.result && this.kpiTemplateData.result.campaignGoal && this.kpiTemplateData.result.campaignGoal[innerArrObj.id] && this.kpiTemplateData.result.campaignGoal[innerArrObj.id].length > 0 ? this.kpiTemplateData.result.campaignGoal[innerArrObj.id].join(',') : '';
              obj_row[`SUCESS_KPIS_${rowNo}`] = this.kpiTemplateData &&  this.kpiTemplateData.result && this.kpiTemplateData.result.successKPIs && this.kpiTemplateData.result.successKPIs[innerArrObj.id] && this.kpiTemplateData.result.successKPIs[innerArrObj.id].length > 0 ? this.kpiTemplateData.result.successKPIs[innerArrObj.id].join(',') : '';
              obj_row[`OPT_METRICS_${rowNo}`] = this.kpiTemplateData &&  this.kpiTemplateData.result && this.kpiTemplateData.result.optimizationKPIs && this.kpiTemplateData.result.optimizationKPIs[innerArrObj.id] && this.kpiTemplateData.result.optimizationKPIs[innerArrObj.id].length > 0 ? this.kpiTemplateData.result.optimizationKPIs[innerArrObj.id].join(',') : '';
          });
          _object_to_send[`Slide_${index + 1}`] = obj_row
        });
      } else {
        _object_to_send[`Slide_1`] = this.returnObjectMapKPIFrameWork();
      }
      break;
      case TCP_DOC_ID.CAMPAIGN_IDEA:
        const campaignIdeaObj = this.selectedCampaign.campaignIdeas && this.selectedCampaign.campaignIdeas.length > 0 ? this.selectedCampaign.campaignIdeas : [];
        _object_to_send["SITUATION"] = campaignIdeaObj && campaignIdeaObj[0] ? campaignIdeaObj[0].situation : "";
        _object_to_send["TARGET"] = campaignIdeaObj && campaignIdeaObj[0] ? campaignIdeaObj[0].target : "";
        _object_to_send["INSIGHT"] = campaignIdeaObj && campaignIdeaObj[0] ? campaignIdeaObj[0].insight : "";
        _object_to_send["RESULTS"] = campaignIdeaObj && campaignIdeaObj[0] ? campaignIdeaObj[0].results : "";
        _object_to_send["TITLE"] = campaignIdeaObj && campaignIdeaObj[0] ? campaignIdeaObj[0].ciTitle : "";
        _object_to_send["CONNECTIONTOBRAND"] = campaignIdeaObj && campaignIdeaObj[0] ? campaignIdeaObj[0].connectionToBBI : "";
        _object_to_send["ADDINSIGHT"] = campaignIdeaObj && campaignIdeaObj[0] ? campaignIdeaObj[0].addionalInsight : "";
        _object_to_send["WHTWULDWEDO"] = campaignIdeaObj && campaignIdeaObj[0] ? campaignIdeaObj[0].whatWouldWeDo : "";
        _object_to_send["campaignImage"] = campaignIdeaObj && campaignIdeaObj[0] ? campaignIdeaObj[0].campaignImage : "";
        break;
      case TCP_DOC_ID.CONTENT_DISTRIBUTION_STRATEGY:
        let audienceDataArray = [];
        this.tcpChartData.commsTasks.forEach(commsTask => {
          console.log(commsTask);
          commsTask.scenarioData[0] && commsTask.scenarioData[0].channels.forEach(channel => {

            if (channel.audience && channel.audience.split(",").length > 0) {
              let AudienceList = channel.audience.split(",");
              AudienceList.forEach(element => {
                let _objAudienceRow: any = {};
                _objAudienceRow.audienceTitle = element ? element : "";
                _objAudienceRow.commsTaskTitle = commsTask && commsTask.name ? commsTask.name : "";
                _objAudienceRow.keyInterceptions = channel && channel.keyInterception ? channel.keyInterception : "";
                _objAudienceRow.touchPointName = channel && channel.touchPointName ? channel.touchPointName : "";
                _objAudienceRow.kpi = channel && channel.optimizationKPIs ? channel.optimizationKPIs : "";
                _objAudienceRow.channelName = channel && channel.channelName ? channel.channelName : "";
                audienceDataArray.push(_objAudienceRow);
              });
            }
          });
        });
        if (audienceDataArray.length > 0) {
          audienceDataArray = audienceDataArray.sort((a, b) => {
            return a.audienceTitle.localeCompare(b.audienceTitle);
          });
          let slideNo = 1;
          let rowNo = 1;
          let SlideRowCounter = 0;
          const chunkAudeinceArr = _.chunk(audienceDataArray, 4);
          chunkAudeinceArr.forEach((element: Array<any>, index: number) => {
            let obj_row: any = this.returnObjectMap();;
            element.forEach((innerArrObj: any, idx: number) => {
              let rowNo = idx + 1;
              obj_row[`AUDIENCES_ROW_${rowNo}`] = innerArrObj.audienceTitle ? innerArrObj.audienceTitle : "";
              obj_row[`COMMS_TASKS_ROW_${rowNo}`] = innerArrObj.commsTaskTitle ? innerArrObj.commsTaskTitle : "";
              obj_row[`KEY_INTERCEPTIONS_ROW_${rowNo}`] = innerArrObj.keyInterceptions ? innerArrObj.keyInterceptions : "";
              obj_row[`TOUCHPOINTS_ROW_${rowNo}`] = innerArrObj.touchPointName ? innerArrObj.touchPointName : "";
              obj_row[`KPI_ROW_${rowNo}`] = innerArrObj.kpi ? innerArrObj.kpi : "";
              obj_row[`PRIORITY_CHANNEL_ROW_${rowNo}`] = innerArrObj.channelName ? innerArrObj.channelName : "";
            });
            _object_to_send[`Slide_1${index + 1}`] = obj_row
          });
        } else {
          _object_to_send[`Slide_1`] = this.returnObjectMap();
        }
        break;
    }
    return _object_to_send;
  }
  returnObjectMapKPIFrameWork() {
    return {
      "BRAND_OBJECTIVE_1": "",
      "BRAND_OBJECTIVE_2": "",
      "CAMPAIGN_OBJECTIVE_1_1": "",
      "CAMPAIGN_OBJECTIVE_1_2": "",
      "CAMPAIGN_OBJECTIVE_1_3": "",
      "CAMPAIGN_OBJECTIVE_2_1": "",
      "CAMPAIGN_OBJECTIVE_2_2": "",
      "CAMPAIGN_OBJECTIVE_2_3": "",
      "CAMPAIGN_GOAL_1": "",
      "CAMPAIGN_GOAL_2": "",
      "SUCESS_KPIS_1": "",
      "SUCESS_KPIS_2": "",
      "OPT_METRICS_1": "",
      "OPT_METRICS_2": "",
    }
  }
  returnObjectMapArchitecture() {
    return {
      "MJTBD1_TITLE": "",
      "MJTBD2_TITLE": "",
      "MJTBD3_TITLE": "",
      "MJTBD1_COMMSOBJECTIVE": "",
      "MJTBD2_COMMSOBJECTIVE": "",
      "MJTBD3_COMMSOBJECTIVE": "",
      "MJTBD1_AUDIENCEPRIORITY": "",
      "MJTBD2_AUDIENCEPRIORITY": "",
      "MJTBD3_AUDIENCEPRIORITY": "",
      "MJTBD1_KPISTEP3": "",
      "MJTBD2_KPISTEP3": "",
      "MJTBD3_KPISTEP3": "",
      "MJTBD1_ASSOCIATEDFORMAT": "",
      "MJTBD2_ASSOCIATEDFORMAT": "",
      "MJTBD3_ASSOCIATEDFORMAT": "",
      "BIGBRANDIDEA": "",
      "BRANDPROBLEMTOSOLVE": "",
    }
  }
  returnObjectMapBrnadgrowth() {
    return {
      "MJTBD1_TITLE": "",
      "MJTBD2_TITLE": "",
      "MJTBD3_TITLE": ""
    }
  }
  returnObjectMap() {
    return {
      "AUDIENCES_ROW_1": "",
      "AUDIENCES_ROW_2": "",
      "AUDIENCES_ROW_3": "",
      "AUDIENCES_ROW_4": "",
      "COMMS_TASKS_ROW_1": "",
      "COMMS_TASKS_ROW_2": "",
      "COMMS_TASKS_ROW_3": "",
      "COMMS_TASKS_ROW_4": "",
      "KEY_INTERCEPTIONS_ROW_1": "",
      "KEY_INTERCEPTIONS_ROW_2": "",
      "KEY_INTERCEPTIONS_ROW_3": "",
      "KEY_INTERCEPTIONS_ROW_4": "",
      "TOUCHPOINTS_ROW_1": "",
      "TOUCHPOINTS_ROW_2": "",
      "TOUCHPOINTS_ROW_3": "",
      "TOUCHPOINTS_ROW_4": "",
      "KPI_ROW_1": "",
      "KPI_ROW_2": "",
      "KPI_ROW_3": "",
      "KPI_ROW_4": "",
      "PRIORITY_CHANNEL_ROW_1": "",
      "PRIORITY_CHANNEL_ROW_2": "",
      "PRIORITY_CHANNEL_ROW_3": "",
      "PRIORITY_CHANNEL_ROW_4": "",
    }
  }
  async downloadFromLink(id: string) {
    const plan = this.elementDetails.tcpDocData.plan;
    const docId = id.toLocaleUpperCase();
    const selectedCampaignName = this.selectedCampaign ? this.selectedCampaign.campaignName : "";
    let fileName = `${this.year} ${plan} ${selectedCampaignName} ${TCP_DOC_ID[docId]}.pptx`;
    switch (`${TCP_DOC_ID[docId]}`) {
      case TCP_DOC_ID.ACTIVATION_CALENDER:
        fileName = `${this.year} ${plan} ${TCP_DOC_ID[docId]}.pptx`;
        fileName = fileName.replace(/ /, '_');
        this.TcpProgressPdfComponent.downloadPdfFromLink(`${BASE_API_URL}/v3/PPT_Templates/Activation_Calendar.pptx`, fileName);
        // window.open(`${BASE_API_URL}/v3/PPT_Templates/Activation_Calendar.pptx`);
        // return {fileName,filePath:`${BASE_API_URL}/v3/PPT_Templates/Activation_Calendar.pptx`}
        // var res = await fetch(`${BASE_API_URL}/v3/PPT_Templates/Activation_Calendar.pptx`);
        // var blob = await res.blob();
        // saveAs(blob, fileName);
        break;
      /*case TCP_DOC_ID.AUDIENCE_CONSIDERATION: 
        const campaignName = this.selectedCampaign.campaignName;
        fileName = `${this.year} ${plan} ${TCP_DOC_ID[docId]}.pptx`;
        fileName = fileName.replace(/ /, '_');
        const audienceDocObj = this.oppBriefAudienceDocList && this.oppBriefAudienceDocList.find(doc => doc.title == `${campaignName} ${FILE_NAMES.OPP_BRIEF_AUDIENCE}`);
        this.TcpProgressPdfComponent.downloadPdfFromLink(`${BASE_API_URL}/v3/PPT_Templates/Appendix.pptx`, fileName);
        break;*/
      case TCP_DOC_ID.KPI_FRAMEWORK:
        fileName = `${this.year} ${plan} ${TCP_DOC_ID[docId]}.pptx`;
        fileName = fileName.replace(/ /, '_');
        this.TcpProgressPdfComponent.downloadPdfFromLink(`${BASE_API_URL}/v3/PPT_Templates/TCP_media_KPI_Framework.pptx`, fileName);
        break;

      case TCP_DOC_ID.CONNECTION_MAP:
        this.TcpProgressPdfComponent.downloadPdfFromLink(`${BASE_API_URL}/v3/PPT_Templates/ConnectionMap.pptx`, fileName);
        break;
    }
  }

  /*........... Service/API calls........ */
  async getOppBriefDocsFromSharepoint() {
    const { year, hierarchy } = this.elementDetails.tcpDocData;
    let audiencePath = `${SHAREPOINT_DOC_PATH.TCP}/${year}/${hierarchy}/${SHAREPOINT_DOC_PATH.OPPORTUNITY_BRIEF}/${SHAREPOINT_DOC_PATH.AUDIENCE}`;
    this.oppBriefAudienceDocList = await getDocsFromSharepointFilesAPI(audiencePath, this.globalElementsService);
  }

  showtheChart() {
    return this.mjtbdId && this.campaignId && this.tcpChartData;
  }
  getTcpChartData(mjtbdObj) {
    this.planService.getTcpProgressChartData(mjtbdObj).subscribe((res) => {
      this.tcpChartData = res;
    })
  }
  fileChangeEvent(event: any): void {
    this.message = "Uploading File...";
    this.showCloseBtn= false;
    let fileList: FileList = event.target.files;
    this.dialog.open(this.uploadDialog, {
      minWidth: '500px',
      disableClose: true
    }); 
    getFileBuffer(fileList[0]).then(async (fileBufferArray: ArrayBuffer) => {
      let fileName =  fileList[0].name;
      let path=`${this.tcpDocData.hierarchy}/${this.year}/`;
      let Base64String = ab2b64.ab2b64(fileBufferArray);
      let type = fileList[0].type;
      let docObj = {
        data: {
          planId: this.tcpDocData.planId,
          year: this.year,
          fileName,
          filePath:path,
          base64Data: Base64String,
          fileContentType: type
        }
      };
        await this.planService.uploadtcpdocs(docObj).subscribe((res) => {
          this.message = "Uploaded Successfully";
          this.showCloseBtn= true;
          event.target.value = null;
          this.refreshTCPDoctableData();
        });
    }, (error) => {
      console.log('Error: ', error);
      this.message = "Error while uploading file.";
      this.showCloseBtn= true;
      event.target.value = null;
    });

  }
  closeDialog() {
    this.dialog.closeAll();
  }

async getKpiFrameworktemplateData() {
  
  await this.planService.getKpiFramworkTemplateData({mjtbdIdArray: this.mjtbdIdArray}).subscribe((res) => {
    this.kpiTemplateData =  res;
  },(err) => {
    this.kpiTemplateData  = null;
  })
}
async refreshTCPDoctableData() {
 await this.planService.getTCPDocData({planId: this.tcpDocData.planId, year:this.tcpDocData.year}).subscribe((res) => {
  this.dataSource = new MatTableDataSource<any>(res["data"]);
 }); 
 
  }
  returnDate(_date) {
   return new Date(_date).toLocaleDateString();
  }
 async downloadFile(filePath,fileName) {
  // this.planService.downloadTCPDoc({filePath,fileName});
    this.planService.downloadTCPDoc({filePath,fileName}).subscribe((res) => {
      this.DownloadBase64Data =  res["data"];
      this.downloadFileFromBase64(fileName, res["data"],this.base64FileTypeByExtension("pptx"));
    });
  }
showDelteBtn(element) {
  return (this.userInfo.gpid === element.gpid || isValidUserAccess(ROLES.ADMIN,'<=', null, this.tcpDocData.planId)) ;
}
openDeleteFileDailog(fileDelteData) {
  this.showDeleteFileBtn = true;
  this.deletedailogMessage = `Are you sure you want to delete ${fileDelteData.fileName.toLowerCase()} file?`;
  this.delteFileObj = fileDelteData;
  this.dialog.open(this.deleteFiledDialog, {
    minWidth: '500px',
    disableClose: true
  });
}
  async deleteFile(fileDelteObj) {
    //this.message = "Deleting File...";
    if(fileDelteObj) {
      await this.planService.deleteTCPDocData(fileDelteObj).subscribe(res => {
        if(res) {
          this.showDeleteFileBtn = false;
          this.deletedailogMessage = `Deleted Successfully.`;
          this.delteFileObj = null;
          this.dialog.open(this.deleteFiledDialog, {
            minWidth: '500px',
            disableClose: true
          });
          this.refreshTCPDoctableData();
        }
      }, err =>{
          this.showDeleteFileBtn = false;
          this.deletedailogMessage = `Error Deleting File.`;
          this.delteFileObj = null;
      });
    } else {
      this.closeDeleteDialog();
    }
  }
  closeDeleteDialog() {
    this.showDeleteFileBtn = false;
    this.deletedailogMessage = ``;
    this.delteFileObj = null;
    this.dialog.closeAll();
  }
}

