import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { PAGE_MODES } from './../../../../constants/app-constants';
import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { BrandManagementDialogsComponent } from '../../../../common/components/dialogs/brand-management-dialogs/brand-management-dialogs.component';
import { MatDialog } from '../../../../../../node_modules/@angular/material';
import { IPortfolioSave } from '../../../../common/interfaces/brandManagement';
import { take } from '../../../../../../node_modules/rxjs/operators';
import { Subscription } from '../../../../../../node_modules/rxjs';
import { BrandManagementService } from '../../../services/brand-management.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';

@Component({
  selector: 'app-enhance-portfolio',
  templateUrl: './enhance-portfolio.component.html',
  styleUrls: ['./enhance-portfolio.component.scss']
})
export class EnhancePortfolioComponent implements OnInit, OnDestroy {

  @Input() pageMode;
  @Input() editData;
  @Output() changePageModeEmitter = new EventEmitter
  @Output() portfolioSelectionEvent = new EventEmitter
  userInfo = JSON.parse(sessionStorage.getItem('user'));

  @Output('dirty') formDirty = new EventEmitter<boolean>()

  isEditMode = false;
  portfolioForm: FormGroup;
  displayName: string;
  marketsList = [];
  saveBtnSubscription: Subscription;
  
  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    private globalElementsService: GlobalElementsService,
    private brandManagementService: BrandManagementService,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }


  /*........... Initialization Methods........ */

  ngOnInit() {

    this.globalElementsService.setShowSaveBtn(this.userInfo.roleId !== 6);
    this.globalElementsService.setSaveEnableState(false);
    this.displayName = 'Create New Portfolio'
    this.isEditMode = this.pageMode == PAGE_MODES.EDIT;
    this.getMarkets();
    this.getMDMportfolios();
    this.iniitializePortfolioForm();

    this.portfolioForm.valueChanges.subscribe(() => {
      this.globalElementsService.setSaveEnableState(this.portfolioForm.valid);
      this.formDirty.emit(this.portfolioForm.dirty);
    })


    this.saveBtnSubscription = this.globalElementsService.getSidePanelSaveEvent.pipe(take(1)).subscribe(() => this.onSubmit());


    if (this.isEditMode) {
      this.displayName = 'Edit Portfolio'
      this.updatePortfolioForm();
      this.userInfo.roleId === 6 ? this.portfolioForm.disable() : null;

    }

  }


  getMDMportfolios() {
    // this.globalElementsService.getPortfoliosFromMDM().subscribe((res: any)=>{
    //   this.mdmPortfolios = res;
    // })

  }

  iniitializePortfolioForm() {
    this.portfolioForm = this.fb.group({
      portfolio: ['', Validators.required],
      market: ['', Validators.required],
      finVal: ['']
    })

  }

  /*........... functionality Methods........ */



  updatePortfolioForm() {
    this.portfolioForm.patchValue({
      portfolio: this.editData[0].displayName,
      market: this.editData[0].marketData.id,
      finVal: this.editData[0].financialValue
    })
  }

  onSubmit() {
    const dialogRef = this.dialog.open(BrandManagementDialogsComponent, {
      data: {
        type: this.isEditMode ? 'update' : 'save',
        module: 'portfolio',
        apiData: this.constructPortfolioObj(),
      },
      minWidth: '500px'
    })

    dialogRef.afterClosed().subscribe(res => {
      this.backToHome();
    });
    
  }
  
  
  backToHome() {
    this.portfolioSelectionEvent.emit([]);
    this.changePageModeEmitter.emit(PAGE_MODES.HOME)
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Portfolio_Management`,
      eventAction: "Back Button clicked",
      eventLable: `Global Elements Portfolio Management Back button click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }


  portfolioFormErrorHandeling(control: string, error: string) {
    return this.portfolioForm.controls[control].hasError(error);
  }


  /*........... Service/API calls........ */

  getMarkets() {
    this.brandManagementService.getAllMarkets().subscribe((res: any) => {
      this.marketsList = res.markets.sort((a, b)=> a.displayName >= b.displayName ? 1 : -1);
    });
  }


  constructPortfolioObj(): IPortfolioSave {
    let portfolio: IPortfolioSave = {
      marketId: this.portfolioForm.value.market,
      portfolioName: this.portfolioForm.value.portfolio,
      financialValue: this.portfolioForm.value.finVal
    };
    if (this.isEditMode) {
      portfolio.portfolioId = this.editData[0].id;
    }
    return portfolio;
  }

  /*........... Clean up methods........ */
  ngOnDestroy() {
    this.saveBtnSubscription.unsubscribe();
    this.globalElementsService.setShowSaveBtn(false);
    this.formDirty.emit(false);
  }


}
