import { DomSanitizer , SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { TeamMembersDialogComponent } from './../../common/components/dialogs/team-members-dialog/team-members-dialog.component';
import { manageCustomAudienceDialog } from './../../common/components/dialogs/manage-custom-audience-dialog/manage-custom-audience-dialog.component';
import { BREADCRUMBS_REFERENCE_TYPES, BREADCRUMBS_LAST_NODE_TYPES } from './../../constants/app-constants';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { MjtbdDialogComponent } from 'app/common/components/dialogs/mjtbd-dialog/mjtbd-dialog.component';
import { IApproverList, IDocument, IBreadcrumb, ITeamMembers, ITeamMembersReq } from 'app/common/interfaces/common';
import { IBrandObjectives, IMjForm, IMjtbdBody, IMjtbdForm, IMjtbdPostObj, IMjtbdSave, IMJTBDdropdowns, IAudienceThumnails } from 'app/common/interfaces/plan';
import { getCompletedAndFinalizedUsers, getDocsFromSharepointFilesAPI } from 'app/common/utils/common-util';
import { PAGE_MODES,FINAL_STATUS_LIST, FORM_REFERENCE_TYPES, MINIFIED_STATUS_LIST, SHAREPOINT_DOC_PATH, STATUS_LIST, STATUS_LIST_TYPES, TCP_DOC_ID, GLOBAL_ELEMENTS } from 'app/constants/app-constants';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import * as moment from 'moment';
import { Subscription } from '../../../../node_modules/rxjs';
import { take } from '../../../../node_modules/rxjs/operators';
import { PlanService } from '../services/plan.service';
import {MultiselectAutocompleteComponent} from 'app/common/components/multiselect-autocomplete/multiselect-autocomplete.component';
import * as  _  from 'lodash';
import { environment } from 'environments/environment';
const BASE_API_URL = `${environment.api}`;
import { SendNotificationsDialogComponent } from './../../common/components/dialogs/send-notifications-dialog/send-notifications-dialog.component';
import { isValidUserAccess } from '../../common/utils/common-util'
import { saveAs } from 'file-saver';
import { fileMimeTypes } from 'app/constants/app-constants';
import ab2b64 from 'ab2b64' ;

@Component({
  selector: 'app-create-mjtbd',
  templateUrl: './create-mjtbd.component.html',
  styleUrls: ['./create-mjtbd.component.scss']
})
export class CreateMjtbdComponent implements OnInit, OnDestroy {

  @Input() elementDetails;
  
  @Output('dirty') formDirty = new EventEmitter<boolean>()
  @ViewChild('showImgageDailog', { static: true }) showImgageDailog: TemplateRef<any>;

  userInfo = JSON.parse(sessionStorage.getItem('user'));
  attach_money = sessionStorage.getItem('currencySign');
  selectedMarket = sessionStorage.getItem('market');
  mjtbdName: string;
  editMode: boolean = false;
  mjtbdForm: FormGroup;
  brandObjList: Array<string> = [];
  commsObjList: Array<string> = [];
  behavioralGrowthTargetList: Array<string> = [];
  audienceList: Array<string> = [];
  customAudienceList: Array<any> = [];
  audienceDocs: Array<IDocument> = [];
  mjtbdFormData: IMjForm;
  selectedBrandObjs: Array<string> = [];
  selectedCommsObjs: Array<string> = [];
  selectedGrowthTarget: Array<string> = [];
  selectedAudience: Array<string> = [];
  isLoading: boolean = false;
  saveBtnSubscription: Subscription;
  mjtbdStatusList: Array<string> = STATUS_LIST;
  mjtbdFormStatus: string = STATUS_LIST_TYPES.DRAFT;
  mjtbdApproverList: Array<IApproverList>;
  selectedMjtbdApprovers: Array<IApproverList>;
  mjtbdEditableStatus: boolean = true;
  oldStatus: string;
  pathDetails: IBreadcrumb;
  teamMembersList: Array<ITeamMembers> = [];
  selectedTeamMembers: Array<number> = [];
  selectedTeamMembersAvatar = [];
  audienceThumnailList: Array<IAudienceThumnails>;
  audienceImageObj;
  mode = PAGE_MODES.HOME;
  tooltipTitleMJTBDTarget:string;
  showtooltipTitleMJTBDTarget:boolean =  false;
  
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private globalElementsService: GlobalElementsService,
    private sanitizer: DomSanitizer,
    private planService: PlanService,
  ) { }
  /*........... Initialization Methods........ */

  ngOnInit() {
    //console.log(this.mjtbdFormData.mjtbdFormTitle);
    const { planId } = this.elementDetails.mjtbdData;
    this.globalElementsService.setShowSaveBtn(isValidUserAccess(6,'!==',null,planId));
    this.globalElementsService.setSaveEnableState(false);
 
    this.initiateMjtbdForm();
    this.getAudienceDocsFromSharePoint();
    this.mjtbdForm.valueChanges.subscribe(() => {
      this.globalElementsService.setSaveEnableState(this.mjtbdForm.valid && this.mjtbdEditableStatus);
      this.formDirty.emit(this.mjtbdForm.dirty);
    })
    this.globalElementsService.getTooltipData('MJTBDsNewEdit', this.selectedMarket).subscribe((res_n: any) => {
      if(res_n.data && res_n.data[0]) {
        this.tooltipTitleMJTBDTarget = res_n.data[0]["tooltipText"];
      }
    });

   
    this.getMJTBDFormDropDowns(planId);

    this.saveBtnSubscription = this.globalElementsService.getSidePanelSaveEvent.pipe(take(1)).subscribe(() => this.onSubmit());
    if (this.elementDetails.mjtbdData.mode == 'edit') {
      this.editMode = true;
      this.isLoading = true;
      isValidUserAccess(6,'===',null,planId) ? this.mjtbdForm.disable() : null;
    } else {
      this.getApproversList();
      this.defaultPathDetails();
    }
    this.updateStatusList();
    this.getTeamMembersList();
    this.getCustomAudienceList();
  }
  showTooltip(){
    this.showtooltipTitleMJTBDTarget = true;
  }
  hideTooltip(){
    this.showtooltipTitleMJTBDTarget = false;
  }

  initiateMjtbdForm() {
    this.mjtbdForm = this.fb.group({
      mjtbdFormTitle: ['', Validators.required],
      mjtbdApprover: [''],
      totalMJTBDBudget: [''],
      cWMBudget: [''],
      startDt: [''],
      endDt: [''],
      brandObj: [''],
      commsObj: [''],
      growthTarget: [''],
      audience: [''],
      associatedFormat: [''],
      customAudience: ['']
    });
  }

  /*........... functionality Methods........ */

  brandObjectiveChange(brndObjctive: Array<string>) {
    this.selectedBrandObjs = brndObjctive;
    this.mjtbdForm.patchValue({
      commsObj: null
    });
    this.selectedCommsObjs = [];
    this.getCommsObj();

  }

  getCommsObj(){
    const { planId } = this.elementDetails.mjtbdData;
    let brndObj = {
      brandObjective: this.selectedBrandObjs,
      planId
    }
    this.planService.getMJTBDcommsObj(brndObj).subscribe((res: Array<string>)=>{
      this.commsObjList = res;
    })
  }

  addTeamMembers(){
    
    let tmDialogRef;
    tmDialogRef = this.dialog.open(TeamMembersDialogComponent, {
      data: {
      teamMembers : this.teamMembersList,
      selectedMembers : this.selectedTeamMembers
      },
      height: '400px',
      width: '500px',
      disableClose: true
    });

    tmDialogRef.afterClosed().subscribe((res: any) => {
      this.selectedTeamMembers = res;
      this.generateTeamMemeberAvatars(); 
    });
  }
  openManageCustomAudiencePopup(){
    
    let manageAudienceCustomDailog;
    const { planId } = this.elementDetails.mjtbdData;
    manageAudienceCustomDailog = this.dialog.open(manageCustomAudienceDialog, {
      
      data: {
        planId,
        refrenceId: this.mjtbdFormData && this.mjtbdFormData.mjtbdId ? this.mjtbdFormData.mjtbdId : null,
        type: 'MJTBD'
      },
      height: '500px',
      minWidth: '500px'
    });
    manageAudienceCustomDailog.afterClosed().subscribe((res: any) => {
      this.getCustomAudienceList();

    });
  }
  openshowImgageDailog(audienceObj) {
    this.dialog.open(this.showImgageDailog, {
      height: '800',
      width: '900',
      disableClose: true,
    });
    this.audienceImageObj = audienceObj 
  }
  closeDialog() {
    this.dialog.closeAll();
  }
  generateTeamMemeberAvatars(){
    if(this.teamMembersList){
      const selectedUsers = this.teamMembersList.filter(tm => this.selectedTeamMembers.includes(tm.userId));
      this.selectedTeamMembersAvatar = selectedUsers.map(user => {
        const initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
        let avatar;
        if (user.userAvatar) {
          let byteArray = new Uint8Array(user.userAvatar.data);
          const charString = byteArray.reduce((data, byte) => {
            return data + String.fromCharCode(byte);
          }, '');
          let base64String = btoa(charString);
          avatar = this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + base64String);
        }
        return {
          initials,
          avatar
        }
      })
    }
  }

  mjtbdApproverChange(apprvData: Array<IApproverList>) {
    this.selectedMjtbdApprovers = apprvData;
  }

  commsObjectiveChange(commsObj: Array<string>) {
    this.selectedCommsObjs = commsObj;
  }

  growthTargetChange(growthTargets: Array<string>) {
    this.selectedAudience=[];
    this.mjtbdForm.patchValue({
      audience: null
    });
    this.selectedAudience = [...this.customAudienceList];
    this.audienceThumnailList = [];
    this.selectedGrowthTarget = growthTargets;
    this.getAudienceList();

  }
  getCustomAudienceList(){
    const { planId } = this.elementDetails.mjtbdData;
    const postObj={
      planId,
      type: 'MJTBD'
    }
    this.globalElementsService.customAudienceGetAll(postObj).subscribe((res:Array<string>)=>{
      if(this.customAudienceList.length > 0) {
        const _diffrentList = _.difference(this.customAudienceList.map(el=> el.customAudienceName), res['data'].map(el=> el.customAudienceName)) || [];
         this.selectedAudience = _.without(this.selectedAudience, _diffrentList.join(','));
         this.mjtbdForm.patchValue({
          audience: this.selectedAudience
        });
       }
      this.customAudienceList = res['data'] ?  res['data'] : [];
      this.getAudienceThumbnailUrls();
    })
  }
  getAudienceList(){
    const { planId } = this.elementDetails.mjtbdData;
    const postObj={
      planId,
      growthTarget: this.selectedGrowthTarget
    }
    this.planService.getMJTBDAudience(postObj).subscribe((res:Array<string>)=>{
      this.audienceList = res
    })
  }

  audienceChange(audiences: Array<string>) {
    this.selectedAudience=[];
    if(!audiences) {
      audiences =[];
    }
    this.selectedAudience = audiences;
    if(this.selectedAudience.length==0){
      this.audienceThumnailList = [];
    }
    else{
      this.getAudienceThumbnailUrls();
      // setTimeout(()=>{                           // <<<---using ()=> syntax
      //   this.getAudienceThumbnailUrls();
      // }, 3000);
    }
    
  }

  mjtbdFormErrorHandeling(control: string, error: string) {
    return this.mjtbdForm.controls[control].hasError(error);
  }

  onSubmit() {

    const postObj = this.populatePostObject();
    let dialogRef;
    dialogRef = this.dialog.open(MjtbdDialogComponent, {
      data: postObj,
      minWidth: '500px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.globalElementsService.setSidePanelCancelEvent();
      this.dialog.closeAll();
    });
  }

  patchFormValues(mjtbdFormData: IMjForm) {

    this.selectedBrandObjs = mjtbdFormData.brandObjective ? mjtbdFormData.brandObjective.split(',') : [];
    this.selectedCommsObjs = mjtbdFormData.commsObjective ? mjtbdFormData.commsObjective.split(',') : [];
    this.selectedGrowthTarget = mjtbdFormData.growthTarget ? mjtbdFormData.growthTarget.split(',') : [];
    this.selectedAudience = mjtbdFormData.audiencePriority ? mjtbdFormData.audiencePriority.split(',') : [];
    this.selectedTeamMembers = mjtbdFormData.teamMembers ? mjtbdFormData.teamMembers.split(',').map(el => Number(el)) : [];
    
    this.getAudienceList();
    this.getCommsObj();
    
    this.mjtbdForm.patchValue({
      mjtbdFormTitle: mjtbdFormData ? mjtbdFormData.mjtbdFormTitle : null,
      totalMJTBDBudget: mjtbdFormData ? Number(mjtbdFormData.totalMJTBDBudget).toLocaleString() : null,
      cWMBudget: mjtbdFormData ? Number(mjtbdFormData.workingMediaBudget).toLocaleString() : null,
      startDt: mjtbdFormData.startDt && moment(mjtbdFormData.startDt.toString()).isValid() ? mjtbdFormData.startDt :  null,
      endDt: mjtbdFormData.endDt && moment(mjtbdFormData.endDt.toString()).isValid() ? mjtbdFormData.endDt :  null,
      brandObj: mjtbdFormData.brandObjective ? mjtbdFormData.brandObjective.split(',') : null,
      commsObj: mjtbdFormData.commsObjective ? mjtbdFormData.commsObjective.split(',') : null,
      growthTarget: mjtbdFormData.growthTarget ? mjtbdFormData.growthTarget.split(',') : null,
      audience: mjtbdFormData.audiencePriority ? mjtbdFormData.audiencePriority.split(',') : null,
      associatedFormat: mjtbdFormData ? mjtbdFormData.associatedFormat : null
    });

    this.mjtbdFormStatus = mjtbdFormData ? mjtbdFormData.mjtbdFormStatus : null;
    this.oldStatus = mjtbdFormData ? mjtbdFormData.mjtbdFormStatus : null;
    this.updateStatusList();
    this.getAudienceThumbnailUrls();
  }


  updateStatusList() {
    this.mjtbdForm.get('mjtbdApprover').setValidators([Validators.required]);
    let approversIds: Array<any> = [];
    if (this.mjtbdFormData && this.mjtbdFormData.approversList) {
      approversIds = this.mjtbdFormData.approversList.split(',');
    }

    let isMatched = false;

    // if (this.userInfo.roleId <= 3) {
    //   isMatched = true;
    // } else {
    //   isMatched = false;
    // }

    approversIds.forEach(id => {
      if (this.userInfo.id == id) {
        isMatched = true;
      }
    });

    if (this.mjtbdFormStatus === STATUS_LIST_TYPES.DRAFT || this.mjtbdFormStatus == null) {
      this.mjtbdForm.get('mjtbdApprover').setValidators([]);
      this.mjtbdStatusList = MINIFIED_STATUS_LIST;
      this.mjtbdEditableStatus = true;
    } else if (isMatched && this.mjtbdFormStatus === STATUS_LIST_TYPES.COMPLETE) {
      this.mjtbdEditableStatus = true;
      this.mjtbdStatusList = STATUS_LIST;
    } else if (isMatched && this.mjtbdFormStatus === STATUS_LIST_TYPES.FINAL) {
      this.mjtbdEditableStatus = true;
      this.mjtbdStatusList = FINAL_STATUS_LIST;
    } else {
      this.mjtbdEditableStatus = false;
      this.mjtbdStatusList = STATUS_LIST;
    }    
  }

  mjtbdFormStatusChange() {
    if (this.mjtbdFormStatus === STATUS_LIST_TYPES.DRAFT) {
      this.mjtbdForm.get('mjtbdApprover').setValidators([]);
    } else {
      this.mjtbdForm.get('mjtbdApprover').setValidators([Validators.required]);
    }
    this.mjtbdForm.get('mjtbdApprover').updateValueAndValidity();
    this.globalElementsService.setSaveEnableState(this.mjtbdForm.valid);

  }
  async getAudienceThumbnailUrls() {
    // this.audienceThumnailList = [];
    const { year, market, plan } = this.elementDetails.mjtbdData;
    let path = `${SHAREPOINT_DOC_PATH.INPUT_FILES}/${SHAREPOINT_DOC_PATH.CDNA}/${year}/${market}`;
    if(this.selectedAudience.length > 0){
      this.selectedAudience.forEach(async (audience: string) => {

        if(!this.audienceThumnailList.find((audienceThumnail: any) => audienceThumnail.title === audience)) {
          const condition = `${plan}_${audience}`.trim();
          const hasAudienceDoc = this.audienceDocs.find((doc: IDocument) => doc.fileName.toLocaleLowerCase().indexOf(condition.toLocaleLowerCase()) > -1);
          let audience_thumbnailb64data = hasAudienceDoc && await this.globalElementsService.getThumbnailBase64DataSharePoint_new({filePathWName: `${path}/${hasAudienceDoc.fileName}`}).toPromise();
          // console.log(audience_thumbnailb64data)
          // if(audience_thumbnailb64data && audience_thumbnailb64data["thumbnailb64data"] == "undefined"){
          //   audience_thumbnailb64data = hasAudienceDoc && await this.globalElementsService.getThumbnailBase64DataSharePoint_new({filePathWName: `${path}/${hasAudienceDoc.fileName}`}).toPromise();
          // }
          const audienceDocPath = audience_thumbnailb64data && `data:image/jpg;base64,` + audience_thumbnailb64data["thumbnailb64data"];
        
          if(!this.audienceThumnailList.find((audienceThumnail: any) => audienceThumnail.title === audience)) {
            this.audienceThumnailList.push({ title: audience, thumnailUrl: audienceDocPath, path: hasAudienceDoc ? hasAudienceDoc.path : "" });
          }
        }

        //old logic
        // const condition = `${plan}_${audience}`.trim();
        // const hasAudienceDoc = this.audienceDocs.find((doc: IDocument) => doc.fileName.toLocaleLowerCase().indexOf(condition.toLocaleLowerCase()) > -1);
        // const audience_thumbnailb64data = hasAudienceDoc && await this.globalElementsService.getThumbnailBase64DataSharePoint_new({filePathWName: `${path}/${hasAudienceDoc.fileName}`}).toPromise();
        // const audienceDocPath = audience_thumbnailb64data && `data:image/jpg;base64,` + audience_thumbnailb64data["thumbnailb64data"];
        // if(!this.audienceThumnailList.find((audienceThumnail: any) => audienceThumnail.title === audience)) {
        //   this.audienceThumnailList.push({ title: audience, thumnailUrl: audienceDocPath, path: hasAudienceDoc ? hasAudienceDoc.path : "" });
        // }
      });
      //remove unselected audience
      this.audienceThumnailList = this.audienceThumnailList.filter((el) =>{
        return this.selectedAudience.some((f) => {
          return f === el.title;
        });
      })
    }    
    // console.log(this.selectedAudience)
    // console.log(this.audienceThumnailList)
  }

  /*........... Service/API calls........ */

  getApproversList() {
    const { planId, year } = this.elementDetails.mjtbdData;
    let obj = {
      referenceId: planId,
      referenceType: FORM_REFERENCE_TYPES.PLAN,
      year: year
    }
    this.globalElementsService.getApproversList(obj).subscribe((res: Array<IApproverList>) => {
      this.mjtbdApproverList = res;
      let approvers=[];
      if(this.mjtbdFormData && this.mjtbdFormData.approversList){
        approvers=this.mjtbdFormData.approversList.split(',');
      };
      this.selectedMjtbdApprovers = [];
      approvers.forEach(id => {
        const mjtbdApprover = this.mjtbdApproverList.find((el: any) => el.userId == id);
        if(mjtbdApprover){
          this.selectedMjtbdApprovers.push(mjtbdApprover);
        } else {
          this.selectedMjtbdApprovers = [];
        }
      });
      this.mjtbdForm.get("mjtbdApprover").patchValue(this.selectedMjtbdApprovers);
    })
  }

  getTeamMembersList(){
    const { planId, year } = this.elementDetails.mjtbdData;
    let teamMemberReq : ITeamMembersReq = {
      referenceId: planId,
      referenceType: FORM_REFERENCE_TYPES.PLAN,
      year: year
    }

    this.globalElementsService.getTeamMembersList(teamMemberReq).subscribe((res:Array<ITeamMembers>)=>{
      this.teamMembersList = res ? res.sort((a, b)=> a.name >= b.name ? 1 : -1) : [];
      const uniqueIds = [];
      if(this.teamMembersList){
        this.teamMembersList = res.filter(el => {
          const isDuplicate = uniqueIds.includes(el.userId);
          if (!isDuplicate) {
            uniqueIds.push(el.userId);
        
            return true;
          }
          return false;
        })
      }
      this.generateTeamMemeberAvatars(); 
    })
    
  }
  sendData(){
    return this.mjtbdApproverList;
  }
  sendSelectedData(){
   return this.selectedMjtbdApprovers; 
  }
  selectChange = (event: any) => {
    this.selectedMjtbdApprovers = event.data;
    this.mjtbdForm.get("mjtbdApprover").patchValue(this.selectedMjtbdApprovers);
  };
  getMJTBDFormDropDowns(planId: number) {
    this.planService.getMJTBDFormDropDowns({planId}).subscribe((res: IMJTBDdropdowns) => {
      this.brandObjList = res.brandObjectives;
      this.behavioralGrowthTargetList = res.behavioralGrowthTargets;
    });
  }

  getMJTBDForm() {
    const { year, mjtbdId } = this.elementDetails.mjtbdData;
    let mjtbdObj: IMjtbdBody = {
      mjtbdId,
      year
    }
    this.planService.getMJTBDForm(mjtbdObj).subscribe((res: IMjtbdForm) => {
      this.isLoading = false;
      this.mjtbdFormData = res.mjForm;
      const { brandObjective } = this.mjtbdFormData;
      const { planId } = this.elementDetails.mjtbdData;
      this.getApproversList();
      this.getMJTBDFormDropDowns(planId);
      if (this.mjtbdFormData) {
        this.patchFormValues(this.mjtbdFormData);
        this.getTeamMembersList();
        this.defaultPathDetails();
      }
    })
  }

  async getAudienceDocsFromSharePoint() {
    const { year, market } = this.elementDetails.mjtbdData;
    let path = `${SHAREPOINT_DOC_PATH.INPUT_FILES}/${SHAREPOINT_DOC_PATH.CDNA}/${year}/${market}`;
    getDocsFromSharepointFilesAPI(path, this.globalElementsService).then((res) => {
      this.audienceDocs = res;
      if (this.elementDetails.mjtbdData.mode == 'edit') {
        this.editMode = true;
        this.getMJTBDForm();
      }
    }).catch(err => {
      if (this.elementDetails.mjtbdData.mode == 'edit') {
        this.editMode = true;
        this.getMJTBDForm();
      }
    });
    
  }

  populatePostObject(): IMjtbdPostObj {
    const { year, planId , mode } = this.elementDetails.mjtbdData;
    let formData = this.mjtbdForm.value;
    let mjtbdApprover = formData.mjtbdApprover && formData.mjtbdApprover.map((obj: IApproverList) => obj.userId);
    const { completedByUserId, finalizedByUserID } = getCompletedAndFinalizedUsers(this.oldStatus, this.mjtbdFormStatus, this.mjtbdFormData);
    let mjtbdObj: IMjtbdSave = {
      planId,
      year,
      alertURL: `/plan/${planId}`,
      formInfo: {
        mjtbdFormStatus: this.mjtbdFormStatus,
        mjtbdFormTitle: formData.mjtbdFormTitle,
        totalMJTBDBudget: formData.totalMJTBDBudget.replace(/,/g, ''),
        workingMediaBudget: formData.cWMBudget.replace(/,/g, ''),
        startDt: formData.startDt,
        endDt: formData.endDt,
        commsObjective: !!formData.commsObj ? formData.commsObj.toString() : null,
        brandObjective: !!formData.brandObj ? formData.brandObj.toString() : null,
        audiencePriority: !!formData.audience ? formData.audience.toString() : null,
        growthTarget: !!formData.growthTarget ? formData.growthTarget.toString() : null,
        associatedFormat: formData.associatedFormat,
        documentsPath: "formInfo.documentsPath",
        attachmentsPath: "formInfo.attachmentsPath",
        teamMembers: this.selectedTeamMembers.toString(),
        approversList: mjtbdApprover ? mjtbdApprover.toString() : '',
        completedByUserId: completedByUserId,
        finalizedByUserID: finalizedByUserID,
        currentUserID: this.userInfo.id
      }
    }
    if (mode == 'save') {
      const postObj = {
        mjtbdData: mjtbdObj,
        mode: 'save',
        deafultMsg: 'Saving MJTBD information......Please wait'
      }
      return postObj;
    } else {
      this.editMode = true;
      delete mjtbdObj.planId;
      const { mjtbdId, mjtbdFormId } = this.mjtbdFormData;
      mjtbdObj['mjtbdFormId'] = mjtbdFormId;
      mjtbdObj['mjtbdId'] = mjtbdId;
      const postObj = {
        mjtbdData: mjtbdObj,
        mode: 'edit',
        deafultMsg: 'Updating MJTBD information......Please wait'
      }
      return postObj;
    }

  }
  showDeleteBtn(audienceName) {
    const _result = _.union(this.audienceList, this.customAudienceList.map(el=> el.customAudienceName))
    return _result.indexOf(audienceName) === -1
  }
  deleteAudience(audienceName) {
    this.selectedAudience = _.without(this.selectedAudience,audienceName);
    this.mjtbdForm.patchValue({
      audience: this.selectedAudience
    });
    this.getAudienceThumbnailUrls();
  }
  downloadFile(path:string){
    let fileName = path.split('/').pop();
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length); 
    this.globalElementsService.downloadFile({fileName: fileName, path: path.split('/Shared Documents/')[1]}).subscribe(response => {
      this.downloadFileFromBase64(fileName,response['thumbnailb64data'], fileMimeTypes[extension] );
      // const byteCharacters = window.atob(response['thumbnailb64data']);
      // const downloadLink = document.createElement("a");
      // downloadLink.download = fileName.replace(/ /g,'');
      // downloadLink.href = source;
      // document.body.appendChild(downloadLink);
      // downloadLink.click();
      // document.body.removeChild(downloadLink);
      // const byteNumbers = new Array(byteCharacters.length);
      // for (let i = 0; i < byteCharacters.length; i++) {
      //   byteNumbers[i] = byteCharacters.charCodeAt(i);
      // }
      // const byteArray = new Uint8Array(byteNumbers);
      // const blob = new Blob([byteArray], {type: `data:${fileMimeTypes[extension]}`});
      // saveAs(blob, fileName.replace(/ /g,''));
  })
}
downloadFileFromBase64(fileName, b64encodedString: string, contentType ) {
  if (b64encodedString) {
    var blob = this.base64ToBlob(b64encodedString, contentType);
    saveAs(blob, fileName);
  }
}

public base64ToBlob(b64Data, contentType='', sliceSize=512) {
  let byteArrays = ab2b64.b642ab(b64Data)
  return new Blob([byteArrays], {type: contentType});
}
  /*........... Clean up methods........ */

  ngOnDestroy() {

    this.saveBtnSubscription.unsubscribe();
    this.globalElementsService.setShowSaveBtn(false);
    this.formDirty.emit(false);

  }

  sendMessageToCollaborators(){
    let data = this.populatePostObject();
    let snDialogRef;
      snDialogRef = this.dialog.open(SendNotificationsDialogComponent, {
        data: {
          data :data,
          type:FORM_REFERENCE_TYPES.MJTBD_FORM
          },
        height: '150px',
        width: '500px',
        disableClose:true
      });

    snDialogRef.afterClosed().subscribe((res: any) => {
    });
    
  }
  
  CommaFormatted(event) {
    if(event.which >= 37 && event.which <= 40) return;
   
    if (event.target.value) {
      event.target.value = event.target.value.replace(/\D/g, "")
       .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
   
   numberCheck (args) {
   if (args.key === 'e' || args.key === '+' || args.key === '-') {
     return false;
   } else {
     return true;
   }
  }
  
  defaultPathDetails() {
    this.pathDetails = {
      lastNodeName: this.mjtbdFormData ? 'TCP Architecture >> '+this.mjtbdFormData.mjtbdFormTitle : 'TCP Architecture >> MJTBD',
      pageId: this.elementDetails.mjtbdData.planId,
      pageRefType: BREADCRUMBS_REFERENCE_TYPES.PLAN,
      lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.TCP_DOCUMENT_TRACKER
    }
  }
  downloadFromLink(id: string) {
    // const plan = this.elementDetails.tcpDocData.plan;
    const docId = id.toLocaleUpperCase();
    // const selectedCampaignName = this.selectedCampaign ? this.selectedCampaign.campaignName : "";
    let fileName = `${TCP_DOC_ID[docId]}.pptx`;

    fileName = `${TCP_DOC_ID[docId]}.pptx`;
    fileName = fileName.replace(/ /, '_');
    this.downloadPdfFromLink_new(`${BASE_API_URL}/v3/PPT_Templates/CANVAS_for_TCP_KPI_Framework.pptx`, fileName);
  }

  async downloadPdfFromLink_new(ulr, fileName:string) {
    // const downloadLink = document.createElement("a");
    // downloadLink.download = fileName.replace(/ /g,'');
    // downloadLink.href = ulr;
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);
    var res = await fetch(ulr);
    var blob = await res.blob();
    saveAs(blob, fileName);
  }

  openKPIFrameworkPPTPage(){
    // let sidePanelEle = { ...GLOBAL_ELEMENTS.KPI_FRAMEWORK };
    // this.sharedDataService.setSideNavSelection(sidePanelEle); 
    this.changePageMode(PAGE_MODES.EDIT);
  }
  isHomePageMode() {
    return !(this.mode === PAGE_MODES.HOME);
  }
  changePageMode(pageMode) {
    this.mode = pageMode
  }


}