import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProgramService2 } from '../services/program.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { DASHBOARD_MESSAGE } from 'app/constants/app-constants';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';

@Component({
  selector: 'app-campaign-performance-dashboard',
  templateUrl: './campaign-performance-dashboard.component.html',
  styleUrls: ['./campaign-performance-dashboard.component.scss']
})
export class CampaignPerformanceDashboardComponent implements OnInit {

  @Input() elementDetails;
  
  campaignIFrame;
  planName: string;
  isLoading: boolean = false;
  message: string;

  constructor(
    private sanitizer: DomSanitizer,
    private programService: ProgramService2,
    protected googleTagManagerService: GoogleTagManagerService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.getDashboardUrl();
    if (this.elementDetails.cPerfData) {
      this.planName = this.elementDetails.cPerfData.planName;
    }
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Campaign_Performance_Dashboard`,
      eventAction: "PageView",
      eventLable: `Campaign Performance Dashboard Page View`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  getDashboardUrl() {
    const urlObj = { ...this.elementDetails.cPerfData }
    this.programService.getDashboardUrl(urlObj).subscribe((res) => {
      if (res['campaignIFrame']) {
        this.campaignIFrame = this.sanitizer.bypassSecurityTrustResourceUrl(res['campaignIFrame']);
      }
      this.message = DASHBOARD_MESSAGE.CPERF_SUCCESS.replace("##PLANNAME##", this.planName);
      this.isLoading = false;
    }, (error) => {
      this.isLoading = false;
      this.message = DASHBOARD_MESSAGE.ERROR;
    });
  }

  ngOnDestroy(): void {
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Campaign_Performance_Dashboard`,
      eventAction: "PageExit",
      eventLable: `Campaign Performance Dashboard Page Exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

}
