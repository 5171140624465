import { ICheckEditLock, IEditLock } from 'app/common/interfaces/program';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { FileUploadDialogComponent } from 'app/common/components/dialogs/file-upload-dialog/file-upload-dialog.component';
import { ProgramDialogComponent } from 'app/common/components/dialogs/program-dialog/program-dialog.component';
import { IBreadcrumb, IDocument, IOverview } from 'app/common/interfaces/common';
import { ISectionHeader } from 'app/common/interfaces/overviewSection';
import { IPlanOverviewData } from 'app/common/interfaces/plan';
import { SharedDataService } from 'app/common/services/shared-data.service';
import { getDocsFromSharepointFilesAPI, getFileBuffer, getGpId } from 'app/common/utils/common-util';
import { FILE_NAMES, FILE_TYPE, GLOBAL_ELEMENTS, SHAREPOINT_DOC_PATH, TCP_DOC_ID } from 'app/constants/app-constants';
import { SECTION_HEADER_INFO } from 'app/constants/overview-constants';
import { PlanService } from 'app/plan/services/plan.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ICampaignIdeaSave, ICampaignKPIandAudience, ICommsTaskSave, IOppBriefSave } from 'app/common/interfaces/program';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { AGGR_VIEW, BREADCRUMBS_LAST_NODE_TYPES, BREADCRUMBS_REFERENCE_TYPES } from 'app/constants/app-constants';
import { ProgramService2 } from '../../services/program.service';
import { environment } from 'environments/environment';
import { MatTable } from '@angular/material/table';
import { MatTableDataSource } from '../../../../../node_modules/@angular/material';
import * as _ from 'lodash';
import * as moment from "moment";
import { saveAs } from 'file-saver';
import { fileMimeTypes } from 'app/constants/app-constants';
import ab2b64 from 'ab2b64' ;



@Component({
  selector: 'app-campaign-summary',
  templateUrl: './campaign-summary.component.html',
  styleUrls: ['./campaign-summary.component.scss']
})
export class CampaignSummaryComponent implements OnInit {

  pathDetails : IBreadcrumb;
  paramsId: number;
  campaignName:string;
  mjtbdName:string;
  workingMediaBudget:any;
  targets = [];
  marketId:number;
  planName:string;
  planId:number;
  hierarchy: string;
  successKPIS = [];
  channelsData = [];
  attach_money:any;
  startDate:any;
  endDate:any;
  dataSource;
  displayedColumnsForCommsTask : string[] = ['#','ChannelName', 'Working Media Budget'];

  apiData: Object;
  FileNamePPTX: string;
  DownloadBase64Data: string;

  constructor(
    private programService: ProgramService2,
    private globalElementsService: GlobalElementsService,
    private route: ActivatedRoute,
    private sharedDataService: SharedDataService,
    private fb: FormBuilder,
    public router: Router,
    private planService: PlanService,
    public dialog: MatDialog,
    protected googleTagManagerService: GoogleTagManagerService

  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const campaignId = params['id'];
      this.paramsId = campaignId && Number(campaignId);
      this.attach_money = sessionStorage.getItem('currencySign');

    });
    this.getCampaignData();
  }
  getCampaignData(){
    const postObj = {
      campaignId: this.paramsId
    }
    this.programService.getCampaignDataForBreadCrump(postObj).subscribe(res => {
      if(res){
        this.campaignName = res['campaigns'][0].campaignName;
        this.planId = res['planId'];
        this.pathDetails = {
          lastNodeName: this.campaignName,
          pageId: this.paramsId,
          pageRefType: BREADCRUMBS_REFERENCE_TYPES.CAMPAIGN,
          lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.CAMPAIGN
        }
      }
      this.getHierarchy();
      this.getCampaignSummaryData();
    })
  }
  getHierarchy() {
    let planObj = {
      planId: this.planId
    }
    this.planService.getHierarchy(planObj).subscribe((res: any) => {
      if (res) {
        this.marketId=res.market.id;
        this.planName=res.plan.displayName;
        this.hierarchy = `${res.market.displayName}/${res.portfolio.displayName}/${res.plan.displayName}`;
      }
    }, (error)=>{
    });
    
  }
  getCampaignSummaryData(){
    const postObj = {
      campaignId: this.paramsId
    }
    this.programService.getCampaignSummaryData(postObj).subscribe(res => {
      if(res){
        this.startDate = res['oppBriefData'][0]['startDt'] ? res['oppBriefData'][0]['startDt'] : 'No Start Date available';
        this.endDate = res['oppBriefData'][0]['EndDt'] ? res['oppBriefData'][0]['EndDt'] : 'No End Date available';
        this.campaignName = res['oppBriefData'][0]['campaignName'] ? res['oppBriefData'][0]['campaignName']: null;
        this.mjtbdName = res['oppBriefData'][0]['MJTBDName'] ? res['oppBriefData'][0]['MJTBDName'] : null;
        this.workingMediaBudget = res['oppBriefData'][0]['workingMediaBudget'] ? Number(res['oppBriefData'][0]['workingMediaBudget']).toLocaleString(): 0;
        this.targets = res['oppBriefData'][0]['audiencePriority'] == null ? [] : res['oppBriefData'][0]['audiencePriority'] == '' ? [] : res['oppBriefData'][0]['audiencePriority'].split(",");
        this.successKPIS = res['oppBriefData'][0]['successKPIs'] == null ? [] : res['oppBriefData'][0]['successKPIs'] == '' ? [] : res['oppBriefData'][0]['successKPIs'].split(",");
        this.channelsData = res['channelsData'] ? res['channelsData'] : [];
        this.dataSource = new MatTableDataSource<any>(this.channelsData);
        this.apiData = res; 
      }
    })
  }
  downloadCampaignSummaryPPT(){

    const docId = this.paramsId;
    let fileName = "CampaignSummary.pptx";
    // let fileName = `CampaignSummary${docId}.pptx`
    fileName = fileName.replace(/ /g, '');

    let passingData = {
      templateCampaignIdeaData: this.generateTemplateObj(docId), 
      fileName: fileName,
      otherData: this.apiData
    };

    this.programService.downloadCampaignSummaryPPT(passingData).subscribe((res) => {
      this.FileNamePPTX = "CampaignSummary.pptx";
      // this.DownloadBase64Data = "data:application/pptx;base64," + res["base64Data"];
      this.DownloadBase64Data = res["base64Data"];
      this.downloadFileFromBase64(fileName);
    });
  }
  downloadFileFromBase64(fileName) {
    const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    this.downloadFileFromBase64New(fileName,this.DownloadBase64Data, fileMimeTypes[extension] );
    // const byteCharacters = window.atob(this.DownloadBase64Data);
    // const downloadLink = document.createElement("a");
    // downloadLink.href = this.DownloadBase64Data;
    // downloadLink.download = fileName;
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);
    // const byteNumbers = new Array(byteCharacters.length);
    //   for (let i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    //   }
    //   const byteArray = new Uint8Array(byteNumbers);
    //   const blob = new Blob([byteArray], {type: `data:${fileMimeTypes[extension]}`});
    //   saveAs(blob, fileName.replace(/ /g,''));
  }
  downloadFileFromBase64New(fileName, b64encodedString: string, contentType ) {
    if (b64encodedString) {
      var blob = this.base64ToBlob(b64encodedString, contentType);
      saveAs(blob, fileName);
    }
  }

  public base64ToBlob(b64Data, contentType='', sliceSize=512) {
    let byteArrays = ab2b64.b642ab(this.DownloadBase64Data)
    return new Blob([byteArrays], {type: contentType});
  }

  generateTemplateObj(Templatetype: any) {
    const _object_to_send = {};
    const targetsCount = this.targets.length;
    const successKPISCount = this.successKPIS.length;
    const channelsDataCount = this.channelsData.length;
    let maximumSlideCount = 1;
    let maximumElementCount = 0;
    let largestArray = "";

    if(targetsCount> 4 && targetsCount >= successKPISCount && targetsCount >= channelsDataCount ){
      maximumSlideCount = Math.ceil(targetsCount / 4);
      maximumElementCount = targetsCount;
      largestArray = "target";
    }
    if(successKPISCount> 4 && successKPISCount >= targetsCount && successKPISCount >= channelsDataCount ){
      maximumSlideCount= Math.ceil( successKPISCount/ 4);
      maximumElementCount =  successKPISCount;
      largestArray = "successKPI";
    }
    if(channelsDataCount> 4 && channelsDataCount >= successKPISCount && channelsDataCount >= targetsCount ){
      maximumSlideCount = Math.ceil(channelsDataCount / 4);
      maximumElementCount = channelsDataCount;    
      largestArray = "channel";
    }
    if (maximumSlideCount == 1){
      let obj_row: any = this.returnObjectMap();
      for(let i = 0; i < 4; i++){
        let rowNo = i + 1;

        obj_row[`SL_ROW_${rowNo}`] = String(rowNo);
        obj_row[`CHANNEL_NAME_ROW_${rowNo}`] = this.channelsData[i] && this.channelsData[i].channelName? this.channelsData[i].channelName : "";
        obj_row[`BUDGET_ROW_${rowNo}`] = this.channelsData[i] && this.channelsData[i].cWMBudget ? `$ ${this.channelsData[i].cWMBudget}` : 0;
        obj_row[`TARGET_ROW_${rowNo}`] = this.targets[i]? this.targets[i] : "";
        obj_row[`SUCCESS_KPI_ROW_${rowNo}`] = this.successKPIS[i] ? this.successKPIS[i] : "";

      }
      obj_row[`Campaign_Name`] = this.campaignName ? this.campaignName : "";
      obj_row[`Campaign_Start_Date`] = this.startDate ? moment(this.startDate).format('DD/MM/yyyy') : "";
      obj_row[`Campaign_End_Date`] = this.endDate ? moment(this.endDate).format('DD/MM/yyyy') : "";
      obj_row[`Working_Media_Budget`] = this.workingMediaBudget ? `$ ${this.workingMediaBudget}`  : "$ 0";
      obj_row[`Mjtbd`] = this.mjtbdName ? this.mjtbdName : "";
      _object_to_send[`Slide_11`] = obj_row
    }
    else{
      for(let j = 0; j < maximumSlideCount; j++ ){
        let slideNo = j + 1;
        let obj_row: any = this.returnObjectMap();
        for(let i = 0; i < 4; i++){
          let rowNo = i + 1;

          obj_row[`SL_ROW_${rowNo}`] = String(j*4 + rowNo) ;
          obj_row[`CHANNEL_NAME_ROW_${rowNo}`] = this.channelsData[j*4+i] && this.channelsData[j*4+i].channelName ? this.channelsData[j*4+i].channelName : "";
          obj_row[`BUDGET_ROW_${rowNo}`] = this.channelsData[j*4+i] && this.channelsData[j*4+i].cWMBudget ? `$ ${this.channelsData[j*4+i].cWMBudget}`  : 0;
          obj_row[`TARGET_ROW_${rowNo}`] = this.targets[j*4+i]? this.targets[j*4+i] : "";
          obj_row[`SUCCESS_KPI_ROW_${rowNo}`] = this.successKPIS[j*4+i] ? this.successKPIS[j*4+i] : "";

          // obj_row[`CHANNEL_NAME_ROW_${rowNo}`] = this.channelsData[j*4+i] ? this.channelsData[j*4+i].channelName : (slideNo >= 1 && this.channelsData[j*4+1] ? this.channelsData[(j-1)*4+i].channelName : "");
          // obj_row[`BUDGET_ROW_${rowNo}`] = this.channelsData[j*4+i] ? this.channelsData[j*4+i].cWMBudget : (slideNo >= 1 && this.channelsData[j*4+1] ? this.channelsData[(j-1)*4+i].cWMBudget : 0);
          // obj_row[`TARGET_ROW_${rowNo}`] = this.targets[j*4+i]? this.targets[j*4+i] : (slideNo >= 1 && this.targets[j*4+1] ? this.targets[(j-1)*4+i] : "");
          // obj_row[`SUCCESS_KPI_ROW_${rowNo}`] = this.successKPIS[j*4+i] ? this.successKPIS[j*4+i] : (slideNo >= 1 && this.successKPIS[j*4+1] ? this.successKPIS[(j-1)*4+i] : "") ;
        }
        obj_row[`Campaign_Name`] = this.campaignName ? this.campaignName : "";
        obj_row[`Campaign_Start_Date`] = this.startDate ? moment(this.startDate).format('DD/MM/yyyy') : "";
        obj_row[`Campaign_End_Date`] = this.endDate ? moment(this.endDate).format('DD/MM/yyyy') : "";
        obj_row[`Working_Media_Budget`] = this.workingMediaBudget ? `$ ${this.workingMediaBudget}`  : "$ 0";
        obj_row[`Mjtbd`] = this.mjtbdName ? this.mjtbdName : "";
        _object_to_send[`Slide_1${slideNo}`] = obj_row

        }  
    }
   
    return _object_to_send;
  }
  generateTemplateObjOld(Templatetype: any) {
    const _object_to_send = {};

        let channelsDataArray = this.channelsData;
        if (channelsDataArray.length > 0) {
          // channelsDataArray = channelsDataArray.sort((a, b) => {
          //   return a.channelName.localeCompare(b.channelName);
          // });
          let slideNo = 1;
          let rowNo = 1;
          let SlideRowCounter = 0;
          const chunkAudeinceArr = _.chunk(channelsDataArray, 4);
          chunkAudeinceArr.forEach((element: Array<any>, index: number) => {
            let obj_row: any = this.returnObjectMap();;
            element.forEach((innerArrObj: any, idx: number) => {
              let rowNo = idx + 1;
              obj_row[`CHANNEL_NAME_ROW_${rowNo}`] = innerArrObj.channelName ? innerArrObj.channelName : "";
              obj_row[`BUDGET_ROW_${rowNo}`] = innerArrObj.cWMBudget ? innerArrObj.cWMBudget : 0;
            });
            _object_to_send[`Slide_1${index + 1}`] = obj_row
          });
        } else {
          _object_to_send[`Slide_1`] = this.returnObjectMap();
        }
        
   
    return _object_to_send;
  }

  returnObjectMap() {
    return {
      "SL_ROW_1": "",
      "SL_ROW_2": "",
      "SL_ROW_3": "",
      "SL_ROW_4": "",
      "CHANNEL_NAME_ROW_1": "",
      "CHANNEL_NAME_ROW_2": "",
      "CHANNEL_NAME_ROW_3": "",
      "CHANNEL_NAME_ROW_4": "",
      "BUDGET_ROW_1": "",
      "BUDGET_ROW_2": "",
      "BUDGET_ROW_3": "",
      "BUDGET_ROW_4": "",
      "TARGET_ROW_1": "",
      "TARGET_ROW_2": "",
      "TARGET_ROW_3": "",
      "TARGET_ROW_4": "",
      "SUCCESS_KPI_ROW_1": "",
      "SUCCESS_KPI_ROW_2": "",
      "SUCCESS_KPI_ROW_3": "",
      "SUCCESS_KPI_ROW_4": ""
    }
  }

}
