import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil } from 'lodash';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiTimeLoggerInterceptorService implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const endpointConfig = this.getEndpointConfig(req);

    // reinitialize req after removing temp http header data used for transferring endpoint configs
    req = endpointConfig.req;
    const startTimestamp = new Date().getTime();
    const newReq = req.clone({
      headers: req.headers.set('startTimestamp', startTimestamp.toString())
    });

    return next.handle(newReq).pipe(
      tap({
        next: (event: HttpEvent<unknown>) => {
          if (event instanceof HttpResponse) {
            if(endpointConfig.showApiTimeLog){
            const endTimestamp: number = new Date().getTime();
            const startTimestamp2 = Number(
              newReq.headers.get('startTimestamp')
            );
            const responseTimes = endTimestamp - startTimestamp2;
            console.log(
              '::ANGULAR HTTP API RESPONSE TIME: ' +
                responseTimes.toString() +
                ' ms' +
                ' - URL: ' +
                newReq.url
            );
          }
        }
        }
      })
    );
  }
    getEndpointConfig(req: HttpRequest<unknown>): {
    showApiTimeLog: boolean;
    req: HttpRequest<unknown>;
  } {
    let headers: HttpHeaders = req.headers;
    let showApiTimeLog = false;
    if (!isNil(headers.has('showApiTimeLog'))) {
      showApiTimeLog = JSON.parse(headers.get('showApiTimeLog') as string) as boolean;
      headers = headers.delete('showApiTimeLog');
    }
    req = req.clone({ headers });

    return {
      showApiTimeLog,
      req
    };
  }
}
