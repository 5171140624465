import { PAGE_MODES } from './../../../constants/app-constants';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';

@Component({
  selector: 'app-brand-market-group',
  templateUrl: './market-group.component.html',
  styleUrls: ['./market-group.component.scss']
})
export class MarketBrandGroupComponent implements OnInit {

  @Output('dirty')  formDirty = new EventEmitter<boolean>()

  selectedMarketGroupList = [];
  mode = PAGE_MODES.HOME;
  userInfo = JSON.parse(sessionStorage.getItem("user"));

  constructor(protected googleTagManagerService: GoogleTagManagerService
  ) { }

  ngOnInit() {

  }

  getSelectedMarketGroup(childEmmitedEvent) {
    this.selectedMarketGroupList = childEmmitedEvent;
  }


  isHomePageMode() {
    return this.mode === PAGE_MODES.HOME || this.mode == PAGE_MODES.DELETE;
  }

  enableCreateMode() {
    this.changePageMode(PAGE_MODES.CREATE);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Market_Group_Management`,
      eventAction: "Add Market Group click",
      eventLable: `Global Elements New Market Group click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  enableEditMode() {
    this.changePageMode(PAGE_MODES.EDIT);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Market_Group_Management`,
      eventAction: "Edit Market Group clicked",
      eventLable: `Global Elements Edit Market Group click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  enableHomePageMode() {
    this.changePageMode(PAGE_MODES.HOME);
  }

  enableDeleteMode() {
    this.changePageMode(PAGE_MODES.DELETE);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Market_Group_Management`,
      eventAction: "Delete Button Clicked",
      eventLable: `Global Elements Delete Market Group click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  changePageMode(pageMode) {
    this.mode = pageMode
  }

  isFormDirty(isDirty:boolean) {
    this.formDirty.emit(isDirty);
  }


}
