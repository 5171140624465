import { BrandManagementService } from './../../../services/brand-management.service';
import { PAGE_MODES } from './../../../../constants/app-constants';
import { GlobalElementsService } from './../../../services/global-elements.service';
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, OnDestroy } from '@angular/core';
import { MatDialog } from '../../../../../../node_modules/@angular/material';
import { BrandManagementDialogsComponent } from '../../../../common/components/dialogs/brand-management-dialogs/brand-management-dialogs.component';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';

@Component({
  selector: 'app-homepage-market',
  templateUrl: './homepage-market.component.html',
  styleUrls: ['./homepage-market.component.scss']
})
export class HomepageMarketComponent implements OnInit, OnChanges, OnDestroy {

  @Output() marketSelectionEvent = new EventEmitter();
  @Output() setHomePageModeEvent = new EventEmitter();
  @Input() pageMode;

  markets = [];
  selectedMarketList = [];
  isLoading = true;
  errorLoading = false;

  constructor(
    public dialog: MatDialog,    
    private brandManagementService: BrandManagementService,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }

  ngOnInit() {
    this.getMarkets();
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
              eventCategory: `Market_Management`,
              eventAction: "PageView",
              eventLable: `Global Elements Market Management Page view`,
              customeEvent: `custom_${environment.environmentName}_Event`,
              userId: getGpId(),
              user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  ngOnChanges() {
    if (this.pageMode == PAGE_MODES.DELETE) {
      this.handleDelete()
    }
  }

  isMarketSelected(mktId) {
    return this.selectedMarketList.includes(mktId)
  }


  selectMarket(mktId) {

    if (this.selectedMarketList.includes(mktId)) {
      this.selectedMarketList = this.selectedMarketList.filter(el => el != mktId)
    } else {
      this.selectedMarketList.push(mktId);
    }

    const marketsList = this.markets.filter(mkt => this.selectedMarketList.includes(mkt.id))
    this.marketSelectionEvent.emit(marketsList);

  }




  handleDelete() {

    const marketsList = this.markets.filter(mkt => this.selectedMarketList.includes(mkt.id));

    // Get the list of markets which has associated portfolios.
    const marketsWithChildren = marketsList.filter(mkt => mkt.hasChildren);

    let childrenList = [];
    marketsWithChildren.forEach(mkt => {
      childrenList.push(...mkt.children);
    })


    let moduleNames = marketsWithChildren.map(mkt => mkt.displayName).join(', ');
    const selectionHasChildren = !!marketsWithChildren.length; 

    const warnData = {
      type: 'delete',
      module: 'market',
      children: {
        childrenList,
        moduleNames,
        moduleCount: marketsWithChildren.length
      }
    };

    const deleteData =  {
      type: 'delete',
      module: 'market',
      apiData: {
        markets: marketsList
      }
    };
    const dialogRef = this.dialog.open(BrandManagementDialogsComponent, {
      data: selectionHasChildren ? warnData : deleteData ,
      minWidth: '500px'
    })


    dialogRef.beforeClose().subscribe(() => {
      this.selectedMarketList = [];
      this.getMarkets();
      this.marketSelectionEvent.emit([]);
      this.setHomePageModeEvent.emit();

    });
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Market_Management`,
      eventAction: "Delete Market Button Clicked",
      eventLable: `Global Elements Delete market click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }




  getMarkets() {
    this.isLoading = true;
    this.errorLoading = false;
    this.brandManagementService.getAllMarkets().subscribe((res:any) => {
      this.markets = res.markets.sort((a, b)=> a.displayName >= b.displayName ? 1 : -1);
      this.isLoading = false;
      this.errorLoading = false;

    }, () => this.errorLoading = true);

  }

  ngOnDestroy(): void {
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Market_Management`,
      eventAction: "Market Management Page exit",
      eventLable: `Global Elements Market Management Page exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }
}
