import { Component, OnInit } from '@angular/core';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import { PlanService } from '../services/plan.service';

@Component({
  selector: 'app-plan-rto-dashboard',
  templateUrl: './plan-rto-dashboard.component.html',
  styleUrls: ['./plan-rto-dashboard.component.scss']
})
export class PlanRtoDashboardComponent implements OnInit {
  
  displayedColumns: string[] = ['planName', 'marketName', 'rtoURL', 'Action'];
  dataSource = new MatTableDataSource();
  constructor(private planService: PlanService) { }

  ngOnInit() {
    this.planService.getDatoramaData().subscribe((response) => {
      const data = response['data'] ? response['data'].sort((a, b) => a.brandName >= b.brandName ? 1 : -1) : [];
      const finalData = data.map((el) => {
        el.brandName = this.camelizeBrandName(el.brandName.toLowerCase());
        if (el.brandName.includes('Rte')) {
          el.brandName = el.brandName.replace('Rte', 'RTE');
        }
        return el;
      })
      this.dataSource = finalData;
    })
  }
  camelizeBrandName(str: any) {
    let arr: any;
    let brandName: any;
    if (str.includes("-")) {
      arr = str.split("-");
    } else if (str.includes("_")) {
      arr = str.split("_");
    } else {
      arr = str.split(" ");
    }
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    if (str.includes("-")) {
      brandName = arr.join("-");
    } else if (str.includes("_")) {
      brandName = arr.join("_");
    } else {
      brandName = arr.join(" ");
    }
    return brandName;
  }

}
