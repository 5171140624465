import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DownloadFileDialogComponent } from "app/common/components/dialogs/download-file-dialog/download-file-dialog.component";
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';

@Component({
  selector: 'app-campaign-study-details-dialog',
  templateUrl: './campaign-study-details-dialog.component.html',
  styleUrls: ['./campaign-study-details-dialog.component.scss']
})
export class CampaignStudyDetailsDialogComponent implements OnInit {

  message: any;
  customSaveButtonText: any;
  customCancelButtonText: any;
  isTitleVisible: true;
  customTitleText: any;
  campaignDetails: any;
  selectedBrandObjs: any;
  selectedCommsObjs: any;
  ifOlderVersionListVisible = false;
  isLoading: boolean;
  fileCount : any;
  allVersion: any;

  constructor(
    public dialogo: MatDialogRef<CampaignStudyDetailsDialogComponent>,    
    public dialog: MatDialog,
    public globalElementsService: GlobalElementsService,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    cancel(): void {
      this.dialogo.close(false);
    }
    confirm(): void {
      this.dialogo.close(true);
    }

  ngOnInit() {
    this.message= this.data.message;
    this.campaignDetails= this.data;
    this.selectedBrandObjs = this.data.brandsobjective ? this.data.brandsobjective.split(',') : [];
    this.selectedCommsObjs = this.data.commsobjective ? this.data.commsobjective.split(',') : [];
    this.getCampaignStudiesVersionInformation();
  }

  showOlderVerionList(){
    this.ifOlderVersionListVisible = true;
  }

  downloadFile(path: string) {
    let fileName = path.split("/").pop();
    path = path ? path.split("/Shared Documents/")[1] : "";
    let docPath = path.split(`/${fileName}`)[0];
    const extension = fileName.substring(
      fileName.lastIndexOf(".") + 1,
      fileName.length
    );
    const downloadObj = {
      fileName: fileName,
      path: path,
      docPath: docPath,
      extension: extension,
    };
    let dialogRef;
    dialogRef = this.dialog.open(DownloadFileDialogComponent, {
      data: downloadObj,
      minWidth: "500px",
      disableClose: true,
    });
    // this.downloadFileFromBase64(fileName,response['thumbnailb64data'], fileMimeTypes[extension] );
    // const byteCharacters = window.atob(response['thumbnailb64data']);
    // const downloadLink = document.createElement("a");
    // downloadLink.download = fileName.replace(/ /g,'');
    // downloadLink.href = source;
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);
    // const byteNumbers = new Array(byteCharacters.length);
    // for (let i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // const byteArray = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteArray], {type: `data:${fileMimeTypes[extension]}`});
    // saveAs(blob, fileName.replace(/ /g,''));
  }

  getCampaignStudiesVersionInformation() {
    this.isLoading = true;
      this.globalElementsService.checkFileCounts(this.campaignDetails.id).subscribe((response) => {
      if(response['data']){
        this.fileCount = response['count'];
        let count = response['count'];
        this.allVersion = response['data'];
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    })
     
  }

}
