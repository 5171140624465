import { Component, OnInit, Input } from '@angular/core';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as _ from 'lodash';
import { GroupColorConstants } from 'app/color-constants';
import { FormControl } from '@angular/forms';
import { PlanService } from 'app/plan/services/plan.service';
import { GlobalElementsService } from '../../../../global-elements/services/global-elements.service';
import { StoreService } from '../../../../old/_services/_store.service';
import * as Color from 'color';
@Component({
  selector: 'app-upfront-scenario-chart',
  templateUrl: './upfront-scenario-chart.component.html',
  styleUrls: ['./upfront-scenario-chart.component.scss']
})
export class UpfrontScenarioChartComponent implements OnInit {

  kpi: FormControl;
  @Input() elementDetails: any;
  maxSpendLimit: number;

  reachCurveData: any;
  roiSeries: any = [];
  reachSeries: any = [];
  customSeries: any = [];
  lineChart: am4charts.XYChart;
  spendData: { spend: number }[] = [];
  weeks: number = 1;

  canCreateScenarios = false;
  effManualCalValue: number;
  route: any;
  campaignId: any;
  campaign: any;
  revenueType: any;
  scenario: any;
  activeScenario: any;
  channelData: any;
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  OTHER : any = [Color('#7FFFD4'),Color('#00FF00'),Color('#FF0000'),Color('#00FFFF'),Color('#0000FF'),Color('#00008B'),Color('#ADD8E6'),Color('#FFFF00'),Color('#FF00FF'),Color('#033E3E'),Color('#2E8B57'),Color('#16E2F5'),Color('#808000'),Color('#B0BF1A'),Color('#00FA9A'),Color('#FFA500')];
  OtherColorChannelConstant = {};
  isLoading: boolean = true;
  constructor(
    private planService: PlanService,
    private globalElementsService: GlobalElementsService,
    private store: StoreService
  ) { }

  ngOnInit() {
    this.globalElementsService.setShowSaveBtn(false);
    this.kpi = new FormControl('roi');
    this.scenario = this.elementDetails.chartData.scenario;
    this.activeScenario = this.elementDetails.chartData.activeScenario;
    this.getScenarioPlot();
    this.OtherColorChannelConstant={};
  }

  ngAfterViewInit() {

  }
  getRandomColors(channelName) {
    if(this.OtherColorChannelConstant[channelName]) {
      return this.OtherColorChannelConstant[channelName];
    }
    else {
      const random = Math.floor(Math.random() * this.OTHER.length);
      let colorConst=this.OTHER[random].darken(0.65).hex();
      this.OtherColorChannelConstant[channelName] = colorConst;
      return colorConst;
    } 
    
  }
  getScenarioPlot() {
    let chartData = this.elementDetails.chartData;
    let plotObj = {
      scenarioId: chartData.scenario.scenarioId,
      marketId: this.store.get('market'),
      referenceId: chartData.planEntityId,
      commsTaskId: null,
      year: chartData.year
    }
    console.time('APIcall');
    this.isLoading = true;
    this.planService.getScenarioPlot(plotObj).subscribe((res: any) => {
      console.log("plot response:",res);
      if (res) {
        console.timeEnd('APIcall');
        this.isLoading = false;
        this.channelData = res.channelsData;
        this.createChart('roi');
      }
    }, (error)=>{
      this.isLoading = false;
      console.log(error);
    });
  }
  changeKpi(value: string) {
    this.lineChart.series.clear();
    if (value === 'roi') {
      this.createChart('roi');
    } else if (value === 'reach') {
      this.setChartToReach();
    } else {
      this.createChart('eff');
    }
  }

  updateChart(maxSpend = undefined) {
    this.maxSpendLimit = Math.ceil(((+10 * 1.25) / this.weeks) / 1000) * 1000;
    this.spendData = [];
    for (let i = 1; (i * 1000) <= (maxSpend || this.maxSpendLimit); i++) {
      this.spendData.push({ spend: i * 1000 });
    }
    this.lineChart.data = this.spendData;
  }

  createChart(type) {
    
      this.lineChart = am4core.create('scenario-chart', am4charts.XYChart);

      const xAxis = this.lineChart.xAxes.push(new am4charts.ValueAxis());
      const yAxis = this.lineChart.yAxes.push(new am4charts.ValueAxis());
      if(type == 'roi'){
        xAxis.title.text = 'Weekly GRPs';
        yAxis.title.text = 'Estimated ROI';
         // Themes begin
      am4core.useTheme(am4themes_animated);

      // Create chart instance
      // xAxis.dataFields.category = 'spend';
      // xAxis.renderer.grid.template.disabled = true;
      // xAxis.renderer.labels.template.disabled = true;
      xAxis.cursorTooltipEnabled = false;
      xAxis.renderer.grid.template.location = 0;

      xAxis.numberFormatter.numberFormat = "#.##";
      yAxis.numberFormatter.numberFormat = "#.##";

      //yAxis.renderer.labels.template.disabled = true;
      // Add cursor
      // yAxis.dataFields.valueY = 'kpi';
      this.lineChart.cursor = new am4charts.XYCursor();
      this.lineChart.cursor.maxTooltipDistance = 5;

      this.lineChart.legend = new am4charts.Legend();
      this.lineChart.legend.labels.template.fill = am4core.color('#000');

      this.setChartToRoi();
      } else if(type == 'eff') {
        xAxis.title.text = 'Weekly GRPs';
        yAxis.title.text = 'Effectiveness';
         // Themes begin
      am4core.useTheme(am4themes_animated);

      // Create chart instance
      // xAxis.dataFields.category = 'spend';
      // xAxis.renderer.grid.template.disabled = true;
      // xAxis.renderer.labels.template.disabled = true;
      xAxis.cursorTooltipEnabled = false;
      xAxis.renderer.grid.template.location = 0;

      //yAxis.renderer.labels.template.disabled = true;
      // Add cursor
      // yAxis.dataFields.valueY = 'kpi';
      this.lineChart.cursor = new am4charts.XYCursor();
      this.lineChart.cursor.maxTooltipDistance = 5;

      this.lineChart.legend = new am4charts.Legend();
      this.lineChart.legend.labels.template.fill = am4core.color('#000');

      this.setChartToEff();
      }
  }


  createLineSeries(name: string, data: any, custom: boolean = false) {
    console.time('ChartDraw');
    var series = new am4charts.LineSeries();
    series.dataFields.valueY = "kpi";
    series.dataFields.valueX = "spend";
    series.tooltipText = "{name} | {valueY}";
    series.name = name;
    series.strokeWidth = 2;
    series.data = data;
    

    const channelName = name.replace('Custom ', '');
    const color = GroupColorConstants[channelName.toUpperCase()] ? GroupColorConstants[channelName.toUpperCase()] : this.getRandomColors(channelName.toUpperCase());
    series.stroke = am4core.color(color);
    series.tooltip.getFillFromObject = true;
    series.tooltip.background.fill = am4core.color(color);

    if (custom) {
      const bullet = new am4charts.CircleBullet();
      bullet.tooltipText = "{name} | {valueY} | {categoryX}";
      bullet.fill = am4core.color(color);

      series.bullets.push(bullet);
    }

    this.lineChart.series.push(series);
    console.timeEnd('ChartDraw');
  }

  setChartToRoi() {
    let allChannels = [];
    let maxSpendLimit = 0;
    if (this.channelData) {
      this.channelData.map((channel:any) => {
        const  plot  ={...channel.plot} || {};
        const spend = Math.round(plot.weeklySpend/1000)*1000/channel.channelEntity.cpp;
        this.createLineSeries(
          `Custom ${plot.channelName}`,
          [{
            spend,
            kpi: plot.ROI
          }],
          true
        );
        allChannels.push(plot.channelName);
        if (spend > maxSpendLimit) maxSpendLimit += spend;
      });
    }
    console.time('ROIChart');
    this.channelData.forEach(channel => {
      let dataPerChannel: any = [];
      channel.repsoneChart.forEach((data, idx) => {
        console.log("Point count:",channel.repsoneChart.length);
        const spend = data.spend;
        const cpp = data.cpp;
        dataPerChannel.push({
          spend: spend/cpp,
          kpi: (Number(data.RoI)).toFixed(4)
        })
      });
      this.createLineSeries(channel.channelEntity.channelName, dataPerChannel);
    });
    console.timeEnd('ROIChart');
    this.updateChart(maxSpendLimit * 1.25);
  }

  setChartToReach() {
    let allChannels = [];
    let maxSpendLimit = 0;
    if (this.channelData) {
      this.channelData.map((channel:any) => {
        const  plot  ={...channel.plot} || {};
        const spend = Math.round(plot.weeklySpend);
        this.createLineSeries(
          `Custom ${plot.channelName}`,
          [{
            spend,
            kpi: plot.ROI
          }],
          true
        );
        allChannels.push(plot.channelName);
        if (spend > maxSpendLimit) maxSpendLimit += spend;
      });
    } 

    this.channelData.forEach(channel => {
      let dataPerChannel: any = [];

      channel.reachChart.forEach((data, idx) => {
          dataPerChannel.push({
            spend: Math.round(data.spend),
            kpi: data.reach
          })
      });
      this.createLineSeries(channel.channelEntity.channelName, dataPerChannel);
    });
    this.updateChart(maxSpendLimit * 1.25);
  }

  setChartToEff() {
    let allChannels = [];
    let maxSpendLimit = 0;

    if (this.channelData) {
      this.channelData.map((channel:any) => {
        const  plot  ={...channel.plot} || {};
        const spend = Math.round(plot.weeklySpend/channel.channelEntity.cpp);
        const thousandthSpend = Math.ceil((plot.weeklySpend) / 1000) * 1000;
        const thousandthSpendObj=channel.repsoneChart.find(el=> el.spend == thousandthSpend);
        const activeScenarioEff=this.scenario?this.scenario.plannedEffectiveness:0;
        //from above const values spend is equals to thousandthSpend then adding active scenario estimated effectiveness
        let kpi=Number(thousandthSpendObj && thousandthSpendObj.effectiveness ? thousandthSpendObj.effectiveness : 0);
        if(Number(plot.weeklySpend)!=thousandthSpend){
          //old code reason for points not on the curve
          // kpi=Number(thousandthSpendObj && thousandthSpendObj.effectiveness ? thousandthSpendObj.effectiveness : 0)+Number(activeScenarioEff);
          //new code
          kpi=Number(thousandthSpendObj && thousandthSpendObj.effectiveness ? thousandthSpendObj.effectiveness : 0);
        }        
        this.createLineSeries(
          `Custom ${channel.channelEntity.channelName}`,
          [{
            spend,
            kpi: kpi
          }],
          true
        );
        allChannels.push(channel.channelEntity.channelName);
        console.log(spend);
        if (spend > maxSpendLimit) maxSpendLimit += spend;
      });
    }
console.time('EffChart');
    this.channelData.forEach(channel => {
      let dataPerChannel: any = [];
      console.log("Point count:",channel.repsoneChart.length);
      

      channel.repsoneChart.forEach((data) => {
        dataPerChannel.push({ spend: (data.spend/data.cpp), kpi: data.effectiveness })
      });

      this.createLineSeries(channel.channelEntity.channelName, dataPerChannel);
    });
    console.timeEnd('EffChart');
    this.updateChart(maxSpendLimit * 1.25);
  }

}
