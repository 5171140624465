import { Component, Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'LockFilter',
})
export class SearchPipe implements PipeTransform {
  transform(value: FormGroup[], args?: string): FormGroup[] {
    if (!value) {
      return value;
    }
    if (!args) {
      return value;
    }


    args = args.toLowerCase();

    const filtered = value.filter(item => item["displayName"].toLowerCase().indexOf(args) !== -1);
    return filtered;
  }
}