import { Component, OnInit, Inject } from '@angular/core';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PlanService } from 'app/plan/services/plan.service';

@Component({
  selector: 'app-mjtbd-dialog',
  templateUrl: './mjtbd-dialog.component.html',
  styleUrls: ['./mjtbd-dialog.component.scss']
})
export class MjtbdDialogComponent implements OnInit {
  message;
  exitMjtbForm : boolean = false;
  isErr: boolean = false;
  isDelMode:boolean = false;
  errMsg: string = ''
  isDelConfirmed: boolean = false;
  apiResponse;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private planService: PlanService,
    private globalElementsService: GlobalElementsService,
    private dialogRef: MatDialogRef<MjtbdDialogComponent>
  ) { }

  ngOnInit() {

    const { mode, deafultMsg } = this.data;
    this.message = deafultMsg;
    switch (mode) {
      case 'save':
        this.saveMJTBD();
        break;

      case 'edit':
        this.updateMJTBDForm();
        break;

      case 'delete':
        this.validateMJTBDDelete();        
        break;
    }

  }

  saveMJTBD(){
    const {mjtbdData}=this.data;
    this.planService.saveMJTBD(mjtbdData).subscribe(res => {
      this.message = "Successfully saved MJTBD information";
      this.exitMjtbForm = true;
    }, err =>  {
      this.message = "Error in saving MJTBD information."
    })
  }
  updateMJTBDForm(){
    const {mjtbdData}=this.data;
    this.planService.updateMJTBDForm(mjtbdData).subscribe(() => {
      this.message = "Successfully saved MJTBD information";
      this.exitMjtbForm= true;
    }, err =>{
      this.message = "Error in saving MJTBD information."
    })
  }

  deleteMjtbdForm() {
    this.message = "Deleting MJTBD. Please wait..";
    this.planService.deleteMJTBD(this.data).subscribe(() => {
      this.message = "Successfully deleted MJTBD.";
      this.isDelConfirmed = true;
      this.exitMjtbForm = true;
    }, err => {
      if (err.status == 476) {
        this.isErr = true;
        this.errMsg = err.error.message;
      }
      this.isDelConfirmed = true;
      this.message = "Error in deleting MJTBD information."
    })
  }

  validateMJTBDDelete() {
    this.message = "Validating MJTBD data. Please wait..";
    this.planService.validateMJTBDDelete(this.data).subscribe((res: any) => {
      if (res && res.isValid) {
        this.deleteMjtbdForm();
      } else {
        this.isDelConfirmed = true;
        this.message = "A MJTBD with a campaign(s) associated to it cannot be deleted.   You must first delete the campaign(s)"
      }
    }, err => {
      if (err.status == 476) {
        this.isErr = true;
        this.errMsg = err.error.message;
      }
      this.isDelConfirmed = true;
      this.message = "Error in deleting MJTBD information."
    })
  }

  // Quit the edit form on clicking OK
  handleOk() {
    this.exitMjtbForm ? this.globalElementsService.setSidePanelCancelEvent() : null;
  }
    
}
