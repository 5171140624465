import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { MatSortModule } from "@angular/material/sort";
import { BrowserModule } from "@angular/platform-browser";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatCarouselModule } from "@ngmodule/material-carousel";
import { TransferHttpCacheModule } from "@nguniversal/common";
import { environment } from "environments/environment";
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { ImageCropperModule } from "ngx-image-cropper";
import {
  FormsModule,
  ReactiveFormsModule,
} from "../../node_modules/@angular/forms";
import { CookieService } from "../../node_modules/ngx-cookie-service";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { CKEditorModule } from "ckeditor4-angular";

import {
  TreeviewModule,
  DropdownTreeviewComponent,
  TreeviewConfig,
  TreeviewI18nDefault,
  TreeviewI18n,
  DefaultTreeviewEventParser,
  TreeviewEventParser,
} from "ngx-treeview";

// app components

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AnnualMediaCalendarComponent } from "./common/components/annual-media-calendar/annual-media-calendar.component";
import { BreadcrumbsComponent } from "./common/components/breadcrumbs/breadcrumbs.component";
import { ChartComponent } from "./common/components/chart/chart.component";
import { UpfrontScenarioChartComponent } from "./common/components/chart/upfront-scenario-chart/upfront-scenario-chart.component";
import { AvatarUploadDialogComponent } from "./common/components/dialogs/avatar-upload-dialog/avatar-upload-dialog.component";
import { BrandManagementDialogsComponent } from "./common/components/dialogs/brand-management-dialogs/brand-management-dialogs.component";
import { CancelDialogComponent } from "./common/components/dialogs/cancel-dialog/cancel-dialog.component";
import { CreateUserDialogComponent } from "./common/components/dialogs/create-user-dialog/create-user-dialog.component";
import { DocumentsUploadDialogComponent } from "./common/components/dialogs/documents-upload-dialog/documents-upload-dialog.component";
import { EditUserDialogComponent } from "./common/components/dialogs/edit-user-dialog/edit-user-dialog.component";
import { FileUploadDialogComponent } from "./common/components/dialogs/file-upload-dialog/file-upload-dialog.component";
import { MjtbdDialogComponent } from "./common/components/dialogs/mjtbd-dialog/mjtbd-dialog.component";
import { NotificationDialogComponent } from "./common/components/dialogs/notification-dialog/notification-dialog.component";
import { ProgramDialogComponent } from "./common/components/dialogs/program-dialog/program-dialog.component";
import { ScenarioPlanningDialogComponent } from "./common/components/dialogs/scenario-planning-dialog/scenario-planning-dialog.component";
import { TcpArchitectureDialogComponent } from "./common/components/dialogs/tcp-architecture-dialog/tcp-architecture-dialog.component";
import { UpdateTargetDialogComponent } from "./common/components/dialogs/update-target-dialog/update-target-dialog.component";
import { UserDeleteDialogComponent } from "./common/components/dialogs/user-delete-dialog/user-delete-dialog.component";
import { UserProfileDialogComponent } from "./common/components/dialogs/user-profile-dialog/user-profile-dialog.component";
import { userAccessSetupDialogComponent } from "./common/components/dialogs/user-access-setup-dialog/user-access-setup-dialog.component";
import { userAccessSetupDialogMultipleComponent } from "./common/components/dialogs/user-access-setup-dialog-multiple/user-access-setup-dialog-multiple.component";
import { ErrorComponent } from "./common/components/error/error.component";
import { AccessDeniedComponent } from "./common/components/accessDenied/accessDenied.component";

import { OverviewTableComponent } from "./common/components/overview-table/overview-table.component";
import { OverviewComponent } from "./common/components/overview/overview.component";
import { BrandManagementComponent } from "./global-elements/brand-management/brand-management.component";
import { BrandComponent } from "./global-elements/brand-management/brand/brand.component";
import { EnhanceBrandComponent } from "./global-elements/brand-management/brand/enhance-brand/enhance-brand.component";
import { HomepageBrandComponent } from "./global-elements/brand-management/brand/homepage-brand/homepage-brand.component";
import { EnhanceMarketGroupComponent } from "./global-elements/brand-management/market-group/enhance-market-group/enhance-market-group.component";
import { HomepageMarketGroupComponent } from "./global-elements/brand-management/market-group/homepage-market-group/homepage-market-group.component";
import { MarketBrandGroupComponent } from "./global-elements/brand-management/market-group/market-group.component";
import { EnhanceMarketComponent } from "./global-elements/brand-management/market/enhance-market/enhance-market.component";
import { HomepageMarketComponent } from "./global-elements/brand-management/market/homepage-market/homepage-market.component";
import { MarketBrandComponent } from "./global-elements/brand-management/market/market.component";
import { EnhancePortfolioGroupComponent } from "./global-elements/brand-management/portfolio-group/enhance-portfolio-group/enhance-portfolio-group.component";
import { HomepagePortfolioGroupComponent } from "./global-elements/brand-management/portfolio-group/homepage-portfolio-group/homepage-portfolio-group.component";
import { PortfolioBrandGroupComponent } from "./global-elements/brand-management/portfolio-group/portfolio-group.component";
import { EnhancePortfolioComponent } from "./global-elements/brand-management/portfolio/enhance-portfolio/enhance-portfolio.component";
import { HomepagePortfolioComponent } from "./global-elements/brand-management/portfolio/homepage-portfolio/homepage-portfolio.component";
import { PortfolioBrandComponent } from "./global-elements/brand-management/portfolio/portfolio.component";
import { DocumentCenterComponent } from "./global-elements/document-center/document-center.component";
// core components

import { GlobalElementsComponent } from "./global-elements/global-elements.component";
import { HeaderComponent } from "./global-elements/header/header.component";
import { HelpComponent } from "./global-elements/help/help.component";
import { NotificationsComponent } from "./global-elements/notifications/notifications.component";
import { OptimzerQueueComponent } from "./global-elements/optimzer-queue/optimzer-queue.component";
import { BannerComponent } from "./global-elements/side-nav/banner/banner.component";
import { FooterComponent } from "./global-elements/side-nav/footer/footer.component";
import { SideNavComponent } from "./global-elements/side-nav/side-nav.component";
import { CreateUserComponent } from "./global-elements/user/user-management/create-user/create-user.component";
import { CreateBulkUserComponent } from "./global-elements/user/user-management/create-bulk-user/create-bulk-user.component";
import { UserManagementComponent } from "./global-elements/user/user-management/user-management.component";
import { UsersDatatableComponent } from "./global-elements/user/user-management/users-datatable/users-datatable.component";
import { UserProfileComponent } from "./global-elements/user/user-profile/user-profile.component";
import { AppRoutingComponent } from "./landing/app-routing/app-routing.component";
import { MarketComponent } from "./market/market.component";
import { MaterialLibraryModule } from "./material-library.module";
import { StoreService } from "./old/_services/_store.service";
import { CreateMjtbdComponent } from "./plan/create-mjtbd/create-mjtbd.component";
import { PerformanceTargetComponent } from "./plan/performance-target/performance-target.component";
import { PlanComponent } from "./plan/plan.component";
import { CreateScenarioComponent } from "./plan/scenario-planning/create-scenario/create-scenario.component";
import { ScenarioPlanningComponent } from "./plan/scenario-planning/scenario-planning.component";
import { TcpArchitectureComponent } from "./plan/tcp-architecture/tcp-architecture.component";
import { TcpDocumentCenterComponent } from "./plan/tcp-document-center/tcp-document-center.component";
import { UpfrontPlanningComponent } from "./plan/upfront-planning/upfront-planning.component";
import { PortfolioGroupComponent } from "./portfolio-group/portfolio-group.component";
import { PortfolioComponent } from "./portfolio/portfolio.component";
import { CampaignIdeaComponent } from "./program/campaign-idea/campaign-idea.component";
import { CampaignPerformanceDashboardComponent } from "./program/campaign-performance-dashboard/campaign-performance-dashboard.component";
import { DatoramaDashboardComponent } from "./program/datorama-dashboard/datorama-dashboard.component";
import { OpportunityBriefComponent } from "./program/opportunity-brief/opportunity-brief.component";
import { ProgramComponent } from "./program/program.component";
import { CreateScenarioCTComponent } from "./program/scenario-planning-ct/create-scenario/create-scenario.component";
import { ScenarioPlanningCtComponent } from "./program/scenario-planning-ct/scenario-planning-ct.component";
import { TcpProgressPdfComponent } from "./plan/tcp-progress-pdf/tcp-progress-pdf.component";
import { AddYearDialogComponent } from "./common/components/dialogs/add-year-dialog/add-year-dialog.component";
import { ApiErrorDialogComponent } from "./common/components/dialogs/apiError-dialog/apiError-dialog.component";
import { PortfolioAllocationComponent } from "./portfolio/portfolio-allocation/portfolio-allocation.component";
import { CanvasCurrencyPipe } from "./common/pipe/currency.pipe";
import { SearchPipe } from "./common/pipe/search.pipe";
import { TeamMembersDialogComponent } from "./common/components/dialogs/team-members-dialog/team-members-dialog.component";
import { FileNotFoundComponent } from "./common/components/dialogs/file-not-found-dialog/file-not-found/file-not-found.component";
import { MultiselectAutocompleteComponent } from "app/common/components/multiselect-autocomplete/multiselect-autocomplete.component";
import { MultiselectAutocompleteUserSetupComponent } from "app/common/components/multiselect-autocomplete-user-setup/multiselect-autocomplete-user-setup.component";

import { ConfirmationDialogComponent } from "./common/components/dialogs/confirmation-dialog/confirmation-dialog.component";
import { CampaignIdeaUploadDialogComponent } from "./common/components/dialogs/campaign-idea-upload-dialog/campaign-idea-upload-dialog.component";
import { MAT_DIALOG_DATA } from "@angular/material";
import { OptimizerMessageDialogComponent } from "./common/components/dialogs/optimizer-message-dialog/optimizer-message-dialog.component";
import { CampaignSummaryComponent } from "./program/campaign-summary/campaign-summary/campaign-summary.component";
import { MarketMultiselectComponent } from "./common/components/market-multiselect/market-multiselect.component";
import { GetMarketAndYearDialogComponent } from "./common/components/dialogs/get-market-and-year-dialog/get-market-and-year-dialog.component";
import { updatePlanWidgetTargetDialog } from "./common/components/dialogs/update-plan-widget-target-dialog/update-plan-widget-target-dialog.component";
import { updateWidgetValueTypeDialog } from "./common/components/dialogs/update-widget-value-type-dialog/update-widget-value-type-dialog.component";
import { widgetPlanTarget } from "./global-elements/widget-plan-target/widget-plan-target.component";
import { widgetConfiguration } from "./global-elements/widget-configuration/widget-configuration.component";
import { managePortfolioRtmDashboard } from "./global-elements/manage-portfolio-rtm-dashboard/manage-portfolio-rtm-dashboard.component";
import { ImportMjtbdDialogComponent } from "./common/components/dialogs/import-mjtbd-dialog/import-mjtbd-dialog.component";
import { manageCustomAudienceDialog } from "./common/components/dialogs/manage-custom-audience-dialog/manage-custom-audience-dialog.component";
import { portfolioTCPTracker } from "./portfolio/portfolio-tcp-tracker/portfolio-tcp-tracker.component";
import { portfolioPerformanceDashboardComponent } from "./portfolio/portfolio-rto-dashboard/portfolio-rto-dashboard.component";

import { NgxPaginationModule } from "ngx-pagination";
import { TeammembersMultiselectComponent } from "./common/components/teammembers-multiselect/teammembers-multiselect.component";
import { SendNotificationsDialogComponent } from "./common/components/dialogs/send-notifications-dialog/send-notifications-dialog.component";
import { ManageCommstasksTouchpointsChannelsComponent } from "./global-elements/manage-commstasks-touchpoints-channels/manage-commstasks-touchpoints-channels.component";
import { ChannelslistTemplateUploadComponent } from "./common/components/dialogs/channelslist-template-upload/channelslist-template-upload.component";
import { BulkUserUploadDailog } from "./common/components/dialogs/bulk-user-upload-dailog/bulk-user-upload-dailog.component";
import { DeleteChannelDialogComponent } from "./common/components/dialogs/delete-channel-dialog/delete-channel-dialog.component";
import { EditChannelDialogComponent } from "./common/components/dialogs/edit-channel-dialog/edit-channel-dialog.component";
import { KPIFrameworkPPTComponent } from "./plan/kpi-framework-ppt/kpi-framework-ppt.component";
import { DeleteRtoDashboardDialogComponent } from "./common/components/dialogs/delete-rto-dashboard-dialog/delete-rto-dashboard-dialog.component";
import { SaveRtoDashboardDialogComponent } from "./common/components/dialogs/save-rto-dashboard-dialog/save-rto-dashboard-dialog.component";
import { AutocompleteMatselectComponent } from "./common/components/autocomplete-matselect/autocomplete-matselect.component";
import { DownloadFileDialogComponent } from "./common/components/dialogs/download-file-dialog/download-file-dialog.component";
import { TooltipConfigurationComponent } from "./global-elements/tooltip-configuration/tooltip-configuration.component";
import { EnhanceTooltipCategoryComponent } from "./global-elements/tooltip-configuration/tooltip-category-list/add-tooltip-category/add-tooltip-category.component";
import { ListTooltipCategory } from "./global-elements/tooltip-configuration/tooltip-category-list/list-tooltip-category/list-tooltip-category.component";
import { TooltipCategory } from "./global-elements/tooltip-configuration/tooltip-category-list/tooltip-category.component";
import { TooltipList } from "./global-elements/tooltip-configuration/tooltip-list/tooltip-list.component";
import { EnhanceTooltipListComponent } from "./global-elements/tooltip-configuration/tooltip-list/add-tooltip/add-tooltip.component";
import { TooltipListHomePage } from "./global-elements/tooltip-configuration/tooltip-list/list-tooltip/list-tooltip.component";
import { CommonDialogToShowNotificationComponent } from "./common/components/dialogs/common-dailog-to-show-notification/common-dailog-to-show-notification";
import { CustomConfirmationDialogComponent } from "./common/components/dialogs/custom-confirmation-dialog/custom-confirmation-dialog.component";
import { PlanRtoDashboardComponent } from "./plan/plan-rto-dashboard/plan-rto-dashboard.component";
import { BrandMappingComponent } from "./global-elements/brand-mapping/brand-mapping.component";
import { AddBrandMappingDialogComponent } from "./common/components/dialogs/add-brand-mapping-dialog/add-brand-mapping-dialog.component";
import { CoreModule } from "./core/core.module";
import { SharedApiModule } from "./common/api/shared-api.module";
import { LoaderComponent } from "./common/components/loader/loader.component";
import { ShowAuditHistoryDialogComponent } from "./common/components/dialogs/show-audit-history-dialog/show-audit-history-dialog.component";
import { ShowUpdateMessageDialogComponent } from "./common/components/dialogs/show-update-message-dialog/show-update-message-dialog.component";
import { WarningMessageBrandMappingDialogComponent } from "./common/components/dialogs/warning-message-brand-mapping-dialog/warning-message-brand-mapping-dialog.component";
import { CampaignStudyDetailsDialogComponent } from "./common/components/dialogs/campaign-study-details-dialog/campaign-study-details-dialog.component";
import { UploadCampaignStudiesDialogComponent } from "./common/components/dialogs/uploadcampaignstudies-dialog/uploadcampaignstudies-dialog.component";
import { NgxPowerBiModule } from "ngx-powerbi";
import { powerbiReportsDashboardComponent } from "./portfolio/powerbi-reports-dashboard/powerbi-reports-dashboard.component";
import { VisibilityService } from "./global-elements/services/visibility.service";
export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  allowZero: true,
  decimal: ".",
  precision: 2,
  prefix: "$ ",
  suffix: "",
  thousands: ",",
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.NATURAL,
};

@NgModule({
  declarations: [
    AppComponent,
    GlobalElementsComponent,
    MarketComponent,
    PortfolioComponent,
    PlanComponent,
    ProgramComponent,
    HeaderComponent,
    SideNavComponent,
    HelpComponent,
    BannerComponent,
    FooterComponent,
    UserManagementComponent,
    UserDeleteDialogComponent,
    CreateUserComponent,
    CreateBulkUserComponent,
    UsersDatatableComponent,
    CreateUserDialogComponent,
    EditUserDialogComponent,
    UserProfileComponent,
    UserProfileDialogComponent,
    userAccessSetupDialogComponent,
    userAccessSetupDialogMultipleComponent,
    AvatarUploadDialogComponent,
    UpfrontPlanningComponent,
    DocumentCenterComponent,
    ScenarioPlanningComponent,
    CreateScenarioComponent,
    DocumentsUploadDialogComponent,
    UploadCampaignStudiesDialogComponent,
    ScenarioPlanningDialogComponent,
    NotificationsComponent,
    BrandManagementComponent,
    ScenarioPlanningCtComponent,
    CreateScenarioCTComponent,
    AppRoutingComponent,
    ChartComponent,
    PortfolioGroupComponent,
    OverviewComponent,
    OverviewTableComponent,
    MarketBrandComponent,
    MarketBrandGroupComponent,
    PortfolioBrandComponent,
    PortfolioBrandGroupComponent,
    BrandComponent,
    EnhanceMarketComponent,
    HomepageMarketGroupComponent,
    EnhanceMarketGroupComponent,
    HomepagePortfolioComponent,
    EnhancePortfolioComponent,
    EnhancePortfolioGroupComponent,
    HomepagePortfolioGroupComponent,
    HomepageBrandComponent,
    EnhanceBrandComponent,
    HomepageMarketComponent,
    UpfrontScenarioChartComponent,
    CreateMjtbdComponent,
    BrandManagementDialogsComponent,
    PerformanceTargetComponent,
    FileUploadDialogComponent,
    UpdateTargetDialogComponent,
    TcpArchitectureComponent,
    TcpArchitectureDialogComponent,
    MjtbdDialogComponent,
    ErrorComponent,
    AccessDeniedComponent,
    OpportunityBriefComponent,
    CampaignIdeaComponent,
    OptimzerQueueComponent,
    ProgramDialogComponent,
    DatoramaDashboardComponent,
    CampaignPerformanceDashboardComponent,
    AnnualMediaCalendarComponent,
    LoaderComponent,
    NotificationDialogComponent,
    CancelDialogComponent,
    TcpDocumentCenterComponent,
    BreadcrumbsComponent,
    TcpProgressPdfComponent,
    AddYearDialogComponent,
    ApiErrorDialogComponent,
    PortfolioAllocationComponent,
    CanvasCurrencyPipe,
    SearchPipe,
    TeamMembersDialogComponent,
    FileNotFoundComponent,
    MultiselectAutocompleteComponent,
    MultiselectAutocompleteUserSetupComponent,
    CampaignIdeaUploadDialogComponent,
    ConfirmationDialogComponent,
    OptimizerMessageDialogComponent,
    CampaignSummaryComponent,
    GetMarketAndYearDialogComponent,
    widgetPlanTarget,
    widgetConfiguration,
    managePortfolioRtmDashboard,
    updatePlanWidgetTargetDialog,
    updateWidgetValueTypeDialog,
    MarketMultiselectComponent,
    ImportMjtbdDialogComponent,
    manageCustomAudienceDialog,
    portfolioTCPTracker,
    portfolioPerformanceDashboardComponent,
    powerbiReportsDashboardComponent,
    TeammembersMultiselectComponent,
    SendNotificationsDialogComponent,
    ManageCommstasksTouchpointsChannelsComponent,
    ChannelslistTemplateUploadComponent,
    BulkUserUploadDailog,
    DeleteChannelDialogComponent,
    EditChannelDialogComponent,
    KPIFrameworkPPTComponent,
    DeleteRtoDashboardDialogComponent,
    SaveRtoDashboardDialogComponent,
    AutocompleteMatselectComponent,
    DownloadFileDialogComponent,
    TooltipConfigurationComponent,
    EnhanceTooltipCategoryComponent,
    ListTooltipCategory,
    TooltipCategory,
    CommonDialogToShowNotificationComponent,
    TooltipList,
    TooltipListHomePage,
    EnhanceTooltipListComponent,
    CustomConfirmationDialogComponent,
    PlanRtoDashboardComponent,
    BrandMappingComponent,
    AddBrandMappingDialogComponent,
    ShowAuditHistoryDialogComponent,
    ShowUpdateMessageDialogComponent,
    WarningMessageBrandMappingDialogComponent,
    CampaignStudyDetailsDialogComponent,

    /* HomeComponent,
    DatoAuthComponent,
    PageNotFoundComponent, */
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TransferHttpCacheModule,
    CoreModule,
    MaterialLibraryModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPaginationModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    MatCarouselModule.forRoot(),
    NgxDocViewerModule,
    TreeviewModule.forRoot(),
    // new
    NgxPowerBiModule,
    MatSortModule,
    MatTooltipModule,
    ImageCropperModule,
    CKEditorModule,
    SharedApiModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    UserDeleteDialogComponent,
    CreateUserDialogComponent,
    AddYearDialogComponent,
    ApiErrorDialogComponent,
    EditUserDialogComponent,
    UserProfileDialogComponent,
    userAccessSetupDialogComponent,
    userAccessSetupDialogMultipleComponent,
    DocumentsUploadDialogComponent,
    UploadCampaignStudiesDialogComponent,
    ScenarioPlanningDialogComponent,
    BrandManagementDialogsComponent,
    AvatarUploadDialogComponent,
    FileUploadDialogComponent,
    UpdateTargetDialogComponent,
    updatePlanWidgetTargetDialog,
    updateWidgetValueTypeDialog,
    manageCustomAudienceDialog,
    TcpArchitectureDialogComponent,
    MjtbdDialogComponent,
    ProgramDialogComponent,
    TeamMembersDialogComponent,
    NotificationDialogComponent,
    FileNotFoundComponent,
    CampaignIdeaUploadDialogComponent,
    ConfirmationDialogComponent,
    OptimizerMessageDialogComponent,
    GetMarketAndYearDialogComponent,
    ImportMjtbdDialogComponent,
    CancelDialogComponent,
    SendNotificationsDialogComponent,
    ChannelslistTemplateUploadComponent,
    BulkUserUploadDailog,
    DeleteChannelDialogComponent,
    EditChannelDialogComponent,
    DeleteRtoDashboardDialogComponent,
    SaveRtoDashboardDialogComponent,
    CommonDialogToShowNotificationComponent,
    DownloadFileDialogComponent,
    portfolioTCPTracker,
    CustomConfirmationDialogComponent,
    BrandMappingComponent,
    ShowAuditHistoryDialogComponent,
    AddBrandMappingDialogComponent,
    ShowUpdateMessageDialogComponent,
    WarningMessageBrandMappingDialogComponent,
    CampaignStudyDetailsDialogComponent,
 ],
  providers: [
    CookieService,
    StoreService,
    VisibilityService,
    { provide: "googleTagManagerId", useValue: environment.googletagManagerID },
    { provide: MAT_DIALOG_DATA, useValue: {} },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
