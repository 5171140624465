export const environment = {
  production: false,
  api: "https://msp-dev2.pepsico.com/api",
  host: "https://msp-dev2.pepsico.com",
  logo: "https://msp-dev2.pepsico.com",
  apiKey: "b1q30mj83yxe9l15s5onjh03em2qiu7e",
  workfront: "https://pepsicomarketing.sb01.workfront.com/",
  // apiKey: 'e7o3t23e2p9x24p8kfxxiqrwfapaoavf',
  // workfront: 'https://pepsicomarketing.my.workfront.com/',
  workfrontTcpTemplateID: "5fe0d2f20250103284f4230a273dc620",
  googletagManagerID: "GTM-K73JGRD",
  environmentName: "DEV2",
  pathLoc: "Shared Documents/TEST/",
  SHAREPOINT_PATH_URL: "https://pepsico.sharepoint.com/sites/NACanvas",
  PSDCLIENTID: "0oa1l4r67xv0ikckJ0h8",
  httpHeaders: {},
  config: {
    offlineMode: false,
    noCache: false,
    cacheMaxAge: "20000",
    cacheFlushInterval: "20000",
    showApiLoader: true,
    showApiErrorDialog: false,
    showApiTimeLog: false,
  },
};
