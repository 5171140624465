export const REFERNCE_CODE = {
    PLAN: 'PLAN',
    ACTUAL: 'ACTUAL',
    TARGET: 'TARGET'
}

export const TABLE_KEYS = {
    WORKING_MEDIA: 'annualWorkingMediaBudget',
    YTD_ROI: 'ytdROI'
}

export const TABLE_HEADERS = {
    MARKET: {
        MAIN: ['name', 'workingMedia', 'spend', 'KPITracker', 'plannedTracker'],
        SUB: ["businessUnit", "totalPlanned", "plannedToDate", "actualToDate", "spenDiff", "plannedROI", "actualROI", "roiDiff", "portfolioAllocation"]
    },
    PORTFOLIO: {
        MAIN: ['name', 'workingMedia', 'spend', 'KPITracker', 'plannedTracker'],
        SUB: ["businessUnit", "totalPlanned", "plannedToDate", "actualToDate", "spenDiff", "plannedROI", "actualROI", "roiDiff", "upfrontPlan"]
    },
    PORTFOLIO_GROUP: {
        MAIN: ['name', 'workingMedia', 'spend', 'KPITracker', 'plannedTracker'],
        SUB: ["businessUnit", "totalPlanned", "plannedToDate", "actualToDate", "spenDiff", "plannedROI", "actualROI", "roiDiff", "upfrontPlan"]
    },
    PLAN: {
        MAIN: ["name", "workingMedia", "mjtbdSpend", "details", "tracker"],
        SUB: ["MJTBD", "totalPlanned", "plannedToDate", "mjtbdActualToDate",  "brandGrowth", "audiencesSegments", "plan"]
    }
}

export const SECTION_HEADER_INFO = {
    MARKET: {
        text: 'Year to Date vs. Plan Overview',
        path: '',
        btnText: '',
        isBtnShow: false
    },
    PORTFOLIO: {
        text: 'Year to Date vs. Plan Overview',
        path: '',
        btnText: 'PORTFOLIO ALLOCATION',
        isBtnShow: true
    },
    PORTFOLIO_GROUP: {
        text: 'Year to Date vs. Plan Overview',
        path: '',
        btnText: '',
        isBtnShow: false
    },
    PLAN: {
        text: 'Year to Date vs. Plan Overview',
        path: '',
        btnText: 'UPFRONT SCENARIOS',
        isBtnShow: false
    },
    PROGRAM: {
        text: 'Year to Date vs. Plan Overview',
        path: '',
        btnText: '',
        isBtnShow: false
    }

}



export const CHANNEL_OBJ = [
    {
      channel: 'TV',
      accesor: 'channelMixTv'
    },
    {
      channel: 'Digital',
      accesor: 'channelMixDigital'
    },
    {
      channel: 'Audio',
      accesor: 'channelMixAudio'
    },
    {
      channel: 'Print',
      accesor: 'channelMixPrint'
    },
    {
      channel: 'OOH',
      accesor: 'channelMixOoh'
    },
    {
        channel: 'Other',
        accesor: 'channelMixOther'
    }
  ];

export const CHANNEL_TABLE_OBJ = {
    channel: '',
    planned: '',
    actual: '',
    diff: 0
}


export const CHART_OBJ = {
    channel: '',
    value: 0
}

export const CHANNEL_COLORS = ["#FC4C02", "#0085CA", "#009639", "#7030A0", "#0C2D83", "#939393","#F1948A","#191970","#DAF7A6","#36454f","#6c8970","#5278a2","#9552a2","#5b1e3d","#ebe033","#859dbc","#b89dc9","#428970","#1f1ea0","#09493b","#ab063c","#5bcf7e","#2a3634","#e98042","#85380a"];

