import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { GlobalElementsService } from './../../../../global-elements/services/global-elements.service';
@Component({
  selector: 'app-edit-channel-dialog',
  templateUrl: './edit-channel-dialog.component.html',
  styleUrls: ['./edit-channel-dialog.component.scss']
})
export class EditChannelDialogComponent implements OnInit {
  marketInfo: any [];
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  selectedMarket: string;
  selectedChannelName:string;
  apiData:any;
  message:string;
  isUpdateConfirmed:boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalElementsService: GlobalElementsService,
  ) { }

  ngOnInit() {
    this.apiData = this.data ? this.data : [];
    // this.selectedMarket = this.apiData.marketId ? this.apiData.marketId.toString():'';
    this.selectedChannelName = this.apiData.channelName ? this.apiData.channelName : '';
    // this.getMarkets();
  }
  // getMarkets() {
  //   this.globalElementsService.getAllMarket(this.userInfo.gpid).subscribe(res => {
  //     if(res['markets'].length > 0){
  //       this.marketInfo = res['markets'];
  //     } else {
  //       this.marketInfo = [];
  //     }
  //   })

  // }
  updateChannelInfo(){
    const postObj = {
      marketId:this.apiData.marketId,
      channelName:this.selectedChannelName,
      updatedby:this.userInfo.id,
      Id:this.apiData.channelId
    }
    this.message = 'Updating info please wait...';
    this.globalElementsService.updateChannelInfo(postObj).subscribe(res => {
      if(res['status'] == 200){
        this.message = "Updated Successfully!";
        this.isUpdateConfirmed = true;
      } else if(res['status'] == 300){
        this.message = res['message'];
        this.isUpdateConfirmed = true;
      } else {
        this.message = "Error Updating Channel.";
      }
    })
  }

}
