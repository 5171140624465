import { Component, OnInit, Inject } from '@angular/core';
import { PlanService } from '../../../../plan/services/plan.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
GlobalElementsService

@Component({
  selector: 'app-add-brand-mapping-dialog',
  templateUrl: './add-brand-mapping-dialog.component.html',
  styleUrls: ['./add-brand-mapping-dialog.component.scss']
})
export class AddBrandMappingDialogComponent implements OnInit {

  addBrandMappingForm:FormGroup;
  markets = [];
  ROIbrands = [];
  defaultMarketId:number;
  ALLROIbrands: any;
  mdmBrandType=["Masterbrand","Sub Brand"]
  message: string;



  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private planService: PlanService,
  private globalElementsService: GlobalElementsService,
  private fb: FormBuilder,) { }

  ngOnInit() {
    this.message= this.data.message;
  }


  saveBrandRTOURLs(){
    const data = this.contructBrandRTOURLS();
  }
  contructBrandRTOURLS(){
    const {selectedMarket,selectedBrand,brandRTOURL} = this.addBrandMappingForm.value;
    console.log(this.addBrandMappingForm.value);
    const dataObj = {
      selectedMarket,
      selectedBrand,
      brandRTOURL
    }
    return dataObj;
  }
  
}
