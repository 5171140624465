import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { environment } from 'environments/environment';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { fileMimeTypes } from 'app/constants/app-constants';

@Component({
  selector: 'app-download-file-dialog',
  templateUrl: './download-file-dialog.component.html',
  styleUrls: ['./download-file-dialog.component.scss']
})
export class DownloadFileDialogComponent implements OnInit {
  message:string  = "Download in Progress";
  isDownloadConfirmed:boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DownloadFileDialogComponent>,
    private globalElementsService: GlobalElementsService
  ) { }

  ngOnInit() {
    const fileName = this.data.fileName;
    const extension = this.data.extension;
    const path = this.data.path;
    const docPath = this.data.docPath;
    console.log(this.data);
    this.globalElementsService.downloadFile({fileName: fileName, path: path.split('/Shared Documents/')[1]}).subscribe(response => {
    if(response['sqAuthheader']){
      axios({
        method: 'GET',
        url: `https://pepsico.sharepoint.com/sites/NACanvas/_api/web/getfolderbyserverrelativeurl('Shared Documents/${docPath}')/Files('${fileName}')/$value/`,
        headers: response['sqAuthheader'],
        responseType: 'arraybuffer'
         }).then(response => {
           if(response.status == 200){
             try{
              const byteArray = new Uint8Array(response.data);
              const blob = new Blob([byteArray], {type: `data:${fileMimeTypes[extension]}`});
              saveAs(blob, fileName);
              this.message = "File Downloaded Successfully!";
              this.isDownloadConfirmed = true;
             } catch(error){
               console.log(error);
               this.message = "Failed to download the file";
             }
           }
        }).catch((error) => {
          console.log(error);
       })
     }
    })
  }

}
