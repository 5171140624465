import { Component, OnInit ,Input, Output,EventEmitter} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-autocomplete-matselect',
  templateUrl: './autocomplete-matselect.component.html',
  styleUrls: ['./autocomplete-matselect.component.scss']
})
export class AutocompleteMatselectComponent implements OnInit {

  myControl = new FormControl('');
  // options: any = [];
  filteredOptions: Observable<string[]>;
  @Output() result = new EventEmitter<any>();
  @Output() clicked = new EventEmitter<any>();
  @Input() selectHeading;
  @Input() placeHolder;
  @Input() valueField;
  @Input() textField;
  @Input() options:any = [];
  selectedOption:any = [];
  @Input() currentlySelectedValue;

  constructor() { }

  ngOnInit() {
    // this.options = this.data;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    if(this.currentlySelectedValue){
      this.selectedOption = this.currentlySelectedValue;
    }
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option[this.textField].toLowerCase().includes(filterValue));
  }
  emitSelectionValues(event){
    this.selectedOption = event.option.value;
    this.myControl.patchValue('');
    this.result.emit(this.selectedOption);
  }
  getInputEvent(event){
    this.clicked.emit(true);
  }

}
