import { MAT_DIALOG_DATA } from '@angular/material';
import { PlanService } from './../../../../plan/services/plan.service';
import { Component, OnInit, Inject } from '@angular/core';
import { ProgramService2 } from '../../../../program/services/program.service';

@Component({
  selector: 'app-scenario-planning-dialog',
  templateUrl: './scenario-planning-dialog.component.html',
  styleUrls: ['./scenario-planning-dialog.component.scss']
})
export class ScenarioPlanningDialogComponent implements OnInit {

  message: string = '';
  isErr: boolean = false;
  errMsg: string = ''
  isDelConfirmed: boolean = false;
  apiResponse;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private planService: PlanService,
    private programService: ProgramService2
  ) { }

  ngOnInit() {
    this.message = '';
    this.apiResponse = null;
    switch (this.data.type) {
      case 'save':
        this.saveChannel();
        break;
      case 'saveList':
        this.saveChannelList();
        break;

      case 'update':
        this.updateScenario();
        break;

      case 'delete':
      this.message = "Are you sure you want to delete?"
      if(this.data.isLast){
        this.message = "Deleting the last channel in this scenario will result in deletion of the scenario as well. Are you sure you want to proceed?"
      }
        break;

      default:
        break;
    }
  }


  saveChannel() {
    this.isErr = false;
    switch (this.data.module) {
      case 'commsTask':
        this.message = "Saving Touchpoint";
        this.programService.saveTouchpoint(this.data.apiData).subscribe((res: any) => {
          this.apiResponse = res;
          this.message = 'Successfully Saved Touchpoint'
        }, err => {
          this.apiResponse = err;
          this.isErr = true;
          this.errMsg = err.error.message;
          this.message = "Error Saving Touchpoint."
        });
        break;

      case 'ufPlanScenario':
        this.message = "Creating Baseline Scenario";
        if (this.data.isTotalBudgetEmpty) {
          this.message = "Baseline Scenario requires a non-zero Working Media Budget value. Please update and try again."
        } else {
          this.planService.createBaseLineScenario(this.data.apiData).subscribe((res: any) => {
            this.apiResponse = res;
            this.message = 'Successfully Created Baseline Scenario.'
          }, err => {
            this.apiResponse = err;
            if (err.status == 476) {
              this.isErr = true;
              this.errMsg = err.error.message;
            }
            this.message = "Error in Creating Baseline Scenario."
          });
        }

        break;

          case 'scenarioMeta':
          this.message = "Saving Scenario";
          this.planService.updateScenarioName(this.data.apiData).subscribe(() => {
            this.message = 'Successfully Saved Scenario.'
          }, err => {
            this.apiResponse = err;
            if (err.status == 476) {
              this.isErr = true;
              this.errMsg = err.error.message;
            }

            this.message = `Error Saving Scenario.`;
          });
          break;
          default:
            //Validate CPP on create
            if(this.data.apiData.channelInfo.isCustomChannel == 'N'){
              if(this.data.apiData.channelInfo.cpp == ""|| this.data.apiData.channelInfo.cpp == 0|| this.data.apiData.channelInfo.cpp == null ){
                this.message = "CPP must be greater than 1.";
                return;
              }
            }

            this.message = "Saving Channel";
            this.planService.saveChannel(this.data.apiData).subscribe((res: any) => {
              this.apiResponse = res;
              this.message = 'Successfully Saved Channel.'
            }, err => {
              this.apiResponse = err;
              if (err.status == 476) {
                this.isErr = true;
                this.errMsg = err.error.message;
              }

              this.message = `Error Saving Channel.`;
            });

        }

      }

  saveChannelList() {
    this.isErr = false;
        //Validate CPP on create
       this.data.apiData.forEach((channelInfo, idx) => {
        if(channelInfo.isCustomChannel == 'N'){
          if(channelInfo.cpp == ""|| channelInfo.cpp == 0|| channelInfo.cpp == null ){
            this.message = "CPP must be greater than 1.";
            return;
          }
        }
       })
       console.log("this.data.apiData",this.data.apiData);

        this.message = "Saving Scenario";
        this.planService.saveChannelList(this.data.apiData).subscribe((res: any) => {
          this.apiResponse = res;
          this.message = 'Successfully Saved Scenario.'
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = `Error Saving Channels.`;
        });

    }

  updateScenario() {
    this.isErr = false;
    switch (this.data.element) {

      case 'scenarioStatus':
        this.message = "Saving Scenario Status";
        this.planService.updateScenario(this.data.apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Successfully Saved Scenario Status."
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = `Error in Saving Scenario Status.`;
        });

        break;

      case 'ufPlanStatus':
        this.message = "Saving Upfront Plan Status";
        this.planService.updateUpfrontPlan(this.data.apiData).subscribe(res => {
          this.apiResponse = res;
          this.message = "Successfully Saved Upfront Plan Status."
        }, err => {
          this.apiResponse = err;
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }

          this.message = `Error in Saving Upfront Plan Status.`;
        });

        break;
      default:
        break;

    }
  }


  initiateDelete() {
    this.isDelConfirmed = true;

    switch (this.data.element) {


      case 'scenario':
        this.message = "Deleting Scenario ...";
        this.planService.deleteScenario(this.data.apiData).subscribe(res => {
          this.message = "Successfully Deleted Scenario"
        }, err => {
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }
          this.message = `Error Deleting Scenario.`;
        })
        break;

      case 'channel':
        this.message = "Deleting Channel ...";
        this.planService.deleteScenarioChannel(this.data.cWMBudget,this.data.apiData,this.data.year,this.data.scenarioId,this.data.commsTaskId).subscribe(res => {
          this.message = "Successfully Deleted Channel",
          this.apiResponse = res;
        }, err => {
          if (err.status == 476) {
            this.isErr = true;
            this.errMsg = err.error.message;
          }
          this.message = `Error Deleting Channel.`;
        });
        break;

    }
  }



}
