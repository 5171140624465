import { GlobalElementsService } from "app/global-elements/services/global-elements.service";
import { IMarketCurrency } from "./../../common/interfaces/market";
import { GLOBAL_ELEMENTS, DOCS_LEVEL } from "../../constants/app-constants";
import { PlanService } from "../../plan/services/plan.service";
import { IportfolioTCPTracker } from "app/common/interfaces/plan";
const BASE_API_URL = `${environment.api}/v3`;
import { MarketService } from "../../market/services/market.service";
import { Router } from "@angular/router";
import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { SharedDataService } from "../../common/services/shared-data.service";
import { PortfolioService } from "../../portfolio/services/portfolio.service";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { getGpId } from "app/common/utils/common-util";
import { StoreService } from "../../old/_services/_store.service";
import { environment } from "environments/environment";
import { Subscription } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { googleAnalyticsCode } from "app/util";
import * as FullStory from "@fullstory/browser";

import {
  isValidUserAccess,
  isValidUserMarketAccess,
} from "../../common/utils/common-util";
import { filter, isEmpty, replace } from "lodash";
import { pairwise } from "rxjs/operators";
import { NavigationEnd } from "@angular/router";
import { PowerBIDashboardService } from "app/common/api/service/powerbidashboard.service";

const USER_INFO = JSON.parse(sessionStorage.getItem("user"));
@Component({
  selector: "app-routing",
  templateUrl: "./app-routing.component.html",
  styleUrls: ["./app-routing.component.scss"],
})
export class AppRoutingComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("yearinput", { static: false })
  yearinput: ElementRef<HTMLInputElement>;
  @ViewChild("marketinput", { static: false })
  marketinput: ElementRef<HTMLInputElement>;
  @ViewChild("portfolioinput", { static: false })
  portfolioinput: ElementRef<HTMLInputElement>;
  @ViewChild("planinput", { static: false })
  planinput: ElementRef<HTMLInputElement>;
  marketChangeSubscription: Subscription;
  userName: string;
  userInfo;
  enablePowerBILink: boolean = false;
  selectedCampaign: Array<string> = [];
  selectedMJTBD: Array<string> = [];
  disablePortfolioDropdown: boolean = true;
  disableMJTBDSection: boolean = true;
  disableMJTBDSubSection: boolean = true;
  disableCampaignSubSection: boolean = true;
  portfolioTCPTracker: IportfolioTCPTracker;
  mediaTransLink: boolean = false;
  isbrandSelected: boolean = false;
  defaultMarket;
  marketsList = [];
  portfolioByGroupsList = [];
  masterportfolioByGroupsList = [];
  portfoliosList = [];
  masterPlansList = [];
  plansList = [];
  tempPlansList = [];
  mjtbdsList = [];
  campaignsList: [];
  workingYear;
  selectedPortfolio: any;
  pfListModel = {
    id: "",
    displayName: "",
    isGroup: false,
    isAccesible: true,
    PFrtoURL: "",
  };
  selectedPlan;
  tmSelectForm: FormGroup;
  selectedPlanValue = [];
  selectedYear: any;
  workingYears = [];
  masterWorkingYearsList = [];
  multipleMarkets = [];
  mastermultipleMarkets = [];
  selectedMarket;
  currentPage: string;
  showMarketDropDown: boolean = false;
  showAggrView: boolean = true;
  showCurrencyExchange: boolean = false;
  localCurrencyDetails: IMarketCurrency;
  selectedYearValue: any;
  selectedMarketValue: any;
  showMTD: boolean = false;
  showCMD: boolean = false;
  RTOMode = "";
  saturationCurveAvailable: boolean = false;
  selectedMode = "";
  selectedOption = "Track progress";
  RTOLinkAvailable = false;
  showROIeResults = false;
  totalConnectionsPlan = false;
  totalConnectionsManagePlan = false;
  tooltipText: string = "";
  isUSDcurrency: boolean = true;
  viewCreativeDashboard: boolean = false;
  powerBIReportFound: any;

  constructor(
    private router: Router,
    private sharedDataService: SharedDataService,
    private marketService: MarketService,
    private portfolioService: PortfolioService,
    private planService: PlanService,
    protected googleTagManagerService: GoogleTagManagerService,
    private store: StoreService,
    private globalElementsService: GlobalElementsService,
    private powerBIDashboardService: PowerBIDashboardService,
    private fb: FormBuilder
  ) {
    this.tmSelectForm = this.fb.group({
      selectedPlan: [""],
    });
    this.tmSelectForm = this.fb.group({
      selectedPoftfolio: [""],
    });
  }

  ngOnInit() {
    //Newly Added Code for Market Filter Starts Here
    this.getWorkingYears();
    this.returnAccessMTDASH();
    this.sharedDataService.getWorkingYear.subscribe(
      (yr: number) => (this.selectedYear = yr)
    );
    //this.mediaTransLink = this.selectedPortfolio != "" ? false : true;
    //Newly Added Code for Market Filter Ends Here

    this.sharedDataService.getWorkingYear.subscribe((res) => {
      this.workingYear = res;
      this.getMjtbdList(this.selectedPlanValue ? this.selectedPlanValue : []);
      this.campaignsList = [];
    });

    this.sharedDataService.getUserInfo.subscribe((userInfo) => {
      const user = userInfo || USER_INFO;
      if (user) {
        this.userInfo = user;
        this.userName = `${user.firstName} ${user.lastName}`;

        if (user.routingPage !== "Y") {
          this.navigateToDefault();
        }
        this.marketsList = user.markets;

        //Newly Added Code for Market Filter Starts Here
        this.multipleMarkets = this.marketsList.sort((a, b) =>
          a.displayName >= b.displayName ? 1 : -1
        );
        this.mastermultipleMarkets = this.marketsList.sort((a, b) =>
          a.displayName >= b.displayName ? 1 : -1
        );
        if (this.marketsList.length > 1) {
          this.showMarketDropDown = true;
        } else {
          this.showMarketDropDown = false;
        }
        const marketInStore = this.store.get("market");
        this.selectedMarket = Number(marketInStore);
        this.store.set("market", this.selectedMarket);
        this.sharedDataService.setMarket(this.selectedMarket);

        //Newly Added Code for Market Filter Ends Here
      }
      this.enablePowerBILinks();
    });
    const marketId = this.store.get('market');
    let marketName:any;
    if(marketId){
      marketName = this.marketsList.filter((el) => el.id == marketId);
      console.log(`MARKET ID => ${marketId}`);
      console.log(`MARKET NAME => ${marketName[0].displayName}`);
    }
    FullStory.setVars('page', {
      'market': marketName ? marketName[0].displayName : ''
   });
    this.marketChangeSubscription = this.sharedDataService.getMarket.subscribe(
      (mktId) => {
        console.log(this.selectedMarket);
        const data = this.marketsList.filter((el) => el.id == mktId);
        if (this.marketinput) {
          if (data.length > 0) {
            this.marketinput.nativeElement.value = data[0].displayName;
          } else {
            this.marketinput.nativeElement.value =
              this.marketsList[0].displayName;
          }
        }
        const userInfo = JSON.parse(sessionStorage.getItem("user"));

        let currentSelectedMarket = this.store.get("market");
        if (!currentSelectedMarket) {
          this.selectedMarket = userInfo.markets[0].id;
          this.store.set("market", this.selectedMarket);
        }
        if (this.router && this.router.url.includes("/appRouting")) {
          this.defaultMarket = this.marketsList.find(
            (mkt) => mkt.id == this.selectedMarket
          );
          this.getPortfoliosList(this.defaultMarket);
          this.getPlansForMarket(this.defaultMarket);
          // if (this.planinput) {
          //   this.planinput.nativeElement.value = "";
          // }
          // if (this.portfolioinput) {
          //   this.portfolioinput.nativeElement.value = "";
          // }
        }
      }
    );

    setTimeout(() => {
      let selectedPlan = this.store.get("selectedPlan");
      if (selectedPlan == null) {
        selectedPlan = "";
      }
      if (selectedPlan && this.planinput) {
        this.planinput.nativeElement.value = selectedPlan.displayName;
      }
      if (selectedPlan != "") {
        this.isbrandSelected = true;
        this.totalConnectionsPlan = true;
        this.totalConnectionsManagePlan = true;
        // this.viewCreativeDashboard = true;
      } else {
        this.isbrandSelected = false;
        this.totalConnectionsPlan = false;
        this.totalConnectionsManagePlan = false;
        // this.viewCreativeDashboard = false;
        this.tooltipText = "Please select brand to proceed";
      }
      this.handleMarketState();
      //  this.handleRTOstate();
      this.handleTotalConnectionsPlan();
      let selectedPortfolio = this.store.get("selectedPortfolio");
      const portfolioObj = JSON.parse(selectedPortfolio);
      const displayName = portfolioObj.displayName;
      if (selectedPortfolio == null) {
        selectedPortfolio = "";
      }
      if (
        selectedPortfolio &&
        this.portfolioinput &&
        displayName != undefined
      ) {
        this.portfolioinput.nativeElement.value = displayName;
        this.selectedPortfolio = portfolioObj;
        this.handlePortfolioChange(portfolioObj);
      }
    }, 0);

    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Routing_Page`,
      eventAction: "PageView",
      eventLable: `App Routing Page View`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    //  this.getRTOPowerBIReportdetails();
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }

  //validate the user email not includes pepsico.com
  enablePowerBILinks() {
    if (this.userInfo) {
      if (
        this.userInfo.email.toLowerCase() ===
          "sudha.nachimuthu.contractor@pepsico.com" ||
        this.userInfo.email.toLowerCase() ===
          "namit.singh.contractor@pepsico.com" ||
        this.userInfo.email.toLowerCase() ===
          "bikram.sao.contractor@pepsico.com"
      ) {
        this.enablePowerBILink = true;
      } else if (
        this.userInfo.email.includes(".Contractor@pepsico.com") ||
        !this.userInfo.email.includes("@pepsico.com")
      ) {
        this.enablePowerBILink = false;
      } else {
        this.enablePowerBILink = true;
      }
    }
  }
  ngAfterViewInit() {}

  checkMarket(){
    const filterValue = this.marketinput.nativeElement.value.toLowerCase();
    let options = this.mastermultipleMarkets.filter((el) =>
      el.displayName.toLowerCase() == filterValue
    );
    if(options.length == 0){
      this.marketinput.nativeElement.value = "";    
    }
  }

  // get the RTO power BI report url from DB using the reportType and portfolio id
  getRTOPowerBIReportdetails(reqObj) {
    let reportUrl;
    this.powerBIDashboardService.getPowerBIReportUrls(reqObj).subscribe(
      (res) => {
        if (res) {
          reportUrl = res;
          //open the reporturl in new tab

          if (reportUrl) {
            window.open(reportUrl, "_blank");
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
    return reportUrl;
  }
  handleMarketState() {
    // const marketId = this.store.get("market");
    this.globalElementsService
      .handleMarketState(this.selectedMarket)
      .subscribe((response) => {
        console.log(response);
        if (response["count"] != null) {
          this.saturationCurveAvailable = true;
        } else {
          this.saturationCurveAvailable = false;
          this.tooltipText = `Saturation curve is not available for selected market`;
        }
      });
  }
  navigateToUpfrontScenarios() {
    this.router.navigate([`/upfrontPlanning/${this.selectedPlan.id}`]);
  }
  setWorkingYear(event) {
    this.selectedYear = event.option.value.value;
    // this.selectedYear = this.workingYears.find((el) => el.value == event.option.value);
    this.store.set("workingYear", this.selectedYear);
    this.sharedDataService.setWorkingYear(this.selectedYear);
    
    //update working year in store
    this.store.set("planningYear", this.selectedYear);
    this.sharedDataService.setPlanningYear(this.selectedYear);
  }
  displayFnYear(year): string {
    return year && year.displayName;
  }
  displayFnMarket(market) {
    return market && market.displayName;
  }
  displayFnPortfolio(portfolio) {
    return portfolio && portfolio.displayName;
  }

  displayFnPlan(plan) {
    return plan && plan.displayName;
  }
  getPortfoliosList(market) {
    if (market != undefined) {
      this.defaultMarket = this.marketsList.find(
        (usrMkt) => usrMkt.id == market.id
      );
      this.disablePortfolioDropdown = true;
      const postObj = {
        marketId: market.id,
        year: this.workingYear,
      };
      this.marketService
        .getPortfolioGroupsByMarket(postObj)
        .subscribe((data: any) => {
          this.constructPortfolioList(data);
          if (
            (data && data.portfolios.length > 0) ||
            data.portfolioGroups.length
          ) {
            this.disablePortfolioDropdown = false;
          }
        });
    }
  }

  getPlansForMarket(market) {
    if (market != undefined) {
      this.disableMJTBDSection = true;
      this.selectedPlan = "";
      const postObj = {
        marketId: market.id,
        year: this.workingYear,
      };
      this.marketService.getPlansForMarket(postObj).subscribe((data: any) => {
        this.plansList = data.plans;
        this.masterPlansList = data.plans;
        // let planNames = (data.plans && data.plans.map(el => el.displayName)) || [];
        // planNames = planNames.sort();

        // let plansList = [];
        // planNames.forEach(name => {
        //   const planObj = data.plans.find(el => el.displayName == name);
        //   plansList.push(planObj);
        // });
        // this.plansList = plansList;

        this.disableMJTBDSection = false;
      });
      this.selectedPlan = this.store.get("selectedPlan")
        ? JSON.parse(this.store.get("selectedPlan"))
        : "";

      //this.handleRTOstate();
      this.handleTotalConnectionsPlan();
    }
  }
  toggleMarketDropdown() {
    const multipleMarkets = this.multipleMarkets.length > 1;
    const MULTIPLE_MARKET_VIEW = ["/appRouting", "/market"];
    const isMarketDisplayPage = MULTIPLE_MARKET_VIEW.some(
      (page) => this.currentPage && this.currentPage.includes(page)
    );
    this.showMarketDropDown = multipleMarkets && isMarketDisplayPage;
    this.sharedDataService.setMarket(this.selectedMarket);
  }

  toggleCurrencyView() {
    this.showCurrencyExchange =
      this.localCurrencyDetails &&
      this.localCurrencyDetails.currencyCode !== "USD" &&
      this.currentPage !== "/appRouting" &&
      false;
  }
  toggleAggrView() {
    this.showAggrView = this.currentPage !== "/appRouting";
  }
  changeMarket(event) {
    this.viewCreativeDashboard = false;
    this.portfolioinput.nativeElement.value = "";
    this.planinput.nativeElement.value = "";
    this.selectedMarket = event.option.value.id;
    this.showMTD = this.selectedMarket == "3" ? true : false;
    this.showCMD = this.selectedMarket == "3" ? true : false;
    this.mediaTransLink = false;
    this.showROIeResults = false;

    this.RTOMode = "";
    this.RTOLinkAvailable = false;

    this.store.set("market", this.selectedMarket);
    this.store.set("selectedPlan", "");
    this.store.set("selectedPortfolio", "");
    this.getCurrencyExchange();
    const plan = this.store.get("selectedPlan");
    //const portfolio = this.store.get("selectedPortfolio");
    if (plan == "") {
      this.isbrandSelected = false;
      this.tooltipText = "Please select brand to proceed";
    }
    this.getCurrencyExchange();
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Header_Elements`,
      eventAction: "Change Market Clicked",
      eventLable: `Global Elements Strip Change Market Dropdown clicked`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
    this.sharedDataService.setMarket(this.selectedMarket);
    // if (this.currentPage.match("/market")) {
    //   this.navigateToMarket(this.selectedMarket);
    // }
    this.globalElementsService
      .handleMarketState(this.selectedMarket)
      .subscribe((response) => {
        console.log(response);
        if (response["count"] != null) {
          this.saturationCurveAvailable = true;
        } else {
          this.saturationCurveAvailable = false;
          this.tooltipText = `Saturation curve is not available for selected market`;
        }
      });
    this.handleTotalConnectionsPlan();
    const filteredMarket = this.multipleMarkets.filter(
      (el) => el.id == this.selectedMarket
    );
    const marketName = filteredMarket ? filteredMarket[0]["displayName"] : "";

    /* Adding full story code to capture the page information*/

    FullStory.setVars("page", {
      pageName: marketName,
    });
  }

  getWorkingYears() {
    this.globalElementsService
      .getCanvasYears()
      .subscribe((res: Array<number>) => {
        this.workingYears = [];
        res.map((yr) => {
          this.workingYears.push({
            displayName: "FY " + yr,
            value: yr,
          });
        });
        this.masterWorkingYearsList = this.workingYears;
        this.selectedYearValue = this.workingYears.find(
          (el) => el.value == this.selectedYear
        );
        this.selectedMarketValue = this.mastermultipleMarkets.find(
          (el) => el.id == this.selectedMarket
        );
        this.yearinput.nativeElement.value = this.selectedYearValue.displayName;
        if (this.marketinput) {
          this.marketinput.nativeElement.value =
            this.selectedMarketValue.displayName;
        }

        console.log(this.selectedPlan);
        this.planinput.nativeElement.value = this.selectedPlan
          ? this.selectedPlan.displayName
          : "";
        this.handleTotalConnectionsPlan();
      });
  }

  navigateToAllocation(type: string) {
    let sidePanelEle;
    const { id, displayName } = this.selectedPlan;
    const marketId = this.defaultMarket.id;
    switch (type) {
      case "RTO":
        sidePanelEle = { ...GLOBAL_ELEMENTS.DATORAMA_DASHBOARD };
        sidePanelEle.datoramaData = {
          marketId,
          planId: id,
          planName: displayName,
        };
        this.sharedDataService.setSideNavSelection(sidePanelEle);
        break;

      case "CPD":
        sidePanelEle = { ...GLOBAL_ELEMENTS.CPERF_DASHBOARD };
        sidePanelEle.cPerfData = {
          marketId,
          planId: id,
          planName: displayName,
        };
        this.sharedDataService.setSideNavSelection(sidePanelEle);
        break;
    }
  }

  getPortfolionPlans(market) {
    this.defaultMarket = market;
    this.getPortfoliosList(market);
    this.getPlansForMarket(market);
    this.getCurrencyExchange();
  }

  getCurrencyExchange() {
    this.globalElementsService
      .getCurrencyExchange(this.selectedMarket)
      .subscribe((res: IMarketCurrency) => {
        if (res) {
          this.localCurrencyDetails = res;
          const xchangeRate = Number(res.conversionFactor);
          this.sharedDataService.setCurrencyView(res.currencyCode);
          this.store.set("currencySign", res.currencySign);
          this.sharedDataService.setCurrencyExchangeRate(xchangeRate);
          this.sharedDataService.setCurrencyExchange(res);
          this.currencyViewChange(res.currencyCode);
        }
      });
  }
  currencyViewChange(viewType: string) {
    const prevSelection = this.isUSDcurrency;
    this.isUSDcurrency = viewType === "USD";
    this.sharedDataService.setCurrencyView(
      this.isUSDcurrency ? "USD" : this.localCurrencyDetails.currencyCode
    );
    this.sharedDataService.setCurrencySymbolSource(
      this.isUSDcurrency ? "$" : this.localCurrencyDetails.currencySign
    );
    this.sharedDataService.setCurrencyExchangeRate(
      this.isUSDcurrency ? 1 : this.localCurrencyDetails.conversionFactor
    );
    if (prevSelection !== this.isUSDcurrency) {
      // this.refreshPage();
    }
  }
  refreshPage() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate([this.router.url]);
  }

  handlePlanChange(plan) {
    const planId = plan.option.value.id;
    this.selectedPlan = plan.option.value;
    this.isbrandSelected = plan.option.value != "" ? true : false;
    // this.viewCreativeDashboard = plan.option.value != "" ? true : false;
    if (!this.isbrandSelected) {
      this.tooltipText = "Please select brand to proceed";
    }
    this.planService.getHierarchy({ planId: planId }).subscribe((response) => {
      if (response) {
        this.selectedPortfolio = this.masterportfolioByGroupsList.filter(
          (el) => el.id == response["portfolio"].id
        )[0];
        this.portfolioinput.nativeElement.value =
          this.selectedPortfolio.displayName;
        this.mediaTransLink = true;
        this.RTOLinkAvailable = true;
        // this.showROIeResults = true;
        this.showROIeResults = false;
        this.enableCPD();
      }
    });
    this.store.set("selectedPlan", JSON.stringify(this.selectedPlan));
    // this.tmSelectForm.get("selectedPlan").patchValue(plan);

    //this.handleRTOstate();
    this.handleMarketState();
    this.handleTotalConnectionsPlan();

    /* Adding full story code to capture the page information*/

    FullStory.setVars("page", {
      pageName: this.selectedPlan ? this.selectedPlan.displayName : "",
    });
  }
  checktooltipDisableCondition() {
    if (this.saturationCurveAvailable) {
      if (this.isbrandSelected) {
        return true;
      } else {
        return false;
      }
    }
    if (!this.saturationCurveAvailable) {
      return false;
    }
  }
  handlePortfolioChange(portfolio) {
    if (portfolio.option && portfolio.option.value) {
      this.selectedPortfolio = portfolio.option.value;
      if (portfolio.option.value) {
      }
      this.enableCPD();

      this.mediaTransLink = portfolio.option.value != "" ? true : false;
      this.RTOLinkAvailable = portfolio.option.value != "" ? true : false;

      // this.showROIeResults = portfolio.option.value != "" ? true : false;
      // this.showROIeResults = portfolio.option.value != "" ? true : false;
      // this.viewCreativeDashboard = portfolio.option.value != "" ? true : false;
      const portfolioId = portfolio.option.value.id;

      this.plansList = this.masterPlansList.filter(
        (el) => el.portfolioId == portfolioId
      );

      this.tempPlansList = this.plansList;
      this.selectedPlan = "";
      this.store.set("selectedPlan", this.selectedPlan);
      this.planinput.nativeElement.value = "";
      this.store.set(
        "selectedPortfolio",
        JSON.stringify(this.selectedPortfolio)
      );

      if (this.selectedPlan == "") {
        this.isbrandSelected = false;
        this.tooltipText = "Please select brand to proceed";
        this.viewCreativeDashboard = false;
      } else {
        this.isbrandSelected = true;
      }
    } else if (portfolio) {
      this.selectedPortfolio = portfolio;
      this.enableCPD();
      this.mediaTransLink = portfolio != "" ? true : false;
      this.RTOLinkAvailable = portfolio != "" ? true : false;
      const portfolioId = portfolio.id;
      this.plansList = this.masterPlansList.filter(
        (el) => el.portfolioId == portfolioId
      );
    }
    this.tempPlansList = this.plansList;
    this.store.set("selectedPortfolio", JSON.stringify(this.selectedPortfolio));
    this.planinput.nativeElement.value = "";
    if (this.selectedPlan == "") {
      this.isbrandSelected = false;
      this.tooltipText = "Please select brand to proceed";
      this.viewCreativeDashboard = false;
    } else {
      this.isbrandSelected = true;
    }
    // this.handleRTOstate();
    this.handleTotalConnectionsPlan();
  }
  enableCPD() {
    if (this.selectedPortfolio) {
      this.powerBIDashboardService
        .getPowerBIReportforPortfolio(this.selectedPortfolio.id)
        .subscribe((res: any) => {
          console.log("getPowerBIReportforPortfolio::", res);
          if (res && res.reportFound) {
            this.powerBIReportFound = res.reportFound;
            this.viewCreativeDashboard = true;
          } else {
            this.powerBIReportFound = false;
            this.viewCreativeDashboard = false;
          }
        });
    } else {
      this.viewCreativeDashboard = false;
    }
  }

  yearFilter(): void {
    const filterValue = this.yearinput.nativeElement.value.toLowerCase();
    this.workingYears = this.masterWorkingYearsList.filter((el) =>
      el.displayName.toLowerCase().includes(filterValue)
    );
  }
  marketFilter(): void {
    const filterValue = this.marketinput.nativeElement.value.toLowerCase();
    this.multipleMarkets = this.mastermultipleMarkets.filter((el) =>
      el.displayName.toLowerCase().includes(filterValue)
    );
  }
  checkYear(){
    const filterValue = this.yearinput.nativeElement.value.toLowerCase();
    let options = this.masterWorkingYearsList.filter((el) =>
      el.displayName.toLowerCase() == filterValue
    );
    if(options.length == 0){
      this.yearinput.nativeElement.value = "";
    }
  }
  portfolioFilter(): void {
    const filterValue = this.portfolioinput.nativeElement.value.toLowerCase();
    if (filterValue == "") {
      this.mediaTransLink = false;
      this.showROIeResults = false;
      this.RTOLinkAvailable = false;
      this.handleTotalConnectionsPlan();
      this.viewCreativeDashboard = false;
      //this.handleCreativeMediaDashboard();
    }
    // else {
    //   this.enableCPD();
    // }
    this.portfolioByGroupsList = this.masterportfolioByGroupsList.filter((el) =>
      el.displayName.toLowerCase().includes(filterValue)
    );
    console.log("    this.portfolioByGroupsList ", this.portfolioByGroupsList);
    //this.handleRTOstate();
    /* Adding full story code to capture the page information*/
    
    const marketId = this.store.get('market');
    let marketName:any;
    if(marketId){
      marketName = this.marketsList.filter((el) => el.id == marketId);
      console.log(`MARKETID => ${marketId}`);
    }
    FullStory.setVars('page', {
      'brand': this.selectedPlan ? this.selectedPlan.displayName:'',
      'market': marketName ? marketName[0].displayName : ''
   });
  }
  planFilter(): void {
    const planV = this.store.get("selectedPlan");
    const filterValue = this.planinput.nativeElement.value.toLowerCase();
    const planValue = this.planinput.nativeElement.value;
    if (filterValue == "") {
      this.isbrandSelected = false;
      this.handleTotalConnectionsPlan();
      this.viewCreativeDashboard = false;
      //this.handleCreativeMediaDashboard();
      this.tooltipText = "Please select brand to proceed";
    }
    const portfolioValue = this.portfolioinput.nativeElement.value;
    console.log(this.plansList);
    // if (filterValue != "" && portfolioValue != "") {
    //   this.mediaTransLink = false;
    // }

    if (portfolioValue != "" && filterValue == "") {
      this.plansList = this.masterPlansList.filter(
        (el) => el.portfolioName == portfolioValue
      );
      this.tempPlansList = this.plansList;
    } else if (portfolioValue == "" && filterValue != "") {
      this.plansList = this.masterPlansList.map((element) => {
        return {
          ...element,
          plans: element.plans.filter((subElement) =>
            subElement.displayName.toLowerCase().includes(filterValue)
          ),
        };
      });
      this.plansList = this.plansList.filter(
        (element) => element.plans.length > 0
      );
      console.log(this.plansList);
    } else if (portfolioValue == "" && filterValue == "") {
      this.plansList = this.masterPlansList.map((element) => {
        return {
          ...element,
          plans: element.plans.filter((subElement) =>
            subElement.displayName.toLowerCase().includes(filterValue)
          ),
        };
      });
    } else if (portfolioValue != "" && filterValue != "") {
      this.plansList = this.tempPlansList.map((element) => {
        return {
          ...element,
          plans: element.plans.filter((subElement) =>
            subElement.displayName.toLowerCase().includes(filterValue)
          ),
        };
      });
    }
    if (portfolioValue != "" && filterValue == "") {
      this.enableCPD();
    }
    //this.handleRTOstate();
  }
  handleCreativeMediaDashboard() {
    const portfolioValue =
      this.portfolioinput.nativeElement.value.toLowerCase();
    // const planValue = this.planinput.nativeElement.value.toLowerCase();
    if (portfolioValue == "") {
      this.viewCreativeDashboard = false;
    } else {
      this.viewCreativeDashboard = true;
    }
  }

  setSideNavElement(element, mode) {
    console.log(element, GLOBAL_ELEMENTS[element]);
    this.sharedDataService.setSideNavSelection(GLOBAL_ELEMENTS[element]);
    this.sharedDataService.setdocumentModeSource(mode);
  }

  clickMediaCalendarBtn() {
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Annual Media Calendar Home_Page`,
      eventAction: "Trademark Annual Media Calendar 2.0 Button Click",
      eventLable: `Annual Media Calendar 2.0 Button clicked from Home Page`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
    let tagObjectpageView = {
      event: `custom_${environment.environmentName}_pageView`,
      pageUrl: `canvas-datorama.pepsico.com/external/dashboard`,
      pageTitle: `Global Dataroma Media Calendar Page`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObjectpageView, this.googleTagManagerService);
  }
  compareOptions(option1, option2) {
    return option1.displayName === option2.displayName;
  }
  getMjtbdList(plan) {
    if (!isEmpty(plan)) {
      this.selectedPlanValue = plan;
      this.disableMJTBDSubSection = true;
      const postObj = {
        year: this.workingYear,
        planId: plan.id,
      };
      this.planService.getPlanData(postObj).subscribe((data: any) => {
        this.mjtbdsList = data.mjtbds;
        this.selectedMJTBD = [];
        this.disableMJTBDSubSection = false;
      });
      this.planService.getCampaignsFromTM(postObj).subscribe((response) => {
        this.campaignsList = response["campaigns"]
          ? response["campaigns"].length > 0
            ? response["campaigns"]
            : []
          : [];
        this.selectedCampaign = [];
        this.disableCampaignSubSection = response["campaigns"]
          ? response["campaigns"].length > 0
            ? false
            : true
          : true;
      });
    }
  }

  getCampaignsList(mjtbdId) {
    const postObj = {
      mjtbdId: mjtbdId,
      year: this.workingYear,
    };
    this.planService.getCampaignsByMjtbd(postObj).subscribe((response) => {
      this.campaignsList = response["campaigns"]
        ? response["campaigns"].length > 0
          ? response["campaigns"]
          : []
        : [];
      this.selectedCampaign = [];
      this.disableCampaignSubSection = response["campaigns"]
        ? response["campaigns"].length > 0
          ? false
          : true
        : true;
    });
  }

  constructPortfolioList(mktPortfolios) {
    this.portfolioByGroupsList = [];
    this.portfoliosList = [];
    mktPortfolios.portfolioGroups &&
      mktPortfolios.portfolioGroups.forEach((pg) => {
        const pgModel = { ...this.pfListModel };
        pgModel.id = pg.id;
        pgModel.displayName = pg.name;
        pgModel.isGroup = true;
        this.portfolioByGroupsList.push(pgModel);

        pg.portfolios.forEach((pf) => {
          const pfModel = { ...this.pfListModel };
          pfModel.id = pf.id;
          pfModel.displayName = pf.displayName;
          pfModel.isGroup = false;
          pfModel.isAccesible = pf.accessible;
          this.portfolioByGroupsList.push(pfModel);
        });
      });

    mktPortfolios.portfolios &&
      mktPortfolios.portfolios.forEach((pf) => {
        const pfModel = { ...this.pfListModel };
        pfModel.id = pf.id;
        pfModel.displayName = pf.displayName;
        pfModel.isGroup = false;
        pfModel["isOrphan"] = true;
        pfModel.isAccesible = pf.accessible;
        pfModel.PFrtoURL = pf["portfoliortodsh.rtoLink"];
        this.portfolioByGroupsList.push(pfModel);
        // this.masterportfolioByGroupsList.push(pfModel);
        this.portfolioByGroupsList.sort((a, b) =>
          a.displayName >= b.displayName ? 1 : -1
        );
        // this.masterportfolioByGroupsList.sort((a, b) =>a.displayName >= b.displayName ? 1 : -1);
        this.masterportfolioByGroupsList = this.portfolioByGroupsList;
      });

    this.portfoliosList = this.portfolioByGroupsList.filter(
      (pf) => !pf.isGroup
    );
  }

  navigateToDefault() {
    const { defaultLanding } = this.userInfo;
    const dftLndList = defaultLanding.split(",");
    const defLanLength = dftLndList.length;
    const ele = {};
    ele["id"] = dftLndList[defLanLength - 1];

    switch (defLanLength) {
      case 1:
        const mktId = ele["id"];
        this.navigateToMarket(mktId);
        break;
      case 2:
        this.navigateToPortfolio(ele);
        break;
      case 3:
        const plandId = ele["id"];
        this.navigateToPlan(plandId);
        break;

      default:
        console.error("Default Landing not found");
        break;
    }
  }

  navigateToMarket(marketId: number) {
    this.router.navigate([`/market/${marketId}`]);
  }

  navigateToPortfolioAllocation(portfolioId) {
    !!portfolioId
      ? this.router.navigate([`/portfolioAllocation/${portfolioId}`])
      : null;
  }
  navigateToPortfoliTCPTracker(portfolio) {
    let sidePanelEle = { ...GLOBAL_ELEMENTS.TCP_PORTFOLIO_TRACKER };
    this.portfolioTCPTracker = {
      portfolioId: portfolio.id,
      year: this.workingYear,
    };
    sidePanelEle.portfolioTCPTracker = this.portfolioTCPTracker;
    this.sharedDataService.setSideNavSelection(sidePanelEle);
  }
  navigateToPortfolioRTO(portfolio) {
    let sidePanelEle = { ...GLOBAL_ELEMENTS.PORTFOLIO_RTO_DASH };
    sidePanelEle.portfoliodata = {
      portfolioId: portfolio.id,
      portfolioName: portfolio.displayName,
    };
    this.sharedDataService.setSideNavSelection(sidePanelEle);
  }
  navigateToPortfolio(portfolio) {
    if (portfolio.isGroup) {
      this.router.navigate([`/portfolioGroup/${portfolio.id}`]);
      let tagObject = {
        event: `custom_${environment.environmentName}_Event`,
        eventCategory: `Routing_Page`,
        eventAction: "Portfolio Group Go Button Click",
        eventLable: `Routing Page Go to Portfolio Group`,
        customeEvent: `custom_${environment.environmentName}_Event`,
        userId: getGpId(),
        user_id: getGpId(),
      };
      googleAnalyticsCode(tagObject, this.googleTagManagerService);
    } else {
      this.router.navigate([`/portfolio/${portfolio.id}`]);
      let tagObject = {
        event: `custom_${environment.environmentName}_Event`,
        eventCategory: `Routing_Page`,
        eventAction: "Portfolio Go Button Click",
        eventLable: `Routing Page Go to Portfolio Page`,
        customeEvent: `custom_${environment.environmentName}_Event`,
        userId: getGpId(),
        user_id: getGpId(),
      };
      googleAnalyticsCode(tagObject, this.googleTagManagerService);
    }
  }

  navigateToPortfoliMediaCalendar(portfolio) {
    !!portfolio && !portfolio.isGroup
      ? this.router.navigate([`/annualMediaCalendar/portfolio/${portfolio.id}`])
      : null;
  }

  /**
   * Navigates to the selected plan
   * @param plan : Currently selected matOption value
   */
  navigateToPlan(planId) {
    // planId ? this.router.navigate([`/plan/${planId}`]) : null;
    if(planId)
      this.router.navigate([`/plan/${planId}`], { fragment: "p1" });
  }
  navigateToPlanPage(planId) {
    if (planId) {
      this.router.navigate([`/plan/${planId}`], { fragment: "p2" });
    }
  }
  navigateToTrademarkMediaCalendar(planId) {
    !!planId
      ? this.router.navigate([`/annualMediaCalendar/plan/${planId}`])
      : null;
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Routing_Page`,
      eventAction: "Trademark Go Button Click",
      eventLable: `Routing Page Go to Trademark"`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }

  navigateToMjtbd(mjtbdId) {
    this.store.set("planningYear", this.workingYear);
    this.sharedDataService.setPlanningYear(this.workingYear);
    if (mjtbdId) {
      this.router.navigate([`/program/${mjtbdId}`]);
    }
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Routing_Page`,
      eventAction: "MJTBD Go Button Click",
      eventLable: `Routing Page Go to MJTBD`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }
  navigateToCampaignSummary(campaignId) {
    if (campaignId) {
      this.router.navigate([`/campaignSummary/${campaignId}`]);
    }
  }

  gotoGlobalDocs() {
    const globalDocs = { ...GLOBAL_ELEMENTS.DOCUMENTS };
    this.sharedDataService.setSideNavSelection(globalDocs);
  }

  gotoPortfolioDocs(portfolio) {
    if (portfolio && portfolio.displayName) {
      const subPath = `/${this.defaultMarket.displayName}/${portfolio.displayName}`;
      const portfolioDocs = { ...GLOBAL_ELEMENTS.DOCUMENTS };
      portfolioDocs.level = DOCS_LEVEL.BRAND_DOCUMENTS;
      portfolioDocs.subPath = subPath;
      this.sharedDataService.setSideNavSelection(portfolioDocs);
    } else {
      this.gotoGlobalDocs();
    }
  }
  viewROIeResults(portfolio) {
    if (portfolio && portfolio.displayName) {
    }
  }
  gotoTrademarkDocs(plan) {
    const portfolioDocs = { ...GLOBAL_ELEMENTS.DOCUMENTS };
    portfolioDocs.level = DOCS_LEVEL.BRAND_DOCUMENTS;
    this.sharedDataService.setSideNavSelection(portfolioDocs);
    if (plan && plan.displayName) {
      const subPath = `/${this.defaultMarket.displayName}/${this.selectedPortfolio.displayName}/${plan.displayName}`;
      const planDocs = { ...GLOBAL_ELEMENTS.DOCUMENTS };
      planDocs.level = DOCS_LEVEL.BRAND_DOCUMENTS;
      planDocs.subPath = subPath;
      this.sharedDataService.setSideNavSelection(planDocs);
    } else {
      this.gotoGlobalDocs();
    }
  }
  returnAccess(_oprator, conditionRoleId, portfoio) {
    return isValidUserAccess(
      conditionRoleId,
      _oprator,
      portfoio ? portfoio.id : null
    );
  }
  returnAccessPlan(_oprator, conditionRoleId, selectedPlan) {
    return isValidUserAccess(
      conditionRoleId,
      _oprator,
      null,
      selectedPlan ? selectedPlan.id : null
    );
  }
  navigateToMTDashboard(portfolio) {
    let sidePanelEle = { ...GLOBAL_ELEMENTS.MEDIA_TRANSPARENCY_DASH };
    sidePanelEle.portfoliodata = {
      portfolioId: portfolio.id,
      portfolioName: portfolio.displayName,
      portfolioType: "MTD",
    };
    this.sharedDataService.setSideNavSelection(sidePanelEle);
  }
  navigateToCPDashboard(portfolio) {
    console.log("portfolio", portfolio);
    let sidePanelEle = { ...GLOBAL_ELEMENTS.CREATIVE_MEDIA_PERFORMANCE_DASH };
    sidePanelEle.portfoliodata = {
      portfolioId: portfolio.id,
      portfolioName: portfolio.displayName,
      portfolioType: "CPD",
    };
    this.sharedDataService.setSideNavSelection(sidePanelEle);
  }
  returnAccessMTDASH() {
    const val = isValidUserMarketAccess();
    this.showMTD = val;
    this.showCMD = val;
  }

  gotoRTODashboard() {
    if (this.selectedPlan || this.selectedPortfolio) {
      //hyperlink should open in a new tab
      //open the link in new tab alternates in angular
      const portfolioId = "99999"; //this needs to be replaced with the selected portfolio or plan id
      //this.selectedPortfolio ? this.selectedPortfolio.id : "99999";
      const reqObj = {
        portfolioId: portfolioId,
        reportType: "RTO",
      };
      const reportUrl = this.getRTOPowerBIReportdetails(reqObj);
      //await until the reporturl is fetched

      // this.navigateToAllocation("RTO");
      // } else {
      //   this.navigateToPortfolioRTO(this.selectedPortfolio);
      // }
    }
  }

  handleRTOstate() {
    if ((this.selectedPlan && this.isbrandSelected) || this.selectedPortfolio) {
      //   this.RTOMode = "Brand";
      //   this.RTOLinkAvailable = this.selectedPlan["datoramadatum.rtoURL"]
      //     ? true
      //     : false;
      // } else if (
      //   this.selectedPortfolio &&
      //   this.portfolioinput.nativeElement.value != ""
      // ) {
      //   this.RTOMode = "Portfolio";
      this.RTOLinkAvailable = true; //this.selectedPortfolio["PFrtoURL"] ? true : false;
    } else {
      //  this.RTOMode = "";
      this.RTOLinkAvailable = false;
    }
  }

  handleTotalConnectionsPlan() {
    if (this.planinput) {
      const SelectedPlanValue =
        this.planinput.nativeElement.value.toLowerCase();
      const SelectedPortfolioValue =
        this.portfolioinput.nativeElement.value.toLowerCase();
      console.log("SelectedPlanValue", SelectedPlanValue);
      console.log("SelectedPortfolioValue", SelectedPortfolioValue);
      if (SelectedPlanValue && SelectedPortfolioValue) {
        this.selectedMode = "BrandNPortfolio";
        this.selectedOption = "Track Brand Progress";
        this.totalConnectionsPlan = true;
        this.totalConnectionsManagePlan = true;
      } else if (
        SelectedPlanValue != "" &&
        SelectedPlanValue != "null" &&
        SelectedPlanValue != "undefined"
      ) {
        this.selectedMode = "Brand";
        this.selectedOption = "Track Brand Progress";
        this.totalConnectionsPlan = true;
        this.totalConnectionsManagePlan = true;
      } else if (SelectedPortfolioValue) {
        this.selectedMode = "Portfolio";
        this.selectedOption = "Track Portfolio Progress";
        this.totalConnectionsPlan = true;
        this.totalConnectionsManagePlan = false;
      } else {
        this.totalConnectionsPlan = false;
        this.totalConnectionsManagePlan = false;
        this.selectedMode = "";
      }
    }
  }
  ngOnDestroy(): void {
    this.marketChangeSubscription.unsubscribe();
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Routing_Page`,
      eventAction: "PageExit",
      eventLable: `Routing Page Exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }
}
