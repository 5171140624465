import { Component, OnInit, Inject } from '@angular/core';
import { PlanService } from 'app/plan/services/plan.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileUploadDialogComponent } from '../file-upload-dialog/file-upload-dialog.component';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';

@Component({
  selector: 'app-update-target-dialog',
  templateUrl: './update-target-dialog.component.html',
  styleUrls: ['./update-target-dialog.component.scss']
})
export class UpdateTargetDialogComponent implements OnInit {

  message: string = "Saving brand target data... Please Wait";
  status=false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private planService: PlanService,
    private dialogRef: MatDialogRef<UpdateTargetDialogComponent>,
    private globalElementsService: GlobalElementsService
  ) { }

  ngOnInit() {
    this.updateTargetBrands();
  }


  updateTargetBrands() {    
      this.planService.updateBrandTarget(this.data).subscribe((res:any)=>{
      if(res){
        this.message = "Saved successfully";
        this.status=true;
      }   
    }, (error:any) => {
      this.message = error ? "Error in updating targets: "+error.error.error : "Error in updating targets. Please try again later or contact GlobalMSP@pepsico.com";
      this.status=false;
    })
    
  }

  close(){
    this.dialogRef.close({ status: this.status })
    this.status ? this.globalElementsService.setSidePanelCancelEvent() : null;
  }

}
