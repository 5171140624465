import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-save-rto-dashboard-dialog',
  templateUrl: './save-rto-dashboard-dialog.component.html',
  styleUrls: ['./save-rto-dashboard-dialog.component.scss']
})
export class SaveRtoDashboardDialogComponent implements OnInit {

  message = "Saved Successfully!";
  constructor() { }

  ngOnInit() {
  }

}
