import { GlobalElementsService } from './../../../../global-elements/services/global-elements.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';


@Component({
  selector: 'app-user-delete-dialog',
  templateUrl: './user-delete-dialog.component.html',
  styleUrls: ['./user-delete-dialog.component.scss']
})
export class UserDeleteDialogComponent implements OnInit {

  message: string = "Are you sure you want to delete selected user(s)?";
 isDelConfirmed:boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalElementsService: GlobalElementsService,
    protected googleTagManagerService: GoogleTagManagerService
     

  ) { }

  ngOnInit() {
  }


  deleteUser() {
    this.isDelConfirmed = true;
    this.message = "Deleting...Please wait.";
    this.globalElementsService.deleteUsers(this.data).subscribe(res => {
      this.message = "Successfully deleted user(s).";      
    }, err => {
      this.message = "Error deleting selected users(s).";
    })
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `User_Management`,
      eventAction: "Delet User",
      eventLable: `Global Elements Delete User click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }
}
