import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import { LoaderService } from '../../loader/loader.service';
import { isNil } from 'lodash';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const endpointConfig = this.getEndpointConfig(request);
    request = endpointConfig.req;
    if (endpointConfig.showApiLoader) {
      this.loaderService.show();
    }
    return next.handle(request).pipe(
      tap({
        next: (event: HttpEvent<unknown>) => {
          if (event instanceof HttpResponse) {
              this.loaderService.hide();
          }
        },
        error: () => {
            this.loaderService.hide();
        }
      })
    );
  }
    getEndpointConfig(req: HttpRequest<unknown>): {
    showApiLoader: boolean;
    req: HttpRequest<unknown>;
  } {
    let headers: HttpHeaders = req.headers;
    let showApiLoader = false;
    if (!isNil(headers.has('showApiLoader'))) {
      showApiLoader = JSON.parse(
        headers.get('showApiLoader') as string
      ) as boolean;
      headers = headers.delete('showApiLoader');
    }
    req = req.clone({ headers });

    return {
      showApiLoader,
      req
    };
  }

}
