import { AGGR_VIEW } from "./../../constants/app-constants";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { IMarketCurrency } from "../interfaces/market";

@Injectable({ providedIn: "root" })
export class SharedDataService {
  globalElement: Object;
  userInfo: any;
  workingYear: number =
    JSON.parse(sessionStorage.getItem("workingYear")) ||
    new Date().getFullYear();
  planningYear: number =
    JSON.parse(sessionStorage.getItem("planningYear")) ||
    new Date().getFullYear();
  anualCalWorkingYear: number = this.workingYear;
  aggrView: string = AGGR_VIEW.PLANIT;
  currencyView: string = "USD";
  currencySymbol: string = "$";
  documentMode: string = "Home";
  exchangeRate: number = 1;
  market: number = JSON.parse(sessionStorage.getItem("market")) || null;
  currencyExchange: IMarketCurrency;

  private userInfoSource = new BehaviorSubject(this.userInfo);
  private sideNavSelectionSource = new BehaviorSubject(this.globalElement);
  private workingYearSource = new BehaviorSubject(this.workingYear);
  private anualCalWorkingYearSource = new BehaviorSubject(
    this.anualCalWorkingYear
  );
  private planningYearSource = new BehaviorSubject(this.planningYear);
  private aggrViewSource = new BehaviorSubject(this.aggrView);
  private currencyViewSource = new BehaviorSubject(this.currencyView);
  private currencySymbolSource = new BehaviorSubject(this.currencySymbol);
  private exchangeRateSource = new BehaviorSubject(this.exchangeRate);
  private marketSource = new BehaviorSubject(this.market);
  private currencyExchangeSource = new BehaviorSubject(this.currencyExchange);
  private documentModeSource = new BehaviorSubject(this.documentMode);
  private subject = new Subject();
  sideNavSelection = this.sideNavSelectionSource.asObservable();
  getUserInfo = this.userInfoSource.asObservable();
  getWorkingYear = this.workingYearSource.asObservable();
  getAnualCalWorkingYear = this.anualCalWorkingYearSource.asObservable();
  getPlanningYear = this.planningYearSource.asObservable();
  getAggrView = this.aggrViewSource.asObservable();
  getCurrencyCode = this.currencyViewSource.asObservable();
  getCurrencySymbol = this.currencySymbolSource.asObservable();
  getdocumentMode = this.documentModeSource.asObservable();
  getExchangeRate = this.exchangeRateSource.asObservable();
  getMarket = this.marketSource.asObservable();
  getCurrencyExchange = this.currencyExchangeSource.asObservable();
  callFunctionFromHeaderComponent = this.subject.asObservable();

  constructor() {}

  setSideNavSelection(element: Object) {
    this.sideNavSelectionSource.next(element);
  }

  setWorkingYear(year) {
    this.workingYearSource.next(year);
  }

  callgetWorkingYearsFromHeader() {
    this.subject.next();
  }
  setAnualCalenderWorkingYear(year) {
    this.anualCalWorkingYearSource.next(year);
  }

  setMarket(marketId) {
    this.marketSource.next(marketId);
  }

  setPlanningYear(year) {
    this.planningYearSource.next(year);
  }

  setAggrView(viewType: string) {
    this.aggrViewSource.next(viewType);
  }
  setCurrencyView(currencyView: string) {
    this.currencyViewSource.next(currencyView);
  }
  setCurrencySymbolSource(currencySymbol: string) {
    this.currencySymbolSource.next(currencySymbol);
  }
  setdocumentModeSource(documentMode: string) {
    this.documentModeSource.next(documentMode);
  }

  setCurrencyExchange(currEx: IMarketCurrency) {
    this.currencyExchangeSource.next(currEx);
  }

  setCurrencyExchangeRate(xChangeFactor: number) {
    this.exchangeRateSource.next(xChangeFactor);
  }

  setUserInfo(userInfo: any) {
    this.userInfoSource.next(userInfo);
  }
  async blobToString(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as string;
        resolve(result);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsText(blob);
    });
  }

}
