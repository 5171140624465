import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, OnChanges,AfterViewInit,AfterViewChecked,ViewChild,ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { updatePlanWidgetTargetDialog } from 'app/common/components/dialogs/update-plan-widget-target-dialog/update-plan-widget-target-dialog.component';
import { IportfolioTCPTracker } from 'app/common/interfaces/plan';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { PortfolioService } from './../services/portfolio.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { IBreadcrumb } from '../../common/interfaces/common';
import { BREADCRUMBS_LAST_NODE_TYPES, BREADCRUMBS_REFERENCE_TYPES } from '../../constants/app-constants';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { FILE_NAMES, SHAREPOINT_DOC_PATH, TCP_CHART_LEGENDS, TCP_DOC_ID, TCP_PROGRESS_INPUT_KEYS } from 'app/constants/app-constants';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

@Component({
  selector: 'app-portfolio-tcp-tracker',
  templateUrl: './portfolio-tcp-tracker.component.html',
  styleUrls: ['./portfolio-tcp-tracker.component.scss']
})
export class portfolioTCPTracker implements OnInit, OnDestroy,OnChanges,AfterViewInit {
  
  @Input() elementDetails;  
  @Output('dirty')  formDirty = new EventEmitter<boolean>();
  @ViewChildren('lable') mylist;
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  planTargetForm: FormGroup;
  targetData: IportfolioTCPTracker ;
  saveBtnSubscription: Subscription;
  pathDetails : IBreadcrumb;
  isFormInitialized: boolean = false;
  plans: Array<any> = [];
  mjtbdsList: Array<any>  = [];
  campaignsList: Array<any>  = [];
  ngForRenderSubscription: Subscription;
  chart
  chartLoaded: boolean = false;
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private globalElementsService: GlobalElementsService,
    private portfolioService: PortfolioService,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }


  /*........... Initialization Methods........ */
  ngAfterViewInit() {
    this.ngForRenderSubscription = this.mylist.changes.subscribe(() => { 
      if(!this.chartLoaded) {
        this.ngForRendred();
        this.chartLoaded = true;
      }
  });
  }

  ngForRendred() {
    for(let i =0; i < this.plans.length ; i++) {
      this.populateTcpProgressChart(`tcpProgressChart_${i}`, this.plans[i].id, this.targetData.year);
    }
  }
  ngOnInit() {
    this.targetData=this.elementDetails.portfolioTCPTracker;
   this.pathDetails = {
      
      lastNodeName: 'TCP Status',
      pageId: this.targetData.portfolioId,
      pageRefType: BREADCRUMBS_REFERENCE_TYPES.PORTFOLIO,
      lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.TCP_TRACKER
    }
    this.getplanData();
  }

getplanData () {
  this.portfolioService.getPlansByPortfolioId({portfolioId: this.targetData.portfolioId}).subscribe(res =>{
    this.plans = res['data'] ?  res['data'] : [] ;
  }, err => {

  })
}
planChnageEvent(planId) {
  this.portfolioService.getmjtbdAndCampainList({planId: planId, year: this.targetData.year}).subscribe(res =>{
    this.mjtbdsList = res['mjtbdList'] ?  res['mjtbdList'] : [];
    this.campaignsList = res['campaignList'] ?  res['campaignList'] : []; 
  }, err => {

  })
}
  ngOnChanges(){
  }
  intiateForm(data) {
   /* this.planTargetForm = this.fb.group({
      monthlyTarget: [ Number(0).toFixed(2), [Validators.max(100), Validators.min(0)]],
      quarterlyTarget: [Number(0).toFixed(2), [Validators.max(100), Validators.min(0)]],
      annualTarget: [Number(0).toFixed(2), [Validators.max(100), Validators.min(0)]]
    });*/
    if (!this.isFormInitialized) {
      this.planTargetForm = this.fb.group({
        widgets: this.fb.array([]),
      });
      this.isFormInitialized = true;
    }
    
  }
  widgets(): FormArray {
    return this.planTargetForm.get("widgets") as FormArray
  }
initialData(data) {
  this.globalElementsService.getWidgetPlanedData(data).subscribe((res) => {
    if(res) {
      const _data = res.data;
      for(let key in  _data) {
        let newWidgetForm = this.newWidget();
        newWidgetForm.patchValue({
          name: key,
          widgetTargets: _data[key] ? Number(_data[key]).toFixed(2) :  Number(0).toFixed(2)
        });
        this.widgets().push(newWidgetForm);
      }
    }
  });
}
  /*........... functionality Methods........ */
  newWidget(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      widgetTargets:  [ Number(0).toFixed(2), [ Validators.required, Validators.max(100), Validators.min(0)]],
    })
  }
 async getKPIFramworkPercentage(planId, year) {
   const res = await this.portfolioService.getKPIPercentage({planId, year}).toPromise();
   return res && res['kpiPercentage'] ? Number(res['kpiPercentage']).toFixed(2) : 0
  }
  async getTCPArchitecturePercentage(planId, year) {
    const res = await this.portfolioService.getTCPArchitecturePercentage({planId, year}).toPromise();
    return res && res['tcpArchPercentage'] ? Number(res['tcpArchPercentage']).toFixed(2) : 0
   }
   async getOppBreifPercentage(planId, year) {
    const res = await this.portfolioService.getOppBreifPercentage({planId, year}).toPromise();
    return res && res['oppBriefPercentage'] ? Number(res['oppBriefPercentage']).toFixed(2) : 0
   }
   async getCampaignIdeaPercentage(planId, year) {
    const res = await this.portfolioService.getCampaignIdeaPercentage({planId, year}).toPromise();
    return res && res['campaignIdeaPercentage'] ? Number(res['campaignIdeaPercentage']).toFixed(2) : 0
   }
   async getConnectionPercentage(planId, year) {
    const res = await this.portfolioService.getConnectionPercentage({planId, year}).toPromise();
    return res && res['connectionsPercentage'] ? Number(res['connectionsPercentage']).toFixed(2) : 0
   }
  async constructChartData(planId, year) {
    const chartData = [
      {
        category: TCP_CHART_LEGENDS.KPI_FRAMEWORK,
        value: await this.getKPIFramworkPercentage(planId, year),
        full: 100,
      }, {
        category: TCP_CHART_LEGENDS.ARCHITECTURE,
        value: await this.getTCPArchitecturePercentage(planId, year),
        full: 100
      }, {
        category: TCP_CHART_LEGENDS.OPP_BRIEF,
        value:  await this.getOppBreifPercentage(planId, year),
        full: 100
      },
      {
        category: TCP_CHART_LEGENDS.IDEA,
        value: await this.getCampaignIdeaPercentage(planId, year),
        full: 100
      }, {
        category: TCP_CHART_LEGENDS.CONNECTIONS,
        value: await this.getConnectionPercentage(planId, year),
        full: 100
      }
    ];


    return chartData;

  
}
am4themes_repartition(target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [
      am4core.color("#0C2D83"),
      am4core.color("#DA291C"),
      am4core.color("#ED7D31"),
      am4core.color("#0085CA"),
      am4core.color("#7030A0"),
    ];
  }
}

async populateTcpProgressChart(chartid,planId,year) {
  am4core.useTheme(this.am4themes_repartition);

  this.chart = am4core.create(chartid, am4charts.RadarChart);
  let chart = this.chart;
  chart.data = await this.constructChartData(planId, year);
  //this.checkHasChartValues(chart.data);

  chart.startAngle = -90;
  chart.endAngle = 180;
  chart.innerRadius = am4core.percent(30);

  chart.numberFormatter.numberFormat = "#.#'%'";

  let cAxis: any = new am4charts.CategoryAxis()
  let categoryAxis = chart.yAxes.push(cAxis);
  categoryAxis.dataFields.category = "category";
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.grid.template.strokeOpacity = 0;
  categoryAxis.renderer.labels.template.horizontalCenter = "right";
  categoryAxis.renderer.labels.template.fontWeight = 500;
  categoryAxis.renderer.labels.template.fontSize = 10;
  categoryAxis.renderer.labels.template.adapter.add("fill", function (fill, target) {
    return (target.dataItem.index >= 0) ? chart.colors.getIndex(target.dataItem.index) : fill;
  });
  categoryAxis.renderer.minGridDistance = 10;

  let valueAx: any = new am4charts.ValueAxis();
  let valueAxis = chart.xAxes.push(valueAx);
  valueAxis.renderer.grid.template.strokeOpacity = 0;
  valueAxis.min = 0;
  valueAxis.max = 100;
  valueAxis.strictMinMax = true;

  let series1 = chart.series.push(new am4charts.RadarColumnSeries());
  series1.dataFields.valueX = "full";
  series1.dataFields.categoryY = "category";
  series1.clustered = false;
  series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
  series1.columns.template.fillOpacity = 0.08;
  series1.columns.template.strokeWidth = 0;
  series1.columns.template.radarColumn.cornerRadius = 10;

  let series2 = chart.series.push(new am4charts.RadarColumnSeries());
  series2.dataFields.valueX = "value";
  series2.dataFields.categoryY = "category";
  series2.clustered = false;
  series2.columns.template.strokeWidth = 0;
  series2.columns.template.tooltipText = "{category}: [bold]{value}[/]";
  //series2.columns.template.tooltipText.zIndex = Infinity;
  series2.tooltip.label.maxWidth = 150;
  series2.tooltip.label.wrap = true;
  series2.tooltip.label.fontSize = 12;
  series2.columns.template.radarColumn.cornerRadius = 10;

  series2.columns.template.adapter.add("fill", function (fill, target) {
    return chart.colors.getIndex(target.dataItem.index);
  });

  valueAxis.disabled = true;
}
  /*........... Clean up methods........ */

  ngOnDestroy(){
    this.ngForRenderSubscription.unsubscribe();
    //this.globalElementsService.setShowSaveBtn(false);
   // this.formDirty.emit(false);
  }
}
