import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { ITeamMembersReq } from './../../common/interfaces/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { FileUploadDialogComponent } from 'app/common/components/dialogs/file-upload-dialog/file-upload-dialog.component';
import { TcpArchitectureDialogComponent } from 'app/common/components/dialogs/tcp-architecture-dialog/tcp-architecture-dialog.component';
import { IApproverList, IDocument, ITeamMembers } from 'app/common/interfaces/common';
import { ITcpArchitecture, ITcpObject } from 'app/common/interfaces/plan';
import { getCompletedAndFinalizedUsers, getFileBuffer, getDocsFromSharepoint, getGpId , getDocsFromSharepointFilesAPI, isValidUserAccess} from 'app/common/utils/common-util';
import { FINAL_STATUS_LIST, FORM_REFERENCE_TYPES, MINIFIED_STATUS_LIST, SHAREPOINT_DOC_PATH, STATUS_LIST, STATUS_LIST_TYPES } from 'app/constants/app-constants';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import * as moment from 'moment';
import { Subscription } from '../../../../node_modules/rxjs';
import { take } from '../../../../node_modules/rxjs/operators';
import { DomSanitizer } from '../../../../node_modules/@angular/platform-browser';
import { TeamMembersDialogComponent } from '../../common/components/dialogs/team-members-dialog/team-members-dialog.component';
import { environment } from 'environments/environment';
import {MultiselectAutocompleteComponent} from 'app/common/components/multiselect-autocomplete/multiselect-autocomplete.component';
import { SendNotificationsDialogComponent } from './../../common/components/dialogs/send-notifications-dialog/send-notifications-dialog.component';
import {googleAnalyticsCode} from 'app/util';

import { saveAs } from 'file-saver';
import { fileMimeTypes } from 'app/constants/app-constants';
import ab2b64 from 'ab2b64' ;
import { DownloadFileDialogComponent } from 'app/common/components/dialogs/download-file-dialog/download-file-dialog.component';
@Component({
  selector: 'app-tcp-architecture',
  templateUrl: './tcp-architecture.component.html',
  styleUrls: ['./tcp-architecture.component.scss']
})
export class TcpArchitectureComponent implements OnInit, OnDestroy {
  @Input() elementDetails;
  userInfo = JSON.parse(sessionStorage.getItem('user'));

  @Output('dirty') formDirty = new EventEmitter<boolean>()

  USER_INFO = JSON.parse(sessionStorage.getItem('user'));
  tcpForm: FormGroup;
  tcpData: ITcpObject;
  selectedDocument: string;
  docPath: string;
  supportingDocs: Array<IDocument> = [];
  saveBtnSubscription: Subscription;
  tcpStatusList: Array<string> = STATUS_LIST;
  tcpApproverList: Array<IApproverList>;
  selectedTcpApprovers: Array<IApproverList>;
  tcpArchStatus: string = STATUS_LIST_TYPES.DRAFT;
  tcpArchEditableStatus: boolean = true;
  oldStatus:string;
  teamMembersList: Array<ITeamMembers> = [];
  selectedTeamMembers: Array<number> = [];
  selectedTeamMembersAvatar = [];
  tcpPlanId:number;
  tcpYear:number;
  constructor(
    private globalElementsService: GlobalElementsService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }

  /*........... Initialization Methods........ */

  ngOnInit() {
    this.globalElementsService.setShowSaveBtn(this.returnAccess('!==', 6));
    this.globalElementsService.setSaveEnableState(false);
    this.tcpData = this.elementDetails.tcpData.data;
    this.selectedTeamMembers = this.tcpData.teamMembers ? this.tcpData.teamMembers.split(',').map(el => Number(el)) : [];
    const { year, hierarchy } = this.elementDetails.tcpData;
    this.docPath = `${SHAREPOINT_DOC_PATH.TCP}/${year}/${hierarchy}/${SHAREPOINT_DOC_PATH.TCP_ARCHITECTURE}/${SHAREPOINT_DOC_PATH.ATTACHMENTS}`;
    this.getTcpDocList();
    this.intiateForm(this.tcpData);
    this.tcpForm.valueChanges.subscribe(() => {
      this.globalElementsService.setSaveEnableState(this.tcpForm.valid && this.tcpArchEditableStatus);
      this.formDirty.emit(this.tcpForm.dirty);
    })


    this.saveBtnSubscription = this.globalElementsService.getSidePanelSaveEvent.pipe(take(1)).subscribe(() => this.updateTCParchitecture());
    this.getApproversList();
    this.getTeamMembersList();
    this.returnAccess('===', 6) ? this.tcpForm.disable() : null;
    let tagObject =  {
        event: `custom_${environment.environmentName}_Event`,
              eventCategory: `TCP_Architecture_Page`,
              eventAction: "PageView",
              eventLable: `Input TCP Architecture View`,
              customeEvent: `custom_${environment.environmentName}_Event`,
              userId: getGpId(),
              user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  updateStatusList() {

    this.tcpForm.get('tcpApprover').setValidators([Validators.required]);
    let approversIds: Array<any> = [];
    if (this.elementDetails.tcpData.data.approversList) {
      approversIds = this.elementDetails.tcpData.data.approversList.split(',');
    }

    let isMatched = false;

    // if (this.USER_INFO.roleId <= 3) {
    //   isMatched = true;
    // } else {
    //   isMatched = false;
    // }

    approversIds.forEach(id => {
      if (this.USER_INFO.id == id) {
        isMatched = true;
      }
    });

    if (this.tcpArchStatus === STATUS_LIST_TYPES.DRAFT || this.tcpArchStatus == null) {
      this.tcpForm.get('tcpApprover').setValidators([]);
      this.tcpStatusList = MINIFIED_STATUS_LIST;
      this.tcpArchEditableStatus = true;
    } else if (isMatched && this.tcpArchStatus === STATUS_LIST_TYPES.COMPLETE) {
      this.tcpArchEditableStatus = true;
      this.tcpStatusList = STATUS_LIST;
    } else if (isMatched && this.tcpArchStatus === STATUS_LIST_TYPES.FINAL) {
      this.tcpArchEditableStatus = true;
      this.tcpStatusList = FINAL_STATUS_LIST;
    } else {
      this.tcpArchEditableStatus = false;
      this.tcpStatusList = STATUS_LIST;
    }
    this.tcpForm.get('tcpApprover').updateValueAndValidity();
    this.globalElementsService.setSaveEnableState(this.tcpForm.valid);
  }

  tcpArchStatusChange() {
    if (this.tcpArchStatus === STATUS_LIST_TYPES.DRAFT) {
      this.tcpForm.get('tcpApprover').setValidators([]);
    } else {
      this.tcpForm.get('tcpApprover').setValidators([Validators.required]);      
    }
    this.tcpForm.get('tcpApprover').updateValueAndValidity();
    this.globalElementsService.setSaveEnableState(this.tcpForm.valid);
  
  }

  intiateForm(data) {

    this.tcpForm = this.fb.group({
      bigBrandIdea: [data ? data.bigBrandIdea : '', Validators.required],
      brandProblemToSolve: [data ? data.brandProblemToSolve : ''],
      tcpApprover: ['']
    });
    this.tcpArchStatus = data && data.tcpArchStatus || STATUS_LIST_TYPES.DRAFT;
    this.oldStatus = data ? data.tcpArchStatus : null;

    this.updateStatusList();
  }

  sendData(){
    return this.tcpApproverList;
  }
  
  selectChange = (event: any) => {
    this.selectedTcpApprovers = event.data;
    this.tcpForm.get("tcpApprover").patchValue(this.selectedTcpApprovers);
  };

  /*........... functionality Methods........ */


  addTeamMembers(){
    
    let tmDialogRef;
    tmDialogRef = this.dialog.open(TeamMembersDialogComponent, {
      data: {
      teamMembers : this.teamMembersList,
      selectedMembers : this.selectedTeamMembers
      },
      height: '400px',
      minWidth: '500px',
      disableClose:true
    });

    tmDialogRef.afterClosed().subscribe((res: any) => {
      this.selectedTeamMembers = res;
      this.generateTeamMemeberAvatars(); 
    });
  }

  generateTeamMemeberAvatars(){
    if(this.teamMembersList){
      const selectedUsers = this.teamMembersList.filter(tm => this.selectedTeamMembers.includes(tm.userId));
      this.selectedTeamMembersAvatar = selectedUsers.map(user => {
        const initials = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
        let avatar;
        if (user.userAvatar) {
          let byteArray = new Uint8Array(user.userAvatar.data);
          const charString = byteArray.reduce((data, byte) => {
            return data + String.fromCharCode(byte);
          }, '');
          let base64String = btoa(charString);
          avatar = this.sanitizer.bypassSecurityTrustUrl('data:image/jpg;base64, ' + base64String);
        }
        return {
          initials,
          avatar
        }
      })
    }
  }

  tcpFormErrorHandeling(control: string, error: string) {
    return this.tcpForm.controls[control].hasError(error);
  }

  updateTCParchitecture() {
    let dialogRef;
    dialogRef = this.dialog.open(TcpArchitectureDialogComponent, {
      data: this.constructTcpArchitectureObj(),
      minWidth: '500px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.globalElementsService.setSidePanelCancelEvent();
      this.dialog.closeAll();
    });
    let tagObject =  {
event: `custom_${environment.environmentName}_Event`,
              eventCategory: `TCP_Architecture_Page`,
              eventAction: "Save TCP Architecture",
              eventLable: `Input TCP Architecture Save Button Clicked`,
              customeEvent: `custom_${environment.environmentName}_Event`,
              userId: getGpId(),
              user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  fileChangeEvent(event: any): void {
    let fileList: FileList = event.target.files;
    let fileName = fileList[0].name;
    getFileBuffer(fileList[0]).then((fileBufferArray: ArrayBuffer) => {
      let data = fileBufferArray;
      let docObj = {
        body: {
          data,
          fileName: fileName,
          uploadLocation: this.docPath
        }
      };
      let dialogRef;
      dialogRef = this.dialog.open(FileUploadDialogComponent, {
        data: { fileType: '', docObj },
        minWidth: '500px',
        disableClose: true
      });
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res.status) {
          this.getTcpDocList();
        }
        this.dialog.closeAll();
      });
    }, (error) => {
      console.log('Error: ', error);
    });

  }
  
  async getTcpDocList() {
    this.supportingDocs = await getDocsFromSharepointFilesAPI(this.docPath, this.globalElementsService);
  }

  

  tcpApproverChange(apprvData: Array<IApproverList>) {
    this.selectedTcpApprovers = apprvData;
  }


//   downloadFile(path:string){
//     let fileName = path.split('/').pop();
//     const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);  
//     this.globalElementsService.downloadFile({fileName: fileName, path: path.split('/Shared Documents/')[1]}).subscribe(response => {
//       this.downloadFileFromBase64(fileName,response['thumbnailb64data'], fileMimeTypes[extension] );
//       // const byteCharacters = window.atob(response['thumbnailb64data']);
//       // const downloadLink = document.createElement("a");
//       // downloadLink.download = fileName.replace(/ /g,'');
//       // downloadLink.href = source;
//       // document.body.appendChild(downloadLink);
//       // downloadLink.click();
//       // document.body.removeChild(downloadLink);
//       // const byteNumbers = new Array(byteCharacters.length);
//       // for (let i = 0; i < byteCharacters.length; i++) {
//       //   byteNumbers[i] = byteCharacters.charCodeAt(i);
//       // }
//       // const byteArray = new Uint8Array(byteNumbers);
//       // const blob = new Blob([byteArray], {type: `data:${fileMimeTypes[extension]}`});
//       // saveAs(blob, fileName.replace(/ /g,''));
//   })
// }
downloadFile(path:string){
  let fileName = path.split('/').pop();
  path = path ? path.split('/Shared Documents/')[1] : '';
  let docPath = path.split(`/${fileName}`)[0];
  const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
  const downloadObj = {
    fileName:fileName,
    path:path,
    docPath:docPath,
    extension:extension
  } 
  let dialogRef;
      dialogRef = this.dialog.open(DownloadFileDialogComponent, {
        data: downloadObj,
        minWidth: '500px',
        disableClose: true
      });
}
downloadFileFromBase64(fileName, b64encodedString: string, contentType ) {
  if (b64encodedString) {
    var blob = this.base64ToBlob(b64encodedString, contentType);
    saveAs(blob, fileName);
  }
}

public base64ToBlob(b64Data, contentType='', sliceSize=512) {

  let byteArrays = ab2b64.b642ab(b64Data)
  return new Blob([byteArrays], {type: contentType});
}

  deleteFile(doc: IDocument) {
    const docObj = {
      isDelete: true,
      fileName: doc.fileName,
      docPath: doc.path.split('/Shared Documents/')[1]
    };
    let dialogRef;
    dialogRef = this.dialog.open(FileUploadDialogComponent, {
      data: { fileType: '', docObj },
      minWidth: '500px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res.status) {
        this.getTcpDocList();
      }
      this.dialog.closeAll();
    });
  }

  /*........... Service/API calls........ */

  // downloadFile(doc: IDocument) {
  //   const docObj = {
  //     fileName: doc.fileName,
  //     docPath: doc.path.split('/Shared Documents/')[1].split(`/${doc.fileName}`)[0]
  //   };
  //   this.globalElementsService.downloadSharepointFile(docObj).subscribe((res: any) => {
  //     console.log(res);
  //     let a = document.createElement("a");
  //     a.href = res.thumbnailb64data.toString();
  //     a.target="_blank";
  //     a.download = '2021_Pepsi_purposeful_positioning.pptx';
  //     a.click();
  //   });
  // }

  getTeamMembersList(){
    const { planId, year } = this.elementDetails.tcpData;
    let teamMemberReq : ITeamMembersReq = {
      referenceId: planId,
      referenceType: FORM_REFERENCE_TYPES.PLAN,
      year: year
    }

    this.globalElementsService.getTeamMembersList(teamMemberReq).subscribe((res:Array<ITeamMembers>)=>{
      this.teamMembersList = res ? res.sort((a, b)=> a.name >= b.name ? 1 : -1) : [];
      const uniqueIds = [];
      if(this.teamMembersList){
        this.teamMembersList = res.filter(el => {
          const isDuplicate = uniqueIds.includes(el.userId);
          if (!isDuplicate) {
            uniqueIds.push(el.userId);
        
            return true;
          }
          return false;
        })
      }
      this.generateTeamMemeberAvatars(); 
    })
    
  }
  sendMessageToCollaborators(){
    let data = this.constructTcpArchitectureObj();
    let snDialogRef;
      snDialogRef = this.dialog.open(SendNotificationsDialogComponent, {
        data: {
          data :data,
          type:FORM_REFERENCE_TYPES.TCP_ARCH,
          },
        height: '150px',
        width: '500px',
        disableClose:true
      });

    snDialogRef.afterClosed().subscribe((res: any) => {
    });
    
  }


  getApproversList() {
    const { planId, year } = this.elementDetails.tcpData;
    let obj = {
      referenceId: planId,
      referenceType: FORM_REFERENCE_TYPES.PLAN,
      year: year
    }
    this.globalElementsService.getApproversList(obj).subscribe((res: Array<IApproverList>) => {
      this.tcpApproverList = res;
      const tcpArcData = this.elementDetails.tcpData.data;
      const approvers = tcpArcData.approversList ? tcpArcData.approversList.split(',') : [];
      this.selectedTcpApprovers = [];
      approvers.forEach((id: number) => {
        const tcpApprover = this.tcpApproverList.find((el: IApproverList) => el.userId == id);
        if(tcpApprover){
          this.selectedTcpApprovers.push(tcpApprover);
        } else {
          this.selectedTcpApprovers = [];
        }
      });
      this.tcpForm.get("tcpApprover").patchValue(this.selectedTcpApprovers);
    })
  }


  constructTcpArchitectureObj(): ITcpArchitecture {
    const formData = this.tcpForm.value;
    let tcpApprover = formData.tcpApprover && formData.tcpApprover.map((obj: IApproverList) => obj.userId);
    const obj = { ...this.tcpData };
    const { completedByUserId, finalizedByUserID } = getCompletedAndFinalizedUsers(this.oldStatus, this.tcpArchStatus, obj);
    const { planId, year } = this.elementDetails.tcpData;
    let tcpPlanId;
    let tcpYear;
    if(!obj['planId']) {
      tcpPlanId = planId;
      tcpYear = year;
    } else {
      tcpPlanId = obj['planId'];
      tcpYear = obj['year'];
    }
    let tcpObj: ITcpArchitecture = {
      archFormId: obj['archFormId'],
      planId: tcpPlanId,
      year: tcpYear,
      alertURL: `/plan/${tcpPlanId}/${tcpYear}`,
      currentUserID: this.userInfo.id,
      archInfo: {
        activationCalendarFormId: obj['activationCalendarFormId'],
        activationCalendarDocPath: obj['activationCalendarDocPath'],
        bigBrandIdea: formData.bigBrandIdea,
        brandProblemToSolve: formData.brandProblemToSolve,
        behavioralGrowthTargets: obj['behavioralGrowthTargets'],
        documentsPath: obj['documentsPath'],
        calendarAttchmentsPath: obj['calendarAttchmentsPath'],
        teamMembers: this.selectedTeamMembers.toString(),
        completedByUserId: completedByUserId,
        finalizedByUserID: finalizedByUserID,
        approversList: tcpApprover ? tcpApprover.toString() : '',
        tcpArchStatus: this.tcpArchStatus || null,
      }
    }
    return tcpObj;
  }

  returnAccess(_oprator, conditionRoleId) {
    const { planId } = this.elementDetails.tcpData;
    return isValidUserAccess(conditionRoleId, _oprator,null, planId);
  }
  /*........... Clean up methods........ */

  ngOnDestroy() {
    this.globalElementsService.setShowSaveBtn(false);
    this.saveBtnSubscription.unsubscribe();
    this.formDirty.emit(false);
    let tagObject =  {
          event: `custom_${environment.environmentName}_Event`,
              eventCategory: `TCP_Architecture_Page`,
              eventAction: "PageExit",
              eventLable: `Input TCP Architecture Page Exit`,
              customeEvent: `custom_${environment.environmentName}_Event`,
              userId: getGpId(),
              user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

}
