import { Component, OnInit } from '@angular/core';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { MatPaginator, MatSort, MatTableDataSource } from '../../../../node_modules/@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { ChannelslistTemplateUploadComponent } from 'app/common/components/dialogs/channelslist-template-upload/channelslist-template-upload.component';
import { DeleteChannelDialogComponent } from 'app/common/components/dialogs/delete-channel-dialog/delete-channel-dialog.component';
import { EditChannelDialogComponent } from 'app/common/components/dialogs/edit-channel-dialog/edit-channel-dialog.component';

@Component({
  selector: 'app-manage-commstasks-touchpoints-channels',
  templateUrl: './manage-commstasks-touchpoints-channels.component.html',
  styleUrls: ['./manage-commstasks-touchpoints-channels.component.scss']
})
export class ManageCommstasksTouchpointsChannelsComponent implements OnInit {
  marketInfo: any [];
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  selectedMarket: string;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ['Channel_Name','Uploaded_By','Updated_By','Created_At','Action'];
 isLoading = true;
    constructor(
      private globalElementsService: GlobalElementsService,
      public dialog: MatDialog,
    ) { }

  ngOnInit() {
    this.getMarkets();
  }
  getMarkets() {
    this.globalElementsService.getAllMarket(this.userInfo.gpid).subscribe(res => {
      if(res['markets'].length > 0){
        this.marketInfo = res['markets'].sort((a, b)=> a.displayName >= b.displayName ? 1 : -1);
      } else {
        this.marketInfo = [];
      }
    })

  }
  marketChangeEvent(marketId){
    this.globalElementsService.getChannelsListbyMarket(this.selectedMarket).subscribe(response => {
      if(response['result'].length > 0){
        this.dataSource = new MatTableDataSource<any>(response['result']);
      } else {
        this.dataSource = new MatTableDataSource<any>([]);
      }
    })
  }
  openUploadDialogmain(){
    if(this.selectedMarket == null){
      return false;
    } else {
      const dialogRef = this.dialog.open(ChannelslistTemplateUploadComponent, {
         
        minWidth: '500px',
        maxHeight: '700px',
        data: this.selectedMarket,
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(res => {
        this.marketChangeEvent(this.selectedMarket);
      })
    }
  }
  deleteChannel(id){
    const dialogRef = this.dialog.open(DeleteChannelDialogComponent, {
      data: id,
      minWidth: '500px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.marketChangeEvent(this.selectedMarket);
    });
  }
  editChannel(channelId,marketId,channelName){
    const data = {
      channelId,
      marketId,
      channelName
    }
    const dialogRef = this.dialog.open(EditChannelDialogComponent, {
      data: data,
      minWidth: '500px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.marketChangeEvent(this.selectedMarket);
    });
  }
}
