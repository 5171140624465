import { Component, Input, OnDestroy, OnInit, EventEmitter, Output } from '@angular/core';
import { PAGE_MODES, FINAL_STATUS_LIST, FORM_REFERENCE_TYPES, MINIFIED_STATUS_LIST, SHAREPOINT_DOC_PATH, STATUS_LIST, STATUS_LIST_TYPES, TCP_DOC_ID, GLOBAL_ELEMENTS } from 'app/constants/app-constants';
import { environment } from 'environments/environment';
const BASE_API_URL = `${environment.api}`;

@Component({
  selector: 'app-kpi-framework-ppt',
  templateUrl: './kpi-framework-ppt.component.html',
  styleUrls: ['./kpi-framework-ppt.component.scss']
})
export class KPIFrameworkPPTComponent implements OnInit {
  @Output() changePageModeEmitter = new EventEmitter
  isLoading = true;
  url = `${BASE_API_URL}/v3/PPT_Templates_TCP_KPI_Framework/CANVAS_for_TCP_KPI_Framework.pptx`;
  // url = "https://canvas-dev2.pepsico.com/api/v3/PPT_Templates_TCP_KPI_Framework/CANVAS_for_TCP_KPI_Framework.pptx";

  constructor() { }

  ngOnInit() {
  }
  contentLoaded() {
    console.log('File loaded');
    this.isLoading = false;
  }

  backToHome() {
    this.changePageModeEmitter.emit(PAGE_MODES.HOME)
  }

}
