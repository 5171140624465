import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, concatMap, delay, retryWhen, tap } from "rxjs/operators";
import { AppError } from "../error/app-error";
import { isNil } from "lodash";
import { ErrorHandlerService } from "../error/error-handler.service";

@Injectable({
  providedIn: "root",
})
export class CoreInterceptor implements HttpInterceptor {
  constructor(private errorHandlerService: ErrorHandlerService) {}
  // function which will be called for all http callss
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const updatedRequest = request.clone({});
    return next.handle(updatedRequest).pipe(
      tap((httpEvent: HttpEvent<unknown>) => {
        if (httpEvent.type === 0) {
          return;
        }
      }),
      catchError((httpError) => {
        if (httpError instanceof HttpErrorResponse) {
          console.log("httpError.url", httpError.url);
          if (httpError.error instanceof ErrorEvent) {
            console.error("Error Event", httpError.error);
          } else {
            console.log(
              `Error status : ${httpError.status} ${httpError.statusText}`
            );
            this.callhandleError(request, httpError);
            return throwError(httpError);
          }
        }
        this.callhandleError(request, httpError);
        return throwError(httpError);
      })
    );
  }

  getEndpointConfig(req: HttpRequest<unknown>): {
    showApiErrorDialog: boolean;
    req: HttpRequest<unknown>;
  } {
    let headers: HttpHeaders = req.headers;
    let showApiErrorDialog = false;

    if (!isNil(headers.has("v"))) {
      showApiErrorDialog = JSON.parse(
        headers.get("showApiErrorDialog") as string
      ) as boolean;
      headers = headers.delete("showApiErrorDialog");
    } else {
      showApiErrorDialog = false;
    }
    req = req.clone({ headers });
    return {
      showApiErrorDialog,
      req,
    };
  }

  callhandleError(request: HttpRequest<unknown>, error: any) {
    const endpointConfig = this.getEndpointConfig(request);
    let showApiErrorDialog = endpointConfig.showApiErrorDialog;
    const appError: AppError = new AppError(error);
    if (showApiErrorDialog && error.status === 400) {
      this.errorHandlerService.handleError(
        appError.message,
        "Bad Request",
        "Ok"
      );
    } else if (showApiErrorDialog && error.status === 476) {
      this.errorHandlerService.handleError(
        appError.message,
        appError.message,
        "Ok"
      );
    } else if (showApiErrorDialog && error.status !== 400) {
      this.errorHandlerService.handleError(
        appError.message,
        "Error in response",
        "Ok"
      );
    }
  }
}
