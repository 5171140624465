import { Component, OnInit, Input, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { jsPDF } from 'jspdf';
import * as moment from 'moment';
import { IMjtbds, ICampaign } from 'app/common/interfaces/plan';
import { IApproverList } from 'app/common/interfaces/common';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { fileMimeTypes } from 'app/constants/app-constants';
import ab2b64 from 'ab2b64' ;
@Component({
  selector: 'app-tcp-progress-pdf',
  templateUrl: './tcp-progress-pdf.component.html',
  styleUrls: ['./tcp-progress-pdf.component.scss']
})
export class TcpProgressPdfComponent {

  @Input() tcpChartData;
  @Input() selectedMjtbd: IMjtbds;
  @Input() year: number;
  @Input() mjtbdData: Array<IMjtbds>;
  @Input() selectedCampaign: ICampaign;

  uniqueBehavioursGrowth: Array<string>;
  uniqueCommsObjectives: Array<string>;
  campaignGoals: Array<string> = [];
  optimizationKPIs: Array<string>;
  successKPIs: string;
  bigBrandIdea: string;
  brandProblemToSolve: string;
  associatedFormat: Array<string>;
  audiencePriority: Array<string>;

  constructor(private http: HttpClient) { }

  getFormattedDate(dateString) {
    return moment(dateString).isValid() ? moment(dateString).format('lll') : 'N/A';
  }

  printDate() {
    return moment().format('lll');
  }

  downloadHtmltoPDF(id: string, fileName:string) {
    const data = document.getElementById(id) as HTMLElement;
    let pdf = new jsPDF({ orientation: 'l' });
    pdf.html(data.innerHTML, {margin:2, }).then(() => {
      pdf.save(fileName);
    });
  }

  getApproversName(approvers: Array<IApproverList>) {
    const approversName = approvers ? approvers.map((obj: IApproverList) => obj.name) : [];
    return approversName.join(',');
  }

  // downloadPdfFromLink(file, fileName:string) {
  //   this.http.get(file.path, { responseType: 'blob' })
  //     .subscribe(res => {
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         var a = document.createElement("a");
  //         a.href = reader.result.toString();
  //         a.download = fileName;
  //         a.click();
  //       }
  //       reader.readAsDataURL(res);
  //     });
  // }
  
  async downloadPdfFromLink(ulr, fileName:string) {
    // const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
    // const byteCharacters = window.atob(ulr); 
    // const downloadLink = document.createElement("a");
    // downloadLink.download = fileName.replace(/ /g,'');
    // downloadLink.href = ulr;
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);

    // const byteNumbers = new Array(byteCharacters.length);
    //   for (let i = 0; i < byteCharacters.length; i++) {
    //     byteNumbers[i] = byteCharacters.charCodeAt(i);
    //   }
    //   const byteArray = new Uint8Array(byteNumbers);
    //   const blob = new Blob([byteArray], {type: `data:${fileMimeTypes[extension]}`});
    //   saveAs(blob, fileName.replace(/ /g,''));

    var res = await fetch(ulr);
    var blob = await res.blob();
    saveAs(blob, fileName);
  }

  
  

  populateKpiFrameworkData(selectedCampaign: ICampaign) {
    let behavioursGrowth = [];
    let commsObjectives = [];
    this.campaignGoals = [];
    this.optimizationKPIs = [];
    this.mjtbdData.forEach((mjtbd) => {
      if (mjtbd.mjtbdForm.brandObjective) {
        behavioursGrowth = [...behavioursGrowth, ...mjtbd.mjtbdForm.brandObjective.split(',')];
      }
      if (mjtbd.mjtbdForm.commsObjective) {
        commsObjectives = [...commsObjectives, ...mjtbd.mjtbdForm.commsObjective.split(',')];
      }
    });
    this.uniqueBehavioursGrowth = [...new Set(behavioursGrowth)];
    this.uniqueCommsObjectives = [...new Set(commsObjectives)];
    this.successKPIs = selectedCampaign.oppForm ? selectedCampaign.oppForm.successKPIs : '';
    if (selectedCampaign) {
      selectedCampaign.campaignIdeas.forEach(campaignIdea => {
        if (campaignIdea.campaignGoal) {
          this.campaignGoals = [...this.campaignGoals, ...campaignIdea.campaignGoal.split(',')];
        }
      });
    }

    if (this.tcpChartData && this.tcpChartData.commsTasks.length != 0) {
      this.tcpChartData.commsTasks.forEach(commsTask => {
        if (commsTask.scenarioData.length != 0) {
          commsTask.scenarioData.forEach(scenarioItem => {
            if (scenarioItem.channels.length != 0) {
              scenarioItem.channels.forEach(channel => {
                if (channel.optimizationKPIs) {
                  this.optimizationKPIs.push(channel.optimizationKPIs);
                }
              });
            }
          })
        }
      });
    }

  }

  populateArchitecturePdfData(tcpData) {
    this.associatedFormat = [];
    this.audiencePriority = [];
    this.bigBrandIdea = tcpData ? tcpData.bigBrandIdea : '';
    this.brandProblemToSolve = tcpData ? tcpData.brandProblemToSolve : '';
    this.mjtbdData.forEach((mjtbd) => {
      if (mjtbd.mjtbdForm.associatedFormat) {
        this.associatedFormat.push(mjtbd.mjtbdForm.associatedFormat);
      }
      if (mjtbd.mjtbdForm.audiencePriority) {
        this.audiencePriority.push(mjtbd.mjtbdForm.audiencePriority);
      }
    });

  }


}
