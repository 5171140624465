import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { NgxPowerBiService } from "ngx-powerbi";
import { IEmbedConfiguration, models } from "powerbi-client";
import { PowerBIDashboardService } from "app/common/api/service/powerbidashboard.service";
import { eToken } from "app/common/interfaces/powerbitoken";
import { interval } from "rxjs";
import { VisibilityService } from "app/global-elements/services/visibility.service";
@Component({
  selector: "powerbi-reports-dashboard",
  templateUrl: "./powerbi-reports-dashboard.component.html",
  styleUrls: ["./powerbi-reports-dashboard.component.scss"],
})
export class powerbiReportsDashboardComponent implements OnInit {
  @Input() elementDetails;
  powerbiReportsDashboardComponent;
  portfolioId: string;
  portfolioName: string;
  isLoading: boolean = false;
  message: string;
  reportToken: string;
  accToken: string;
  embedReportToken: string;
  embedRepToken: eToken;
  embedReportTokenExpiry: string;
  report: any;
  config: IEmbedConfiguration;
  private powerBiService: NgxPowerBiService;
  private pbiContainerElement: HTMLElement;
  MINUTES_BEFORE_EXPIRATION = 10;
  // Set the refresh interval time to 30 seconds
  INTERVAL_TIME = 30000;
  isVisible = true;
  configList: any;
  portfolioType: any;
  embedReportType: string;
  currentReport: any;
  constructor(
    private sanitizer: DomSanitizer,
    protected googleTagManagerService: GoogleTagManagerService,
    private powerBIDashboardService: PowerBIDashboardService,
    private visibilityService: VisibilityService
  ) {
    this.powerBiService = new NgxPowerBiService();
  }

  ngOnInit() {
    this.isLoading = true;
    this.powerBIDashboardService.castToken.subscribe(
      (accToken) => (this.accToken = accToken)
    );
    this.powerBIDashboardService.castembedToken.subscribe(
      (ereportToken: eToken) => {
        this.embedRepToken = ereportToken;
        console.log("inside media this.embedRepToken", this.embedRepToken);
        if (
          this.embedRepToken.token == "" ||
          this.embedRepToken.token == null
        ) {
          this.powerBIDashboardService.getAuthToken();
          setTimeout(() => {
            this.updateToken();
          }, 18000);
        }
        this.checkTokenAndUpdate(this.embedRepToken.expiry);
    }
    );
    this.checkTokenAndUpdate(this.embedRepToken.expiry);
    if (this.elementDetails) {
      this.portfolioName = this.elementDetails.portfoliodata.portfolioName;
      this.portfolioId = this.elementDetails.portfoliodata.portfolioId;
      this.portfolioType = this.elementDetails.portfoliodata.portfolioType;
      this.configList = this.powerBIDashboardService.embedReportConfigList;
      console.log("portfolioType", this.portfolioType);
      this.config = this.getReportConfig();
    }
    this.pbiContainerElement = <HTMLElement>(
      document.getElementById("custom-pbi-container")
    );
    if (this.config) {
      this.powerBiService.embed(this.pbiContainerElement, this.config);

      const report = this.powerBiService.get(this.pbiContainerElement);

      report.on("loaded", () => {
        this.isLoading = false;
        console.log("Power BI Report Loaded");
        if (
          this.currentReport.bookmarkId != null &&
          this.currentReport.bookmarkId != ""
        ) {
          console.log("Bookmark ID:: ", this.currentReport.bookmarkId);
          this.applyBookmark(report, this.currentReport.bookmarkId);
        }

        // this.setupBookmarksManager(report);
      });
      report.on("error", () => {
        report.reload();
        if (this.embedReportType == "MTD") {
          console.log(
            "Error in rendering the Media Transparency Dashboard Report"
          );
        } else {
          console.log(
            "Error in rendering the Creative Performance Dashboard Report"
          );
        }
      });
      console.log(report);
    }

    this.setInterval();
    this.visibilityService.visibilityChange$.subscribe((isVisible) => {
      this.isVisible = isVisible;
      if (this.isVisible) {
        this.checkTokenAndUpdate(this.embedRepToken.expiry);
      }
    });
  }
  applyBookmark(report: any, bookmarkName: string) {
    const bookmarksManager = report.bookmarksManager;

    bookmarksManager
      .apply(bookmarkName)
      .then(() => {
        console.log(`Bookmark ${bookmarkName} applied successfully`);
      })
      .catch((error) => {
        console.error("Error applying bookmark:", error);
      });
  }
  setupBookmarksManager(report: any) {
    const bookmarksManager = report.bookmarksManager;

    // Retrieve and log bookmarks
    bookmarksManager
      .getBookmarks()
      .then((bookmarks) => {
        bookmarks.forEach((bookmark) => {
          console.log(`Bookmark Name: ${bookmark.name}`);
          console.log(`Bookmark State: ${bookmark.state}`);
        });
      })
      .catch((error) => {
        console.error("Error retrieving bookmarks:", error);
      });
  }
  getReportConfig() {
    let config: IEmbedConfiguration;
    let reportFouldforPortfolio = false;
    this.configList.forEach((reportsDetail) => {
      if (
        this.portfolioId == reportsDetail.portfolioId &&
        reportsDetail.reportType == this.elementDetails.type
        // ||reportsDetail.reportType == this.elementDetails.type && reportsDetail.reportType == "MTD"
      ) {
        reportFouldforPortfolio = true;
        this.embedReportType = reportsDetail.reportType;
        console.log("currentReport reportsDetail161 :: ", reportsDetail);
        this.currentReport = reportsDetail;
        config = {
          type: "report",
          id: reportsDetail.reportId,
          embedUrl: reportsDetail.embedUrl,
          accessToken: this.embedRepToken.token,
          tokenType: models.TokenType.Embed,
          permissions: models.Permissions.Read,
          viewMode: models.ViewMode.View,
          settings: {
            filterPaneEnabled: true,
            navContentPaneEnabled: false,
            panes: {
              bookmarks: {
                visible: true,
              },
            },
          },
        };
        return;
      }
    });

    if (!reportFouldforPortfolio) {
      this.configList.forEach((reportsDetail) => {
        if (
          reportsDetail.reportType == this.elementDetails.type &&
          reportsDetail.portfolioId == "99999" &&
          reportsDetail.reportType == "MTD"
        ) {
          // reportFouldforPortfolio = true;
          this.embedReportType = reportsDetail.reportType;
          console.log("currentReport reportsDetail161 :: ", reportsDetail);
          this.currentReport = reportsDetail;
          config = {
            type: "report",
            id: reportsDetail.reportId,
            embedUrl: reportsDetail.embedUrl,
            accessToken: this.embedRepToken.token,
            tokenType: models.TokenType.Embed,
            permissions: models.Permissions.Read,
            viewMode: models.ViewMode.View,
            settings: {
              filterPaneEnabled: true,
              navContentPaneEnabled: false,
              panes: {
                bookmarks: {
                  visible: true,
                },
              },
            },
          };
        }
      });
    }
    this.config = config;
    return this.config;
  }

  setInterval() {
    interval(180000) // Check every 3 mins (adjust as needed)
      .subscribe(() => {
        if (this.embedRepToken && this.embedRepToken.expiry) {
          this.checkTokenAndUpdate(this.embedRepToken.expiry);
        }
      }),
      (error) => {
        console.error("Error refreshing Power BI tokens:", error);
      };
  }
  checkTokenAndUpdate(tokenExpiration) {
    // Get the current time,
    console.log("tokenExpiration::", tokenExpiration);
    const currentTime = Date.now();
    const expiration = Date.parse(tokenExpiration);
    console.log("currentTime", currentTime);
    const currentTimeInNorthCentral = moment
      .tz("America/Chicago")
      .utc()
      .valueOf();
    console.log("currentTimeInNorthCentral", currentTimeInNorthCentral);

    console.log("expiration", expiration);
    // Time until token expiration in milliseconds
    const timeUntilExpiration =
      expiration - moment.tz("America/Chicago").utc().valueOf();
    const timeToUpdate = this.MINUTES_BEFORE_EXPIRATION * 60 * 1000;
    console.log("timeUntilExpiration", timeUntilExpiration);
    console.log("timeToUpdate", timeToUpdate);
    // Update the token if it is about to expired
    if (timeUntilExpiration > 0 && timeUntilExpiration <= timeToUpdate) {
      this.powerBIDashboardService.getAuthToken();
      console.log("this.embedRepToken.token", this.embedRepToken.token);
      setTimeout(() => {
        this.updateToken();
      }, 18000);
    }
  }
  updateToken() {
    // Get a reference to the embedded report HTML element
    if (this.pbiContainerElement == null) {
      let report = this.powerBiService.get(this.pbiContainerElement);
      console.log("Power BI report:: ", report);
      console.log("insideMedia Dash timeout", this.embedRepToken.token);
      // Refresh the token when it's about to expire
      console.log("this.embedRepToken.token", this.embedRepToken.token);
      report.setAccessToken(this.embedRepToken.token);
      report.reload();
    }
  }

  ngOnDestroy(): void {}
}
