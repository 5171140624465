import { Component, OnInit, Inject } from '@angular/core';
import { PlanService } from 'app/plan/services/plan.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileUploadDialogComponent } from '../file-upload-dialog/file-upload-dialog.component';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';

@Component({
  selector: 'app-update-widget-value-type-dialog',
  templateUrl: './update-widget-value-type-dialog.component.html',
  styleUrls: ['./update-widget-value-type-dialog.component.scss']
})
export class updateWidgetValueTypeDialog implements OnInit {

  message: string = "Saving widget configuration... Please Wait";
  status=false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private planService: PlanService,
    private dialogRef: MatDialogRef<updateWidgetValueTypeDialog>,
    private globalElementsService: GlobalElementsService
  ) { }

  ngOnInit() {
    this.updateTargetBrands();
  }


  updateTargetBrands() {    
    this.globalElementsService.updateWidgetConfiguration(this.data).subscribe((res:any)=>{
      if(res){
        this.message = "Saved Successfully";
        this.status=true;
      }   
    }, () => {
      this.message = "Error while updating widget targets";
      this.status=false;
    })
    
  }

  close(){
    this.dialogRef.close({ status: this.status })
    this.status ? this.globalElementsService.setSidePanelCancelEvent() : null;
  }

}
