import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'apiError-dialog',
  templateUrl: './apiError-dialog.component.html'
})
export class ApiErrorDialogComponent implements OnInit {


    ngOnInit(): void {
    }

 constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ApiErrorDialogComponent>
    ) {
     }

    onOkClick() {
        this.dialogRef.close({});
    }

}
