import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpCacheService } from '../cache/http-cache.service';
import { HttpApiHelperService } from './helper/http-api-helper.service';
import { IHttpApiRequest } from './interface/ihttp-api-request';
import { IHttpApiRequestOptions } from './interface/ihttp-api-request-options';
@Injectable({
  providedIn: 'root'
})
export class HttpApiService {
  constructor(
    private http: HttpClient,
    private httpApiHelperService: HttpApiHelperService,
    private httpCacheService: HttpCacheService
  ) {}

  public get<T>(request: IHttpApiRequest): Observable<T> {
    const url = this.httpApiHelperService.getUrl(request);
    const options: IHttpApiRequestOptions =
      this.httpApiHelperService.getHttpOptions(request);
    return this.http.get<T>(url, options);
  }
  public post<T>(request: IHttpApiRequest): Observable<T> {
    const url = this.httpApiHelperService.getUrl(request);
    const options: IHttpApiRequestOptions =
      this.httpApiHelperService.getHttpOptions(request);
    const isMock = this.httpApiHelperService.isMock(request);
    if (!isMock) {
      return this.http
        .post<T>(url, request.data ? request.data : null, options)
        .pipe(
          map((response) => {
            this.httpCacheService.clearCacheByRequest(request);
            return response;
          })
        );
    } else {
      return this.get<T>(request);
    }
  }
  public put<T>(request: IHttpApiRequest): Observable<T> {
    const url = this.httpApiHelperService.getUrl(request);
    const options: IHttpApiRequestOptions =
      this.httpApiHelperService.getHttpOptions(request);
    const isMock = this.httpApiHelperService.isMock(request);
    if (!isMock) {
      return this.http
        .put<T>(url, request.data ? request.data : null, options)
        .pipe(
          map((response) => {
            this.httpCacheService.clearCacheByRequest(request);
            return response;
          })
        );
    } else {
      return this.get<T>(request);
    }
  }
  public delete<T>(request: IHttpApiRequest): Observable<T> {
    const url = this.httpApiHelperService.getUrl(request);
    const options: IHttpApiRequestOptions =
      this.httpApiHelperService.getHttpOptions(request);
    const isMock = this.httpApiHelperService.isMock(request);
    if (!isMock) {
      return this.http.delete<T>(url, options).pipe(
        map((response) => {
          this.httpCacheService.clearCacheByRequest(request);
          return response;
        })
      );
    } else {
      return this.get<T>(request);
    }
  }
  public patch<T>(request: IHttpApiRequest): Observable<T> {
    const url = this.httpApiHelperService.getUrl(request);
    const options: IHttpApiRequestOptions =
      this.httpApiHelperService.getHttpOptions(request);
    const isMock = this.httpApiHelperService.isMock(request);
    if (!isMock) {
      return this.http
        .patch<T>(url, request.data ? request.data : null, options)
        .pipe(
          map((response) => {
            this.httpCacheService.clearCacheByRequest(request);
            return response;
          })
        );
    } else {
      return this.get<T>(request);
    }
  }
}
