import { BrandManagementDialogsComponent } from './../../../../common/components/dialogs/brand-management-dialogs/brand-management-dialogs.component';
import { MatDialog } from '@angular/material/dialog';
import { GlobalElementsService } from './../../../services/global-elements.service';
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, OnDestroy } from '@angular/core';
import { BrandManagementService } from '../../../services/brand-management.service';
import { PAGE_MODES } from '../../../../constants/app-constants';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';

@Component({
  selector: 'app-homepage-portfolio',
  templateUrl: './homepage-portfolio.component.html',
  styleUrls: ['./homepage-portfolio.component.scss']
})
export class HomepagePortfolioComponent implements OnInit, OnChanges, OnDestroy {

  @Output() portfolioSelectionEvent = new EventEmitter();
  @Output() setHomePageModeEvent = new EventEmitter();
  @Input() pageMode;

  portfolios = [];
  selectedPortfolioList = [];
  isLoading = true;
  errorLoading = false;
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  marketInfo: any [];
  selectedMarket;
  masterPortfolios = [];

  constructor(
    public dialog: MatDialog,
    private brandManagementService: BrandManagementService,
    protected googleTagManagerService: GoogleTagManagerService,
    private globalElementsService:GlobalElementsService
  ) { }

  ngOnInit() {
    this.getPortfolios();
    this.getMarkets();
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Portfolio_Management`,
      eventAction: "PageView",
      eventLable: `Global Elements Portfolio Management Page view`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  ngOnChanges() {
    if (this.pageMode == PAGE_MODES.DELETE) {
      this.handleDelete()
    }
  }

  isPortfolioSelected(mktId) {
    return this.selectedPortfolioList.includes(mktId)
  }


  selectPortfolio(mktId) {

    if (this.selectedPortfolioList.includes(mktId)) {
      this.selectedPortfolioList = this.selectedPortfolioList.filter(el => el != mktId)
    } else {
      this.selectedPortfolioList.push(mktId);
    }

    const portfoliosList = this.portfolios.filter(mkt => this.selectedPortfolioList.includes(mkt.id))
    this.portfolioSelectionEvent.emit(portfoliosList);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Portfolio_Management`,
      eventAction: "Select Portfolio click",
      eventLable: `Global Elements Portfolio Dropdown clicked`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }


  handleDelete() {


    const portfoliosList = this.portfolios.filter(pf => this.selectedPortfolioList.includes(pf.id));

    // Get the list of markets which has associated portfolios.
    const portfoliosWithChildren = portfoliosList.filter(pf => pf.hasChildren);

    let childrenList = [];
    portfoliosWithChildren.forEach(pf => {
      childrenList.push(...pf.children);
    })


    let moduleNames = portfoliosWithChildren.map(pf => pf.displayName).join(', ');
    const selectionHasChildren = !!portfoliosWithChildren.length;

    const warnData = {
      type: 'delete',
      module: 'portfolio',
      children: {
        childrenList,
        moduleNames,
        moduleCount: portfoliosWithChildren.length
      }
    };

    const deleteData = {
      type: 'delete',
      module: 'portfolio',
      apiData: {
        portfolios: this.selectedPortfolioList
      }
    };


    
    const dialogRef = this.dialog.open(BrandManagementDialogsComponent, {
      data: selectionHasChildren ? warnData : deleteData ,

      minWidth: '500px'
    })


    dialogRef.beforeClose().subscribe(() => {
      this.selectedPortfolioList = [];
      this.getPortfolios();
      this.portfolioSelectionEvent.emit([]);
      this.setHomePageModeEvent.emit();

    });

  }



  getPortfolios() {
    this.isLoading = true;
    this.errorLoading = false;
    this.brandManagementService.getAllPortfolios().subscribe((res: any) => {
      this.masterPortfolios = res.portfolios;
      this.portfolios = res.portfolios;
      this.isLoading = false;
      this.errorLoading = false;
    }, () => this.errorLoading = true);
  }

  getMarkets() {
    this.globalElementsService.getAllMarket(this.userInfo.gpid).subscribe(res => {
      if(res['markets'].length > 0){
        this.marketInfo = res['markets'].sort((a, b)=> a.displayName >= b.displayName ? 1 : -1);
        // this.marketChangeEvent(null);
      } else {
        this.marketInfo = [];
      }
    })
  }

  applyFilter(){
    this.portfolios = this.masterPortfolios.filter(el => el.marketData.id == this.selectedMarket);
  }

  ngOnDestroy(): void {
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Portfolio_Management`,
      eventAction: "Page Exit",
      eventLable: `Global Elements Portfolio Management Page exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

}
