import { Component, OnInit ,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { PlanService } from '../../../../plan/services/plan.service';

@Component({
  selector: 'app-send-notifications-dialog',
  templateUrl: './send-notifications-dialog.component.html',
  styleUrls: ['./send-notifications-dialog.component.scss']
})
export class SendNotificationsDialogComponent implements OnInit {

  message: string = '';
  showBtn: boolean = true;
  apiResponse;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private planService: PlanService,
  ) { }

  ngOnInit() {
    this.message = 'Message Sent';
    const data = {
      data:this.data.data,
      type:this.data.type
    };
    this.planService.sendNotifications(data).subscribe(res => {
      if(res['status'] == 200){
        this.message = 'Message Sent';
      } else {
        this.message = 'Error in sending notification.';
      }
    })
  }

}
