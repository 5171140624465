import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { updatePlanWidgetTargetDialog } from 'app/common/components/dialogs/update-plan-widget-target-dialog/update-plan-widget-target-dialog.component';
import { IWidgetPlanInfo } from 'app/common/interfaces/plan';
import { GlobalElementsService } from 'app/global-elements/services/global-elements.service';
import { Subscription } from '../../../../node_modules/rxjs';
import { take } from '../../../../node_modules/rxjs/operators';
import { IBreadcrumb } from './../../common/interfaces/common';
import { BREADCRUMBS_LAST_NODE_TYPES, BREADCRUMBS_REFERENCE_TYPES } from './../../constants/app-constants';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import { number } from '@amcharts/amcharts4/core';


@Component({
  selector: 'app-widget-plan-target',
  templateUrl: './widget-plan-target.component.html',
  styleUrls: ['./widget-plan-target.component.scss']
})
export class widgetPlanTarget implements OnInit, OnDestroy {
  
  @Input() elementDetails;  
  @Output('dirty')  formDirty = new EventEmitter<boolean>()
  
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  planTargetForm: FormGroup;
  targetData: IWidgetPlanInfo ;
  saveBtnSubscription: Subscription;
  pathDetails : IBreadcrumb;
  isFormInitialized: boolean = false;
  widgetValueConfig: any = [];
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private globalElementsService: GlobalElementsService,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }

  /*........... Initialization Methods........ */

  ngOnInit() {
    this.globalElementsService.setShowSaveBtn(this.userInfo.roleId !== 6);
    this.globalElementsService.setSaveEnableState(false);
    this.getWidgetConfig();
    this.targetData=this.elementDetails.planWidgetData;
    this.pathDetails = {
      
      lastNodeName: 'Edit Plan Targets',
      pageId: this.targetData.planId,
      pageRefType: BREADCRUMBS_REFERENCE_TYPES.PLAN,
      lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.ANNUAL_BRAND_TARGETS
    }
   // console.log('elementDetails', this.elementDetails);
    this.intiateForm(this.targetData);
    this.initialData(this.targetData);
    this.planTargetForm.valueChanges.subscribe(() => {
      this.globalElementsService.setSaveEnableState(this.planTargetForm.valid);
      this.formDirty.emit(this.planTargetForm.dirty);
    })


    this.saveBtnSubscription = this.globalElementsService.getSidePanelSaveEvent.pipe(take(1)).subscribe(() => this.updateBrandTarget());
    this.userInfo.roleId === 6 ? this.planTargetForm.disable() : null;
  }
  getWidgetConfig() {
    this.globalElementsService.getMasterWidgetList().subscribe((res) => {
      if(res) {
        this.widgetValueConfig = res.data;
      }
    });
  }
  returnpercentage(widgetName) {
    const widgetConfig =  this.widgetValueConfig ?  this.widgetValueConfig : [];
    let _result =  false;
    let filterData = widgetConfig.find((el)=> {
      return el.widgetDisplayName === widgetName
    });
    if(filterData &&  filterData.widgetValueType) {
      _result = filterData.widgetValueType === 'Number' ? true :  false;
    }
    return _result ? '' : '%';
  }
  intiateForm(data) {
   /* this.planTargetForm = this.fb.group({
      monthlyTarget: [ Number(0).toFixed(2), [Validators.max(100), Validators.min(0)]],
      quarterlyTarget: [Number(0).toFixed(2), [Validators.max(100), Validators.min(0)]],
      annualTarget: [Number(0).toFixed(2), [Validators.max(100), Validators.min(0)]]
    });*/
    if (!this.isFormInitialized) {
      this.planTargetForm = this.fb.group({
        widgets: this.fb.array([]),
      });
      this.isFormInitialized = true;
    }
    
  }
  widgets(): FormArray {
    return this.planTargetForm.get("widgets") as FormArray
  }
initialData(data) {
  this.globalElementsService.getWidgetPlanedData(data).subscribe((res) => {
    if(res) {
      const _data = res.data;
      for(let key in  _data) {
        let newWidgetForm = this.newWidget();
        newWidgetForm.patchValue({
          name: key,
          widgetTargets: _data[key] ? Number(_data[key]).toFixed(2) :  Number(0).toFixed(2)
        });
        this.widgets().push(newWidgetForm);
      }
    }
  });
}
  /*........... functionality Methods........ */
  newWidget(): FormGroup {
    return this.fb.group({
      name: ['', Validators.required],
      widgetTargets:  [ Number(0).toFixed(2), [ Validators.required, Validators.max(100), Validators.min(0)]],
    })
  }
  updateBrandTarget() {
    let dialogRef;
    dialogRef = this.dialog.open(updatePlanWidgetTargetDialog, {
      data: this.constructBrandTargetObj(),
      minWidth: '500px'
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.globalElementsService.setSidePanelCancelEvent();
      this.dialog.closeAll();
    });
  }

  constructBrandTargetObj(): IWidgetPlanInfo {
    const formData = this.planTargetForm.value;
    let targetObj: IWidgetPlanInfo = {
      planId: this.targetData.planId,
      year: this.targetData.year,
      //widgetId: this.targetData.widgetId,
      //widgetName: this.targetData.widgetName,
      ...formData
    }
    return targetObj;
  }
  
  /*........... Clean up methods........ */
 
  ngOnDestroy(){
    this.saveBtnSubscription.unsubscribe();
    this.globalElementsService.setShowSaveBtn(false);
    this.formDirty.emit(false);
  }
}
