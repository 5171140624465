import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Component, Input, OnInit, SimpleChanges,Output,EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IOverview } from 'app/common/interfaces/common';
import { IOverTableInfo, ISectionHeader } from 'app/common/interfaces/overviewSection';
import { CHANNEL_COLORS, CHANNEL_OBJ, CHANNEL_TABLE_OBJ, CHART_OBJ } from './../../../constants/overview-constants';
import { isValidUserAccess } from '../../utils/common-util'
am4core.useTheme(am4themes_animated);
import { PlanService } from "app/plan/services/plan.service";
import { PortfolioService } from "app/portfolio/services/portfolio.service";
import { MarketService } from "app/market/services/market.service";
import { SharedDataService } from 'app/common/services/shared-data.service';


@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  @Input() toolTipData;
  @Input() type:string;
  @Input() overviewData: Array<IOverview>;
  @Input() overviewTableData:IOverTableInfo;
  @Input() sectionHeader:ISectionHeader;
  @Input() workingYear:number;
  @Input() planId:string;
  @Input() portfolioId :string;
  @Input() marketId :string;
  @Input() tcpViewOvwData;
  @Output() callParent: EventEmitter<any> = new EventEmitter();

  userInfo = JSON.parse(sessionStorage.getItem('user')); 
  attach_money = sessionStorage.getItem('currencySign');
  dataSource= new MatTableDataSource<any>();
  displayedColumns: string[] = ['info', 'channel', 'planned', 'actuval', 'diff'];  
  channelColors:Array<string>=[...CHANNEL_COLORS];
  plannedData:IOverview;
  actualData:IOverview;
  targetData:IOverview;
  totAnualWorkingMediaBudget:number;
  totPlannedSpend:number;
  totActualSpend:number;
  totDiff:number;
  targetROI:number;
  actuvalROI:number;
  roiDiff:number;
  isAllocationBtn: boolean=false;
  allocationBtnText: string;
  refrenceId: string = null;
  IsReadOnly: boolean = true;
  targetYtdField: string;
  isUpdate: boolean = false;
  isChanged: boolean = false;
  CurrencySymbol: string;
  showOverViewTooltip:boolean = false;

  constructor(
    private planService: PlanService,
    private portfolioService: PortfolioService, 
    private marketService: MarketService,
    private sharedDataService: SharedDataService
    ) { }

  ngOnInit() { 
    if(this.type.toLowerCase() === 'plan' ) {
      this.refrenceId = this.planId;
    } else if(this.type.toLowerCase() === 'portfolio') {
      this.refrenceId = this.portfolioId;
    }
    this.sharedDataService.getCurrencySymbol.subscribe(res => this.CurrencySymbol = res);
  }
  showTooltip(){
    this.showOverViewTooltip = true;
  }
  hideTooltip(){
    this.showOverViewTooltip = false;
  }
  
  
  loadOverviewChart(data) {
    let chart = am4core.create("overviewChartDiv", am4charts.PieChart);
    // Add data
    chart.data = data;
    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "channel";
    pieSeries.innerRadius = am4core.percent(30);
    //pieSeries.labels.template.text = "{category}";
    pieSeries.slices.template.tooltipText = `{category} : ${this.attach_money}{value.formatNumber('#,###.')} ({value.percent.formatNumber('#.')}%)`;
    pieSeries.ticks.template.disabled = true;
    pieSeries.labels.template.disabled = true;
    pieSeries.tooltip.disabled = false;
    let rgm = new am4core.RadialGradientModifier();
    rgm.brightnesses.push(0, 0, 0, 0, 0);
    pieSeries.slices.template.fillModifier = rgm;
    pieSeries.slices.template.strokeModifier = rgm;
    pieSeries.slices.template.strokeOpacity = 0.4;
    pieSeries.slices.template.strokeWidth = 0;
    var slice = pieSeries.slices.template;
    slice.states.getKey("active").properties.shiftRadius = 0;
    let colorArry =  this.channelColors.map(el => am4core.color(el));
    pieSeries.colors.list = [
      ...colorArry
    ];

  }

 
  ngOnChanges(changes: SimpleChanges) {
    if (changes && (
      (changes.type && changes.type.currentValue != changes.type.previousValue)
     || (changes.workingYear && changes.workingYear.currentValue != changes.workingYear.previousValue)
     || (changes.overviewData && changes.overviewData.currentValue != changes.overviewData.previousValue)
    )) {
      this.genOverviewData();
    }
  }
  returnAccess(_oprator, rolconditionId) {
    if(this.type.toLowerCase() === 'plan') {
      return isValidUserAccess(rolconditionId, _oprator, this.planId);
    } else if (this.type.toLowerCase() === 'portfolio') {
      return isValidUserAccess(rolconditionId, _oprator, this.portfolioId);
    } else {
      return isValidUserAccess(rolconditionId, _oprator);
    }
  }
  genOverviewData() {
    const overviewData = this.overviewData || [];
    let channelTableData = [];
    let chartData=[];
    this.plannedData = overviewData && overviewData.find(el => el.refernceCode == 'PLAN');
    this.actualData = overviewData && overviewData.find(el => el.refernceCode == 'ACTUAL');
    this.targetData = overviewData && overviewData.find(el => el.refernceCode == 'TARGET');
    const targetYtdRoi = this.targetData ? Number(this.targetData['ytdROI']) : 0;
    
    // custom target ROI start
    const tcpViewOvwData = this.tcpViewOvwData || [];
    let customData = tcpViewOvwData && tcpViewOvwData.find(el => el.refernceCode == 'TARGET');
    let customROI = customData && customData.customTargetROI;
    if(customROI){
      this.isChanged = true;
    }
    this.targetYtdField = customROI? Number(customROI).toFixed(2): Number(this.targetData['ytdROI']).toFixed(2);
    // custom target ROI end    

    const flowPackMedia= overviewData && overviewData.find(el => el.refernceCode == 'FLOWPACK_MEDIA');
    if(flowPackMedia && flowPackMedia.flowPackData){
      const flowPackMediaData = flowPackMedia.flowPackData;
      //flowPackMediaData['channelSpend'].forEach((flwpkChnl, key) => {
        for(let key in flowPackMediaData) {        
        let tempChannelTableObj = { ...CHANNEL_TABLE_OBJ };
        let tempChartObj={...CHART_OBJ}
        tempChannelTableObj.channel = key;
        tempChartObj.channel=key;
        const flwpkChnl = flowPackMediaData[key];
        tempChannelTableObj.planned = (flwpkChnl && flwpkChnl.planned) || 0;
        tempChannelTableObj.actual = (flwpkChnl && flwpkChnl.actual) || 0;
        tempChannelTableObj.diff = Number(tempChannelTableObj.planned) ? ((Number(tempChannelTableObj.actual) - Number(tempChannelTableObj.planned)) / Number(tempChannelTableObj.planned)) : 0;
        tempChartObj.value= Number(tempChannelTableObj.actual);
        channelTableData.push(tempChannelTableObj);
        chartData.push(tempChartObj);
      }
      
    } else{

      
      CHANNEL_OBJ.forEach(ch => {
        let tempChannelTableObj = { ...CHANNEL_TABLE_OBJ };
        let tempChartObj={...CHART_OBJ}
        tempChannelTableObj.channel = ch.channel;
        tempChartObj.channel= ch.channel;
        tempChannelTableObj.planned = this.plannedData[ch.accesor];
        tempChannelTableObj.actual = this.actualData[ch.accesor];
        tempChannelTableObj.diff = ((this.plannedData[ch.accesor] - this.actualData[ch.accesor]) / this.actualData[ch.accesor]);
        tempChartObj.value= Number(this.actualData[ch.accesor]);
        channelTableData.push(tempChannelTableObj);
        chartData.push(tempChartObj);
      });
    }


    this.totPlannedSpend=this.plannedData?this.plannedData['annualWorkingMediaBudget']:0;
    this.totPlannedSpend=Number(this.totPlannedSpend);
    this.totActualSpend=this.actualData?this.actualData['annualWorkingMediaBudget']:0;
    this.totDiff=(this.totPlannedSpend && this.totActualSpend) ? (this.totPlannedSpend - Number(this.totActualSpend))/this.totActualSpend : null;
    if(!isFinite(this.totDiff)){

      this.totDiff = null;
    }
    //custom ROI calculation start
    if(this.targetYtdField){
      this.roiDiff= this.targetYtdField ? (Number(this.actualData['ytdROI'])- Number(this.targetYtdField))/Number(this.targetYtdField): null;
    }
    else{
      this.roiDiff= targetYtdRoi ? (Number(this.actualData['ytdROI'])- targetYtdRoi)/targetYtdRoi: null;this.isChanged = true;
    }      
    //  custom ROI calculation end   

    this.totAnualWorkingMediaBudget = Number(this.totPlannedSpend) + Number(this.totActualSpend) + Number(this.targetData['annualWorkingMediaBudget']);
    this.dataSource = new MatTableDataSource<any>(channelTableData);
    this.loadOverviewChart(chartData);
  }

  editTargetROI(){
    this.IsReadOnly = false;
    this.isUpdate = true;
  }

  updateTargetROI(){
    if(this.type=="plan"){
      let obj={
        planId: this.overviewData[0].planId,
        updatedROI: this.targetYtdField,
        year: this.workingYear
      }
      this.planService.updateTargetROI(obj).subscribe((res) => {
        this.formReset();
      }) 
    }
    else if(this.type=="portfolio"){
      let obj={
        portfolioId: this.portfolioId,
        updatedROI: this.targetYtdField,
        year: this.workingYear
      }
      this.portfolioService.updateTargetROI(obj).subscribe((res) => {
        this.formReset();
      }) 
    }
    else if(this.type=="market"){
      let obj={
        marketId: this.marketId,
        updatedROI: this.targetYtdField,
        year: this.workingYear
      }
      this.marketService.updateTargetROI(obj).subscribe((res) => {
        this.formReset();
      }) 
    }     
  }

  resetTargetROI(){
    if(this.type=="plan"){
      let obj={
        planId: this.overviewData[0].planId,
        year: this.workingYear
      }
      this.planService.resetTargetROI(obj).subscribe((res) => {
        this.formReset();
      }) 
    }
    else if(this.type=="portfolio"){
      let obj={
        portfolioId: this.portfolioId,
        year: this.workingYear
      }
      this.portfolioService.resetTargetROI(obj).subscribe((res) => {
        this.formReset();
      })
    }
    else if(this.type=="market"){
      let obj={
        marketId: this.marketId,
        year: this.workingYear
      }
      this.marketService.resetTargetROI(obj).subscribe((res) => {
        this.formReset();
      })
    }
  }

  formReset(){
    this.IsReadOnly = true;
    this.isUpdate = false;
    this.callParent.emit();
  }

  numberCheck(event) {
    let pattern = /^\d{1,3}$|(?=^.{1,3}$)^\d+\.\d{0,2}$/ ;///^\d+\.?\d{0,1}$/
    if(this.targetYtdField == ''|| this.targetYtdField == null)
      return
    if (!pattern.test(this.targetYtdField)) 
      event.preventDefault();
      // this.targetYtdField = Number(this.targetYtdField).toFixed(2);   
  }
  
}