import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { GlobalElementsService } from './../../../../global-elements/services/global-elements.service';

@Component({
  selector: 'app-delete-rto-dashboard-dialog',
  templateUrl: './delete-rto-dashboard-dialog.component.html',
  styleUrls: ['./delete-rto-dashboard-dialog.component.scss']
})
export class DeleteRtoDashboardDialogComponent implements OnInit {
portfolioId:string;
rtoLink:string;
isDelConfirmed: boolean = false;
message:string = 'Are you sure you want to delete ?';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalElementsService: GlobalElementsService,     
  ) { }

  ngOnInit() {
    this.portfolioId = this.data.portfolioId;
    this.rtoLink = this.data.rtoLink;
    if(this.rtoLink == ''){
      this.message = "We have nothing to Delete here.";
      this.isDelConfirmed = true;
    }
  }

  deleteRTOEntry(){
      this.message = 'Deleting please wait...';
      this.globalElementsService.savePortfolioRTMData(this.portfolioId, "").subscribe(response => {
        console.log(response);
        this.isDelConfirmed = true;
        this.message = 'Deleted Successfully!';
      })
  }
}
