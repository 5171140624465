import { pairwise } from "rxjs/operators";
import { Component, OnInit, AfterViewInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { FileUploadDialogComponent } from "app/common/components/dialogs/file-upload-dialog/file-upload-dialog.component";
import { MjtbdDialogComponent } from "app/common/components/dialogs/mjtbd-dialog/mjtbd-dialog.component";
import { IDocument, IOverview } from "app/common/interfaces/common";
import {
  IOverTableInfo,
  IOverviewTable,
  ISectionHeader,
} from "app/common/interfaces/overviewSection";
import {
  IBrandBaseTarget,
  IMjtbds,
  IPlanInfo,
  IPlanResponse,
} from "app/common/interfaces/plan";
import { SharedDataService } from "app/common/services/shared-data.service";
import {
  getDocsFromSharepoint,
  getDocsFromSharepointFilesAPI,
  getFileBuffer,
  getGpId,
} from "app/common/utils/common-util";
import { constructOverviewTableData } from "app/common/utils/overview-util";
import {
  FILE_NAMES,
  FILE_TYPE,
  GLOBAL_ELEMENTS,
  SHAREPOINT_DOC_PATH,
} from "app/constants/app-constants";
import {
  SECTION_HEADER_INFO,
  TABLE_HEADERS,
} from "app/constants/overview-constants";
import { GlobalElementsService } from "app/global-elements/services/global-elements.service";
import { StoreService } from "../old/_services/_store.service";
import { IBreadcrumb } from "./../common/interfaces/common";
import { IPlanOverviewData } from "./../common/interfaces/plan";
import {
  AGGR_VIEW,
  BREADCRUMBS_LAST_NODE_TYPES,
  BREADCRUMBS_REFERENCE_TYPES,
} from "./../constants/app-constants";
import { PlanService } from "./services/plan.service";
import { Subscription } from "../../../node_modules/rxjs";
import { environment } from "environments/environment";
import {
  returnAccessflag,
  isValidUserAccess,
} from "app/common/utils/common-util";
import { GetMarketAndYearDialogComponent } from "app/common/components/dialogs/get-market-and-year-dialog/get-market-and-year-dialog.component";
import { ACCESS_LEVEL } from "app/constants/app-constants";
import { ImportMjtbdDialogComponent } from "../common/components/dialogs/import-mjtbd-dialog/import-mjtbd-dialog.component";
import { googleAnalyticsCode } from "app/util";
import { IMarketCurrency } from "app/common/interfaces/market";
import { saveAs } from "file-saver";
import { fileMimeTypes } from "app/constants/app-constants";
import ab2b64 from "ab2b64";
import axios from "axios";
import { DownloadFileDialogComponent } from "app/common/components/dialogs/download-file-dialog/download-file-dialog.component";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: "app-plan",
  templateUrl: "./plan.component.html",
  styleUrls: ["./plan.component.scss"],
})
export class PlanComponent implements OnInit, AfterViewInit, OnDestroy {
  userInfo = JSON.parse(sessionStorage.getItem("user"));
  selectedMarket = sessionStorage.getItem("market");
  paramsId: number;
  planName: string;
  workingYear: number;
  pathDetails: IBreadcrumb;
  pathDetailsTCPArch: IBreadcrumb;
  isLoading: boolean;
  type: string = "plan";
  planItViewovwData: Array<IOverview>;
  tcpViewOvwData: Array<IOverview>;
  overviewData: Array<IOverview>;
  sectionHeader: ISectionHeader;
  canvasYearsList = [];
  selectedPlanningYear: number;
  overviewTableObj: IOverviewTable = {
    businessUnit: "",
    totalPlanned: "",
    plannedToDate: "",
    actualToDate: "",
    spenDiff: "",
    plannedROI: "",
    actualROI: "",
    roiDiff: "",
    upfrontPlan: "",
    isGroup: false,
    isList: false,
    id: "",
    path: "",
    brandGrowth: "",
    audienceSeg: "",
    status: "",
    accessible: false,
  };
  overviewTableData: IOverTableInfo;
  mjtbds: Array<IMjtbds>;
  brandTarget: IBrandBaseTarget;
  hhDiff: number;
  awarenessDiff: number;
  preferenceDiff: number;
  marketDiff: number;
  hierarchy: string;
  positioningDoc: IDocument;
  brandIdeaDoc: IDocument;
  growthStrategyDoc: IDocument;
  selectedDocument: string;
  tcpData;
  behavioralGrowthTargets: Array<string>;
  foundationalAttachdocData: Array<IDocument> = [];
  market: string;
  plan: string;
  portfolio: string;
  planInfo: IPlanInfo;
  tcpActCalData: any[];
  finalList: Array<IOverviewTable> = [];
  positioningDocPath: string;
  brandIdeaDocPath: string;
  growthStrategyDocPath: string;
  enableTcpProgressBtn: boolean = false;
  aggrView: string;
  actualData: IOverview;
  planNetRevenue: number;
  aggrViewSubscription: Subscription;
  workingYearSubscription: Subscription;
  brandName: string;
  planId: string;
  tooltipTitle: string;
  tooltipTitleAnnualBrandMarketingTarget: string;
  showtooltipTitleAnnualBrandMarketingTarget: boolean = false;
  tooltipTitleMJTBDSpend: string;
  constructor(
    private router: Router,
    private planService: PlanService,
    private route: ActivatedRoute,
    private sharedDataService: SharedDataService,
    public dialog: MatDialog,
    private store: StoreService,
    private globalElementsService: GlobalElementsService,
    protected googleTagManagerService: GoogleTagManagerService,
    private viewportScroller: ViewportScroller,
    private sanitizer: DomSanitizer
  ) {}

  /*........... Initialization Methods........ */

  ngOnInit() {
    this.sharedDataService.callFunctionFromHeaderComponent.subscribe(
      (result) => {
        this.getWorkingYears();
      }
    );
    //this.setPlanningYear();

    this.getWorkingYears();
    this.globalElementsService
      .getTooltipData("YeartoDatevsPlanOverview", this.selectedMarket)
      .subscribe((res_n: any) => {
        if (res_n.data && res_n.data[0]) {
          this.tooltipTitle = res_n.data[0]["tooltipText"];
        }
      });
    this.globalElementsService
      .getTooltipData("AnnualBrandMarketingTargets", this.selectedMarket)
      .subscribe((res_n: any) => {
        if (res_n.data && res_n.data[0]) {
          this.tooltipTitleAnnualBrandMarketingTarget =
            res_n.data[0]["tooltipText"];
        }
      });
    this.globalElementsService
      .getTooltipData("MJTBDs", this.selectedMarket)
      .subscribe((res_n: any) => {
        if (res_n.data && res_n.data[0]) {
          this.tooltipTitleMJTBDSpend = res_n.data[0]["tooltipText"];
        }
      });
    this.route.params.subscribe((params) => {
      const planId = params["id"];
      this.planId = planId;
      const year = params["year"];
      if (year && parseInt(year)) {
        this.store.set("planningYear", parseInt(year));
        this.sharedDataService.setPlanningYear(parseInt(year));
        this.selectedPlanningYear = parseInt(year);

        this.store.set("workingYear", parseInt(year));
        this.sharedDataService.setWorkingYear(parseInt(year));
        this.workingYear = parseInt(year);
      }
      const returnAccessFlagResult = returnAccessflag(
        ACCESS_LEVEL.PLAN,
        planId
      );
      if (!returnAccessFlagResult.result) {
        this.router.navigate(["/accessDenied"]);
      } else if (
        returnAccessFlagResult.marketChange &&
        returnAccessFlagResult.marketId
      ) {
        this.globalElementsService
          .getCurrencyExchange(returnAccessFlagResult.marketId)
          .subscribe(
            (_res: IMarketCurrency) => {
              if (_res) {
                const xchangeRate = Number(_res["conversionFactor"]);
                sessionStorage.setItem("currencySign", _res["currencySign"]);
                this.sharedDataService.setMarket(
                  returnAccessFlagResult.marketId
                );
                this.sharedDataService.setCurrencyView(_res["currencyCode"]);
                this.sharedDataService.setCurrencySymbolSource(
                  _res["currencySign"] ? _res["currencySign"] : "$"
                );
                this.sharedDataService.setCurrencyExchangeRate(xchangeRate);
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.navigate([this.router.url]);
              }
            },
            (err) => {}
          );
      }
      this.paramsId = planId && Number(planId);
      let tagObject = {
        event: `pageView`,
        pageUrl: `plan/${this.paramsId}`,
        pageTitle: `Trademark Page`,
        userId: getGpId(),
        user_id: getGpId(),
      };
      googleAnalyticsCode(tagObject, this.googleTagManagerService);
    });

    this.aggrViewSubscription = this.sharedDataService.getAggrView.subscribe(
      (viewType: string) => {
        this.aggrView = viewType;
        if (this.aggrView == AGGR_VIEW.PLANIT) {
          this.getMediaMix();
          this.overviewData = this.planItViewovwData;
        } else {
          this.overviewData = this.tcpViewOvwData;
        }
        if (this.overviewData) {
          this.actualData =
            this.overviewData &&
            this.overviewData.find((el) => el.refernceCode == "ACTUAL");
          if (this.brandTarget) {
            this.brandTarget.ytdMarketShareActual = Number(
              this.actualData &&
                this.actualData.annualWorkingMediaBudgetForFullYear &&
                this.planNetRevenue
                ? this.actualData.annualWorkingMediaBudgetForFullYear /
                    this.planNetRevenue
                : 0
            );
            this.brandTarget.ytdMarketShareActual =
              Number(this.brandTarget.ytdMarketShareActual) &&
              Number(this.brandTarget.ytdMarketShareActual * 100);
            this.marketDiff = this.brandTarget.annualMarketShareTarget
              ? this.brandTarget.ytdMarketShareActual -
                this.brandTarget.annualMarketShareTarget
              : 0;
          }
        }
      }
    );

    this.sharedDataService.getPlanningYear.subscribe((yr: number) => {
      this.selectedPlanningYear = yr;
      this.getplanData();
      this.getHierarchy();
    });

    this.workingYearSubscription =
      this.sharedDataService.getWorkingYear.subscribe((res: any) => {
        if (res) {
          this.workingYear = res;
          this.getOverviewData();
        }
      });

    this.sharedDataService.sideNavSelection
      .pipe(pairwise())
      .subscribe((res: any) => {
        const prevEle = res[0];
        const currEle = res[1];
        if (currEle && currEle.id == GLOBAL_ELEMENTS.DEFAULT.id) {
          this.getplanData();
        }
        if (prevEle && prevEle.id == GLOBAL_ELEMENTS.PERFORMANCE_TARGET.id) {
          this.getOverviewData();
        }
      });
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Trademark_Page`,
      eventAction: "PageView",
      eventLable: `Plan Page View`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }
  ngAfterViewInit(): void {
    this.route.url.subscribe((url) => {
      const currRoute = url.join("/");
      console.log("url", url);
      if (currRoute) {
        this.navigateToPlanPage(currRoute);
      }
    });
  }
  showTooltip() {
    this.showtooltipTitleAnnualBrandMarketingTarget = true;
  }
  hideTooltip() {
    this.showtooltipTitleAnnualBrandMarketingTarget = false;
  }

  /*........... functionality Methods........ */

  editPerformanceTargets() {
    let sidePanelEle = { ...GLOBAL_ELEMENTS.PERFORMANCE_TARGET };
    sidePanelEle.targetData = this.brandTarget;
    sidePanelEle.planId = this.paramsId;
    sidePanelEle.year = this.workingYear;
    this.sharedDataService.setSideNavSelection(sidePanelEle);
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Trademark_Page`,
      eventAction: "Edit BrandMarketing Targets Click",
      eventLable: `Edit BrandMarketing Targets Click From Trademark Page`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }

  openMjtbdPage() {
    let sidePanelEle = { ...GLOBAL_ELEMENTS.NEW_MJTBD };
    sidePanelEle.mjtbdData = {
      year: this.selectedPlanningYear,
      hierarchy: this.hierarchy,
      planId: this.paramsId,
      mode: "save",
      market: this.market,
      plan: this.plan,
      marketId: this.planInfo.marketId,
    };
    this.sharedDataService.setSideNavSelection(sidePanelEle);
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Trademark_Page`,
      eventAction: "Create MJTBD Click",
      eventLable: `Create MJTBD Button Clicked`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }

  navigateToUpfrontScenarios() {
    this.router.navigate([`/upfrontPlanning/${this.paramsId}`]);
  }
  navigateToPagePathRefrence(pagePath, refrenceId) {
    this.router.navigate([`/${pagePath}/${refrenceId}`]);
  }
  navigateToAnnualMediaCalendar() {
    this.router.navigate([`/annualMediaCalendar/plan/${this.paramsId}`]);
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Trademark_Page`,
      eventAction: "Trademark Media Calendar Click",
      eventLable: `Media Calendar Button Clicked From Trademark Page`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
  }

  navigateToAllocation() {
    this.router.navigate([`/upfrontPlanning/${this.paramsId}`]);
  }
  returnAccess(_oprator, conditionRoleId) {
    return isValidUserAccess(conditionRoleId, _oprator, null, this.planId);
  }
  fileChangeEvent(event: any, fileType): void {
    let fileList: FileList = event.target.files;
    this.selectedDocument = fileList[0].name;
    getFileBuffer(fileList[0]).then(
      (fileBufferArray: ArrayBuffer) => {
        let fileName;
        let path;
        let subPath = `${SHAREPOINT_DOC_PATH.TCP}/${this.selectedPlanningYear}/${this.hierarchy}`;
        let lastDot = fileList[0].name.lastIndexOf(".");
        const ext = fileList[0].name.substring(lastDot + 1);
        if (fileType == FILE_TYPE.POSITIONING) {
          path = `${subPath}/${SHAREPOINT_DOC_PATH.FOUNDATIONAL}`;
          fileName = `${this.selectedPlanningYear}_${this.plan}_${FILE_NAMES.PURPOSEFUL_POSITIONING}.${ext}`;
        } else if (fileType == FILE_TYPE.BRAND_IDEA) {
          path = `${subPath}/${SHAREPOINT_DOC_PATH.FOUNDATIONAL}`;
          fileName = `${this.selectedPlanningYear}_${this.plan}_${FILE_NAMES.BRAND_IDEA}.${ext}`;
        } else if (fileType == FILE_TYPE.GROWTH_STRATEGY) {
          path = `${subPath}/${SHAREPOINT_DOC_PATH.FOUNDATIONAL}`;
          fileName = `${this.selectedPlanningYear}_${this.plan}_${FILE_NAMES.GROWTH_STRATEGY}.${ext}`;
        } else if (fileType == FILE_TYPE.FOUNDATIONAL_ATTACHMENT) {
          path = `${subPath}/${SHAREPOINT_DOC_PATH.FOUNDATIONAL}/${SHAREPOINT_DOC_PATH.ATTACHMENTS}`;
          fileName = this.selectedDocument;
        } else if (fileType == FILE_TYPE.ACTIVATION_CALENDER) {
          path = `${subPath}/${SHAREPOINT_DOC_PATH.TCP_ARCHITECTURE}/${SHAREPOINT_DOC_PATH.TCP_ACT_CALENDER}`;
          fileName = `${this.plan} ${FILE_NAMES.ACTIVATION_CALENDER}.${ext}`;
        } else {
          fileName = "";
        }

        let data = fileBufferArray;
        let docObj = {
          body: {
            data,
            fileName: fileName,
            uploadLocation: path,
          },
        };
        let dialogRef;
        dialogRef = this.dialog.open(FileUploadDialogComponent, {
          data: { fileType, docObj },
          minWidth: "500px",
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((res: any) => {
          event.target.value = null;
          if (res.status) {
            if (fileType == FILE_TYPE.ACTIVATION_CALENDER) {
              this.getTcpActCalenderDocsFromSharepoint();
            } else if (fileType == FILE_TYPE.FOUNDATIONAL_ATTACHMENT) {
              this.getFoundationalAttachDocsFromSharepoint();
            } else {
              this.updateThumbnail(res.fileType, res.thumbnailImage);
            }
          }
          this.dialog.closeAll();
        });
      },
      (error) => {
        console.log("Error: ", error);
      }
    );
  }
  async getBrandDocsFromSharepoint_withthumbnail() {
    const path = `${SHAREPOINT_DOC_PATH.TCP}/${this.selectedPlanningYear}/${this.hierarchy}/${SHAREPOINT_DOC_PATH.FOUNDATIONAL}`;
    let docData = await getDocsFromSharepointFilesAPI(
      path,
      this.globalElementsService
    );

    docData = docData.sort((a, b) => {
      return b.modifiedDtUnix - a.modifiedDtUnix;
    });

    const positioning_fileName = `${this.selectedPlanningYear}_${this.plan}_${FILE_NAMES.PURPOSEFUL_POSITIONING}`;
    const brandIdea_fileName = `${this.selectedPlanningYear}_${this.plan}_${FILE_NAMES.BRAND_IDEA}`;
    const growthStrategy_fileName = `${this.selectedPlanningYear}_${this.plan}_${FILE_NAMES.GROWTH_STRATEGY}`;

    this.positioningDoc = docData.find(
      (doc: IDocument) =>
        doc.fileName &&
        doc.fileName.split(".")[0].toLocaleLowerCase() ==
          `${positioning_fileName.toLocaleLowerCase()}`
    );
    this.brandIdeaDoc = docData.find(
      (doc: IDocument) =>
        doc.fileName &&
        doc.fileName.split(".")[0].toLocaleLowerCase() ==
          `${brandIdea_fileName.toLocaleLowerCase()}`
    );
    this.growthStrategyDoc = docData.find(
      (doc: IDocument) =>
        doc.fileName &&
        doc.fileName.split(".")[0].toLocaleLowerCase() ==
          `${growthStrategy_fileName.toLocaleLowerCase()}`
    );

    const positioning_thumbnailb64data =
      this.positioningDoc &&
      (await this.globalElementsService
        .getThumbnailBase64DataSharePoint_new({
          filePathWName: `${path}/${this.positioningDoc.fileName}`,
        })
        .toPromise());
    const brandIdea_thumbnailb64data =
      this.brandIdeaDoc &&
      (await this.globalElementsService
        .getThumbnailBase64DataSharePoint_new({
          filePathWName: `${path}/${this.brandIdeaDoc.fileName}`,
        })
        .toPromise());
    const growthStrategy_thumbnailb64data =
      this.growthStrategyDoc &&
      (await this.globalElementsService
        .getThumbnailBase64DataSharePoint_new({
          filePathWName: `${path}/${this.growthStrategyDoc.fileName}`,
        })
        .toPromise());

    this.positioningDocPath =
      positioning_thumbnailb64data &&
      `data:image/jpg;base64,` +
        positioning_thumbnailb64data["thumbnailb64data"];
    this.brandIdeaDocPath =
      brandIdea_thumbnailb64data &&
      `data:image/jpg;base64,` + brandIdea_thumbnailb64data["thumbnailb64data"];
    this.growthStrategyDocPath =
      growthStrategy_thumbnailb64data &&
      `data:image/jpg;base64,` +
        growthStrategy_thumbnailb64data["thumbnailb64data"];
  }
  async updateThumbnail(fileType, positioning_thumbnailb64data) {
    this.getBrandDocsFromSharepoint_withthumbnail();
    if (
      positioning_thumbnailb64data &&
      positioning_thumbnailb64data["thumbnailb64data"]
    ) {
      switch (fileType) {
        case FILE_TYPE.POSITIONING:
          {
            this.positioningDocPath =
              positioning_thumbnailb64data &&
              `data:image/jpg;base64,` +
                positioning_thumbnailb64data["thumbnailb64data"];
          }
          break;
        case FILE_TYPE.BRAND_IDEA:
          {
            this.brandIdeaDocPath =
              positioning_thumbnailb64data &&
              `data:image/jpg;base64,` +
                positioning_thumbnailb64data["thumbnailb64data"];
          }
          break;
        case FILE_TYPE.GROWTH_STRATEGY:
          {
            this.growthStrategyDocPath =
              positioning_thumbnailb64data &&
              `data:image/jpg;base64,` +
                positioning_thumbnailb64data["thumbnailb64data"];
          }
          break;
      }
    }
    /*const positioning_thumbnailb64data = this.positioningDoc && await this.globalElementsService.getThumbnailBase64DataSharePoint(this.positioningDoc["PictureThumbnailURL"]).toPromise();
    const brandIdea_thumbnailb64data = this.brandIdeaDoc && await this.globalElementsService.getThumbnailBase64DataSharePoint(this.brandIdeaDoc["PictureThumbnailURL"]).toPromise();
    const growthStrategy_thumbnailb64data = this.growthStrategyDoc && await this.globalElementsService.getThumbnailBase64DataSharePoint(this.growthStrategyDoc["PictureThumbnailURL"]).toPromise();

    this.positioningDocPath = positioning_thumbnailb64data && `data:image/jpg;base64,` + positioning_thumbnailb64data["thumbnailb64data"];
    this.brandIdeaDocPath = brandIdea_thumbnailb64data && `data:image/jpg;base64,` + brandIdea_thumbnailb64data["thumbnailb64data"];
    this.growthStrategyDocPath = growthStrategy_thumbnailb64data && `data:image/jpg;base64,` + growthStrategy_thumbnailb64data["thumbnailb64data"];*/
  }
  //   downloadFile(path:string){
  //     let fileName = path.split('/').pop();
  //     const extension = fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length);
  //     this.globalElementsService.downloadFile({fileName: fileName, path: path.split('/Shared Documents/')[1]}).subscribe(response => {
  //       this.downloadFileFromBase64(fileName,response['thumbnailb64data'], fileMimeTypes[extension] );
  //       // const byteCharacters = window.atob(response['thumbnailb64data']);
  //       // const downloadLink = document.createElement("a");
  //       // downloadLink.download = fileName.replace(/ /g,'');
  //       // downloadLink.href = source;
  //       // document.body.appendChild(downloadLink);
  //       // downloadLink.click();
  //       // document.body.removeChild(downloadLink);
  //       // const byteNumbers = new Array(byteCharacters.length);
  //       // for (let i = 0; i < byteCharacters.length; i++) {
  //       //   byteNumbers[i] = byteCharacters.charCodeAt(i);
  //       // }
  //       // const byteArray = new Uint8Array(byteNumbers);
  //       // const blob = new Blob([byteArray], {type: `data:${fileMimeTypes[extension]}`});
  //       // saveAs(blob, fileName.replace(/ /g,''));
  //   })
  // }
  downloadFile(path: string) {
    let fileName = path.split("/").pop();
    path = path ? path.split("/Shared Documents/")[1] : "";
    let docPath = path.split(`/${fileName}`)[0];
    const extension = fileName.substring(
      fileName.lastIndexOf(".") + 1,
      fileName.length
    );
    const downloadObj = {
      fileName: fileName,
      path: path,
      docPath: docPath,
      extension: extension,
    };
    let dialogRef;
    dialogRef = this.dialog.open(DownloadFileDialogComponent, {
      data: downloadObj,
      minWidth: "500px",
      disableClose: true,
    });

    // this.globalElementsService.downloadFile({fileName: fileName, path: path.split('/Shared Documents/')[1]}).subscribe(response => {
    //   if(response['sqAuthheader']){
    //     axios({
    //       method: 'GET',
    //       url: `https://pepsico.sharepoint.com/sites/NACanvas/_api/web/getfolderbyserverrelativeurl('Shared Documents/${docPath}')/Files('${fileName}')/$value/`,
    //       headers: response['sqAuthheader'],
    //       responseType: 'arraybuffer'
    //        }).then(response => {
    //          console.log(response.data);
    //          const byteArray = new Uint8Array(response.data);
    //          const blob = new Blob([byteArray], {type: `data:${fileMimeTypes[extension]}`});
    //          saveAs(blob, fileName);
    //     }).catch((error) => {
    //       console.log(error);
    //     })
    //   }
    // this.downloadFileFromBase64(fileName,response['thumbnailb64data'], fileMimeTypes[extension] );
    // const byteCharacters = window.atob(response['thumbnailb64data']);
    // const downloadLink = document.createElement("a");
    // downloadLink.download = fileName.replace(/ /g,'');
    // downloadLink.href = source;
    // document.body.appendChild(downloadLink);
    // downloadLink.click();
    // document.body.removeChild(downloadLink);
    // const byteNumbers = new Array(byteCharacters.length);
    // for (let i = 0; i < byteCharacters.length; i++) {
    //   byteNumbers[i] = byteCharacters.charCodeAt(i);
    // }
    // const byteArray = new Uint8Array(byteNumbers);
    // const blob = new Blob([byteArray], {type: `data:${fileMimeTypes[extension]}`});
    // saveAs(blob, fileName.replace(/ /g,''));
    // })
  }

  downloadFileFromBase64(fileName, b64encodedString: string, contentType) {
    if (b64encodedString) {
      var blob = this.base64ToBlob(b64encodedString, contentType);
      saveAs(blob, fileName);
    }
  }

  public base64ToBlob(b64Data, contentType = "", sliceSize = 512) {
    let byteArrays = ab2b64.b642ab(b64Data);
    return new Blob([byteArrays], { type: contentType });
  }
  deleteFile(doc: IDocument, fileType: string) {
    const docObj = {
      isDelete: true,
      fileName: doc.fileName,
      docPath: doc.path.split("/Shared Documents/")[1],
    };
    let dialogRef;
    dialogRef = this.dialog.open(FileUploadDialogComponent, {
      data: { fileType: "", docObj },
      minWidth: "500px",
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res.status) {
        if (fileType == FILE_TYPE.ACTIVATION_CALENDER) {
          this.getTcpActCalenderDocsFromSharepoint();
        } else if (fileType == FILE_TYPE.FOUNDATIONAL_ATTACHMENT) {
          this.getFoundationalAttachDocsFromSharepoint();
        } else {
          this.getBrandDocsFromSharepoint_withthumbnail();
        }
      }
      this.dialog.closeAll();
    });
  }

  sanatizeURL(URL): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `${URL}&amp;action=embedview&amp;wdAr=1.7777777777777777`
    );
  }

  async getFoundationalAttachDocsFromSharepoint() {
    const path = `${SHAREPOINT_DOC_PATH.TCP}/${this.selectedPlanningYear}/${this.hierarchy}/${SHAREPOINT_DOC_PATH.FOUNDATIONAL}/${SHAREPOINT_DOC_PATH.ATTACHMENTS}`;
    // this.foundationalAttachdocData = await getDocsFromSharepoint(path, this.globalElementsService);
    this.foundationalAttachdocData = await getDocsFromSharepointFilesAPI(
      path,
      this.globalElementsService
    );
    // console.log(this.foundationalAttachdocData);
  }

  async getTcpActCalenderDocsFromSharepoint() {
    const path = `${SHAREPOINT_DOC_PATH.TCP}/${this.selectedPlanningYear}/${this.hierarchy}/${SHAREPOINT_DOC_PATH.TCP_ARCHITECTURE}/${SHAREPOINT_DOC_PATH.TCP_ACT_CALENDER}`;
    this.tcpActCalData = await getDocsFromSharepointFilesAPI(
      path,
      this.globalElementsService
    );
    this.tcpActCalData = this.tcpActCalData.sort((a, b) => {
      return b.modifiedDtUnix - a.modifiedDtUnix;
    });
  }

  async getBrandDocsFromSharepoint() {
    const path = `${SHAREPOINT_DOC_PATH.TCP}/${this.selectedPlanningYear}/${this.hierarchy}/${SHAREPOINT_DOC_PATH.FOUNDATIONAL}`;
    const docData = await getDocsFromSharepoint(
      path,
      this.globalElementsService
    );

    const positioning_fileName = `${this.selectedPlanningYear}_${this.plan}_${FILE_NAMES.PURPOSEFUL_POSITIONING}`;
    const brandIdea_fileName = `${this.selectedPlanningYear}_${this.plan}_${FILE_NAMES.BRAND_IDEA}`;
    const growthStrategy_fileName = `${this.selectedPlanningYear}_${this.plan}_${FILE_NAMES.GROWTH_STRATEGY}`;

    this.positioningDoc = docData.find(
      (doc: IDocument) => doc.fileName == `${positioning_fileName}`
    );
    this.brandIdeaDoc = docData.find(
      (doc: IDocument) => doc.fileName == `${brandIdea_fileName}`
    );
    this.growthStrategyDoc = docData.find(
      (doc: IDocument) => doc.fileName == `${growthStrategy_fileName}`
    );

    const positioning_thumbnailb64data =
      this.positioningDoc &&
      (await this.globalElementsService
        .getThumbnailBase64DataSharePoint(
          this.positioningDoc["PictureThumbnailURL"]
        )
        .toPromise());
    const brandIdea_thumbnailb64data =
      this.brandIdeaDoc &&
      (await this.globalElementsService
        .getThumbnailBase64DataSharePoint(
          this.brandIdeaDoc["PictureThumbnailURL"]
        )
        .toPromise());
    const growthStrategy_thumbnailb64data =
      this.growthStrategyDoc &&
      (await this.globalElementsService
        .getThumbnailBase64DataSharePoint(
          this.growthStrategyDoc["PictureThumbnailURL"]
        )
        .toPromise());

    this.positioningDocPath =
      positioning_thumbnailb64data &&
      `data:image/jpg;base64,` +
        positioning_thumbnailb64data["thumbnailb64data"];
    this.brandIdeaDocPath =
      brandIdea_thumbnailb64data &&
      `data:image/jpg;base64,` + brandIdea_thumbnailb64data["thumbnailb64data"];
    this.growthStrategyDocPath =
      growthStrategy_thumbnailb64data &&
      `data:image/jpg;base64,` +
        growthStrategy_thumbnailb64data["thumbnailb64data"];
  }

  editTcpInfo() {
    let sidePanelEle = { ...GLOBAL_ELEMENTS.TCP_ARCHITECTURE };
    sidePanelEle.tcpData = {
      data: this.tcpData,
      year: this.selectedPlanningYear,
      hierarchy: this.hierarchy,
      planId: this.paramsId,
    };
    this.sharedDataService.setSideNavSelection(sidePanelEle);
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Trademark_Page`,
      eventAction: "Edit TCP Architecture Click",
      eventLable: `Edit TCP Architecture Button Clicked From Trademark Page`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);
    let tagObjectPageview = {
      event: `custom_${environment.environmentName}_pageView`,
      pageUrl: `${this.hierarchy}/${this.pathDetailsTCPArch.lastNodeName}`,
      pageTitle: `${this.pathDetails.lastNodeType} Page`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObjectPageview, this.googleTagManagerService);
  }

  deleteMjtbd(postObj) {
    let dialogRef;
    dialogRef = this.dialog.open(MjtbdDialogComponent, {
      data: postObj,
      minWidth: "500px",
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      this.getplanData();
      this.dialog.closeAll();
    });
  }

  navigateToTcpDocumentCenter() {
    let sidePanelEle = { ...GLOBAL_ELEMENTS.TCP_DOCUMENT_CENTER };
    sidePanelEle.tcpDocData = {
      mjtbdData: this.mjtbds,
      year: this.selectedPlanningYear,
      hierarchy: this.hierarchy,
      planId: this.paramsId,
      tcpData: this.tcpData,
      plan: this.plan,
      tcpActCalData: this.tcpActCalData || [],
    };
    this.sharedDataService.setSideNavSelection(sidePanelEle);
  }

  /*........... Service/API calls........ */

  getOverviewData() {
    this.overviewData = null;
    const obj = {
      planId: this.paramsId,
      year: this.workingYear,
    };
    this.planService
      .getOverviewData(obj)
      .subscribe((res: IPlanOverviewData) => {
        this.tcpViewOvwData = res.planOverview;
        this.planItViewovwData = res.planItOverview;
        this.planNetRevenue =
          res.netRevenue && Number(res.netRevenue) ? Number(res.netRevenue) : 0;
        this.overviewData =
          this.aggrView == AGGR_VIEW.PLANIT
            ? this.planItViewovwData
            : this.tcpViewOvwData;
        this.actualData =
          this.overviewData &&
          this.overviewData.find((el) => el.refernceCode == "ACTUAL");
        this.brandTarget = res.brandTarget;
        //this.brandTarget.ytdMarketShareActual = this.actualData && this.actualData.annualWorkingMediaBudgetForFullYear &&  this.planNetRevenue ? this.actualData.annualWorkingMediaBudgetForFullYear / this.planNetRevenue : 0;
        let brandTarget = { ...this.brandTarget };
        this.hhDiff = brandTarget.annualHHpentrationTarget
          ? brandTarget.ytdHHpentrationActual -
            brandTarget.annualHHpentrationTarget
          : 0;
        this.awarenessDiff = brandTarget.annualAwarenessIntensityTarget
          ? brandTarget.ytdAwarenessIntensityActual -
            brandTarget.annualAwarenessIntensityTarget
          : 0;
        this.preferenceDiff = brandTarget.annualPrefIntensityTarget
          ? brandTarget.ytdPrefIntensityActual -
            brandTarget.annualPrefIntensityTarget
          : 0;
        this.actualData =
          this.overviewData &&
          this.overviewData.find((el) => el.refernceCode == "ACTUAL");
        if (this.brandTarget) {
          this.brandTarget.ytdMarketShareActual = Number(
            this.actualData &&
              this.actualData.annualWorkingMediaBudgetForFullYear &&
              this.planNetRevenue
              ? this.actualData.annualWorkingMediaBudgetForFullYear /
                  this.planNetRevenue
              : 0
          );
          this.brandTarget.ytdMarketShareActual =
            Number(this.brandTarget.ytdMarketShareActual) &&
            Number(this.brandTarget.ytdMarketShareActual * 100);
          this.marketDiff = this.brandTarget.annualMarketShareTarget
            ? this.brandTarget.ytdMarketShareActual -
              this.brandTarget.annualMarketShareTarget
            : 0;
        }
        if (this.aggrView == AGGR_VIEW.PLANIT) {
          this.getMediaMix();
        }
      });
  }

  getMediaMix() {
    if (!this.paramsId || !this.workingYear) {
      return null;
    }
    const postObj = {
      planId: this.paramsId,
      year: this.workingYear,
    };

    this.planService.getMediaMix(postObj).subscribe((res: any) => {
      const mediaMixObj: IOverview = {
        refernceCode: "FLOWPACK_MEDIA",
        annualWorkingMediaBudget: null,
        flowPackData: (res && res.channelSpend) || null,
        ytdROI: null,
        year: this.selectedPlanningYear,
      };
      this.overviewData = [...this.overviewData, mediaMixObj];
    });
  }

  getplanData() {
    if (!this.paramsId || !this.selectedPlanningYear) {
      return null;
    }
    this.finalList = [];
    this.overviewTableData = null;
    const obj = {
      planId: this.paramsId,
      year: this.selectedPlanningYear,
    };
    this.planService.getPlanData(obj).subscribe((res: IPlanResponse) => {
      if (res) {
        this.planName = res.plan.displayName;
        this.tcpData = res.tcpObject || {};
        this.behavioralGrowthTargets = res.behavioralGrowthTargets;
        let sectionHeader = { ...SECTION_HEADER_INFO };
        this.sectionHeader = sectionHeader.PLAN;
        this.mjtbds = res.mjtbds;
        this.overviewTableData = this.constructOverviewTableData();
        this.enableTcpProgressBtn = true;
        let plan = this.planName;
        const arr = plan.split(" ");
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        this.brandName = arr.join(" ");
        this.pathDetails = {
          lastNodeName: this.brandName,
          pageId: this.paramsId,
          pageRefType: BREADCRUMBS_REFERENCE_TYPES.PLAN,
          lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.PLAN,
        };
        if (this.pathDetails.lastNodeName.includes("Rte")) {
          let replaceData = this.pathDetails.lastNodeName.replace("Rte", "RTE");
          console.log(replaceData);
          this.pathDetails.lastNodeName = replaceData;
        }
        this.pathDetailsTCPArch = {
          lastNodeName: "TCP Architecture",
          pageId: this.paramsId,
          pageRefType: BREADCRUMBS_REFERENCE_TYPES.PLAN,
          lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.TCP_ARCHITECTURE,
        };
      }
    });
  }

  getHierarchy() {
    if (!this.paramsId) {
      return null;
    }
    let planObj = {
      planId: this.paramsId,
    };
    this.planService.getHierarchy(planObj).subscribe((res: any) => {
      if (res) {
        this.hierarchy = `${res.market.displayName}/${res.portfolio.displayName}/${res.plan.displayName}`;
        this.portfolio = res.portfolio.displayName;
        this.market = res.market.displayName;
        this.plan = res.plan.displayName;
        this.getBrandDocsFromSharepoint_withthumbnail();
        this.getFoundationalAttachDocsFromSharepoint();
        this.getTcpActCalenderDocsFromSharepoint();
        this.planInfo = {
          year: this.selectedPlanningYear,
          hierarchy: this.hierarchy,
          planId: this.paramsId,
          market: this.market,
          plan: this.plan,
          marketId: res.market.id,
          portfolio: res.portfolio.displayName,
          portfolioId: res.portfolio.id,
        };
      }
    });
  }

  getWorkingYears() {
    this.globalElementsService
      .getCanvasYears()
      .subscribe((res: Array<number>) => {
        this.canvasYearsList = [];
        res.map((yr) => {
          this.canvasYearsList.push({
            displayName: "FY " + yr,
            value: yr,
          });
        });
        this.selectedPlanningYear = parseInt(this.store.get("planningYear"));
      });
  }

  setPlanningYear() {
    this.store.set("planningYear", this.selectedPlanningYear);
    this.sharedDataService.setPlanningYear(this.selectedPlanningYear);
    this.store.set("workingYear", this.selectedPlanningYear);
    this.sharedDataService.setWorkingYear(this.selectedPlanningYear);
  }

  constructOverviewTableData(): IOverTableInfo {
    this.mjtbds.forEach((mjtb) => {
      const row = constructOverviewTableData(
        {
          displayName: mjtb.name,
          status: mjtb.status,
          overview: mjtb.mjtbdOverview,
        },
        this.overviewTableObj
      );
      row.id = mjtb.id;
      row.accessible = mjtb.accessible;
      row.path = `/program/${mjtb.id}`;
      (row.brandGrowth = mjtb.mjtbdForm ? mjtb.mjtbdForm.growthTarget : ""),
        (row.audienceSeg = mjtb.mjtbdForm
          ? mjtb.mjtbdForm.audiencePriority
          : ""),
        this.finalList.push(row);
    });
    let tableObj: IOverTableInfo = {
      tableMainHeaderRow: TABLE_HEADERS.PLAN.MAIN,
      tableSubHeaderRow: TABLE_HEADERS.PLAN.SUB,
      tableData: this.finalList,
    };
    return tableObj;
  }

  ngOnDestroy(): void {
    let tagObject = {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Trademark_Page`,
      eventAction: "PageExit",
      eventLable: `Trademark Page Exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    };
    googleAnalyticsCode(tagObject, this.googleTagManagerService);

    this.aggrViewSubscription.unsubscribe();
    this.workingYearSubscription.unsubscribe();
  }
  openDialog(type: any) {
    const path = `${SHAREPOINT_DOC_PATH.TCP}/${this.selectedPlanningYear}/${this.hierarchy}/${SHAREPOINT_DOC_PATH.FOUNDATIONAL}`;
    let dialogRef;
    const planData = {
      selectedWorkingYear: this.selectedPlanningYear,
      plan: this.plan,
      planId: this.paramsId,
      portfolio: this.portfolio,
    };
    dialogRef = this.dialog.open(GetMarketAndYearDialogComponent, {
      data: {
        userInfo: this.userInfo,
        path: path,
        planData: planData,
        type: type,
      },
      maxWidth: "600px",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.updateThumbnail(res.fileType, res.thumbnailImage);
      }
    });
  }
  navigateToPlanPage(planId) {
    // if (planId) {
    //   this.router.navigate([`/${planId}/#p2`]).then(() => {
    //     //  this.viewportScroller.scrollToAnchor("p2");
    const fragment = this.route.snapshot.fragment;
    if (fragment == "p1") {
      let element1 = document.getElementById("p1");
      if (element1) {
        element1.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }else{
      console.log("fragment", fragment);
      const element = document.getElementById("p2");
      console.log("element", element);
      if (fragment && element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }    
  }
  openMJTBDPopup() {
    let tmDialogRef;
    tmDialogRef = this.dialog.open(ImportMjtbdDialogComponent, {
      data: {
        planId: this.paramsId,
        plan: this.plan,
        year: this.selectedPlanningYear,
        market: this.planInfo.marketId,
      },
      height: "500px",
      width: "500px",
    });

    tmDialogRef.afterClosed().subscribe((res: any) => {
      this.getplanData();
    });
  }
}
