import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


interface Preferences {
  [key: string]: any;
}

const defaultPreferences: Preferences = {
  'dashboard:v2': false,
  'planning:v2': false
}
@Injectable()
export class StoreService {

  protected store: Map<string, any> = new Map();

  private _preferences = new BehaviorSubject<Preferences>({
    ...defaultPreferences,
    ...JSON.parse(sessionStorage.getItem('prefs') || '{}')
  });

  public preferences$ = this._preferences.asObservable();

  constructor() {

  }

  set preferences(value: Preferences) {
    this._preferences.next(value);
    this.set('prefs', JSON.stringify(value));
  }

  get preferences() {
    return this._preferences.getValue();
  }

  getPreference(key: string) {
    return this.preferences[key] || false;
  }

  public get(key: string): any {
    return sessionStorage.getItem(key)
    //return this.store.get(key);
  }

  public set(key: string, value: any) {
    sessionStorage.setItem(key, value);
    //this.store.set(key, value);
  }

  canCreate() {
    const licType = this.get('licenseType');

    const permissionLevel2 = this.get('level2');
    const navLevel2 = this.get('navLevel2');

    if (licType === 'F' && ((navLevel2 === permissionLevel2) || permissionLevel2 === 'ALL')) {
      return true;
    }

    return false;
  }

}
