import { BrandManagementDialogsComponent } from './../../../../common/components/dialogs/brand-management-dialogs/brand-management-dialogs.component';
import { IBrandDelete } from './../../../../common/interfaces/brandManagement';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IBrand } from '../../../../common/interfaces/brandManagement';
import { PAGE_MODES } from './../../../../constants/app-constants';
import { BrandManagementService } from './../../../services/brand-management.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';


@Component({
  selector: 'app-homepage-brand',
  templateUrl: './homepage-brand.component.html',
  styleUrls: ['./homepage-brand.component.scss']
})
export class HomepageBrandComponent implements OnInit, OnChanges, OnDestroy {

  @Output() brandSelectionEvent = new EventEmitter();
  @Output() setHomePageModeEvent = new EventEmitter();
  @Input() pageMode;

  brands: Array<IBrand> = [];
  masterBrands: Array<IBrand> = [];
  selectedBrandsList: Array<number> = [];
  isLoading = true;
  errorLoading = false;
  userInfo = JSON.parse(sessionStorage.getItem('user'));
  name:any;  

  constructor(
    public dialog: MatDialog,
    private brandManagementService: BrandManagementService,
    protected googleTagManagerService: GoogleTagManagerService
  ) { }

  ngOnInit() {
    this.getBrands();
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Brand_Management`,
      eventAction: "PageView",
      eventLable: `Global Elements Brand Management Page view`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  ngOnChanges() {
    if (this.pageMode == PAGE_MODES.DELETE) {
      this.handleDelete()
    }
  }

  isbrandselected(brandId) {
    return this.selectedBrandsList.includes(brandId)
  }


  selectBrand(brandId) {

    if (this.selectedBrandsList.includes(brandId)) {
      this.selectedBrandsList = this.selectedBrandsList.filter(el => el != brandId)
    } else {
      this.selectedBrandsList.push(brandId);
    }

    const brandsList = this.brands.filter(brand => this.selectedBrandsList.includes(brand.brandId))
    this.brandSelectionEvent.emit(brandsList);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Brand_Management`,
      eventAction: "Brand Select Clicked",
      eventLable: `Global Elements select Brand click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }


  handleDelete() {

    const brandsList = this.brands.filter(brand => this.selectedBrandsList.includes(brand.brandId));


    const brandDeleteObjList: Array<IBrandDelete> = brandsList.reduce((acc: Array<any>, cv: IBrand) => {
      const temp: IBrandDelete = {
        brandId: cv.brandId,
        brandLevel: cv.brandType
      }
      acc.push(temp);
      return acc;
    }, [])

    const dialogRef = this.dialog.open(BrandManagementDialogsComponent, {
      data: {
        type: 'delete',
        module: 'brand',
        apiData: {
          brands: brandDeleteObjList
        }
      },

      minWidth: '500px'
    })


    dialogRef.beforeClose().subscribe(() => {
      this.selectedBrandsList = [];
      this.getBrands();
      this.brandSelectionEvent.emit([]);
      this.setHomePageModeEvent.emit();

    });

  }

  getBrands() {
    this.isLoading = true;
    this.errorLoading = false;
    this.brandManagementService.getAllBrandsByMkt(this.userInfo.defaultMarket).subscribe((res: Array<IBrand>) => {
      this.brands = res;
      this.masterBrands= this.brands
      this.isLoading = false;
      this.errorLoading = false;

    }, () => this.errorLoading = true);
  }

  Search(){
    this.brands = this.masterBrands.filter(el => el.brandName.toLocaleLowerCase().includes(this.name ? this.name.toLocaleLowerCase():''));
  }

  ngOnDestroy(): void {
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Brand_Management`,
      eventAction: "Brand Management Page exit",
      eventLable: `"Global Elements Brand Management Page exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
    }
}
