import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { PAGE_MODES } from './../../../constants/app-constants';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';

@Component({
  selector: 'app-brand-portfolio-group',
  templateUrl: './portfolio-group.component.html',
  styleUrls: ['./portfolio-group.component.scss']
})
export class PortfolioBrandGroupComponent implements OnInit {
  
  @Output('dirty') formDirty = new EventEmitter<boolean>();

  selectedPortfolioGroupList = [];
  mode = PAGE_MODES.HOME;
  userInfo = JSON.parse(sessionStorage.getItem("user"));

  constructor(protected googleTagManagerService: GoogleTagManagerService
  ) { }

  ngOnInit() {

  }

  getSelectedPortfolioGroup(childEmmitedEvent) {
    this.selectedPortfolioGroupList = childEmmitedEvent;
  }


  isHomePageMode() {
    return this.mode === PAGE_MODES.HOME || this.mode == PAGE_MODES.DELETE;
  }

  enableCreateMode() {
    this.changePageMode(PAGE_MODES.CREATE);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Portfolio_Group_Management`,
      eventAction: "New Portfolio Group click",
      eventLable: `Global Elements New Portfolio Group Button clickk`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }

  enableEditMode() {
    this.changePageMode(PAGE_MODES.EDIT);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Portfolio_Group_Management`,
      eventAction: "Edit Portfolio Group Button click",
      eventLable: `Global Elements Portfolio Group Edit Button click`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);

  }

  enableHomePageMode(){
    this.changePageMode(PAGE_MODES.HOME);
  }
  
  enableDeleteMode(){
    this.changePageMode(PAGE_MODES.DELETE);
    let tagObject =  {
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Portfolio_Group_Management`,
      eventAction: "Delete Portfolio Group",
      eventLable: `Global Elements Delete Portfolio Group Clicked`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
  }


  changePageMode(pageMode) {
    this.mode = pageMode
  }

  isFormDirty(isDirty: boolean) {
    this.formDirty.emit(isDirty);
  }
}
