import { Component, OnInit, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { PortfolioService } from "../services/portfolio.service";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { getGpId } from "../../common/utils/common-util";
import { DASHBOARD_MESSAGE } from "../../constants/app-constants";
import { environment } from "../../../environments/environment";
import { PowerBiConfigService } from "app/common/api/service/token.service";
import { NgxPowerBiService } from "ngx-powerbi";
import { Report } from "report";
import { IEmbedConfiguration, models } from "powerbi-client";
@Component({
  selector: "app-portfolio-rto-dashboard",
  templateUrl: "./portfolio-rto-dashboard.component.html",
  styleUrls: ["./portfolio-rto-dashboard.component.scss"],
})
export class portfolioPerformanceDashboardComponent implements OnInit {
  @Input() elementDetails;

  portfolioRTODashIframe;
  portfolioId: string;
  portfolioName: string;
  isLoading: boolean = false;
  message: string;
  accessToken: string;
  reportId: string;
  reportEmbedUrl: string;
  reportToken: string;
  config: IEmbedConfiguration;
  private powerBiService: NgxPowerBiService;
  private pbiContainerElement: HTMLElement;
  constructor(
    private sanitizer: DomSanitizer,
    private portfolioService: PortfolioService,
    protected googleTagManagerService: GoogleTagManagerService,
    private powerBiConfigService: PowerBiConfigService
  ) {
    this.powerBiService = new NgxPowerBiService();
  }

  ngOnInit() {
    this.isLoading = true;
    this.accessToken = this.powerBiConfigService.accessToken.token;
    // this.getEmbedToken();
    console.log(
      "portfolioPerformanceDashboardComponent accesstoken",
      this.accessToken
    );
    this.pbiContainerElement = <HTMLElement>(
      document.getElementById("custom-pbi-container")
    );
    this.reportId = this.powerBiConfigService.reportId;
    this.reportEmbedUrl = this.powerBiConfigService.reportEmbedUrl;
    this.config = this.powerBiConfigService.config;
    console.log("this.config", this.config);
    this.powerBiService.embed(this.pbiContainerElement, this.config);
    const reportInfo = {
      reportId: this.reportId,
      datasetId: this.reportId,
      workspaceId: this.reportId,
    };
    // this.reportToken = this.getEmbedToken() as unknown as string;
    console.log("this.reportToken", this.reportToken);
    console.log("this.accessToken:::::", this.accessToken);

    if (this.elementDetails.portfoliodata) {
      this.portfolioName = this.elementDetails.portfoliodata.portfolioName;
      this.portfolioId = this.elementDetails.portfoliodata.portfolioId;
      //this.getPortfolioDashboardUrl();
    } else {
      this.message = DASHBOARD_MESSAGE.DATORAMA_SUCCESS.replace(
        "##PLANNAME##",
        this.portfolioName
      );
      this.isLoading = false;
    }
    this.googleTagManagerService.pushTag({
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `PortFolio_Performance_Dashboard`,
      eventAction: "PageView",
      eventLable: `PortFolio Performance Dashboard Page View`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    });
  }

  getPortfolioDashboardUrl() {
    const urlObj = { ...this.elementDetails.cPerfData };
    this.portfolioService
      .getPortfolioDashboardUrl(this.portfolioId.toString())
      .subscribe(
        (res) => {
          if (res && res["rtoLink"]) {
            const rtolink = res["rtoLink"] + "&accessToken=" + this.accessToken;
            console.log("rtolink", rtolink);
            this.portfolioRTODashIframe =
              this.sanitizer.bypassSecurityTrustResourceUrl(rtolink);
          }
          this.message = DASHBOARD_MESSAGE.DATORAMA_SUCCESS.replace(
            "##PLANNAME##",
            this.portfolioName
          );
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.message = DASHBOARD_MESSAGE.ERROR;
        }
      );
  }
  /*getEmbedToken() {
    this.powerBiConfigService.getEmbedToken().subscribe((embedToken) => {
      if (embedToken) {
        // console.log("getEmbedToken::::::::", embedToken);
        this.reportToken = embedToken.result.token;
        console.log("reportToken::::::::", this.reportToken);
        if (this.reportToken) {
          console.log("IEmbedConfiguration reportToken", this.reportToken);
          // return embedToken;
          const config: IEmbedConfiguration = {
            type: "report",
            id: this.reportId,
            embedUrl: this.reportEmbedUrl,
            accessToken: this.reportToken as unknown as string,
            tokenType: models.TokenType.Embed,
            permissions: models.Permissions.All,
            viewMode: models.ViewMode.View,
          };
          console.log("IEmbedConfiguration config", config);
        }
      }
    });
  }*/

  ngOnDestroy(): void {
    this.googleTagManagerService.pushTag({
      event: `custom_${environment.environmentName}_Event`,
      eventCategory: `Campaign_Performance_Dashboard`,
      eventAction: "PageExit",
      eventLable: `Campaign Performance Dashboard Page Exit`,
      customeEvent: `custom_${environment.environmentName}_Event`,
      userId: getGpId(),
      user_id: getGpId(),
    });
  }
}
