import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-optimizer-message-dialog',
  templateUrl: './optimizer-message-dialog.component.html',
  styleUrls: ['./optimizer-message-dialog.component.scss']
})
export class OptimizerMessageDialogComponent implements OnInit {

  message:string;
  constructor() { }

  ngOnInit() {
    // this.message = 'Scenario submitted. The system takes 5-10 minutes to optimize your scenario and will return both the original and optimized scenarios in the platform. You will receive a notification when the optimization is completed.';
    this.message = 'Scenario optimization is in progress. The system may take a couple minutes to complete your request. You will receive a notification once the optimization is completed.';
  }

}
