import { IBreadcrumb } from './../../interfaces/common';
import { CALENDER_ROUTES, CALENDER_TABLE_MODES, CALENDAR_MONTHS, BREADCRUMBS_REFERENCE_TYPES, BREADCRUMBS_LAST_NODE_TYPES } from './../../../constants/app-constants';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IPortfolioBody } from 'app/common/interfaces/portfolio';
import { AnnualMediaCalendarService } from 'app/common/services/annual-media-calendar.service';
import { SharedDataService } from 'app/common/services/shared-data.service';
import { StoreService } from '../../../old/_services/_store.service'
import { MatTableDataSource, MatRadioChange, MatSort, Sort } from '../../../../../node_modules/@angular/material';
import { GlobalElementsService } from './../../../global-elements/services/global-elements.service';
import { IBudgetByMonth, IBudgetByWeek, CALENDAR_ROUTES } from './../../interfaces/annualMediaCalender';
import * as moment from 'moment'
import { IPlanBody } from '../../interfaces/plan';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';
import { getGpId } from 'app/common/utils/common-util';


@Component({
  selector: 'app-annual-media-calendar',
  templateUrl: './annual-media-calendar.component.html',
  styleUrls: ['./annual-media-calendar.component.scss']
})
export class AnnualMediaCalendarComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selectedMarket = sessionStorage.getItem('market');
  tooltipTitlePlanMediaCalendar:string;
  showtooltipTitlePlanMediaCalendar:boolean = false;
  paramsId: number;
  calendarRoutes: CALENDAR_ROUTES;
  anualCalWorkingYear: number;
  pathDetails: IBreadcrumb;
  isPortfolioRoute: boolean = true;
  isWeekView: boolean = true;
  isFlowPack: boolean = true;
  isUserInput: boolean = false;
  dataSource = new MatTableDataSource<any>();
  calendarMonths: Array<string> = [...CALENDAR_MONTHS];
  displayedColumns: string[];
  displayColumnsForMonthlyView: string[] = [
    'name',
    'totalBudget',
    ...CALENDAR_MONTHS
  ];
  displayName: string;
  campaignList: Array<string> = [];
  selectedCampaign1: Array<string> = [];
  displayColumnsForWeeklyView: string[] = [
    'name',
    'totalBudget',
    'week_1',
    'week_2',
    'week_3',
    'week_4',
    'week_5',
    'week_6',
    'week_7',
    'week_8',
    'week_9',
    'week_10',
    'week_11',
    'week_12',
    'week_13',
    'week_14',
    'week_15',
    'week_16',
    'week_17',
    'week_18',
    'week_19',
    'week_20',
    'week_21',
    'week_22',
    'week_23',
    'week_24',
    'week_25',
    'week_26',
    'week_27',
    'week_28',
    'week_29',
    'week_30',
    'week_31',
    'week_32',
    'week_33',
    'week_34',
    'week_35',
    'week_36',
    'week_37',
    'week_38',
    'week_39',
    'week_40',
    'week_41',
    'week_42',
    'week_43',
    'week_44',
    'week_45',
    'week_46',
    'week_47',
    'week_48',
    'week_49',
    'week_50',
    'week_51',
    'week_52'
  ];
  

  planWeeklyCalendar: Array<IBudgetByWeek> = [];
  channelWeeklyCalendar: Array<IBudgetByWeek> = [];
  channelUserWeeklyCalendar: Array<IBudgetByWeek> = [];
  subBrandsWeeklyCalendar: Array<IBudgetByWeek> = [];
  subBrandsUserWeeklyCalendar: Array<IBudgetByWeek> = [];
  mjtbdWeeklyCalendar: Array<IBudgetByWeek> = [];
  commstasksWeeklyCalendar: Array<IBudgetByWeek> = [];

  planMonthlyCalendar: Array<IBudgetByMonth> = [];
  channelMonthlyCalendar: Array<IBudgetByMonth> = [];
  subBrandsMonthlyCalendar: Array<IBudgetByMonth> = [];
  mjtbdMonthlyCalendar: Array<IBudgetByMonth> = [];
  commstasksMonthlyCalendar: Array<IBudgetByMonth> = [];
  channelUserMonthlyCalendar: Array<IBudgetByMonth> = [];
  mapAllWeeksOfYear = [];
  isLoading: boolean = true;
  errorLoading: boolean = false;
  currentView: string;
  channelWeeklyDataSource;
  channelUserWeeklyDataSource;
  planWeeklyDataSource;
  subBrandsWeeklyDataSource;
  subBrandsUserWeeklyDataSource
  subBrandsUserMonthlyCalendar
  mjtbdWeeklyDataSource
  commstasksWeeklyDataSource


  channelMonthlyDataSource;
  channelUserMonthlyDataSource;
  planMonthlyDataSource;
  subBrandsMonthlyDataSource;
  subBrandsUserMonthlyDataSource
  mjtbdMonthlyDataSource;
  commstasksMonthlyDataSource;



  tableView;

  constructor(
    private globalElementsService: GlobalElementsService,
    private route: ActivatedRoute,
    private sharedData: SharedDataService,
    private annualMediaCalendarService: AnnualMediaCalendarService,
    private store: StoreService,
    protected googleTagManagerService: GoogleTagManagerService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      const planId = params['id'];
      this.paramsId = planId && Number(planId);
    });
    this.globalElementsService.getTooltipData('AnnualMediaCalendar', this.selectedMarket).subscribe((res_n: any) => {
      if(res_n.data && res_n.data[0]) {
        this.tooltipTitlePlanMediaCalendar = res_n.data[0]["tooltipText"];
      }
    });
    this.calendarRoutes = this.route.snapshot.params.routeName;
    this.isPortfolioRoute = CALENDER_ROUTES.PORTFOLIO === this.calendarRoutes;
    this.sharedData.getAnualCalWorkingYear.subscribe((res: any) => {
      if (res) {
        this.anualCalWorkingYear = res;
        this.getAnnualMediaCalendar();
        this.mapAllWeeksOfYear = this.getWeekPeriodsInYear(this.anualCalWorkingYear);
        if(!this.isPortfolioRoute) {
          this.calendarViewChangeNew('FLOWPACK');
        }
      }
    })

    this.displayedColumns = [... this.displayColumnsForWeeklyView];
    this.currentView = this.isPortfolioRoute ? 'TM' : 'subBrands';

  }
  showTooltip(){
    this.showtooltipTitlePlanMediaCalendar = true;
  }
  hideTooltip(){
    this.showtooltipTitlePlanMediaCalendar = false;
  }

  viewTableChange(viewType: string) {
    this.currentView = viewType;

    this.displayedColumns = this.isWeekView ? [...this.displayColumnsForWeeklyView] : [...this.displayColumnsForMonthlyView]
    if (viewType == 'channel') {
      this.dataSource = this.isWeekView ? this.channelWeeklyDataSource : this.channelMonthlyDataSource;
      this.adjustColumnSorting();
    }
    if (viewType == 'trademark') {
      this.dataSource = this.isWeekView ? this.planWeeklyDataSource : this.planMonthlyDataSource;
      this.adjustColumnSorting();
    }
    if (viewType == 'subBrands') {
      this.dataSource = this.isWeekView ? this.subBrandsWeeklyDataSource : this.subBrandsMonthlyDataSource;
      this.adjustColumnSorting();
    }
    if (viewType == 'mjtbd') {
      this.displayedColumns = this.isWeekView ? [...this.displayColumnsForWeeklyView, 'week_53'] : [...this.displayColumnsForMonthlyView]
      this.dataSource = this.isWeekView ? this.mjtbdWeeklyDataSource : this.mjtbdMonthlyDataSource;
      this.adjustColumnSorting();
    }
    if (viewType == 'commstasks') {
      this.dataSource = this.isWeekView ? this.commstasksWeeklyDataSource : this.commstasksMonthlyDataSource;
      this.adjustColumnSorting();
      this.campaignList = this.dataSource.filteredData.map((el) => el.campaignName ).filter((value, index, self) => self.indexOf(value) === index);
      this.selectedCampaign1 = [];
    }
    if (viewType == 'subBrands-user') {
      this.dataSource = this.isWeekView ? this.subBrandsUserWeeklyDataSource : this.subBrandsUserMonthlyDataSource;
      this.adjustColumnSorting();
    }
    if (viewType == 'channel-user') {
      this.dataSource = this.isWeekView ? this.channelUserWeeklyDataSource : this.channelUserMonthlyDataSource;
      this.adjustColumnSorting();
    }
  }

  calendarViewChange(view: string) {
    this.isWeekView = view == 'WEEK';
    this.viewTableChange(this.currentView);
  }
  calendarViewChangeNew(view: string) {
    // this.isFlowPack = view == 'FLOWPACK';
    if (view == 'FLOWPACK') {
      this.currentView = this.isPortfolioRoute ? 'TM' : 'subBrands';
      this.isUserInput = false;
      this.isFlowPack = true;
    } else {
      this.currentView = 'mjtbd';
      this.isUserInput = true;
      this.isFlowPack = false;
    }
    this.viewTableChange(this.currentView);
  }
  clickMediaCalendarBtn(){
    if(!this.isPortfolioRoute){
      let tagObject =  {
        event: `custom_${environment.environmentName}_Event`,
        eventCategory: `Annual Media Calendar Trademark_Page`,
        eventAction: "Trademark Annual Media Calendar 2.0 Button Click",
        eventLable: `Annual Media Calendar 2.0 Button clicked from Trademark Page`,
        customeEvent: `custom_${environment.environmentName}_Event`,
        userId: getGpId(),
        user_id: getGpId()
      }
      googleAnalyticsCode(tagObject,this.googleTagManagerService);
    } 
    else {
      let tagObject =  {
        event: `custom_${environment.environmentName}_Event`,
        eventCategory: `Annual Media Calendar Portfolio_Page`,
        eventAction: "Portfolio Annual Media Calendar 2.0 Button Click",
        eventLable: `Annual Media Calendar 2.0 Button clicked from Portfolio Page`,
        customeEvent: `custom_${environment.environmentName}_Event`,
        userId: getGpId(),
        user_id: getGpId()
      }
      googleAnalyticsCode(tagObject,this.googleTagManagerService);
    }
    let tagObject =  {
      event: `custom_${environment.environmentName}_pageView`,
      pageUrl:`canvas-datorama.pepsico.com/external/dashboard`,
      pageTitle:`Global Dataroma Media Calendar Page`,
      userId: getGpId(),
      user_id: getGpId()
    }
    googleAnalyticsCode(tagObject,this.googleTagManagerService);
}

  getAnnualMediaCalendar() {
    this.isLoading = true;
    this.errorLoading = false;

    // Fetch Plan and channel based on portfolio ID
    if (CALENDER_ROUTES.PORTFOLIO === this.calendarRoutes) {
      let portfolioObj: IPortfolioBody = {
        portfolioId: this.paramsId,
        year: this.anualCalWorkingYear
      }
      this.annualMediaCalendarService.getPortfolioAnnualMediaCalendar(portfolioObj).subscribe((res: any) => {
        this.isLoading = false;
        const { channelCalendar, planCalendar, displayName } = res;
        this.constructWeeklyDataSource(CALENDER_TABLE_MODES.PLAN, planCalendar, true);
        this.constructWeeklyDataSource(CALENDER_TABLE_MODES.CHANNEL, channelCalendar, false);
        this.constructMonthlyDataSource(CALENDER_TABLE_MODES.PLAN, planCalendar, false);
        this.constructMonthlyDataSource(CALENDER_TABLE_MODES.CHANNEL, channelCalendar, false);
        this.displayName = displayName;
        this.pathDetails = {
          lastNodeName: 'Media Channel Calendar',
          pageId: this.paramsId,
          pageRefType: BREADCRUMBS_REFERENCE_TYPES.PORTFOLIO,
          lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.MEDIA_CHANNEL_CALENDAR_PF
        }

      }, () => {
        this.isLoading = false;
        this.errorLoading = true;
      });
    }


    //Fetch Sub brands and Channels based on Plan ID
    if (CALENDER_ROUTES.PLAN === this.calendarRoutes) {
      let planObj: IPlanBody = {
        planId: this.paramsId,
        year: this.anualCalWorkingYear
      }

      this.annualMediaCalendarService.getPlanAnnualMediaCalendar(planObj).subscribe((res: any) => {
        this.isLoading = false;
        const { channelCalendar, subBrandCalendar, displayName, mjtbdCalendar, commsTasksCalendar, subBrandUserCalendar, ChannelUserCalendar } = res;
        this.constructWeeklyDataSource(CALENDER_TABLE_MODES.SUB_BRAND, subBrandCalendar, true);
        this.constructWeeklyDataSource(CALENDER_TABLE_MODES.CHANNEL, channelCalendar, false);
        this.constructMonthlyDataSource(CALENDER_TABLE_MODES.SUB_BRAND, subBrandCalendar, false);
        // this.constructWeeklyDataSource(CALENDER_TABLE_MODES.SUB_BRAND_USER, subBrandUserCalendar, false);
        // this.constructMonthlyDataSource(CALENDER_TABLE_MODES.SUB_BRAND_USER, subBrandUserCalendar, false);
        this.constructMonthlyDataSource(CALENDER_TABLE_MODES.CHANNEL, channelCalendar, false);
        // this.constructWeeklyDataSource(CALENDER_TABLE_MODES.CHANNEL_USER, ChannelUserCalendar, false);
        // this.constructMonthlyDataSource(CALENDER_TABLE_MODES.CHANNEL_USER, ChannelUserCalendar, false);
        this.constructWeeklyMJTBDDataSource(CALENDER_TABLE_MODES.MJTBD, mjtbdCalendar, false);
        this.constructWeeklyMJTBDDataSource(CALENDER_TABLE_MODES.COMMSTASKS, commsTasksCalendar, false);
        this.constructMonthlyMJTBDDataSourceNew(CALENDER_TABLE_MODES.MJTBD, mjtbdCalendar, false);
        this.constructMonthlyMJTBDDataSourceNew(CALENDER_TABLE_MODES.COMMSTASKS, commsTasksCalendar, false);
        this.displayName = displayName;
        this.pathDetails = {
          lastNodeName: 'Media Channel Calendar',
          pageId: this.paramsId,
          pageRefType: BREADCRUMBS_REFERENCE_TYPES.PLAN,
          lastNodeType: BREADCRUMBS_LAST_NODE_TYPES.MEDIA_CHANNEL_CALENDAR_PLAN
        }
      }, () => {
        this.isLoading = false;
        this.errorLoading = true;
      });
    }

  }

  createArray(n: number) {
    return new Array(n);
  }

  getWeekSpend(ele: IBudgetByWeek, weekNum) {
    const amount = ele[`week_${weekNum}`] || 0;
    return amount;
  }

  getMonthSpend(ele: IBudgetByMonth, month: string) {
    return ele[month] || 0;
  }

  constructWeeklyDataSource(tableType: string, calendarData: any, isDefault: boolean) {

    let weeklyCalendar: Array<IBudgetByWeek> = [];
    Object.keys(calendarData).map((name: string) => {

      let weeklyBudget = <IBudgetByWeek>{
        name,
        totalBudget: 0
      };
      Object.keys(calendarData[name]).map(wkNum => {
        const spendBudget = calendarData[name][wkNum]['campaignSpend'];
        const formattedSpendBudget = spendBudget ? Number(spendBudget) : null;
        weeklyBudget.totalBudget += formattedSpendBudget;
        weeklyBudget[`week_${wkNum}`] = formattedSpendBudget;
      })
      weeklyCalendar.push(weeklyBudget);
    })



    switch (tableType) {
      case CALENDER_TABLE_MODES.CHANNEL:
        this.channelWeeklyCalendar = [...weeklyCalendar];
        this.channelWeeklyDataSource = new MatTableDataSource<any>(this.channelWeeklyCalendar);
        if (isDefault) {
          this.dataSource = this.channelWeeklyDataSource;
          this.adjustColumnSorting();
        }
        break;
      case CALENDER_TABLE_MODES.CHANNEL_USER:
        this.channelUserWeeklyCalendar = [...weeklyCalendar];
        this.channelUserWeeklyDataSource = new MatTableDataSource<any>(this.channelUserWeeklyCalendar);
        if (isDefault) {
          this.dataSource = this.channelUserWeeklyDataSource;
          this.adjustColumnSorting();
        }
        break;

      case CALENDER_TABLE_MODES.PLAN:
        this.planWeeklyCalendar = [...weeklyCalendar];
        this.planWeeklyDataSource = new MatTableDataSource<any>(this.planWeeklyCalendar);
        if (isDefault) {
          this.dataSource = this.planWeeklyDataSource;
          this.adjustColumnSorting();
        }
        break;

      case CALENDER_TABLE_MODES.SUB_BRAND:
        this.subBrandsWeeklyCalendar = [...weeklyCalendar];
        this.subBrandsWeeklyDataSource = new MatTableDataSource<any>(this.subBrandsWeeklyCalendar);
        if (isDefault) {
          this.dataSource = this.subBrandsWeeklyDataSource;
          this.adjustColumnSorting();
        }
        break;

      case CALENDER_TABLE_MODES.MJTBD:
        this.mjtbdWeeklyCalendar = [...weeklyCalendar];
        this.mjtbdWeeklyDataSource = new MatTableDataSource<any>(this.mjtbdWeeklyCalendar);
        if (isDefault) {
          this.dataSource = this.mjtbdWeeklyDataSource;
          this.adjustColumnSorting();
        }
        break;

      case CALENDER_TABLE_MODES.COMMSTASKS:
        this.commstasksWeeklyCalendar = [...weeklyCalendar];
        this.commstasksWeeklyDataSource = new MatTableDataSource<any>(this.commstasksWeeklyCalendar);
        if (isDefault) {
          this.dataSource = this.commstasksWeeklyDataSource;
          this.adjustColumnSorting();
        }
        break;
      case CALENDER_TABLE_MODES.SUB_BRAND_USER:
        this.subBrandsUserWeeklyCalendar = [...weeklyCalendar];
        this.subBrandsUserWeeklyDataSource = new MatTableDataSource<any>(this.subBrandsUserWeeklyCalendar);
        if (isDefault) {
          this.dataSource = this.subBrandsUserWeeklyDataSource;
          this.adjustColumnSorting();
        }
        break;
    }

  }



  constructMonthlyDataSource(tableType: string, calendarData: any, isDefault: boolean) {

    let monthlyCalendar: Array<IBudgetByMonth> = [];

    Object.keys(calendarData).map(name => {
      let monthlyBudget = <IBudgetByMonth>{
        name,
        totalBudget: 0
      };

      Object.keys(calendarData[name]).map(wkNum => {

        const startDate = calendarData[name][wkNum]['campaignStartDate'];
        const endDate = calendarData[name][wkNum]['campaignEndDate'];
        const spendBudget = calendarData[name][wkNum]['campaignSpend'];
        const campaignStartMonth = calendarData[name][wkNum]['campaignStartMonth'];

        const formattedSpendBudget = spendBudget ? Number(spendBudget) : 0;
        const startDateMoment = moment(startDate, "YYYY-MM-DD");

        if (startDateMoment.isValid()) {
          var monthName = campaignStartMonth;
          var MONTH = monthName ? monthName.substring(0,3) : '';
          const month = MONTH;
          if (!monthlyBudget[month]) {
            monthlyBudget[month] = 0;
          }
          monthlyBudget[month] += formattedSpendBudget;

        }
      })
      this.calendarMonths.forEach(month => {
        if (monthlyBudget[month]) {
          monthlyBudget.totalBudget += monthlyBudget[month];
        }
      })
      monthlyCalendar.push(monthlyBudget);

    })

    switch (tableType) {
      case CALENDER_TABLE_MODES.SUB_BRAND_USER:
        this.subBrandsUserMonthlyCalendar = [...monthlyCalendar];
        this.subBrandsUserMonthlyDataSource = new MatTableDataSource<any>(this.subBrandsUserMonthlyCalendar);
        if (isDefault) {
          this.dataSource = this.subBrandsUserMonthlyDataSource;
          this.adjustColumnSorting();
        }
        break;
      case CALENDER_TABLE_MODES.CHANNEL:
        this.channelMonthlyCalendar = [...monthlyCalendar];
        this.channelMonthlyDataSource = new MatTableDataSource<any>(this.channelMonthlyCalendar);
        if (isDefault) {
          this.dataSource = this.channelMonthlyDataSource;
          this.adjustColumnSorting();
        }
        break;
      case CALENDER_TABLE_MODES.CHANNEL_USER:
        this.channelUserMonthlyCalendar = [...monthlyCalendar];
        this.channelUserMonthlyDataSource = new MatTableDataSource<any>(this.channelUserMonthlyCalendar);
        if (isDefault) {
          this.dataSource = this.channelUserMonthlyDataSource;
          this.adjustColumnSorting();
        }
        break;

      case CALENDER_TABLE_MODES.PLAN:
        this.planMonthlyCalendar = [...monthlyCalendar];
        this.planMonthlyDataSource = new MatTableDataSource<any>(this.planMonthlyCalendar);
        if (isDefault) {
          this.dataSource = this.planMonthlyDataSource;
          this.adjustColumnSorting();
        }
        break;


      case CALENDER_TABLE_MODES.SUB_BRAND:
        this.subBrandsMonthlyCalendar = [...monthlyCalendar];
        this.subBrandsMonthlyDataSource = new MatTableDataSource<any>(this.subBrandsMonthlyCalendar);
        if (isDefault) {
          this.dataSource = this.subBrandsMonthlyDataSource;
          this.adjustColumnSorting();

        }
        break;
      case CALENDER_TABLE_MODES.MJTBD:
        this.mjtbdMonthlyCalendar = [...monthlyCalendar];
        this.mjtbdMonthlyDataSource = new MatTableDataSource<any>(this.mjtbdMonthlyCalendar);
        if (isDefault) {
          this.dataSource = this.mjtbdMonthlyDataSource;
          this.adjustColumnSorting();

        }
        break;
      case CALENDER_TABLE_MODES.COMMSTASKS:
        this.commstasksMonthlyCalendar = [...monthlyCalendar];
        this.commstasksMonthlyDataSource = new MatTableDataSource<any>(this.commstasksMonthlyCalendar);
        if (isDefault) {
          this.dataSource = this.commstasksMonthlyDataSource;
          this.adjustColumnSorting();

        }
        break;
    }
  }

getWeekPeriodsInYear(year) {
    let weeks = [];
  
    // Get the first and last day of the year
    let currentDay = moment([year, 1]).startOf('year');
    let dayOfWeek = moment(currentDay).day();
    let lastDay = moment([year, 1]).endOf('year');
    let weeksInYear = moment(`${year}-01-01`).isoWeeksInYear();
    let daysToAdd = 7 - dayOfWeek;
  
    for (let weekNumber = 1; weekNumber <= weeksInYear + 1; weekNumber++) {
      let endOfWeek = moment(currentDay).add(daysToAdd, 'days');
      let _push = true;
      if (moment(endOfWeek).year() !== year) {
          endOfWeek = lastDay;
      }
      weeks.push({ weekNumber, start: moment(currentDay).format('YYYY-MM-DD'), end: moment(endOfWeek).format('YYYY-MM-DD') });
      currentDay = endOfWeek.add(1, 'day');
      daysToAdd = 6;
      if(endOfWeek == lastDay) {
        break;
      }
  }
  return weeks;
  }
  getWeekNoFromDate(inputDate:any, isStartWeek) {
  let _result = {};
  let findweeknumber = this.mapAllWeeksOfYear.find((el)=> moment(inputDate).isBetween(el.start, el.end, null, '[]'));
  _result['weekNumber'] = findweeknumber ? findweeknumber.weekNumber : 0;
  if(isStartWeek) {
    _result['diff'] = findweeknumber ? moment(findweeknumber.end).diff(inputDate, "days") : 0;
  } else {
    _result['diff'] = findweeknumber ? ((-1 *moment(findweeknumber.start).diff(inputDate, "days")) + 1) : 0;
  }
  
  return _result;
}
getMonthFromWeekNumber(weekNumber) {
  let findweeknumber = this.mapAllWeeksOfYear.find((el)=> el.weekNumber == weekNumber);
  return findweeknumber.start;
}

  constructWeeklyMJTBDDataSource(tableType: string, calendarData: any, isDefault: boolean) {

    let weeklyCalendar: Array<IBudgetByWeek> = [];
    Object.keys(calendarData).map((name: string) => {

      let weeklyBudget = <IBudgetByWeek>{
        name,
        totalBudget: 0
      };
      if (CALENDER_TABLE_MODES.COMMSTASKS === tableType) {
        weeklyBudget["campaignName"] = '';
      }
      Object.keys(calendarData[name]).map(wkNum => {
        const startDate = calendarData[name][wkNum]['mjtbdStartDate'];
        const endDate = calendarData[name][wkNum]['mjtbdEndDate'];
        if (CALENDER_TABLE_MODES.COMMSTASKS === tableType) {
          weeklyBudget["campaignName"] = calendarData[name][wkNum]['campaignName'];
        }
        const spendBudget = calendarData[name][wkNum]['campaignSpend'];
        let startDateMoment = moment(startDate, "YYYY-MM-DD");
        let endDateMoment = moment(endDate, "YYYY-MM-DD");
        if (startDateMoment.isValid() && endDateMoment.isValid()) {
          if (endDateMoment.year() < startDateMoment.year()) {
            weeklyCalendar.push(weeklyBudget);
            return null;
          }
          let diffInDays = Math.round(endDateMoment.diff(startDateMoment, 'days', true) ? endDateMoment.diff(startDateMoment, 'days', true) : 1);
          weeklyBudget.totalBudget = Number(spendBudget);
          if (startDateMoment.year() != this.anualCalWorkingYear || endDateMoment.year() != this.anualCalWorkingYear) {
            if (startDateMoment.year() < this.anualCalWorkingYear && endDateMoment.year() > this.anualCalWorkingYear) {
              startDateMoment = moment(`${this.anualCalWorkingYear}-01-01`, "YYYY-MM-DD");
              endDateMoment = moment(`${this.anualCalWorkingYear}-12-31`, "YYYY-MM-DD");
            } else if (startDateMoment.year() != this.anualCalWorkingYear && endDateMoment.year() == this.anualCalWorkingYear) {
              startDateMoment = moment(`${this.anualCalWorkingYear}-01-01`, "YYYY-MM-DD");
            } else if (endDateMoment.year() != this.anualCalWorkingYear && startDateMoment.year() == this.anualCalWorkingYear) {
              endDateMoment = moment(`${this.anualCalWorkingYear}-12-31`, "YYYY-MM-DD");
            } else {
              return null;
            }
          }
          let startWeek = this.getWeekNoFromDate(moment(startDateMoment).format("YYYY-MM-DD"), true);
          let endWeek = this.getWeekNoFromDate(moment(endDateMoment).format("YYYY-MM-DD"), false);
          const formattedSpendBudgetInADay = Number(spendBudget) ? Number(spendBudget / Math.round(diffInDays)) : 0;
          // endWeek = startWeek == 1 && endWeek < 52 ? endWeek - 1 : endWeek; 
          for (let i = startWeek["weekNumber"]; i <= endWeek["weekNumber"]; i++) {
            let shortName = `week_${i}`;
            if (!weeklyBudget[shortName]) {
              weeklyBudget[shortName] = 0;
            }
            if (startWeek["weekNumber"] == i) {
              weeklyBudget[shortName] = formattedSpendBudgetInADay * (startWeek["diff"]);
            } else if (endWeek["weekNumber"] == i) {
              weeklyBudget[shortName] = formattedSpendBudgetInADay * (endWeek["diff"]);
            } else {
              weeklyBudget[shortName] = formattedSpendBudgetInADay * 7;
            }
          }
        } else {
          weeklyBudget.totalBudget = Number(spendBudget);
        }
      })
      weeklyCalendar.push(weeklyBudget);
    })



    switch (tableType) {

      case CALENDER_TABLE_MODES.MJTBD:
        this.mjtbdWeeklyCalendar = [...weeklyCalendar];
        this.mjtbdWeeklyDataSource = new MatTableDataSource<any>(this.mjtbdWeeklyCalendar);
        if (isDefault) {
          this.dataSource = this.mjtbdWeeklyDataSource;
          this.adjustColumnSorting();
        }
        break;

      case CALENDER_TABLE_MODES.COMMSTASKS:
        this.commstasksWeeklyCalendar = [...weeklyCalendar];
        this.commstasksWeeklyDataSource = new MatTableDataSource<any>(this.commstasksWeeklyCalendar);
        if (isDefault) {
          this.dataSource = this.commstasksWeeklyDataSource;
          this.adjustColumnSorting();
        }
        break;
    }

  }


  constructMonthlyMJTBDDataSourceOld(tableType: string, calendarData: any, isDefault: boolean) {

    let monthlyCalendar: Array<IBudgetByMonth> = [];

    Object.keys(calendarData).map(name => {
      let monthlyBudget = <IBudgetByMonth>{
        name,
        totalBudget: 0
      };

      Object.keys(calendarData[name]).map(wkNum => {

        const startDate = calendarData[name][wkNum]['mjtbdStartDate'];
        const endDate = calendarData[name][wkNum]['mjtbdEndDate'];

        const spendBudget = calendarData[name][wkNum]['campaignSpend'];
        const startDateMoment = moment(startDate, "YYYY-MM-DD");

        const endDateMoment = moment(endDate, "YYYY-MM-DD");
        let monthDiff = endDateMoment.diff(startDateMoment, 'months') ? endDateMoment.diff(startDateMoment, 'months',true) : 1;
        let weekDiff = endDateMoment.diff(startDateMoment, 'weeks') ? endDateMoment.diff(startDateMoment, 'weeks') : 1;


        const formattedSpendBudget = Number(spendBudget) ? Number(spendBudget / Math.round(monthDiff)) : 0;
        const formattedSpendBudgetWeekly = Number(spendBudget) ? Number(spendBudget / weekDiff) : 0;

        let startMonth = moment(startDateMoment, "YYYY-MM-DD").month() + 1;
        let endMonth = moment(endDateMoment, "YYYY-MM-DD").month() + 1;

        let endMonthDateOfStartDate  = moment(startDateMoment).endOf('month').format('YYYY-MM-DD');
        let startMonthDateOfStartDate = moment(startDateMoment).startOf('month').format('YYYY-MM-DD');

        let endMonthDateOfEndDate  = moment(endDateMoment).endOf('month').format('YYYY-MM-DD');
        let startMonthDateOfEndDate = moment(endDateMoment).startOf('month').format('YYYY-MM-DD');

        let totalStartMonthWeeks =  moment(endMonthDateOfStartDate).diff(startMonthDateOfStartDate,'weeks');

        let weeksDiff_startMonth =  totalStartMonthWeeks - moment(endMonthDateOfStartDate).diff(startDateMoment,'weeks');

        let totalEndMonthWeeks = moment(endMonthDateOfEndDate).diff(startMonthDateOfEndDate,'weeks');
        // let startOfMonth = moment(startDateMoment).startOf('month').format('YYYY-MM-DD');
        
        let weeksDiff_endMonth =  totalEndMonthWeeks - moment(endDateMoment).diff(startMonthDateOfEndDate,'weeks');
        

        if (startDateMoment.isValid() && endDateMoment.isValid()) {
          for (let i = startMonth; i <= endMonth; i++) {
            
            let shortName = moment.monthsShort(i - 1);
            if (!monthlyBudget[shortName]) {
              monthlyBudget[shortName] = 0;
            }
            if(startMonth == i){
              monthlyBudget[shortName] = formattedSpendBudgetWeekly * weeksDiff_startMonth;
            } else if(endMonth == i){
              monthlyBudget[shortName] = formattedSpendBudgetWeekly * weeksDiff_endMonth;
            } else {
              monthlyBudget[shortName] = formattedSpendBudget;
              // startMonth = startMonth + 1;
            }
            
          }
        } else {
          monthlyBudget.totalBudget = Number(spendBudget);
        }
        
      })
      this.calendarMonths.forEach(month => {
        if (monthlyBudget[month]) {
          monthlyBudget.totalBudget += monthlyBudget[month];
        }
      })
      monthlyCalendar.push(monthlyBudget);

    })

    switch (tableType) {



      case CALENDER_TABLE_MODES.MJTBD:
        this.mjtbdMonthlyCalendar = [...monthlyCalendar];
        this.mjtbdMonthlyDataSource = new MatTableDataSource<any>(this.mjtbdMonthlyCalendar);
        if (isDefault) {
          this.dataSource = this.mjtbdMonthlyDataSource;
          this.adjustColumnSorting();

        }
        break;
      case CALENDER_TABLE_MODES.COMMSTASKS:
        this.commstasksMonthlyCalendar = [...monthlyCalendar];
        this.commstasksMonthlyDataSource = new MatTableDataSource<any>(this.commstasksMonthlyCalendar);
        if (isDefault) {
          this.dataSource = this.commstasksMonthlyDataSource;
          this.adjustColumnSorting();

        }
        break;
    }
  }
  campainSelectChange(selectedCampaignList) {
    if(selectedCampaignList.length > 0) {
      const _data = this.isWeekView ? this.commstasksWeeklyDataSource : this.commstasksMonthlyDataSource;
      this.dataSource = new MatTableDataSource<any>(_data.filteredData.filter(el => selectedCampaignList.indexOf(el.campaignName) > -1 ));
    } else {
      this.dataSource = this.isWeekView ? this.commstasksWeeklyDataSource : this.commstasksMonthlyDataSource;
  }
}
  constructMonthlyMJTBDDataSourceNew(tableType: string, calendarData: any, isDefault: boolean) {

    let monthlyCalendar: Array<IBudgetByMonth> = [];

    Object.keys(calendarData).map(name => {
      let monthlyBudget = <IBudgetByMonth>{
        name,
        totalBudget: 0
      };
    });
   const _data  = tableType ==  CALENDER_TABLE_MODES.MJTBD ? this.mjtbdWeeklyDataSource : this.commstasksWeeklyDataSource
   _data.filteredData.map((weekRowItem) => {
      let monthlyBudget = <IBudgetByMonth>{
        name : weekRowItem.name,
        totalBudget : weekRowItem.totalBudget
      }
      Object.keys(weekRowItem).forEach((el) => {
        if (CALENDER_TABLE_MODES.COMMSTASKS === tableType) {
          monthlyBudget["campaignName"] = weekRowItem.campaignName;
        }
        if(el.indexOf(`week_`) > -1){
          let weeknumber = el.split(`week_`)[1];
          let date = this.getMonthFromWeekNumber(weeknumber);
          let shortMonth = moment.monthsShort(moment(date).month());
          if (!monthlyBudget[shortMonth]) {
            monthlyBudget[shortMonth] = 0;
          }
          monthlyBudget[shortMonth] += weekRowItem[el];
        }
      })
      monthlyCalendar.push(monthlyBudget);

    })
    switch (tableType) {

      case CALENDER_TABLE_MODES.MJTBD:
        this.mjtbdMonthlyCalendar = [...monthlyCalendar];
        this.mjtbdMonthlyDataSource = new MatTableDataSource<any>(this.mjtbdMonthlyCalendar);
        if (isDefault) {
          this.dataSource = this.mjtbdMonthlyDataSource;
          this.adjustColumnSorting();

        }
        break;
      case CALENDER_TABLE_MODES.COMMSTASKS:
        this.commstasksMonthlyCalendar = [...monthlyCalendar];
        this.commstasksMonthlyDataSource = new MatTableDataSource<any>(this.commstasksMonthlyCalendar);
        if (isDefault) {
          this.dataSource = this.commstasksMonthlyDataSource;
          this.adjustColumnSorting();

        }
        break;
    }
  }

  adjustColumnSorting() {
    if(this.dataSource && this.sort) {
      this.dataSource.sort = this.sort;
      const sortState: Sort = { active: 'totalBudget', direction: 'desc' };
      this.sort.active = sortState.active;
      this.sort.direction = sortState.direction;
      this.sort.sortChange.emit(sortState);
    }
  }
}
