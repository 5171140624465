import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BrandManagementService } from 'app/global-elements/services/brand-management.service';
import { GlobalElementsService } from '../../../../global-elements/services/global-elements.service';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import { PlanService } from "../../../../plan/services/plan.service";
import { IBrandBaseTarget, IMjtbds, IPlanInfo, IPlanResponse } from 'app/common/interfaces/plan';
import { GLOBAL_ELEMENTS } from 'app/constants/app-constants';
import { SharedDataService } from 'app/common/services/shared-data.service';
import { FINAL_STATUS_LIST, FORM_REFERENCE_TYPES, MINIFIED_STATUS_LIST, SHAREPOINT_DOC_PATH, STATUS_LIST, STATUS_LIST_TYPES } from 'app/constants/app-constants';
import { IApproverList, IDocument, IBreadcrumb, ITeamMembers, ITeamMembersReq } from 'app/common/interfaces/common';
import { MatDialog } from '@angular/material';
import { MjtbdDialogComponent } from 'app/common/components/dialogs/mjtbd-dialog/mjtbd-dialog.component';
import { IBrandObjectives, IMjForm, IMjtbdBody, IMjtbdForm, IMjtbdPostObj, IMjtbdSave, IMJTBDdropdowns, IAudienceThumnails } from 'app/common/interfaces/plan';
import { IBrand } from '../../../../common/interfaces/brandManagement';
import * as moment from 'moment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-import-mjtbd-dialog',
  templateUrl: './import-mjtbd-dialog.component.html',
  styleUrls: ['./import-mjtbd-dialog.component.scss']
})

export class ImportMjtbdDialogComponent implements OnInit {
  userInfo;
  documentUpload: FormGroup;
  yearsList=[];
  marketsList=[];
  bugetDataSource: MatTableDataSource<any>;
  displayedColumns = ['select', 'name'];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<Element>(true, []);
  mjtbds=[];
  selectedMJTBD:any;
  mjtbdFormData: IMjForm;
  mjtbdApproverList=[];
  brandObjList: Array<string> = [];
  commsObjList: Array<string> = [];
  behavioralGrowthTargetList: Array<string> = [];
  audienceList: Array<string> = [];
  teamMembersList: Array<ITeamMembers> = [];
  brands: Array<IBrand> = [];
  selectedBrandName:any;
  constructor(private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ImportMjtbdDialogComponent>,
    private brandManagementService: BrandManagementService,
    public globalElementsService: GlobalElementsService,
    private planService: PlanService,
    private sharedData: SharedDataService,
    public dialog: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,) { 
    this.userInfo = JSON.parse(sessionStorage.getItem("user"));
    this.documentUpload = this.formBuilder.group({
      fcYear :[''],
      fcMarket :[''],
      MJTBD :[''],
      MJTBDName:[''],
      brand:['']
    });
  }

  ngOnInit() {
    this.fetchMarkets();
    this.getWorkingYears();
    this.documentUpload.get("fcMarket").patchValue(this.data.market)
    this.documentUpload.get("brand").patchValue(this.data.plan)
    this.documentUpload.get("fcYear").patchValue(this.data.year)
    this.getBrands()
   
  }
  
  fetchMarkets() {
    /*this.brandManagementService.getAllMarkets().subscribe((res: any) => {
      this.marketsList = res.markets;
    });*/
    this.marketsList =  this.userInfo.markets.sort((a, b)=> a.displayName >= b.displayName ? 1 : -1);
  }

  getWorkingYears() {
    this.globalElementsService.getCanvasYears().subscribe((res: Array<number>) => {
      this.yearsList = [];
      res.map(yr => {
        this.yearsList.push({
          value: yr
        })
      })
    });
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  getMJTBDData() {
    const obj = {
      marketid:this.documentUpload.get("fcMarket").value,
      planname: this.selectedBrandName,
      year: this.documentUpload.get("fcYear").value
    }
    if(this.documentUpload.get("fcYear").value !== null && this.selectedBrandName !== null){
    this.planService.getPlanImportData(obj).subscribe((res: IPlanResponse) => {
      if(res){
        this.mjtbds=res.mjtbds;
        this.dataSource= new MatTableDataSource<any>(this.mjtbds);
      }
    });
  //}
  }
  
}

getMJTBDDataName(marketData) {
  this.selectedMJTBD=marketData;
  this.documentUpload.get("MJTBDName").patchValue(marketData.mjtbdname)
}

importSelectedMJTBD() {
for(let i=0; i<this.selection.selected.length;i++){
  const MJTBDSelctedList={
    mjtbdData:this.selection.selected[i],
    year:this.documentUpload.get("fcYear").value
  }
 this.planService.getAndSaveImportMJTBD(MJTBDSelctedList).subscribe((res:any) => {
   this.mjtbdFormData=res.mjForm;
  this.saveMJTBD(this.data.planId,this.data.year)
 });
}
}

saveMJTBD(planId,year) {
  const postObj = this.populatePostObject(planId,year);
  let dialogRef;
  dialogRef = this.dialog.open(MjtbdDialogComponent, {
    data: postObj,
    minWidth: '500px'
  });
  dialogRef.afterClosed().subscribe((res: any) => {
    this.globalElementsService.setSidePanelCancelEvent();
    this.dialog.closeAll();
    
  });


}

populatePostObject(planId,year){
  
  let mjtbdObj: IMjtbdSave = {
    planId,
    year,
    alertURL: `/plan/${planId}`,
    formInfo: {
      mjtbdFormStatus: this.mjtbdFormData.mjtbdFormStatus,
      mjtbdFormTitle: this.mjtbdFormData.mjtbdFormTitle,
      totalMJTBDBudget: 0.00,
      workingMediaBudget: 0.00,
      startDt: moment(this.mjtbdFormData.startDt).isValid() ? this.mjtbdFormData.startDt : null,
      endDt: moment(this.mjtbdFormData.endDt).isValid() ? this.mjtbdFormData.endDt : null,
      commsObjective: this.mjtbdFormData.commsObjective,
      brandObjective: this.mjtbdFormData.brandObjective,
      audiencePriority: this.data.market == this.documentUpload.get("fcMarket").value ? this.mjtbdFormData.audiencePriority : null,
      growthTarget: this.data.market == this.documentUpload.get("fcMarket").value ? this.mjtbdFormData.growthTarget : null,
      associatedFormat: this.mjtbdFormData.associatedFormat,
      documentsPath: "formInfo.documentsPath",
      attachmentsPath: "formInfo.attachmentsPath",
      teamMembers: null,
      approversList: null,
      completedByUserId: this.mjtbdFormData.completedByUserId,
      finalizedByUserID: this.mjtbdFormData.finalizedByUserID,
      currentUserID: this.userInfo.id
    }
  }
    const postObj = {
      mjtbdData: mjtbdObj,
      mode: 'save',
      deafultMsg: 'Saving MJTBD information......Please wait'
    }
    return postObj;
}

getBrands() {
  const selctedMarket = this.documentUpload.get("fcMarket").value;
  this.brandManagementService.getAllBrandsByMkt(selctedMarket).subscribe((res: Array<IBrand>) => {
    this.brands = res;
    this.getBrandName(this.documentUpload.get("brand").value);
  })
}

getBrandName(brandName) {
  this.brands.forEach((el) => {
  if(el.brandName == brandName) {
  this.selectedBrandName=el.brandName;
  this.documentUpload.get("brand").patchValue(el.brandName)
  }
  });

  
  //if(this.data.planId !== this.documentUpload.get("brand").value){
    this.getMJTBDData()
  //}

}

}



/*export interface Element {
  name: string;
  position: number;
  PlannedDate:''
}
const ELEMENT_DATA: Element[] = [
  {position: 1, name: 'Drive awareness of bubly as the most fun sparkling water (awareness)',PlannedDate:''},
  {position: 2, name: 'Drive awareness of bubly as the most fun sparkling water (awareness)',PlannedDate:''},
  {position: 3, name: 'Drive awareness of bubly as the most fun sparkling water (awareness)',PlannedDate:''},
  {position: 4, name: 'Drive awareness of bubly as the most fun sparkling water (awareness)',PlannedDate:''}
  
];*/
