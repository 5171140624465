import { BrandManagementService } from './../../../services/brand-management.service';
import { PAGE_MODES } from './../../../../constants/app-constants';
import { GlobalElementsService } from './../../../services/global-elements.service';
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, OnDestroy } from '@angular/core';
import { MatDialog } from '../../../../../../node_modules/@angular/material';
import { CommonDialogToShowNotificationComponent  } from '../../../../common/components/dialogs/common-dailog-to-show-notification/common-dailog-to-show-notification';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getGpId } from 'app/common/utils/common-util';
import { environment } from 'environments/environment';
import {googleAnalyticsCode} from 'app/util';

@Component({
  selector: 'app-list-tooltip-category',
  templateUrl: './list-tooltip-category.component.html',
  styleUrls: ['./list-tooltip-category.component.scss']
})
export class ListTooltipCategory implements OnInit, OnChanges, OnDestroy {

  @Output() tooltipCatSelctionEvent = new EventEmitter();
  @Output() setHomePageModeEvent = new EventEmitter();
  @Input() pageMode;

  tooltipCategories = [];
  selectedtooltipCategoryList = [];
  isLoading = true;
  errorLoading = false;

  constructor(
    public dialog: MatDialog,
    protected googleTagManagerService: GoogleTagManagerService,
    private globalElementsService:GlobalElementsService
  ) { }

  ngOnInit() {
    this.getTooltipCategories();
    
  }

  ngOnChanges() {
    if (this.pageMode == PAGE_MODES.DELETE) {
      this.handleDelete()
    }
  }

  istooltipCatCodeSelected(tooltipCatCode) {
    return this.selectedtooltipCategoryList.includes(tooltipCatCode)
  }


  selectTooltipCatCode(tooltipCatCode) {

    if (this.selectedtooltipCategoryList.includes(tooltipCatCode)) {
      this.selectedtooltipCategoryList = this.selectedtooltipCategoryList.filter(el => el != tooltipCatCode)
    } else {
      this.selectedtooltipCategoryList.push(tooltipCatCode);
    }

    const marketsList = this.tooltipCategories.filter(mkt => this.selectedtooltipCategoryList.includes(mkt.id))
    this.tooltipCatSelctionEvent.emit(marketsList);

  }




  handleDelete() {

    const tooltipCatList = this.tooltipCategories.filter(mkt => this.selectedtooltipCategoryList.includes(mkt.id));

    // Get the list of markets which has associated portfolios.


    const deleteData =  {
      type: 'delete',
      modulenm: 'tooltipCat',
      apiData: {
        tooltipCatList: tooltipCatList
      }
    };
    const dialogRef = this.dialog.open(CommonDialogToShowNotificationComponent, {
      data: deleteData ,
      minWidth: '500px'
    })


    dialogRef.beforeClose().subscribe(() => {
      this.selectedtooltipCategoryList = [];
      this.getTooltipCategories();
      this.tooltipCatSelctionEvent.emit([]);
      this.setHomePageModeEvent.emit();

    });
  }




  getTooltipCategories() {
    this.isLoading = true;
    this.errorLoading = false;
    this.globalElementsService.getTooltipCatData().subscribe((res:any) => {
      this.isLoading = false;
      this.errorLoading = false;
      this.tooltipCategories = res.data;
    }, () => this.errorLoading = true);

  }

  ngOnDestroy(): void {
  }
}
