import { REFERNCE_CODE, TABLE_KEYS } from "../../constants/overview-constants"

export let constructOverviewTableData = (overviewObj, overviewTableObj) => {
    const tableRow = { ...overviewTableObj }
    if (!overviewObj) {
        return tableRow
    }
    const tempoverviewObj = { ...overviewObj }
    const plan = tempoverviewObj.overview.find(el => el.refernceCode == REFERNCE_CODE.PLAN);
    const actual = tempoverviewObj.overview.find(el => el.refernceCode == REFERNCE_CODE.ACTUAL);
    const target = tempoverviewObj.overview.find(el => el.refernceCode == REFERNCE_CODE.TARGET);
    tableRow.businessUnit = tempoverviewObj.displayName;
    tableRow.totalPlanned = target ? target[TABLE_KEYS.WORKING_MEDIA] : 0;
    tableRow.plannedToDate = plan ? plan[TABLE_KEYS.WORKING_MEDIA] : 0;
    tableRow.actualToDate = actual ? actual[TABLE_KEYS.WORKING_MEDIA] : 0;
    tableRow.plannedROI = plan ? plan[TABLE_KEYS.YTD_ROI] : 0;
    tableRow.actualROI = actual ? actual[TABLE_KEYS.YTD_ROI] : 0;
    tableRow.status=tempoverviewObj.status;
    tableRow.spenDiff = plan ? Number(plan[TABLE_KEYS.WORKING_MEDIA]) != 0 && Number(actual[TABLE_KEYS.WORKING_MEDIA]) != 0 ? (Number(plan[TABLE_KEYS.WORKING_MEDIA]) - Number(actual[TABLE_KEYS.WORKING_MEDIA])) / Number(actual[TABLE_KEYS.WORKING_MEDIA]) : 0 : 0;
    tableRow.roiDiff = plan ? Number(plan[TABLE_KEYS.YTD_ROI]) != 0 ? (Number(actual[TABLE_KEYS.YTD_ROI]) - Number(plan[TABLE_KEYS.YTD_ROI])) / Number(plan[TABLE_KEYS.YTD_ROI]) : 0 : 0;
    return tableRow;
}