import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-update-message-dialog',
  templateUrl: './show-update-message-dialog.component.html',
  styleUrls: ['./show-update-message-dialog.component.scss']
})
export class ShowUpdateMessageDialogComponent implements OnInit {
  message:string;
  constructor() { }

  ngOnInit() {
    this.message = 'Updated Successfully!';
  }

}
