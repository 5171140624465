import * as am4core from "@amcharts/amcharts4/core";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { SharedDataService } from "./common/services/shared-data.service";
import { GLOBAL_ELEMENTS } from "./constants/app-constants";
import { GlobalElementsService } from "./global-elements/services/global-elements.service";
import { CoreService } from "./core/core.service";
import { environment } from "environments/environment";
import { PowerBIDashboardService } from "./common/api/service/powerbidashboard.service";

import { eToken } from "app/common/interfaces/powerbitoken";
import * as moment from "moment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  loader!: { isLoading: boolean };
  title = "MSP";
  opened: boolean;
  isSideNavOpen: boolean;
  accToken: string;
  MINUTES_BEFORE_EXPIRATION = 10;
 @ViewChild("sideNavRef", { static: true }) sideNavRef: any;
  isWide: boolean = false;

  constructor(
    public router: Router,
    private sharedData: SharedDataService,
    private globalElementsService: GlobalElementsService,

    private coreService: CoreService,
    private powerBIDashboardService: PowerBIDashboardService
  ) {}

  ngOnInit(): void {
    am4core.addLicense("CH305766243");
    this.loader = this.coreService.loader;
   //Initiate the power BI access token retrieval process (To establish the connection with the Power BI service using service principal credentials)
  this.powerBIDashboardService.getAuthToken();
    if (environment.production) {
      const script1 = document.createElement("script");
      script1.src = "../assets/appdynamics-prod.js";
      script1.type = "text/javascript";
      document.head.appendChild(script1);
    } else if (
      !environment.production &&
      environment.host.indexOf("localhost") == -1
    ) {
      const script1 = document.createElement("script");
      script1.src = "../assets/appdynamics-nonprod.js";
      script1.type = "text/javascript";
      document.head.appendChild(script1);
    }
    this.globalElementsService.getSidePanelCancelEvent.subscribe(
      () => (this.isSideNavOpen = false)
    );
    this.sharedData.sideNavSelection.subscribe((res) => {
      if (res) {
        this.isWide =
          res["id"] == 17 ||
          res["id"] == 18 ||
          res["id"] == 24 ||
          res["id"] == 27 ||
          res["id"] == 28 ||
          res["id"] == 29 ||
          res["id"] == 30
            ? true
           : false;
      }
      this.isSideNavOpen = !!res;
    });

    this.powerBIDashboardService.castToken.subscribe(
      (accToken) => (this.accToken = accToken)
    );
    //Subscribe to embed token to verify the token expiration and initiate the token refresh process
    this.powerBIDashboardService.castembedToken.subscribe(
      (ereportToken: eToken) => {
        const embedRepToken = ereportToken;
        console.log("inside app this.embedRepToken ", embedRepToken);
        this.checkTokenAndUpdate(embedRepToken.expiry);
      }
    );
  }
  //token refresh In North Central time zone
  checkTokenAndUpdate(tokenExpiration) {
    // Get the current time,
    console.log("tokenExpiration::", tokenExpiration);
    const currentTime = Date.now();
    const expiration = Date.parse(tokenExpiration);
    console.log("currentTime", currentTime);
    const currentTimeInNorthCentral = moment
      .tz("America/Chicago")
      .utc()
      .valueOf();
    console.log("currentTimeInNorthCentral", currentTimeInNorthCentral);

    console.log("expiration", expiration);
    // Time until token expiration in milliseconds
    const timeUntilExpiration =
      expiration - moment.tz("America/Chicago").utc().valueOf();
    const timeToUpdate = this.MINUTES_BEFORE_EXPIRATION * 60 * 1000;
    console.log("timeUntilExpiration", timeUntilExpiration);
    console.log("timeToUpdate", timeToUpdate);
    // Update the token if it is about to expired
    if (timeUntilExpiration > 0 && timeUntilExpiration <= timeToUpdate) {
      this.powerBIDashboardService.getAuthToken();
      // console.log("this.embedRepToken.token", embedRepToken.token);
      // setTimeout(() => {
      //   this.updateToken();
      // }, 18000);
    }
  }

  toggleSideNav() {
    let sidePanelEle = { ...GLOBAL_ELEMENTS.DEFAULT };
    this.sharedData.setSideNavSelection(sidePanelEle);
    this.isSideNavOpen = !this.isSideNavOpen;
  }

}
