import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { GLOBAL_ELEMENTS } from 'app/constants/app-constants';
import { SharedDataService } from 'app/common/services/shared-data.service';
import { IOverTableInfo } from 'app/common/interfaces/overviewSection';
import { isValidUserAccess } from 'app/common/utils/common-util';
import { IPlanInfo } from 'app/common/interfaces/plan';
import { MatDialog } from '@angular/material';
const statusColors={
  draft:'#ACACAC',
  complete:'#091125',
  final:'#0085CA'
};
@Component({
  selector: 'app-overview-table',
  templateUrl: './overview-table.component.html',
  styleUrls: ['./overview-table.component.scss'],
})
export class OverviewTableComponent implements OnInit {
  
  @Input() overviewTableData: IOverTableInfo;
  @Input() tooltipTitleMJTBDSpend:string;
  @Input() workingYear: number;
  @Input() type: string;
  @Input() planInfo: IPlanInfo;
  @Output() activeDeleteMjtbd = new EventEmitter<any>();
  @Input() refrenceId: string;
  @Input() planningYear: number;
  
  userInfo = JSON.parse(sessionStorage.getItem('user'));

  bugetDataSource: MatTableDataSource<any>;
  statusColors=statusColors;
  panelOpenState: boolean;
  showtooltipTitleMJTBDSpend:boolean = false;

  constructor(
    private sharedData: SharedDataService,
    public dialog: MatDialog,
  ) { }

  /*........... Initialization Methods........ */

  ngOnInit() {
    if(this.type == 'plan'){
      this.panelOpenState=true;
    }
    this.overviewTableData.tableData.forEach(el => {
      el.businessUnit = el.businessUnit ? el.businessUnit.toLocaleUpperCase() : '';
      if(el.businessUnit.length > 30){
        el.fullName = el.businessUnit;
        el.businessUnit = el.businessUnit.slice(0,20).concat('...');
      }
    })
    console.log(this.overviewTableData.tableData)
    this.bugetDataSource= new MatTableDataSource<any>(this.overviewTableData.tableData.sort((a,b) => a.businessUnit > b.businessUnit ? 1 : -1));
  }
  showTooltip(){
    this.showtooltipTitleMJTBDSpend = true;
  }
  hideTooltip(){
    this.showtooltipTitleMJTBDSpend = false;
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes && (changes.workingYear && changes.workingYear.currentValue != changes.workingYear.previousValue)) {
      this.bugetDataSource = new MatTableDataSource<any>(this.overviewTableData.tableData);
    }

  }

  /*........... functionality Methods........ */ 
  
  editMjtbdForm(mjtbdId:number){
    let sidePanelEle = { ...GLOBAL_ELEMENTS.NEW_MJTBD };
    const {year, hierarchy, planId, market, plan} = this.planInfo;
    sidePanelEle.mjtbdData={
      year:year,
      hierarchy:hierarchy,
      planId:planId,
      mode:'edit',
      market:market,
      plan:plan,
      mjtbdId
    }
    this.sharedData.setSideNavSelection(sidePanelEle); 
  }

  deleteMjtbdForm(mjtbdId: number) {
    const { year } = this.planInfo;
    const postObj = {
      year,
      mjtbdId,
      mode: 'delete',
    }
    this.activeDeleteMjtbd.emit(postObj);
  }
  returnAccess(_oprator, conditionRoleId) {
    if(this.type.toLowerCase() === 'plan') {
      return isValidUserAccess(conditionRoleId,_oprator, null, this.refrenceId);
    } else if(this.type.toLowerCase() === 'portfolio') {
      return isValidUserAccess(conditionRoleId, _oprator, this.refrenceId);
    } else {
      return isValidUserAccess(conditionRoleId, _oprator);
    }
  
  }
}
